import {locale} from '@res/strings/locale';

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import navigationRoutes from '@navigation/navigationRoutes';
import buildRoute from '@navigation/buildRoute';

const LotteryRulesItem = ({src, content, action = false}) => {
  return (
    <div className="flex flex-row md:flex-col items-center w-full md:w-1/3 mb-4 md:mb-0 md:px-[8px]">
      <img
        src={src}
        className="max-w-[88px] md:max-w-full"
        alt={locale.ALT?.lotteryRule}
      />
      <p className="md:max-w-[293px] pl-[12px] md:px-0 text-[14px] md:text-base text-primary md:text-center leading-[20px] md:leading-base">
        {content}
        {action && (
          <Link className="underline" to={buildRoute(navigationRoutes.TICKETS)}>
            {locale?.personalAccount}
          </Link>
        )}
      </p>
    </div>
  );
};

LotteryRulesItem.propTypes = {
  src: PropTypes.string,
  content: PropTypes.string,
  action: PropTypes.any,
};

export default LotteryRulesItem;
