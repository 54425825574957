import testIds from '@constants/testIds';
import src from '@images/icons/close-icon-inverse.svg';
import {locale} from '@res/strings/locale';
import React from 'react';

const ModalCloseAction = ({handleCloseModal, width = 19}) => {
  return (
    <span className="absolute right-[16px] top-[19px] cursor-pointer z-[1000]">
      <span
        testid={testIds.CLOSE_MODAL}
        className="click-area z-10"
        onClick={handleCloseModal}>
        <img {...{src, alt: locale.ALT?.closeMenuIcon, width}} />
      </span>
    </span>
  );
};

export default ModalCloseAction;
