import PropTypes from 'prop-types';
import LotteryResultsItemList from '@components/LotteryPage/LotteryResultsItemList';
import LotteryResultsMoneyList from '@components/LotteryPage/LotteryResultsMoneyList';
import ContentTitle from '@components/PageContent/ContentTitle';
import {Helmet} from 'react-helmet';
import React from 'react';

const LotteryResults = ({data}) => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet>
      <ContentTitle
        {...{data: data?.meta_results?.h1 ?? data?.title, isHide: true}}
      />
      <LotteryResultsItemList {...{data: data?.item_prizes}} />
      <LotteryResultsMoneyList {...{data: data?.money_prizes}} />
    </>
  );
};

LotteryResults.propTypes = {
  data: PropTypes.any,
};

export default LotteryResults;
