import Button from '@components/Button';
import CheckBox from '@components/FormElements/CheckBox';
import Input from '@components/FormElements/Input';
import InputError from '@components/FormElements/InputError';
import ModalWrapper from '@components/Modals/ModalWrapper';
import {yupResolver} from '@hookform/resolvers/yup';
import useModalControls from '@hooks/useModalControls';
import useModalRegister from '@hooks/useModalRegister';
import promocode_icon from '@images/icons/promocode-icon.svg';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import PromoCodeService from '@services/PromoCodeService';
import {useStore} from '@store/configureStore';
import errorMsgHandler from '@utils/errorMsgHandler';
import {observer} from 'mobx-react-lite';
import qs from 'query-string';
import React, {useEffect, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useLocation} from 'react-router';
import * as yup from 'yup';

const FinishRegistrationModal = ({handleCloseModal}) => {
  useModalRegister(modalRoutes.COMPLETE_REGISTRATION, {
    handleCloseModal,
  });

  const {user} = useStore();
  const {openModal} = useModalControls();
  const location = useLocation();

  const params = qs.parse(location.search);

  useEffect(() => {
    if (!params?.identifier) {
      handleCloseModal();
    }
  }, [params]);

  const registrationFormSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(locale?.ERROR_MESSAGES?.email)
          .required(locale?.ERROR_MESSAGES?.required),
        currency: yup.string(),
        terms: yup
          .bool()
          .required(locale?.ERROR_MESSAGES?.required)
          .oneOf([true], locale?.ERROR_MESSAGES?.termsNotChecked),
        promo_code: yup.string(),
      }),
    [],
  );

  const {
    formState: {errors, isValid},
    handleSubmit,
    control,
    setError,
  } = useForm({
    resolver: yupResolver(registrationFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      currency: window?.GlobalConfig?.currencies[0],
      email: '',
      terms: true,
      receive_email: true,
      receive_sms: true,
      promo_code: PromoCodeService.getPromoCode() ?? '',
    },
  });

  const onSubmit = async (data) => {
    try {
      await user.signUp.run(
        {
          identifier: params?.identifier,
          email: data.email,
          currency: data.currency,
          receive_email: data.receive_email,
          receive_sms: data.receive_sms,
          promo_code: data.promo_code,
        },
        true,
      );
    } catch (errors) {
      errorMsgHandler(errors, {
        setError,
        fields: ['email', 'currency', 'system'],
      });
    }
  };

  return (
    <ModalWrapper {...{handleCloseModal}}>
      <div className="flex flex-col px-[32px] pt-[24px] pb-[50px] h-[100%]">
        <div className="mb-[24px] text-center mt-[15px]">
          <h3 className="text-resolutionBlue font-bold text-[19px] leading-[26px] mb-[11px] capitalize">
            {locale?.register}
          </h3>
          <p className="text-resolutionBlue text-base leading-[19px]">
            {locale?.finishRegistrationMessage}
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="email"
            render={({field: {onChange, value}}) => (
              <Input
                placeholder={locale?.enterEmail}
                type="email"
                {...{onChange, value}}
                isError={errors.email}
                errorMessage={errors.email?.message}
                className="text-[14px]"
              />
            )}
          />
          <div className="relative">
            <Controller
              control={control}
              name="promo_code"
              render={({field: {onChange, value}}) => (
                <Input
                  placeholder={locale?.PLACEHOLDER?.enterPromoCode}
                  type="text"
                  {...{onChange, value}}
                  isError={errors.promo_code}
                  errorMessage={errors.promo_code?.message}
                  className="text-[14px] pl-[45px]"
                />
              )}
            />
            <img
              className="absolute top-[11px] left-[15px]"
              src={promocode_icon}
              alt="promocode"
            />
          </div>
          {/*<Controller
            control={control}
            name="currency"
            render={({field: {onChange, value, ref}}) => (
              <ReactSelect
                inputRef={ref}
                options={currencyOptions}
                value={currencyOptions.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
              />
            )}
          />*/}
          <Controller
            control={control}
            name="terms"
            render={({field: {onChange, value, ref}}) => (
              <CheckBox
                label={locale?.termsLabel}
                inputRef={ref}
                isError={errors.terms}
                errorMessage={errors.terms?.message}
                linkText={locale?.termsLinkText}
                linkUrl={navigationRoutes.TERMS_AND_CONDITIONS}
                {...{value, onChange}}
              />
            )}
          />

          {!!errors.system && <InputError message={errors.system?.message} />}

          <Button
            disabled={user.signUp?.inProgress || !isValid}
            htmlType="submit"
            title={
              user.signUp?.inProgress ? locale?.processing : locale?.doRegister
            }
            className="w-full h-[40px] text-[14px] font-bold leading-5 py-[10px] rounded-[5px] mt-[33px] uppercase"
            type="primary"
          />
        </form>
      </div>
    </ModalWrapper>
  );
};

export default observer(FinishRegistrationModal);
