import classNames from 'classnames';
import PropTypes from 'prop-types';

const BannerStatus = ({title = '', className = 'hidden'}) => {
  return (
    <div className="position-inherit top-4 left-4 xl:top-6 xl:left-6">
      <div
        className={classNames(
          'px-3 text-[14px] leading-[34px] font-bold uppercase rounded-[4px]',
          className,
        )}>
        {title}
      </div>
    </div>
  );
};

BannerStatus.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default BannerStatus;
