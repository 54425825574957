import 'react-toastify/dist/ReactToastify.min.css';
import './style.css';
import React from 'react';
import {ToastContainer} from 'react-toastify';

const NotificationContainer = () => {
  return (
    <ToastContainer
      bodyClassName={() => 'w-full'}
      limit={5}
      className="pointer-events-none"
    />
  );
};

export default NotificationContainer;
