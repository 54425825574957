import cashBoxConfig from '@constants/cashBoxConfig';
import {locale} from '@res/strings/locale';
import * as yup from 'yup';

const useCashBoxFormSchema = ({fields, max, min}) => {
  const schema = {};
  const textFields = fields?.filter(
    (el) =>
      el.type === cashBoxConfig.FORM_FIELDS.TYPES.TEXT ||
      el.type === cashBoxConfig.FORM_FIELDS.TYPES.EMAIL ||
      el.type === cashBoxConfig.FORM_FIELDS.TYPES.PHONE,
  );
  if (textFields) {
    textFields?.map((field) => {
      if (
        field?.field_values?.length > 0 &&
        field?.field_name === cashBoxConfig.FORM_FIELDS.NAMES.WALLET
      ) {
        schema[cashBoxConfig.FORM_FIELDS.NAMES.SAVED] = yup
          .string()
          .required(locale?.ERROR_MESSAGES?.required);
      } else if (field.name === cashBoxConfig.FORM_FIELDS.NAMES.AMOUNT) {
        schema.amount = yup
          .number()
          .typeError(locale?.ERROR_MESSAGES?.numberTypeError)
          .required(locale?.ERROR_MESSAGES?.required)
          .min(min, `${locale?.ERROR_MESSAGES?.amountMin} ${min}`)
          .max(max, `${locale?.ERROR_MESSAGES?.amountMax} ${max}`);
      } else if (field.name === cashBoxConfig.FORM_FIELDS.NAMES.EMAIL) {
        schema[field.name] = yup
          .string()
          .email(locale?.ERROR_MESSAGES?.email)
          .required(locale?.ERROR_MESSAGES?.required);
      } else if (
        field?.name === cashBoxConfig.FORM_FIELDS.NAMES.SECURITY_ANSWER
      ) {
        schema[field.name] = yup
          .string()
          .min(6, locale?.ERROR_MESSAGES?.secretAnswerMin)
          .required(locale?.ERROR_MESSAGES?.required)
          .matches(
            cashBoxConfig.REGEX.INTERAC_SECRET_ANSWER,
            locale?.ERROR_MESSAGES?.secretAnswerMathces ||
              'Password must contain only characters and numbers',
          );
      } else {
        if (field?.field_name === cashBoxConfig.FORM_FIELDS.NAMES.WALLET) {
          schema[field.field_name] = yup
            .string()
            .required(locale?.ERROR_MESSAGES?.required);
        } else {
          if (field?.name === cashBoxConfig.FORM_FIELDS.NAMES.FIELD) {
            if (
              field?.field_name === cashBoxConfig.FORM_FIELDS.NAMES.CARD_NUMBER
            ) {
              schema[field.field_name] = yup
                .string()
                .matches(
                  cashBoxConfig.REGEX.CARD_NUMBER,
                  locale?.ERROR_MESSAGES?.cardNumber,
                )
                .required(locale?.ERROR_MESSAGES?.required);
            } else {
              schema[field.field_name] = yup
                .string()
                .required(locale?.ERROR_MESSAGES?.required);
            }
          } else {
            schema[field.name] = yup
              .string()
              .required(locale?.ERROR_MESSAGES?.required);
          }
        }
      }
    });
  }

  return yup.object().shape(schema);
};

export default useCashBoxFormSchema;
