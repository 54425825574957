import {apiCall, Endpoints, Methods} from '@api';
import cashBoxConfig from '@constants/cashBoxConfig';
import {locale} from '@res/strings/locale';
import NotificationService from '@services/NotificationService';
import {returningAsyncAction} from '@store/helpers/asyncAction';
import {types} from 'mobx-state-tree';

export const CashBoxState = {};

function withdrawal({fields, currency, cashBoxItemUid}) {
  return async function flow(store) {
    try {
      const body = {
        type: cashBoxConfig.METHODS_TYPES.WITHDRAWAL,
        ...fields,
        currency,
        cashbox_item_uid: cashBoxItemUid,
      };
      delete body.paymentMethodUid;

      await apiCall({
        endpoint: Endpoints.CREATE_PAYMENT,
        method: Methods.POST,
        data: body,
        useAuth: true,
        useLocale: false,
      });

      NotificationService.success({
        notification: locale?.NOTIFICATIONS?.withdrawalSuccess,
      });
    } catch (e) {
      NotificationService.error({
        notification: locale?.NOTIFICATIONS?.withdrawalError,
      });
    }
  };
}

const CashBox = types.model({
  withdrawal: returningAsyncAction(withdrawal),
});

export default CashBox;
