import Cookies from 'js-cookie';

const ReferrerService = {
  init: async () => {
    try {
      return new Promise((resolve) => {
        const tlCookie = Cookies.get('tl');
        const trCookie = Cookies.get('tr');

        let url = new URL(document.location.href);

        if (!tlCookie) {
          Cookies.set('tl', document.location.href, {expires: 30});
        }
        if (!trCookie) {
          Cookies.set('tr', document.referrer, {expires: 30});
        }

        url.searchParams.delete('tl');

        window.history.pushState('', '', url.toString());

        resolve();
      });
    } catch (e) {
      console.error('ERR_INIT_REFERRER:', e);
    }
  },
};

export default ReferrerService;
