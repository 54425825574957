import ChapterImage from '@components/PageContent/ChapterImage';
import ChapterImagesContainer from '@components/PageContent/ChapterImagesContainer';
import ChapterImagesPagination from '@components/PageContent/ChapterImagesPagination';
import ChapterImagesZoom from '@components/PageContent/ChapterImagesZoom';
import ContentButton from '@components/PageContent/ContentButton';
import ParagraphTitle from '@components/PageContent/ParagraphTitle';
import Flicking from '@egjs/react-flicking';
import useWindowSize from '@hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';

const ChapterImages = ({title, data, button}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const windowSize = useWindowSize();
  const sliderRef = useRef(null);

  useEffect(() => {
    sliderRef?.current?.resize();
  }, [windowSize]);

  return (
    <>
      <ParagraphTitle {...{title}} />
      <ChapterImagesContainer>
        <Flicking
          ref={sliderRef}
          overflow={false}
          collectStatistics={false}
          onChange={(e) => {
            setActiveIndex(e?.index);
          }}
          zIndex={1}>
          {!!data?.length &&
            data?.map((item, key) => <ChapterImage {...{url: item, key}} />)}
        </Flicking>
        <ChapterImagesPagination {...{data, activeIndex, sliderRef}} />
        <ChapterImagesZoom {...{data, index: activeIndex}} />
      </ChapterImagesContainer>
      <ContentButton {...{data: button}} />
    </>
  );
};

ChapterImages.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any.isRequired,
  button: PropTypes.any,
};

export default ChapterImages;
