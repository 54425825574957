import {locale} from '@res/strings/locale';
import navigationRoutes from '@navigation/navigationRoutes';
import buildRoute from '@navigation/buildRoute';
import formatAmount from '@utils/formatAmount';
import mainPrize_icon from '@images/icons/tournament-mainPrize-icon.svg';
import date_icon from '@images/icons/tournament-date-icon.svg';
import prizePlaces_icon from '@images/icons/tournament-prizePlaces-icon.svg';
import format from 'date-fns/format';
import rule_1 from '@images/lottery/lottery-rule-1.png';
import rule_2 from '@images/lottery/lottery-rule-2.png';
import rule_3 from '@images/lottery/lottery-rule-3.png';
import ticket_gold_lg from '@images/lottery/ticket-gold-lg.png';
import ticket_silver_lg from '@images/lottery/ticket-silver-lg.png';
import ticket_bronze_lg from '@images/lottery/ticket-bronze-lg.png';

export default {
  PRIZES: 'prizes',
  RESULTS: 'results',

  STATUS: {
    NEW: 'new',
    PUBLISHED: 'published',
    IN_PROGRESS: 'in_progress',
    CLOSED: 'closed',
    FINISHED: 'finished',
    CANCELED: 'canceled',
    ARCHIVED: 'archived',
  },
  PREVIEW_STATUS: 'published',
  ACTIVE_STATUS: 'in_progress',
  ARCHIVED_STATUS: '',

  REFETCH_INTERVAL: 5000,

  INIT_PAGINATION_LIMIT: 10,
  PAGINATION_LIMIT: 6,

  FILTER: {
    CURRENT: (i) => i?.status === 'published' || i?.status === 'in_progress',
    COMPLETED: (i) => i?.status !== 'published' && i?.status !== 'in_progress',
  },

  STATUS_BADGE: function (status) {
    const config = {
      [this.STATUS?.PUBLISHED]: {
        title: locale?.BANNER_STATUSES?.planned,
        className: 'bg-denim text-white',
      },
      [this.STATUS?.IN_PROGRESS]: {
        title: locale?.BANNER_STATUSES?.lottery_in_progress,
        className: 'bg-gold text-stratosDarker',
      },
      [this.STATUS?.CLOSED]: {
        title: locale?.BANNER_STATUSES?.lottery_finished,
        className: 'bg-prussianBlueLight text-white',
      },
      [this.STATUS?.FINISHED]: {
        title: locale?.BANNER_STATUSES?.lottery_finished,
        className: 'bg-prussianBlueLight text-white',
      },
      [this.STATUS?.ARCHIVED]: {
        title: locale?.BANNER_STATUSES?.lottery_finished,
        className: 'bg-prussianBlueLight text-white',
      },
      [this.STATUS?.CANCELED]: {
        title: '',
        className: 'hidden',
      },
    };
    return config[status] ?? {title: '', className: 'hidden'};
  },

  TABS: function (data, slug) {
    const resultTab =
      data?.status === this.STATUS?.FINISHED ||
      data?.status === this.STATUS?.ARCHIVED
        ? [
            {
              to: buildRoute(navigationRoutes.LOTTERY, {
                slug,
                route: this?.RESULTS,
              }),
              title: locale?.BUTTONS_TITLES?.results,
            },
          ]
        : [];

    return [
      {
        to: buildRoute(navigationRoutes.LOTTERY_RULES, {slug}),
        title: locale?.BUTTONS_TITLES?.rules,
      },
      {
        to: buildRoute(navigationRoutes.LOTTERY, {
          slug,
          route: this?.PRIZES,
        }),
        title: locale?.BUTTONS_TITLES?.prizes,
      },
      ...resultTab,
    ];
  },

  RULES_PARAMS: function (data, currency) {
    return [
      {
        icon: date_icon,
        title: locale?.LOTTERY_PARAMS?.date,
        data: this.setDate(data?.start_at, data?.end_at),
        width: 'w-full sm:w-[calc(33.333333%-16px)]',
      },
      {
        icon: mainPrize_icon,
        title: locale?.LOTTERY_PARAMS?.mainPrize,
        data:
          data?.money_prizes?.length > 0 &&
          formatAmount({
            amount: data?.money_prizes[0]?.prize?.[currency] ?? 0,
            minimumFractionDigits: 0,
            currency,
          }),
        width: 'w-[calc(50%-4px)] sm:w-[calc(33.333333%-16px)]',
      },
      {
        icon: prizePlaces_icon,
        title: locale?.LOTTERY_PARAMS?.prizePlaces,
        data: data?.prizes_count,
        width: 'w-[calc(50%-4px)] sm:w-[calc(33.333333%-16px)]',
      },
    ];
  },

  RULES_LIST: function (data) {
    /* TODO: add link to profile tickets */
    return [
      {src: rule_1, content: data?.rules?.rules?.rule1},
      {src: rule_2, content: data?.rules?.rules?.rule2, action: true},
      {src: rule_3, content: data?.rules?.rules?.rule3},
    ];
  },

  TICKET_ICONS: {
    gold: ticket_gold_lg,
    silver: ticket_silver_lg,
    bronze: ticket_bronze_lg,
  },

  TICKET_INFO: function (data, currency) {
    return [
      {
        icon: ticket_gold_lg,
        title: locale?.LOTTERY_PARAMS?.golden,
        amount: formatAmount({
          amount: data?.gold?.[currency] / 100,
          currency,
          minimumFractionDigits: 0,
        }),
        className:
          'flex flex-wrap sm:justify-center items-center w-full sm:w-1/2 lg:w-1/3 h-[112px] sm:h-[64px]',
      },
      {
        icon: ticket_silver_lg,
        title: locale?.LOTTERY_PARAMS?.silver,
        amount: formatAmount({
          amount: data?.silver?.[currency] / 100,
          currency,
          minimumFractionDigits: 0,
        }),
        className:
          'flex flex-wrap sm:justify-center items-center w-full sm:w-1/2 lg:w-1/3 h-[112px] sm:h-[64px] border-dashed border-t-2 sm:border-t-0 border-b-2 sm:border-b-0 sm:border-l-2 lg:border-r-2',
      },
      {
        icon: ticket_bronze_lg,
        title: locale?.LOTTERY_PARAMS?.bronze,
        amount: formatAmount({
          amount: data?.bronze?.[currency] / 100,
          currency,
          minimumFractionDigits: 0,
        }),
        className:
          'flex flex-wrap sm:justify-center items-center w-full lg:w-1/3 h-[112px] sm:h-[64px] sm:mt-4 lg:mt-0',
      },
    ];
  },

  WINNERS_COUNT: function (data) {
    const result = [];

    data?.map((i) => {
      result?.push(i?.winners?.length);
    });
    return result;
  },

  setDate: (start, end) => {
    const s = new Date(start);
    const e = new Date(end);

    return `${format(s, 'dd')}.${format(s, 'MM')} - ${format(e, 'dd')}.${format(
      e,
      'MM',
    )}`;
  },
};
