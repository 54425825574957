export default {
  TAB_NAMES: {
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal',
    HISTORY: 'history',
  },
  METHODS_TYPES: {
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal',
  },
  METHOD_NAME: {
    VOUCHER: 'voucher',
  },
  FORM_FIELDS: {
    TYPES: {
      TEXT: 'text',
      SUBMIT: 'submit',
      STATIC: 'static',
      CHOICE: 'choice',
      EMAIL: 'email',
      PHONE: 'phone',
    },
    NAMES: {
      AMOUNT: 'amount',
      QR: 'qr',
      WARNING: 'warning',
      INFO: 'info',
      CARD_NUMBER: 'card_number',
      SAVED: 'saved',
      SECURITY_QUESTION: 'security_question',
      SECURITY_ANSWER: 'security_answer',
      EMAIL: 'email',
      WALLET: 'wallet',
      FIELD: 'field',
    },
  },
  REGEX: {
    CARD_NUMBER: /^[0-9\s]{8,19}$/,
    SPACES: /\s/g,
    INTERAC_SECRET_ANSWER: /^[a-zA-Z0-9]+$/,
  },
};
