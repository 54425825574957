import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';

const BannerBaseWrapper = ({height, rounded = 'rounded-[10px]', children}) => {
  return (
    <div
      className={classNames(
        'relative w-auto bg-midnight overflow-hidden',
        height,
        rounded,
      )}>
      {children}
    </div>
  );
};

BannerBaseWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  rounded: PropTypes.string,
};

export default BannerBaseWrapper;
