import Button from '@components/Button';
import SelectedOffers from '@components/CashBox/Deposit/SelectedOffers';
import Input from '@components/FormElements/Input';
import ReactCreatableSelect from '@components/FormElements/ReactCreatableSelect';
import ReactSelect from '@components/FormElements/ReactSelect';
import SuggestionsInput from '@components/FormElements/SuggestionsInput';
import analyticsEvents from '@constants/analyticsEvents';
import buttonActions from '@constants/buttonActions';
import cashBoxConfig from '@constants/cashBoxConfig';
import {yupResolver} from '@hookform/resolvers/yup';
import useCashBoxFormSchema from '@hooks/useCashBoxFormSchema';
import useFieldsWatch from '@hooks/useFieldsWatch';
import useModalControls from '@hooks/useModalControls';
import useWindowSize from '@hooks/useWindowSize';
import buildRoute from '@navigation/buildRoute';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import AnalyticsService from '@services/AnalyticsService';
import {useStore} from '@store/configureStore';
import composeUrl from '@utils/composeUrl';
import formatAmount from '@utils/formatAmount';
import getDevice from '@utils/getDevice';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Controller, useForm} from 'react-hook-form';
import {BiCopy} from 'react-icons/bi';
import QRCode from 'react-qr-code';

const FormBuilder = ({paymentMethod, methodType}) => {
  const {user, offers, cashBox} = useStore();
  const {openModal} = useModalControls();
  const [copied, setCopied] = useState(false);
  const [isAmountField, setAmountField] = useState(false);
  const [offerParams, setOfferParams] = useState({});
  const size = useWindowSize();
  const isMobile = useMemo(() => getDevice() !== 'desktop', [size]);
  const cashBoxFormSchema = useCashBoxFormSchema({
    fields: paymentMethod?.form_fields,
    min: paymentMethod?.amounts?.min,
    max: paymentMethod?.amounts?.max,
  });
  const {
    formState: {errors, isValid},
    setValue,
    control,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(cashBoxFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const fieldsWatch = useFieldsWatch(watch, paymentMethod?.form_fields);

  const composePaymentUrl = () => {
    return composeUrl(buildRoute(navigationRoutes.DEPOSIT_REDIRECT), {
      ...fieldsWatch,
      ...offerParams,
      paymentMethodUid: paymentMethod?.uid,
    });
  };

  const handleWithdrawal = async () => {
    if (fieldsWatch?.card_number) {
      fieldsWatch.card_number = fieldsWatch?.card_number?.replace(
        cashBoxConfig.REGEX.SPACES,
        '',
      );
    }
    try {
      await cashBox?.withdrawal.run({
        fields: fieldsWatch,
        currency: user?.profile?.currency,
        cashBoxItemUid: paymentMethod?.uid,
      });
      openModal(modalRoutes.CASH_BOX_HISTORY);
    } catch (error) {
      console.error('HANDLE_WITHDRAWAL_ERROR', error);
    }
  };

  const setDefaultValues = () => {
    if (paymentMethod?.form_fields) {
      paymentMethod?.form_fields?.map((field) => {
        if (
          field?.type === cashBoxConfig.FORM_FIELDS.TYPES.TEXT ||
          field?.type === cashBoxConfig.FORM_FIELDS.TYPES.EMAIL ||
          field?.type === cashBoxConfig.FORM_FIELDS.TYPES.PHONE ||
          field?.type === cashBoxConfig.FORM_FIELDS.TYPES.CHOICE
        ) {
          setValue(
            field?.field_name ? field?.field_name : field.name,
            field?.data ?? '',
            {
              shouldValidate: !!field?.data,
            },
          );
        }
        if (field?.field_values?.length > 0) {
          setValue(cashBoxConfig.FORM_FIELDS.NAMES.SAVED, '', {
            shouldValidate: !!field?.data,
          });
        }
      });
    }
  };

  const checkAmountField = () => {
    if (paymentMethod?.form_fields) {
      paymentMethod?.form_fields?.map((field) => {
        if (field?.name === cashBoxConfig.FORM_FIELDS.NAMES.AMOUNT) {
          setAmountField(true);
        }
      });
    }
  };

  const getOptions = (items, isCustom = false) => {
    const options = [];
    if (items) {
      items?.map((item) => {
        if (isCustom) {
          options.push({value: item?.value, label: item?.title});
        } else {
          options.push({value: item, label: item});
        }
      });
    }
    return options;
  };

  useEffect(() => {
    copied && setTimeout(() => setCopied(false), 500);
  }, [copied]);

  useEffect(() => {
    if (methodType === cashBoxConfig.METHODS_TYPES.DEPOSIT) {
      AnalyticsService.logEvent(analyticsEvents.DEPOSIT_FORM_SHOW);
    }
    if (methodType === cashBoxConfig.METHODS_TYPES.WITHDRAWAL) {
      AnalyticsService.logEvent(analyticsEvents.WITHDRAWAL_FORM_SHOW);
    }
  }, [methodType]);

  useEffect(() => {
    paymentMethod && checkAmountField();
    return () => setAmountField(false);
  }, []);

  const getFormFields = () => {
    return paymentMethod?.form_fields?.map((field, index) => {
      if (field?.type === cashBoxConfig.FORM_FIELDS.TYPES.SUBMIT) {
        return (
          <Button
            key={index}
            htmlType="submit"
            className="uppercase h-[40px] w-full mt-[32px] mb-[30px] font-bold"
            disabled={!isValid || cashBox?.withdrawal?.inProgress}
            isLink={methodType !== cashBoxConfig.METHODS_TYPES.WITHDRAWAL}
            action={
              methodType === cashBoxConfig.METHODS_TYPES.WITHDRAWAL
                ? undefined
                : buttonActions.REDIRECT
            }
            actionData={
              methodType === cashBoxConfig.METHODS_TYPES.WITHDRAWAL
                ? undefined
                : composePaymentUrl()
            }
            onClick={() =>
              methodType === cashBoxConfig.METHODS_TYPES.WITHDRAWAL
                ? handleWithdrawal()
                : openModal(modalRoutes.CASH_BOX_HISTORY)
            }
            target={
              methodType === cashBoxConfig.METHODS_TYPES.WITHDRAWAL
                ? undefined
                : '_blank'
            }
            title={
              cashBox?.withdrawal?.inProgress
                ? locale?.processing
                : `${
                    methodType === cashBoxConfig.METHODS_TYPES.WITHDRAWAL
                      ? locale?.take
                      : !isAmountField
                      ? locale?.deposit
                      : locale?.topUp
                  } ${
                    fieldsWatch?.amount
                      ? formatAmount({
                          amount: fieldsWatch?.amount,
                          minimumFractionDigits: 0,
                          currency: user?.profile?.currency,
                        })
                      : ''
                  }`
            }
            type="primary"
          />
        );
      }
      if (field?.type === cashBoxConfig.FORM_FIELDS.TYPES.STATIC) {
        if (field?.name === cashBoxConfig.FORM_FIELDS.NAMES.QR) {
          return (
            <div key={index} className="flex flex-col items-center">
              {/*<p className="text-resolutionBlue mb-[12px] text-center text-[14px]">*/}
              {/*  {locale?.copyQrMessage}*/}
              {/*</p>*/}
              <a
                href={
                  field?.prefix
                    ? `${field?.prefix}:${field?.field_value?.title}`
                    : field?.field_value?.title
                }
                target="_blank">
                <span className="cursor-pointer">
                  <QRCode
                    value={
                      field?.prefix
                        ? `${field?.prefix}:${field?.field_value?.title}`
                        : field?.field_value?.title
                    }
                    size={160}
                  />
                </span>
              </a>
              <p className="text-resolutionBlue mt-[12px] text-center text-[14px]">
                {locale?.personalCryptoWallet}
              </p>
              <div className="shadow-suggestionDefault rounded-[5px] flex flex-col items-center w-full px-[32px] py-[18px] my-[10px]">
                <p className="text-resolutionBlue mb-[12px] text-center text-[15px] font-bold break-all">
                  {field?.field_value?.title}
                </p>
                <CopyToClipboard
                  text={field?.field_value?.title}
                  onCopy={() => setCopied(true)}>
                  <p
                    className={classNames(
                      {'scale-105 duration-300': copied},
                      'text-denim font-bold text-center text-[14px] uppercase flex flex-row items-center cursor-pointer',
                    )}>
                    <BiCopy size="20" className="mr-[5px]" />
                    {locale?.copyActionText}
                  </p>
                </CopyToClipboard>
              </div>
            </div>
          );
        }
        if (field?.name === cashBoxConfig.FORM_FIELDS.NAMES.WARNING) {
          return (
            <p
              key={index}
              className="text-poloBlue mt-[18px] text-center text-[12px]"
              dangerouslySetInnerHTML={{__html: field?.data}}
            />
          );
        }
        if (
          field?.name === cashBoxConfig.FORM_FIELDS.NAMES.INFO &&
          paymentMethod?.method !== cashBoxConfig.METHOD_NAME.VOUCHER
        ) {
          return (
            <p
              key={index}
              className="text-resolutionBlue mb-[12px] text-center text-[14px]"
              dangerouslySetInnerHTML={{__html: field?.data}}
            />
          );
        }
        if (paymentMethod?.method === cashBoxConfig.METHOD_NAME.VOUCHER) {
          if (field?.name === cashBoxConfig.FORM_FIELDS.NAMES.INFO) {
            return (
              <p
                key={index}
                className="text-resolutionBlue mt-[20px] text-center text-[14px]"
                dangerouslySetInnerHTML={{__html: field?.data}}
              />
            );
          }
        }
      }
      if (field?.type === cashBoxConfig.FORM_FIELDS.TYPES.CHOICE) {
        const options = getOptions(field?.field_values);

        return (
          <div key={index}>
            {field?.label && (
              <p className="text-poloBlue mb-[5px] text-[12px] text-left">
                {field?.label}
              </p>
            )}
            <div className="bg-hawkesBlue rounded-[5px] mb-[10px]">
              <Controller
                control={control}
                name="security_question"
                render={({field: {onChange, value, ref}}) => (
                  <ReactSelect
                    isSearchable={true}
                    placeholder={field?.placeholder ?? ''}
                    inputRef={ref}
                    options={options}
                    value={options.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                  />
                )}
              />
            </div>
          </div>
        );
      }
      if (field?.type === cashBoxConfig.FORM_FIELDS.TYPES.TEXT) {
        if (
          field?.name === cashBoxConfig.FORM_FIELDS.NAMES.AMOUNT &&
          methodType !== cashBoxConfig.METHODS_TYPES.WITHDRAWAL
        ) {
          return (
            <SuggestionsInput
              key={index}
              min={paymentMethod?.amounts?.min}
              max={paymentMethod?.amounts?.max}
              defaultStep={paymentMethod?.amounts?.default}
              suggestions={paymentMethod?.amounts?.suggestions}
              {...{errors, control, setValue, getValues}}
            />
          );
        } else if (
          field?.name !== cashBoxConfig.FORM_FIELDS.NAMES.AMOUNT &&
          field?.field_values?.length > 0 &&
          methodType === cashBoxConfig.METHODS_TYPES.WITHDRAWAL
        ) {
          const options = getOptions(field?.field_values, true);
          return (
            <div key={index}>
              <div>
                {field?.label && (
                  <p className="text-poloBlue mb-[10px] text-[12px]">
                    {field?.label}
                  </p>
                )}
                <Controller
                  key={index}
                  control={control}
                  name={field?.field_name ? field?.field_name : field.name}
                  render={({field: {onChange, value}}) => (
                    <Input
                      type={field?.type}
                      placeholder={field?.placeholder ?? ''}
                      value={value ?? ''}
                      {...{onChange}}
                      isError={
                        errors[
                          field?.field_name ? field?.field_name : field.name
                        ]
                      }
                      errorMessage={
                        errors[
                          field?.field_name ? field?.field_name : field.name
                        ]?.message
                      }
                      className="text-[14px]"
                    />
                  )}
                />
              </div>
              {field?.field_name !==
                cashBoxConfig.FORM_FIELDS.NAMES.SECURITY_ANSWER && (
                <div>
                  <p className="text-poloBlue mb-[5px] text-[12px] text-left">
                    {locale?.selectCardNumber}
                  </p>
                  <div className="bg-hawkesBlue rounded-[5px] mb-[10px]">
                    <Controller
                      control={control}
                      name={cashBoxConfig.FORM_FIELDS.NAMES.SAVED}
                      render={({field: {onChange, value, ref}}) => (
                        <ReactCreatableSelect
                          isSearchable={true}
                          placeholder={field?.placeholder ?? ''}
                          inputRef={ref}
                          options={options}
                          value={options.find((c) => c.value === value)}
                          onChange={(val) => {
                            if (val) {
                              onChange(val.value);
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        } else {
          return (
            <div key={index}>
              {field.name !== cashBoxConfig.FORM_FIELDS.NAMES.AMOUNT &&
                field?.label && (
                  <p className="text-poloBlue mt-[10px] mb-[5px] text-[12px] text-left">
                    {field?.label}
                  </p>
                )}
              <Controller
                control={control}
                name={field?.field_name || field.name}
                render={({field: {onChange, value, ref}}) => (
                  <Input
                    type={field.type}
                    placeholder={field?.placeholder ?? ''}
                    value={value ?? ''}
                    {...{onChange}}
                    isError={errors[field?.field_name || field.name]}
                    errorMessage={
                      errors[field?.field_name || field.name]?.message
                    }
                    className={classNames(
                      {
                        'h-[56px] font-bold text-center text-[19px] leading-[32px] text-resolutionBlue':
                          methodType ===
                            cashBoxConfig.METHODS_TYPES.WITHDRAWAL &&
                          field?.name ===
                            cashBoxConfig.FORM_FIELDS.NAMES.AMOUNT,
                      },
                      'text-[14px]',
                    )}
                  />
                )}
              />
            </div>
          );
        }
      }
      if (field?.type === cashBoxConfig.FORM_FIELDS.TYPES.EMAIL) {
        return (
          <React.Fragment key={index}>
            <p className="text-poloBlue mt-[30px] mb-[10px] text-[12px]">
              {locale?.emailForCheck}
            </p>
            <Controller
              control={control}
              name={field?.name}
              render={({field: {onChange, value}}) => (
                <Input
                  placeholder={field?.placeholder ?? ''}
                  type={field?.type}
                  value={value ?? ''}
                  {...{onChange}}
                  isError={errors[field?.name]}
                  errorMessage={errors[field?.name]?.message}
                  className="text-[14px]"
                />
              )}
            />
          </React.Fragment>
        );
      }
      if (field?.type === cashBoxConfig.FORM_FIELDS.TYPES.PHONE) {
        if (field?.field_values?.length > 0) {
          const options = getOptions(field?.field_values, true);
          return (
            <React.Fragment key={index}>
              <p className="text-poloBlue mb-[5px] mt-[30px] text-[12px] text-left">
                {locale?.selectPhoneNumberAccount}
              </p>
              <div className="bg-hawkesBlue rounded-[5px] mb-[10px]">
                <Controller
                  control={control}
                  name={cashBoxConfig.FORM_FIELDS.NAMES.SAVED}
                  render={({field: {onChange, value, ref}}) => (
                    <ReactCreatableSelect
                      placeholder={field?.placeholder ?? ''}
                      inputRef={ref}
                      options={options}
                      value={options.find((c) => c.value === value)}
                      onChange={(val) => {
                        if (val) {
                          onChange(val.value);
                        }
                      }}
                    />
                  )}
                />
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              {field?.label && (
                <p className="text-poloBlue mt-[30px] mb-[10px] text-[12px]">
                  {field?.label}
                </p>
              )}
              <Controller
                control={control}
                name={field?.field_name || field.name}
                render={({field: {onChange, value}}) => (
                  <Input
                    type={field?.type}
                    value={value ?? ''}
                    placeholder={field?.placeholder ?? ''}
                    {...{onChange}}
                    isError={errors[field?.field_name || field.name]}
                    errorMessage={
                      errors[field?.field_name || field.name]?.message
                    }
                    className="text-[14px]"
                  />
                )}
              />
            </React.Fragment>
          );
        }
      }
    });
  };

  useEffect(() => {
    if (paymentMethod) {
      setDefaultValues();
    }
  }, [paymentMethod]);

  return (
    <>
      <img
        src={paymentMethod?.image}
        alt={paymentMethod?.uid}
        className="m-auto mt-[40px] mb-[25px] max-w-[150px] max-h-[100px]"
      />
      <div
        className={classNames({'w-[360px] max-w-full': !isMobile}, 'w-full')}>
        {methodType !== 'withdrawal' && (
          <SelectedOffers
            {...{offers, paymentMethod, user, getValues, setOfferParams}}
          />
        )}
      </div>
      <p className="text-center text-resolutionBlue text-[15px] font-bold mb-[14px]">
        {methodType === 'deposit'
          ? isAmountField && locale?.selectDepositAmount
          : locale?.selectWithdrawalAmount}
      </p>
      <div className="w-full">{paymentMethod && getFormFields()}</div>
    </>
  );
};

FormBuilder.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  methodType: PropTypes.string,
};

export default observer(FormBuilder);
