import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import regionTypes from '@constants/regionTypes';
import React from 'react';

const OfferInfoWelcome = ({user, data, game}) => {
  return (
    <div>
      <p className="mt-0 mb-[10px] pr-[34px] text-resolutionBlue text-[20px] leading-[22px] font-bold">
        {locale?.OFFER_INFO?.welcomeTitle?.replace(
          '{order}',
          locale?.ORDINAL_NUMBERS?.[data?.order],
        )}
      </p>
      <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px] font-bold">
        {locale?.OFFER_INFO?.welcomeRulesTitle
          ?.replace('{deposit_percents}', data?.bonus?.deposit_percents)
          .replace(
            '{spins_count}',
            data?.free_spin?.spins_count
              ? ` ${locale?.OFFER_INFO?.and} ${data?.free_spin?.spins_count} ${locale?.OFFER_INFO?.giftSpin}`
              : '',
          )}
      </p>
      <ol className="list-decimal pl-5 text-resolutionBlue text-[14px] leading-[20px]">
        <li className="mb-2">{locale?.OFFER_INFO?.emailRule}</li>
        <li className="mb-2">
          {`${locale?.OFFER?.makeDeposit} `}
          {formatAmount({
            amount: data?.bonus?.min_deposit / 100,
            currency: user?.profile?.currency,
            minimumFractionDigits: 0,
          })}
          <br />
          {locale?.OFFER_INFO?.welcomeRuleBonuse
            ?.replace('{deposit_percents}', `${data?.bonus?.deposit_percents}%`)
            .replace(
              '{max_bonus_limit}',
              formatAmount({
                amount: data?.bonus?.max_bonus_limit / 100,
                currency: user?.profile?.currency,
                minimumFractionDigits: 0,
              }),
            )
            .replace('{wager}', data?.bonus?.wager)
            .replace(
              '{max_cashout_money}',
              data?.bonus?.max_cashout_percents > 0
                ? `${data?.bonus?.max_cashout_percents}%`
                : data?.bonus?.max_cashout_money > 0
                ? formatAmount({
                    amount: data?.bonus?.max_cashout_money / 100,
                    currency: user?.profile?.currency,
                    minimumFractionDigits: 0,
                  })
                : locale?.OFFER_INFO?.unlimited,
            )}
          {data?.free_spin?.spins_coun && (
            <>
              <br />
              <div>
                {locale?.OFFER_INFO?.welcomeRuleGiftSpin
                  ?.replace('{spins_count}', data?.free_spin?.spins_count ?? 0)
                  .replace('{game_title}', game?.title ?? '')
                  .replace(
                    '{bet_size}',
                    formatAmount({
                      amount: data?.free_spin?.bet_size / 100,
                      currency: user?.profile?.currency,
                      minimumFractionDigits: 0,
                    }),
                  )}
              </div>
            </>
          )}
        </li>
        <li className="mb-2">{locale?.OFFER_INFO?.welcomeButtonRule}</li>
        {window.GlobalConfig?.region === regionTypes.CANADA && (
          <li className="mb-2">
            {locale?.OFFER_INFO?.WELCOME_FS_INFO?.[data?.order]}
          </li>
        )}
      </ol>
    </div>
  );
};

export default OfferInfoWelcome;
