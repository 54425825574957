import Endpoints from '@api/endpoints';
import Slide from '@components/Carousel/Slide';
import SlideChanger from '@components/Carousel/SlideChanger';
import SlidePagination from '@components/Carousel/SlidePagination';
import {AutoPlay} from '@egjs/flicking-plugins';
import Flicking from '@egjs/react-flicking';
import useRequest from '@hooks/useRequest';
import useToggleState from '@hooks/useToggleState';
import useWindowSize from '@hooks/useWindowSize';
import {useStore} from '@store/configureStore';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useRef, useState} from 'react';

const plugins = [
  new AutoPlay({duration: 5000, direction: 'NEXT', stopOnHover: true}),
];
const Carousel = () => {
  const {user} = useStore();

  const {data: slides, error} = useRequest({
    endpoint: Endpoints.BANNERS,
    params: {
      _sort: 'priority:DESC',
      display_for_in: [
        'all',
        user.authorized ? 'authorized' : 'not_authorized',
      ],
    },
  });

  const windowSize = useWindowSize();
  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSwiping, toggleSwiping] = useToggleState(false);

  const multipleSlides = useMemo(() => slides?.length > 1, [slides]);

  useEffect(() => {
    sliderRef?.current?.resize();
  }, [windowSize]);

  return (
    <div className="container mx-auto max-w-full mt-[16px] md:mt-[20px] xl:mt-[24px] xl:max-w-[1296px]">
      <div className="flex relative w-auto h-[348px] bg-midnight rounded-[10px] overflow-hidden md:h-[238px] lg:h-[250px] xl:h-[320px]">
        <Flicking
          ref={sliderRef}
          zIndex={1}
          overflow={false}
          style={{width: '100%'}}
          {...{plugins}}
          onChange={(e) => {
            setActiveIndex(e.index);
          }}
          onMoveStart={toggleSwiping}
          onMoveEnd={toggleSwiping}
          circular={true}
          collectStatistics={false}>
          {slides?.map((data) => (
            <Slide {...{...data, isSwiping}} key={data.id} />
          ))}
        </Flicking>

        {multipleSlides && (
          <SlidePagination {...{slides, activeIndex, sliderRef}} />
        )}

        {multipleSlides && <SlideChanger {...{sliderRef}} />}
      </div>
    </div>
  );
};

Carousel.propTypes = {};

export default observer(Carousel);
