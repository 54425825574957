import PropTypes from 'prop-types';

const LandingListSubtitle = ({title}) => {
  return (
    <>
      {title && (
        <div className="w-full px-3 md:px-4 pt-5 sm:pt-11 md:pt-10 pb-3 text-white text-[22px] sm:text-[24px] leading-[32px] font-semibold sm:font-bold">
          {title}
        </div>
      )}
    </>
  );
};

LandingListSubtitle.propTypes = {
  title: PropTypes.string,
};

export default LandingListSubtitle;
