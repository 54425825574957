import analyticsEvents from '@constants/analyticsEvents';
import AnalyticsService from '@services/AnalyticsService';
import {useEffect} from 'react';
import {useHistory} from 'react-router';

const PageViewTracker = () => {
  const history = useHistory();

  useEffect(() => {
    trackPageView();
    history.listen(trackPageView);
  }, [history]);

  function trackPageView() {
    AnalyticsService.config({
      path: window.location.pathname.toLowerCase(),
      query: window.location.search,
      hash: window.location.hash,
    });

    AnalyticsService.logEvent(analyticsEvents.PAGE_VIEW);
  }

  return null;
};

export default PageViewTracker;
