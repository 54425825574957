import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const BannerBlackoutWrapper = ({
  height,
  rounded = 'rounded-[10px]',
  children,
}) => {
  return (
    <div
      className={classNames(
        'relative w-auto bg-midnight overflow-hidden opacity-50',
        height,
        rounded,
      )}>
      {children}
    </div>
  );
};

BannerBlackoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  hasBlackout: PropTypes.bool,
  height: PropTypes.string,
  rounded: PropTypes.string,
};

export default BannerBlackoutWrapper;
