import {apiCall, Endpoints, Methods} from '@api';
import transactionStatuses from '@constants/transactionStatuses';
import pending_icon from '@images/icons/pending-icon.svg';
import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import formatDate from '@utils/formatDate';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {BsDot} from 'react-icons/bs';
import success_icon from '@images/icons/success-icon.svg';
import canceled_icon from '@images/icons/canceled-icon.svg';

const TransactionIcon = ({status}) => {
  switch (status) {
    case transactionStatuses.NEW:
    case transactionStatuses.PROCESS:
      return (
        <img
          width="18"
          height="18"
          className="animate-spin mr-[8px]"
          src={pending_icon}
          alt={locale?.iconPending}
        />
      );
    case transactionStatuses.SUCCESS:
      return (
        <img
          width="18"
          height="18"
          className="mr-[8px]"
          src={success_icon}
          alt={locale?.iconSuccess}
        />
      );

    case transactionStatuses.REJECT:
    case transactionStatuses.CANCEL:
    case transactionStatuses.FAIL:
      return (
        <img
          width="18"
          height="18"
          className="mr-[8px]"
          src={canceled_icon}
          alt={locale?.iconCanceled}
        />
      );
    default:
      return null;
  }
};

const Transaction = ({transaction, user}) => {
  const [isTransactionCanceled, setTransactionCanceled] = useState(false);

  //TODO: in progress state
  const cancelTransaction = async (transactionId) => {
    const body = {
      transaction_id: transactionId,
    };
    try {
      await apiCall({
        endpoint: Endpoints.CANCEL_TRANSACTION,
        method: Methods.POST,
        data: body,
        useAuth: true,
        useLocale: false,
      });
      setTransactionCanceled(true);
    } catch (e) {
      console.error('CANCEL_TRANSACTION_ERROR', e);
    }
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center transaction-history-item rounded-[8px] p-[12px] w-full">
        <div>
          <p className="flex items-center text-[11px] leading-[14px] text-poloBlue">
            {transaction?.created_at && formatDate(transaction?.created_at)}
            <BsDot size="12" />
            {locale?.transactionTypes?.[transaction?.type]}

            {transaction?.cashbox_item_name && (
              <>
                <BsDot size="12" />
                {transaction?.cashbox_item_name}
              </>
            )}
          </p>
          <p
            className={classNames(
              [
                transactionStatuses.REJECT,
                transactionStatuses.CANCEL,
                transactionStatuses.FAIL,
              ].includes(transaction?.status)
                ? 'text-redRibbon'
                : 'text-resolutionBlue',
              'flex items-center text-[12px] leading-[14px] mt-[8px]',
            )}>
            <TransactionIcon status={transaction?.status} />
            <p>{locale?.transactionStatuses?.[transaction?.status]}</p>

            {transaction?.type === 'withdrawal' &&
              transaction?.status === transactionStatuses.NEW &&
              !isTransactionCanceled && (
                <span
                  aria-label="click"
                  onClick={() => cancelTransaction(transaction?.id)}
                  className="ml-[8px] underline cursor-pointer">
                  {locale?.cancel}
                </span>
              )}
          </p>
        </div>
        <p
          className={classNames(
            {
              'text-resolutionBlue': transaction?.type === 'deposit',
              'text-denim': transaction?.type !== 'deposit',
            },
            'text-[17px] leading-[24px]',
          )}>
          {transaction?.type === 'deposit' ? '+' : '-'}
          {formatAmount({
            amount: transaction?.amount / 100,
            currency: user?.profile?.currency,
          })}
        </p>
      </div>
      <hr className="w-full" />
    </>
  );
};

Transaction.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default Transaction;
