import gamesFilterConfig from '@constants/gamesFilterConfig';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {observer} from 'mobx-react-lite';
import React from 'react';

const FeaturesFilter = () => {
  const {
    filters: {setFilter, isFilterActive},
  } = useStore();

  return (
    <motion.div
      layout
      className="inline-flex w-full gap-[5px] px-[15px] py-[5px] items-center flex-wrap overflow-hidden bg-midnightBlue">
      {gamesFilterConfig.AVAILABLE_FILTERS.FEATURES.VARIANTS?.map(
        (item, index) => (
          <motion.div
            key={index}
            custom={index}
            layout
            initial={{opacity: 0, x: 20}}
            animate="visible"
            variants={gamesFilterConfig.ANIMATION_CONFIG}
            whileHover={{
              scale: 1.1,
              transition: {type: 'spring', bounce: 0, duration: 0.4},
            }}
            whileTap={{
              scale: 0.95,
              transition: {type: 'spring', bounce: 0, duration: 0.4},
            }}
            onClick={() => {
              setFilter(
                gamesFilterConfig.AVAILABLE_FILTERS.FEATURES.STORE_KEY,
                item,
              );
            }}
            className={classNames(
              {
                'border-chathamsBlue': !isFilterActive(
                  gamesFilterConfig.AVAILABLE_FILTERS.FEATURES.STORE_KEY,
                  item,
                ),
                'border-white': isFilterActive(
                  gamesFilterConfig.AVAILABLE_FILTERS.FEATURES.STORE_KEY,
                  item,
                ),
              },
              'cursor-pointer h-[40px] flex justify-between items-center px-[10px] rounded-[5px] border-[1px]',
            )}>
            <span className="text-white text-[12px] leading-[16px]">
              {locale?.[item]}
            </span>
          </motion.div>
        ),
      )}
    </motion.div>
  );
};

export default observer(FeaturesFilter);
