import classNames from 'classnames';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

const NotificationBadges = ({
  count = null,
  text = null,
  className,
  isAnimated = true,
}) => {
  if (count) {
    return (
      <motion.div
        className={classNames(className)}
        initial="start"
        animate="end"
        variants={{
          start: {
            y: 5,
            x: 10,
            scale: 0.3,
            opacity: 0,
          },
          end: {
            y: -7,
            x: 16,
            scale: 1,
            opacity: 1,
          },
        }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
        }}>
        <motion.span className="inline-block w-[16px] h-[16px] bg-gorse rounded-full absolute">
          <span className="text-small text-stratos text-[11px] text-center absolute top-0 left-[4.5px]">
            {count}
          </span>
        </motion.span>
        <span
          className={classNames(
            {'animate-ping inline-block': isAnimated, hidden: !isAnimated},
            'w-[16px] h-[16px] bg-gorse rounded-full absolute',
          )}
        />
      </motion.div>
    );
  }
  if (text) {
    return (
      <span className={classNames(className, 'relative')}>
        <span className="text-pigeonPost51 text-[14px] leading-[19px] mr-[24px]">
          {text}
        </span>
        <span className="animate-ping inline-block w-[6px] h-[6px] bg-gorse rounded-full absolute top-[40%] right-[0px]" />
        <span className="inline-block w-[6px] h-[6px] bg-gorse rounded-full absolute top-[40%] right-[0px]" />
      </span>
    );
  }
  return (
    <motion.span
      className={classNames(className, 'absolute right-[20px] top-[10px]')}
      initial="start"
      animate="end"
      variants={{
        start: {
          y: 0,
          x: 10,
          scale: 0.3,
          opacity: 0,
        },
        end: {
          y: -12,
          x: 25,
          scale: 1,
          opacity: 1,
        },
      }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
      }}>
      <motion.span>
        <span className="animate-ping inline-block w-[6px] h-[6px] bg-gorse rounded-full absolute" />
        <span className="inline-block w-[6px] h-[6px] bg-gorse rounded-full absolute" />
      </motion.span>
    </motion.span>
  );
};

NotificationBadges.propTypes = {
  count: PropTypes.number,
  text: PropTypes.string,
  className: PropTypes.string,
  isAnimated: PropTypes.bool,
};

export default NotificationBadges;
