import {gql} from '@apollo/client';

export default {
  ADD: gql`
    mutation FavoriteGame($game_id: PositiveInt!) {
      add(game_id: $game_id)
    }
  `,
  REMOVE: gql`
    mutation FavoriteGame($game_id: PositiveInt!) {
      remove(game_id: $game_id)
    }
  `,
  LIST: gql`
    query FavoriteGames {
      list
    }
  `,
};
