import React from 'react';

/* TODO: restyle component */
const LandingListSkeleton = ({itemsCount = 5, useTitle = true}) => {
  return (
    <>
      {useTitle && (
        <div className="flex justify-center">
          <div className="flex flex-col justify-center mt-12 mb-9 text-center sm:mt-16  md:mt-20 md:mb-[42px]">
            <div className="w-full mb-2 text-stratos text-[26px] leading-[32px] font-bold font-sans  sm:text-[28px]  md:text-[36px] lg:text-[40px] bg-stratos animate-pulse">
              78787878787
            </div>
          </div>
        </div>
      )}
      {[...Array(itemsCount)]?.map((item, index) => (
        <div
          key={index}
          className="bg-stratos animate-pulse relative w-auto h-[418px] mt-4 lg:mt-6 bg-midnight rounded-[10px] overflow-hidden md:h-[282px] xl:h-[320px]">
          <div className=" min-h-[440px] md:min-h-[238px] xl:min-h-[282px] lg:min-h-[250px] h-full bg-second-bg-size bg-top md:bg-cover bg-no-repeat md:bg-center bg-stratos animate-pulse" />
        </div>
      ))}
    </>
  );
};

export default LandingListSkeleton;
