import InboxMessageBuilder from '@components/InboxMessages/InboxMessageBuilder';
import ModalWrapper from '@components/Modals/ModalWrapper';
import useModalRegister from '@hooks/useModalRegister';
import modalRoutes from '@navigation/modalRoutes';

const MessageModal = ({handleCloseModal}) => {
  const {
    params: {uuid},
  } = useModalRegister(modalRoutes.MESSAGE, {
    handleCloseModal,
    requiredParams: ['uuid'],
  });

  return (
    <ModalWrapper {...{handleCloseModal}}>
      <div className="bg-active-message-modal h-screen">
        <InboxMessageBuilder {...{uuid}} />
      </div>
    </ModalWrapper>
  );
};

export default MessageModal;
