import {Endpoints} from '@api';
import tokenTypes from '@constants/tokenTypes';
import LocalizationService from '@services/LocalizationService';
import StorageService from '@services/StorageService';
import {rootStore} from '@store/configureStore';
import axios from 'axios';
import qs from 'querystring';
import Methods from '../methods';

export const apiUrl =
  import.meta.env.MODE === 'development'
    ? window.GlobalConfig?.endpoints?.CMS // http://localhost:1337
    : window.GlobalConfig?.endpoints?.CMS;

const defaultConfig = {
  baseURL: apiUrl,
};

const instance = axios.create(defaultConfig);

export const apiCall = async ({
  endpoint,
  method = Methods.GET,
  headers,
  params,
  data,
  useAuth = false,
  useLocale = true,
}) => {
  headers = {
    ...headers,

    /*    'X-Region': window?.GlobalConfig?.region,
    'X-Brand': window?.GlobalConfig?.brand,*/
  };

  if (useAuth) {
    const accessToken = await StorageService.current?.get(tokenTypes.ACCESS);

    if (accessToken) {
      headers = {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }
  }

  if (useLocale) {
    params = {
      ...params,
      _locale: `${LocalizationService.getLanguage()}_${
        window.GlobalConfig?.region
      }${/*isBot*/ false ? '_bot' : ''}`,
    };
  }

  return instance({
    url: endpoint,
    method,
    headers,
    params,
    data,
    timeout: 30000,
    _skip: !useAuth,
    paramsSerializer: (params) => {
      return qs.stringify(
        Object.fromEntries(
          Object.entries(params).filter(([k, v]) => v !== undefined),
        ),
      );
    },
  });
};

const refreshInstance = axios.create({
  ...defaultConfig,
  skipAuthRefresh: true,
});

let isRefreshing = false;
let refreshSubscribers = [];

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const {config: originalRequest, response} = error;

    const status = response?.status;

    /*if (!status) {
      NotificationService.error({
        notification: locale?.NOTIFICATIONS?.networkError,
      });
    }*/

    if (originalRequest?._skip) {
      throw error;
    }

    if (status === 401) {
      if (!(await StorageService.current?.get(tokenTypes.REFRESH))) {
        window.history.pushState(null, null, '/');
        rootStore?.user?.signOut();

        throw error;
      }

      const resultPromise = new Promise((resolve) => {
        refreshSubscribers.push((token) => {
          originalRequest.headers.Authorization = token;
          resolve(axios({...originalRequest}));
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const {data} = await refreshInstance.post(
            Endpoints.REFRESH_TOKEN,
            {
              refresh_token: await StorageService.current?.get(
                tokenTypes.REFRESH,
              ),
            },
            {_skip: true},
          );

          await rootStore.user.setTokens(data.access.token, data.refresh.token);

          refreshSubscribers?.map((cb) => cb(`Bearer ${data.access.token}`));

          isRefreshing = false;
          refreshSubscribers = [];

          return resultPromise;
        } catch (e) {
          isRefreshing = false;
          refreshSubscribers = [];

          window.history.pushState(null, null, '/');

          rootStore?.user?.signOut();

          throw error;
        }
      }

      return resultPromise;
    } else {
      throw error;
    }
  },
);
