import React from 'react';

const TournamentResultsSkeleton = () => {
  return (
    <>
      <div className="bg-stratos animate-pulse relative w-auto h-[348px] mt-4 lg:mt-6 rounded-[10px] overflow-hidden md:h-[238px] lg:h-[250px] xl:h-[320px]">
        <div className=" min-h-[440px] md:min-h-[238px] xl:min-h-[282px] lg:min-h-[250px] h-full bg-second-bg-size bg-top md:bg-cover bg-no-repeat md:bg-center bg-stratos animate-pulse" />
      </div>
      <div className="flex flew-row min-h-[64px] overflow-hidden items-center mb-6 rounded-[8px] bg-stratos animate-pulse" />
      <div className="min-h-[248px] bg-top md:bg-cover bg-no-repeat md:bg-center bg-stratos animate-pulse" />
      <div className="mt-4 min-h-[122px] bg-top md:bg-cover bg-no-repeat md:bg-center bg-stratos animate-pulse" />
    </>
  );
};

export default TournamentResultsSkeleton;
