import {useStore} from '@store/configureStore';
import pushHash from '@utils/pushHash';
import {useHistory} from 'react-router';

const useModalControls = () => {
  const {
    modal: {setParams},
  } = useStore();
  const history = useHistory();

  const openModal = (modalName, params) => {
    setParams(modalName, params);
    pushHash(history, modalName);
  };

  const closeModal = () => {
    pushHash(history, '');
  };

  return {
    openModal,
    closeModal,
  };
};

export default useModalControls;
