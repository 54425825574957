import CashBoxOfferItemSwitchDescription from '@components/Offer/CashBox/CashBoxOfferItemSwitchDescription';
import CashBoxOfferItemTitle from '@components/Offer/CashBox/CashBoxOfferItemTitle';
import PropTypes from 'prop-types';

const CashBoxOfferItemDescription = ({data, user, offers}) => {
  return (
    <div className="flex items-center box-border pl-2 w-[196px] h-[80px] text-left text-white">
      <div className="flex flex-col">
        <CashBoxOfferItemTitle {...{data, user}} />
        <CashBoxOfferItemSwitchDescription {...{data, user, offers}} />
      </div>
    </div>
  );
};

CashBoxOfferItemDescription.propTypes = {
  data: PropTypes.any.isRequired,
  user: PropTypes.any,
  offers: PropTypes.any,
};

export default CashBoxOfferItemDescription;
