import React from 'react';
import {locale} from '@res/strings/locale';

const TicketsSkeleton = () => {
  return (
    <>
      <div className="flex justify-center animate-pulse">
        <div className=" inline-block text-transparent text-center text-[24px] leading-[32px] font-bold bg-badge-secondary-empty">
          {locale?.LOTTERY_PARAMS?.myTickets}
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        <div className="w-[600px] max-w-full mb-6 mt-8 px-4 flex flex-col justify-center items-center">
          <div className="inline-block w-[80px] h-[80px] mb-6 flex justify-center items-center rounded-[50%] bg-badge-secondary-empty animate-pulse" />
          <div className="text-center text-transparent text-base leading-base bg-badge-secondary-empty animate-pulse">
            {locale?.LOTTERY_PARAMS?.noLotteries}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="text-transparent text-[14px] font-bold uppercase h-[40px] w-[260px] mb-[10px] bg-badge-secondary-empty animate-pulse" />
      </div>
    </>
  );
};

export default TicketsSkeleton;
