import {apiCall, Endpoints, Methods} from '@api';
import notificationConfig from '@constants/notificationConfig';
import offerConstants from '@constants/offerConstants';
import {locale} from '@res/strings/locale';
import NotificationService from '@services/NotificationService';
import PromoCodeService from '@services/PromoCodeService';
import asyncAction from '@store/helpers/asyncAction';
import {types} from 'mobx-state-tree';

export const OffersState = {
  list: null,
  welcomeOffers: null,
  selected: null,
  cashback: null,
};

function getOffers() {
  return async function flow(store) {
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.OFFERS,
        useAuth: true,
        params: {
          sort: 'id',
          order: 'desc',
        },
      });
      store.setOffers(data);
    } catch (e) {}
  };
}

function rejectOffer(offer_id) {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.OFFER_REJECT,
        method: Methods.POST,
        useAuth: true,
        useLocale: false,
        data: {offer_id},
      });

      store.getOffers.run();
      store.getWelcomeOffers.run();
    } catch (e) {
      throw e;
    }
  };
}

function rejectWelcomeOffer() {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.WELCOME_OFFERS_REJECT,
        method: Methods.POST,
        useAuth: true,
        useLocale: false,
      });

      store.getOffers.run();
      store.getWelcomeOffers.run();
    } catch (e) {
      throw e;
    }
  };
}

function getWelcomeOffers() {
  return async function flow(store) {
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.WELCOME_OFFERS_ALL,
        useAuth: true,
      });
      store.setWelcomeOffers(data);
    } catch (e) {}
  };
}

function activateWelcomeOffer(data) {
  const notificationInfo = {
    title: locale?.NOTIFICATIONS?.welcomeSuccess?.title,
    message: locale?.NOTIFICATIONS?.welcomeSuccess?.message.replace(
      '{order}',
      locale?.ORDINAL_NUMBERS?.[data?.order],
    ),
  };

  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.WELCOME_OFFERS_ACTIVATE,
        method: Methods.POST,
        useAuth: true,
      });
      NotificationService.default({
        notification: notificationInfo,
        isCloseButtonVisible: true,
      });
      store.getWelcomeOffers.run();
    } catch (e) {
      throw e?.response?.data;
    }
  };
}

function activateTournamentOffer(offer_id) {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.TOURNAMENT_OFFERS_ACTIVATE,
        method: Methods.POST,
        useAuth: true,
        useLocale: false,
        data: {offer_id},
      });
      store.getOffers.run();
    } catch (e) {
      throw e?.response?.data;
    }
  };
}

function activatePromoCode(code, shouldThrow = true, source) {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.ACTIVATE_PROMO_CODE,
        method: Methods.POST,
        useAuth: true,
        data: {code, source},
      });
      PromoCodeService.removePromoCode();
      NotificationService.default({
        notification: locale?.NOTIFICATIONS?.promoCodeSuccess,
        autoClose: 5000,
        template: notificationConfig.TEMPLATES.CONFIRMATION,
        classNames:
          'close-button-white !bg-gradient-b-confirmationNotificationDefault',
      });
      store.getOffers.run();
    } catch (e) {
      if (shouldThrow) {
        throw e?.response?.data;
      } else {
        NotificationService.error({
          notification: locale?.NOTIFICATIONS?.promoCodeError,
          isCloseButtonVisible: true,
          autoClose: 5000,
          template: notificationConfig.TEMPLATES.PROMO_CODE_ACTIVATION,
          classNames:
            'close-button-white !bg-gradient-b-promoCodeActivationFailedNotification',
        });
      }
    }
  };
}

function getCashback() {
  return async function flow(store) {
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.CASHBACK,
        useAuth: true,
      });
      store.setCashback(data);
    } catch (e) {}
  };
}

const Offers = types
  .model({
    list: types.maybeNull(types.frozen()),
    welcomeOffers: types.maybeNull(types.frozen()),
    selected: types.maybeNull(types.frozen()),
    cashback: types.maybeNull(types.frozen()),

    getOffers: asyncAction(getOffers),
    getWelcomeOffers: asyncAction(getWelcomeOffers),
    rejectOffer: asyncAction(rejectOffer),
    rejectWelcomeOffer: asyncAction(rejectWelcomeOffer),
    activateWelcomeOffer: asyncAction(activateWelcomeOffer),
    activateTournamentOffer: asyncAction(activateTournamentOffer),
    activatePromoCode: asyncAction(activatePromoCode),
    getCashback: asyncAction(getCashback),
  })
  .views((store) => ({
    get offersHistory() {
      return store.list
        ?.filter(
          (i) =>
            (i?.type === offerConstants?.TYPE?.CASHBACK ||
              i?.type === offerConstants?.TYPE?.DEPOSIT_BONUS ||
              i?.type === offerConstants?.TYPE?.GIFT_SPIN_WIN_BONUS ||
              i?.type === offerConstants?.TYPE?.TOURNAMENT_WIN_BONUS ||
              i?.type === offerConstants?.TYPE?.NO_DEPOSIT_BONUS) &&
            i?.status !== offerConstants?.STATUS?.AVAILABLE &&
            i?.status !== offerConstants?.STATUS?.REJECTED &&
            !(
              i?.type === offerConstants?.TYPE?.DEPOSIT_BONUS &&
              i?.status === offerConstants?.STATUS?.CANCELED &&
              !i?.transaction_id
            ) &&
            !(
              i?.type === offerConstants?.TYPE?.CASHBACK &&
              i?.status === offerConstants?.STATUS?.CANCELED &&
              !i?.transaction_id
            ),
        )
        .sort((a, b) => new Date(b?.created) - new Date(a?.created));
    },
    get availableOffers() {
      return store.list?.filter(
        (i) => i?.status === offerConstants?.STATUS?.AVAILABLE,
      );
    },
    get availablePageOffers() {
      return store.list?.filter(
        (i) =>
          (i?.type !== offerConstants?.TYPE?.CASHBACK &&
            i.status === offerConstants?.STATUS?.AVAILABLE) ||
          (i.status === offerConstants?.STATUS?.ACTIVE &&
            i?.type !== offerConstants?.TYPE?.TOURNAMENT_WIN_BONUS &&
            i?.type !== offerConstants?.TYPE?.NO_DEPOSIT_BONUS &&
            i?.type !== offerConstants?.TYPE?.GIFT_SPIN_WIN_BONUS &&
            i?.type !== offerConstants?.TYPE?.DEPOSIT_BONUS &&
            i?.type !== offerConstants?.TYPE?.CASHBACK),
      );
    },
    get availableWelcomeOffers() {
      return store.welcomeOffers?.filter(
        (i) =>
          i.status === offerConstants?.STATUS?.AVAILABLE ||
          i.status === offerConstants?.STATUS?.AWAITING,
      );
    },
    get availableWelcomeOffersAll() {
      return store.welcomeOffers;
    },
    get activeWelcomeOffers() {
      return store.welcomeOffers?.filter(
        (i) =>
          i?.status === offerConstants?.STATUS?.AVAILABLE &&
          i?.has_success_deposit === '0',
      );
    },
    get depositOffersList() {
      return [...store?.activeWelcomeOffers, ...store?.availableOffers];
    },
    get availableCount() {
      return (
        (store.availableWelcomeOffers?.length ?? 0) +
        (store.availableOffers?.length ?? 0)
      );
    },
    get availableCashback() {
      return store.cashback?.percent > 0 ? store.cashback : null;
    },
    get availableCashbackOffer() {
      return store.list?.filter(
        (i) =>
          i.status === offerConstants?.STATUS?.AVAILABLE &&
          i?.type === offerConstants?.TYPE?.CASHBACK,
      )[0];
    },
  }))
  .actions((store) => ({
    setOffers(offers) {
      store.list = offers?.data ?? [];
    },
    setWelcomeOffers(offers) {
      store.welcomeOffers = offers ?? [];
    },
    setSelectedOffer(offer) {
      store.selected = offer;
    },
    setCashback(cashback) {
      store.cashback = cashback;
    },
  }));

export default Offers;
