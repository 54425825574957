import Button from '@components/Button';
import categoryKeys from '@constants/categoryKeys';
import buildRoute from '@navigation/buildRoute';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import pushHash from '@utils/pushHash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router';

const BottomButtonBlock = ({authorized}) => {
  const history = useHistory();
  const LeftBtn = (props) =>
    authorized ? (
      <Button
        onClick={() =>
          history.push(
            buildRoute(navigationRoutes.GAME_CATEGORY, {
              category: categoryKeys.ALL_GAMES,
            }),
          )
        }
        title={locale?.games}
        {...{...props}}
      />
    ) : (
      <Button
        onClick={() => pushHash(history, modalRoutes.AUTH)}
        title={locale?.login}
        {...{...props}}
      />
    );
  const RightBtn = (props) =>
    authorized ? (
      <Button
        onClick={() => pushHash(history, modalRoutes.CASH_BOX)}
        title={locale?.cahsbox}
        {...{...props}}
      />
    ) : (
      <Button
        onClick={() => pushHash(history, modalRoutes.REGISTER)}
        title={locale?.register}
        {...{...props}}
      />
    );
  return (
    <div
      className={classNames(
        'fixed bottom-0 flex md:hidden w-full z-50 duration-300',
      )}>
      <div className="w-1/2">
        <LeftBtn type="secondary" className={'w-full rounded-none h-[56px]'} />
      </div>
      <div className="w-1/2">
        <RightBtn type="primary" className={'w-full rounded-none h-[56px]'} />
      </div>
    </div>
  );
};

BottomButtonBlock.propTypes = {
  authorized: PropTypes.bool.isRequired,
};

export default BottomButtonBlock;
