import gamesFilterConfig from '@constants/gamesFilterConfig';
import testIds from '@constants/testIds';
import {useStore} from '@store/configureStore';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

const GameProvidersFilter = ({data}) => {
  const {
    filters: {setFilter, isFilterActive},
  } = useStore();

  return (
    <motion.div
      layout
      className="inline-flex gap-[5px] px-[15px]  py-[5px] items-center flex-wrap overflow-hidden bg-midnightBlue">
      {data?.length &&
        data?.map((provider, index) => (
          <motion.div
            key={provider?.id}
            custom={index}
            layout
            initial={{opacity: 0, x: 20}}
            animate="visible"
            variants={gamesFilterConfig.ANIMATION_CONFIG}
            whileHover={{
              scale: 1.1,
              transition: {type: 'spring', bounce: 0, duration: 0.4},
            }}
            whileTap={{
              scale: 0.95,
              transition: {type: 'spring', bounce: 0, duration: 0.4},
            }}
            onClick={() => {
              setFilter(
                gamesFilterConfig.AVAILABLE_FILTERS.PROVIDERS.STORE_KEY,
                provider?.name,
              );
            }}
            className={classNames(
              {
                'border-chathamsBlue': !isFilterActive(
                  gamesFilterConfig.AVAILABLE_FILTERS.PROVIDERS.STORE_KEY,
                  provider?.name,
                ),
                'border-white': isFilterActive(
                  gamesFilterConfig.AVAILABLE_FILTERS.PROVIDERS.STORE_KEY,
                  provider?.name,
                ),
              },
              'cursor-pointer h-[40px] flex justify-between items-center px-[10px] rounded-[5px] border-[1px]',
            )}>
            <img
              src={provider?.small_image?.url}
              alt={provider?.name}
              className="mr-[7px]"
            />
            <span
              testid={testIds.SINGLE_PROVIDER_FILTER}
              className="text-white text-[12px] leading-[16px]">
              {provider?.name}
            </span>
          </motion.div>
        ))}
    </motion.div>
  );
};

GameProvidersFilter.propTypes = {
  data: PropTypes.array.isRequired,
};

export default observer(GameProvidersFilter);
