import {useStore} from '@store/configureStore';
import offerConstants from '@constants/offerConstants';
import useModalRegister from '@hooks/useModalRegister';
import modalRoutes from '@navigation/modalRoutes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ModalWrapper from '@components/Modals/ModalWrapper';
import OfferInfoModalSwitch from '@components/Modals/OfferInfoModal/OfferInfoModalSwitch';

const OfferInfoModal = ({handleCloseModal}) => {
  const {params} = useModalRegister(modalRoutes.OFFER_INFO, {
    requiredParams: ['data'],
    handleCloseModal,
  });
  const {user, offers} = useStore();

  const getInfoCardHeight = () => {
    switch (params?.data?.type) {
      case offerConstants?.TYPE?.CASHBACK:
        return 'sm:h-[510px]';
      default:
        return 'sm:h-[460px]';
    }
  };

  return (
    <ModalWrapper
      desktopStyle={classNames(
        getInfoCardHeight(),
        'sm:w-[340px] sm:rounded-[8px]',
      )}
      {...{handleCloseModal}}>
      <div className="h-full flex flex-col justify-between pt-6 pb-9 px-4 sm:px-8">
        <OfferInfoModalSwitch {...{user, offers, params}} />
      </div>
    </ModalWrapper>
  );
};

OfferInfoModal.propTypes = {
  handleCloseModal: PropTypes.func,
};

export default OfferInfoModal;
