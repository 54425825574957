import {locale} from '@res/strings/locale';
import OfferInfoAction from '@components/Modals/OfferInfoModal/OfferInfoAction';
import offerConstants from '@constants/offerConstants';
import formatAmount from '@utils/formatAmount';
import PropTypes from 'prop-types';
import React from 'react';

const OfferInfoCashback = ({user, offers, data}) => {
  const cashback = offers?.availableCashback;

  return (
    <>
      <div>
        <p className="mt-0 mb-[10px] pr-[34px] text-resolutionBlue text-[20px] leading-[22px] font-bold">
          {locale?.OFFER_INFO?.title}
        </p>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px] font-bold">
          {locale?.OFFER_INFO?.cashbackSubtitle} <br />
          {locale?.OFFER_INFO?.cashbackRulesTitle?.replace(
            '{percent}',
            cashback.percent,
          )}
        </p>
        <ol className="list-decimal pl-5 text-resolutionBlue text-[14px] leading-[20px]">
          <li className="mb-2">
            {locale?.OFFER_INFO?.cashbackRulePeriod?.replace(
              '{period}',
              cashback.period !== offerConstants?.monthly
                ? locale?.OFFER_INFO?.cashbackWeekly
                : locale?.OFFER_INFO?.cashbackMonthly,
            )}
          </li>
          <li className="mb-2">
            {locale?.OFFER_INFO?.cashbackRuleActivation?.replace(
              '{period}',
              cashback.period !== offerConstants?.monthly
                ? locale?.OFFER_INFO?.cashbackWeeklyDate
                : locale?.OFFER_INFO?.cashbackMonthlyDate,
            )}
          </li>
          <li className="mb-2">
            {locale?.OFFER_INFO?.cashbackRuleAmount?.replace(
              '{amount}',
              formatAmount({
                amount: data?.amount / 100 ?? 0,
                currency: user?.profile?.currency,
                minimumFractionDigits: 0,
              }),
            )}
          </li>
          <li className="mb-2">
            {locale?.OFFER_INFO?.cashbackRuleWager} <b>x{cashback.wager}</b>
          </li>
          <li className="mb-2">{locale?.OFFER_INFO?.cashbackRuleDeposit}</li>
        </ol>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px]">
          {locale?.OFFER_INFO?.cashbackNote?.replace(
            '{min_deposit_percent}',
            cashback.min_deposit_percent,
          )}
        </p>
      </div>
      {data?.type === offerConstants?.TYPE?.CASHBACK && (
        <OfferInfoAction {...{offers, id: data?.id}} />
      )}
    </>
  );
};

OfferInfoCashback.propTypes = {
  user: PropTypes.any,
  offers: PropTypes.any,
  data: PropTypes.any,
};

export default OfferInfoCashback;
