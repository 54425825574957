import Button from '@components/Button';
import notificationConfig from '@constants/notificationConfig';
import danger_icon from '@images/icons/danger-icon.svg';
import success_icon_white from '@images/icons/success-icon-white.svg';
import success_icon from '@images/icons/success-icon.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ServiceNotification = ({
  type,
  notification,
  withActionButton = false,
  onClick = () => {},
  action,
  actionData,
}) => {
  return (
    <>
      {notification && (
        <div
          className="flex flex-row justify-between"
          onClick={() => (!withActionButton ? onClick() : null)}>
          <div>
            <p
              className={classNames(
                {
                  'text-white':
                    type === notificationConfig.TYPES.ERROR ||
                    type === notificationConfig.TYPES.INFO,
                  'text-resolutionBlue':
                    type === notificationConfig.TYPES.DEFAULT ||
                    type === notificationConfig.TYPES.SUCCESS,
                },
                'font-bold text-[14px]',
              )}>
              {notification?.title}
            </p>
            <p
              className={classNames(
                {
                  'text-white':
                    type === notificationConfig.TYPES.ERROR ||
                    type === notificationConfig.TYPES.INFO,
                  'text-resolutionBlue':
                    type === notificationConfig.TYPES.DEFAULT ||
                    type === notificationConfig.TYPES.SUCCESS,
                },
                'text-[14px]',
              )}>
              {notification?.truncate
                ? notificationConfig.HELPERS.truncateMessage(notification)
                : notification?.message}
            </p>
            {withActionButton && (
              <Button
                onClick={!action ? onClick : null}
                isLink={action}
                {...{action, actionData}}
                type={
                  type === notificationConfig.TYPES.DEFAULT ||
                  type === notificationConfig.TYPES.SUCCESS
                    ? 'secondary'
                    : 'primary'
                }
                title={notification?.actionButtonText}
                className="max-w-[225px] w-full mt-[10px] py-[8px] px-[16px] uppercase text-[12px] text-center"
              />
            )}
          </div>
          {!withActionButton && (
            <>
              {type === notificationConfig.TYPES.ERROR ? (
                <img
                  className="self-center"
                  src={danger_icon}
                  alt={notification?.title}
                />
              ) : type === notificationConfig.TYPES.INFO ? (
                <img
                  className="self-center"
                  src={success_icon_white}
                  alt={notification?.title}
                />
              ) : (
                <img
                  className="self-center"
                  src={success_icon}
                  alt={notification?.title}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

ServiceNotification.propTypes = {
  type: PropTypes.string.isRequired,
  notification: PropTypes.object.isRequired,
  withActionButton: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ServiceNotification;
