import useCurrency from '@hooks/useCurrency';
import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import LotteryTicketInfoListItem from '@components/LotteryPage/LotteryTicketInfoListItem';
import lotteryConfig from '@constants/lotteryConfig';
import classNames from 'classnames';

const LotteryTicketInfoList = ({data, theme = 'dark'}) => {
  const currency = useCurrency();
  const mapData = useMemo(
    () =>
      lotteryConfig
        ?.TICKET_INFO(data, currency)
        ?.map((item, key) => (
          <LotteryTicketInfoListItem {...{...item, key, theme}} />
        )),
    [data],
  );

  return (
    <div
      className={classNames('w-full flex inline-flex flex-wrap', {
        'sm:my-6 md:my-8': theme === 'dark',
        'sm:my-2 md:my-2': theme === 'light',
      })}>
      {!!data && mapData}
    </div>
  );
};

LotteryTicketInfoList.propTypes = {
  data: PropTypes.any,
  theme: PropTypes.string,
};

export default LotteryTicketInfoList;
