import GameGridBanner from '@components/GamesGrid/GameGridBanner';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const GameGridBannerSkeleton = ({className}) => {
  return (
    <div
      className={classNames(
        className,
        'animate-fade-in group transition duration-300 flex flex-col pt-[24px] pl-[30px] pb-[39px] items-start rounded-[5px] bg-no-repeat bg-cover bg-center w-full md:max-w-[354px] lg:max-w-[374px] xl:max-w-[416px] mt-[16px] md:mt-0 min-h-[222px] bg-stratos animate-pulse',
      )}
    />
  );
};

GameGridBanner.propTypes = {
  className: PropTypes.string,
};

export default GameGridBannerSkeleton;
