import Button from '@components/Button';
import Icon from '@components/Icon';
import inboxMessagesConfig from '@constants/inboxMessagesConfig';
import useModalControls from '@hooks/useModalControls';
import modalRoutes from '@navigation/modalRoutes';
import formatDate from '@utils/formatDate';
import openSupportChat from '@utils/openSupportChat';
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router';
import {useApolloClient, useLazyQuery} from '@apollo/client';
import {Endpoints} from '@api';
import {INBOX_MESSAGES} from '@graphql';
import empty_inbox_builder_icon from '@images/icons/empty-inbox-builder-icon.svg';
import gift_xl from '@images/inbox-messages/gift-xl.png';
import confirm_xl from '@images/inbox-messages/confirm-xl.png';
import info_xl from '@images/inbox-messages/info-xl.png';
import welcome_xl from '@images/inbox-messages/welcome-xl.png';
import {locale} from '@res/strings/locale';
import Spinner from '@components/Spinner/Spinner';
import {AnimatePresence, motion} from 'framer-motion';

const InboxMessageBuilder = ({uuid}) => {
  const history = useHistory();
  const {openModal} = useModalControls();
  const client = useApolloClient();

  const [getMessage, {loading, data: message}] = useLazyQuery(
    INBOX_MESSAGES.GET,
    {
      context: {
        useAuth: true,
        uri: Endpoints.INBOX_MESSAGES,
      },
    },
  );

  const getButtonAction = () => {
    switch (message?.get?.button_action) {
      case inboxMessagesConfig.BUTTON_ACTION.CASHBOX:
        return openModal(modalRoutes.CASH_BOX);
      case inboxMessagesConfig.BUTTON_ACTION.SUPPORT_CHAT:
        return openSupportChat();
      default:
        return null;
    }
  };

  const getMessageImageUrl = (type) => {
    switch (type) {
      case inboxMessagesConfig.IMAGE.GIFT:
        return gift_xl;
      case inboxMessagesConfig.IMAGE.CONFIRM:
        return confirm_xl;
      case inboxMessagesConfig.IMAGE.INFORMATION:
        return info_xl;
      case inboxMessagesConfig.IMAGE.WELCOME:
        return welcome_xl;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (uuid) {
      getMessage({
        variables: {
          uuid,
        },
      }).finally(() => {
        client.refetchQueries({
          include: [INBOX_MESSAGES.LIST, INBOX_MESSAGES.COUNT],
        });
      });
    }
  }, [uuid]);

  return (
    <>
      {loading ? (
        <div className="relative h-full">
          <Spinner />
        </div>
      ) : (
        <AnimatePresence exitBeforeEnter>
          {message?.get ? (
            <motion.div
              key={uuid}
              className="relative"
              initial={{x: '-100%'}}
              animate={{
                x: 0,
              }}
              exit={{
                x: '100%',
              }}
              transition={{type: 'spring', bounce: 0, duration: 0.4}}>
              <div className="transition duration-300 animate-fade-in text-center py-[5px] md:py-[12px] bg-messages-modal sm:bg-transparent border-b border-message-item-border min-h-[54px] md:min-h-[74px] relative">
                <p className="text-h6 w-full  text-primary ">
                  {message?.get?.title}
                </p>
                <p className="text-secondary text-footnote leading-footnote">
                  {message?.get?.available_at &&
                    formatDate(message?.get?.available_at)}
                </p>
                <div
                  onClick={() => history.goBack()}
                  className="absolute block md:hidden left-[12px] top-[16px] cursor-pointer z-[1000] text-center">
                  <span className="click-area">
                    <Icon
                      icon={'arrow-left'}
                      size={28}
                      className="relative transition duration-100 text-melrose"
                    />
                  </span>
                </div>
              </div>
              <div className="h-[calc(100vh-55px)] sm:h-[calc(100vh-120px)] xl:h-auto overflow-y-scroll no-scrollbar pt-[48px] lg:pt-[24px] text-center px-[16px] pb-[50px] lg:pb-0 lg:px-0">
                <img
                  src={getMessageImageUrl(message?.get?.action_image)}
                  className="w-[200px] h-[200px] m-auto"
                  alt="some alt"
                />
                <div className="lg:px-[40px] lg:pb-[40px] mt-[24px]">
                  <p className="text-primary text-body-primary leading-body-primary mb-[16px]">
                    {message?.get?.content}
                  </p>
                  <Button
                    className="w-full sm:max-w-[328px] mx-auto block uppercase font-bold py-[11px] leading-[10px] text-[14px]"
                    type="primary"
                    title={message?.get?.button_title}
                    onClick={getButtonAction}
                  />
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="empty_inbox_builder_icon"
              className="relative flex flex-col items-center justify-center h-[calc(100vh-55px)] lg:h-full text-center lg:px-[40px] max-w-[400px] mx-auto"
              initial={{opacity: 0}}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{type: 'spring', bounce: 0, duration: 0.4}}>
              <img
                src={empty_inbox_builder_icon}
                alt="empty_inbox_builder_icon"
              />
              <p className="text-primary text-body-primary leading-body-primary mt-[30px]">
                {locale?.INBOX_MESSAGES?.empty_message_builder}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

InboxMessageBuilder.propTypes = {
  uuid: PropTypes.string,
};

export default InboxMessageBuilder;
