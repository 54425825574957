import Endpoints from '@api/endpoints';
import Game from '@components/GamesGrid/Game';
import categoryKeys from '@constants/categoryKeys';
import useGamesArraySize from '@hooks/useGamesArraySize';
import useRequest from '@hooks/useRequest';
import useWindowSize from '@hooks/useWindowSize';
import {locale} from '@res/strings/locale';
import getDevice from '@utils/getDevice';
import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import {useStore} from '@store/configureStore';
import {observer} from 'mobx-react-lite';

const EmptySearchResult = ({categoryKey}) => {
  const {data} = useRequest({
    endpoint: Endpoints.GAMES,
    params: {
      preview: true,
      ['categories.slug_eq']: categoryKeys.POPULAR,
      device: getDevice(),
      _sort: 'priority:DESC',
      _limit: 12,
    },
  });
  const {user} = useStore();
  const size = useWindowSize();
  const itemsCount = useGamesArraySize();
  const [games, setGames] = useState(null);

  const content = useMemo(() => {
    switch (categoryKey) {
      case categoryKeys.FAVORITE_GAMES:
        return {
          title: locale?.favoriteEmptyTitle,
          message: locale?.favoriteEmptyMessage,
        };
      default:
        return {
          title: locale?.searchFailTitle,
          message: locale?.searchFailMessage,
        };
    }
  }, [categoryKey]);

  useEffect(() => {
    if (data?.list) {
      setGames(data?.list?.slice(0, itemsCount));
    }
  }, [itemsCount, data]);

  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>

      <div className="text-center pt-[15px]">
        <h2 className="text-white uppercase font-bold text-[24px] leading-[32px]">
          {content?.title}
        </h2>
        <p className="text-melrose text-[14px]">{content?.message}</p>
      </div>
      <div className="flex flex-col md:flex-row overflow-hidden mt-[64px]">
        <div className="w-full inline-flex flex-wrap items-start gap-[16px] xl:gap-[24px] relative overflow-hidden">
          {games &&
            games?.map((game) => (
              <Game
                key={game?.id}
                authorized={user?.authorized}
                {...{size, game}}
              />
            ))}
        </div>
      </div>
    </>
  );
};

EmptySearchResult.propTypes = {
  categoryKey: PropTypes.string.isRequired,
};

export default observer(EmptySearchResult);
