import './index.css';
import './theme.css';
import './wdyr';
import ErrorFallback from '@components/ErrorFallback';
import LoadingGate from '@components/LoadingGate';
import NetworkGate from '@components/NetworkGate';
import ReactQueryProvider from '@components/ReactQueryProvider';
import ThemeProvider from '@components/ThemeProvider';

import analyticsEvents from '@constants/analyticsEvents';
import MainNavigator from '@navigation/MainNavigator';
import '@polyfills';
import {CaptureConsole as CaptureConsoleIntegration} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import AnalyticsService from '@services/AnalyticsService';
import {MSTProvider, rootStore} from '@store/configureStore';
import 'lazysizes';
import React from 'react';
import ReactDOM from 'react-dom';
import {apolloClient} from '@graphql';
import {ApolloProvider} from '@apollo/client';

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={ErrorFallback}
      showDialog={true}
      onError={(error) => {
        console.error(error);
        AnalyticsService.logException(analyticsEvents.UNKNOWN_EXCEPTION, error);
      }}>
      <ReactQueryProvider>
        <ApolloProvider client={apolloClient}>
          <MSTProvider value={rootStore}>
            <ThemeProvider>
              <NetworkGate>
                <LoadingGate>
                  <MainNavigator />
                </LoadingGate>
              </NetworkGate>
            </ThemeProvider>
          </MSTProvider>
        </ApolloProvider>
      </ReactQueryProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
