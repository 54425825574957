import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import Meta from '@components/Meta';
import React from 'react';

const GameLayout = ({children}) => {
  return (
    <main className="bg-midnightBlue">
      <Header />
      <Meta />
      {children}
      <Footer />
    </main>
  );
};

export default GameLayout;
