import CountdownIcon from '@components/Countdown/CountdownIcon';
import CountdownRenderer from '@components/Countdown/CountdownRenderer';
import BaseDateRender from '@components/Date/BaseDateRender';
import React from 'react';
import PropTypes from 'prop-types';

const CountdownRendererSwitch = ({
  data,
  date,
  label,
  classNamesIcon,
  isDefaultTextSize,
  classNamesCountdown,
  classNamesLabel,
}) => {
  if (data?.completed) {
    return (
      <BaseDateRender
        {...{
          date,
          className:
            'inline-block pl-2 text-[14px] xl:text-[16px] text-white font-bold leading-[20px] align-top',
        }}
      />
    );
  } else {
    return (
      <>
        <CountdownIcon {...{className: classNamesIcon}} />
        <CountdownRenderer
          {...{
            label,
            isDefaultTextSize,
            classNamesCountdown,
            classNamesLabel,
            date: {
              days: data?.days,
              hours: data?.hours,
              minutes: data?.minutes,
              seconds: data?.seconds,
            },
          }}
        />
      </>
    );
  }
};

CountdownRendererSwitch.propTypes = {
  classNamesCountdown: PropTypes.string,
  classNamesLabel: PropTypes.string,
  classNamesIcon: PropTypes.string,
  isDefaultTextSize: PropTypes.bool,
  label: PropTypes.string,
  date: PropTypes.any,
  data: PropTypes.any,
};

export default CountdownRendererSwitch;
