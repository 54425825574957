import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const ContentTitle = ({data, isSingle = true, isHide = false}) => {
  const className = classNames(
    'text-white font-bold text-[19px] sm:text-[24px] leading-[24px] mb-[21px]',
    {hidden: isHide},
  );

  return (
    <>
      {isSingle ? (
        <h1 {...{className}}>{data}</h1>
      ) : (
        <h2 {...{className}}>{data}</h2>
      )}
    </>
  );
};

ContentTitle.propTypes = {
  data: PropTypes.any,
  isSingle: PropTypes.bool,
};

export default ContentTitle;
