import lotteryConfig from '@constants/lotteryConfig';
import useCurrency from '@hooks/useCurrency';

import React from 'react';
import PropTypes from 'prop-types';
import LotteryRulesContent from '@components/LotteryPage/LotteryRulesContent';
import RulesParams from '@components/PageContent/RulesParams';
import ChapterParagraph from '@components/PageContent/ChapterParagraph';
import ContentTitle from '@components/PageContent/ContentTitle';

const LotteryRules = ({data}) => {
  const currency = useCurrency();

  return (
    <>
      <ContentTitle
        {...{data: data?.meta_rules?.h1 ?? data?.title, isHide: true}}
      />
      <RulesParams {...{data: lotteryConfig?.RULES_PARAMS(data, currency)}} />
      <ChapterParagraph
        {...{data: data?.description_long ?? '', color: 'text-primary'}}
      />
      <LotteryRulesContent {...{data}} />
    </>
  );
};

LotteryRules.propTypes = {
  data: PropTypes.any.isRequired,
};

export default LotteryRules;
