import categoryKeys from '@constants/categoryKeys';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import {useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router';

const useGamesSearch = () => {
  const history = useHistory();
  const location = history?.location;
  const {category} = useParams();
  const urlParams = useMemo(
    () => new URLSearchParams(location?.search),
    [location?.search],
  );
  const q = urlParams.get('q');
  const [searchInputValue, setSearchInputValue] = useState(q ?? '');
  const searchPageRoute = useMemo(
    () =>
      buildRoute(navigationRoutes.GAME_CATEGORY, {
        category: categoryKeys.ALL_GAMES,
      }),
    [],
  );
  const [queryString, setQueryString] = useState(null);

  useEffect(() => {
    if (category === categoryKeys.ALL_GAMES && location?.search) {
      setQueryString(urlParams.get('q'));
    } else {
      if (!location?.search) {
        setQueryString(null);
      }
    }
  }, [category, location?.search]);

  useEffect(() => {
    if (searchInputValue) {
      urlParams.set('q', searchInputValue);
      history.push({
        pathname: searchPageRoute,
        search: urlParams.toString(),
        hash: history?.location?.hash,
      });
    } else {
      urlParams.delete('q');
      if (q) {
        history.push({
          search: urlParams.toString(),
          hash: history?.location?.hash,
        });
      }
    }
  }, [searchInputValue]);

  useEffect(() => {
    !q && setSearchInputValue('');
  }, [q]);

  return {
    queryString,
    searchInputValue,
    setSearchInputValue,
  };
};

export default useGamesSearch;
