export default {
  ALL_GAMES: 'all-games',
  POPULAR: 'popular',
  NEW: 'new',
  RECENT: 'recent',
  MY_GAMES: 'my',
  TABLE: 'table',
  LIVE: 'live',
  RECOMMENDED: 'recommendations',
  FAVORITE_GAMES: 'favorite',
};
