import {Endpoints} from '@api';
import useRequest from '@hooks/useRequest';
import {locale} from '@res/strings/locale';
import getDevice from '@utils/getDevice';
import PropTypes from 'prop-types';

const CashBoxOfferItemGiftSpinTitle = ({data}) => {
  const {data: game} = useRequest(
    {
      endpoint: Endpoints.GAMES,
      params: {
        preview: true,
        ['internal_id_eq']: data?.game_id,
        device: getDevice(),
        _limit: 1,
      },
    },
    false,
  );

  return (
    <>
      {`${data?.spins_count} ${locale?.OFFER?.spinsIn} 
      ${game?.list?.[0] ? game?.list?.[0]?.title : data?.game_sid}`}
    </>
  );
};

CashBoxOfferItemGiftSpinTitle.propTypes = {
  data: PropTypes.any,
};

export default CashBoxOfferItemGiftSpinTitle;
