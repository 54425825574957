import qs from 'query-string';

const composeUrl = (url, query) => {
  const queryString = qs.stringify(query);

  if (queryString) {
    return url + '?' + queryString;
  }

  return url;
};

export default composeUrl;
