import LiveChatWrapper from '@components/LiveChatWrapper';
import ModalDriver from '@components/Modals/ModalDriver';
import NotificationContainer from '@components/Notifications/NotificationContainer';
import PageViewTracker from '@components/PageViewTracker';
import RedirectAllGamesPage from '@components/RedirectAllGamesPage';
import ScrollMemory from '@components/ScrollMemory/ScrollMemory';
import UserBanned from '@components/UserBanned';
import categoryKeys from '@constants/categoryKeys';
import useBanned from '@hooks/useBanned';
import BaseLayout from '@layouts/BaseLayout';
import EmptyLayout from '@layouts/EmptyLayout';
import GameLayout from '@layouts/GameLayout';
import ProfileLayout from '@layouts/ProfileLayout';
import StaticLayout from '@layouts/StaticLayout';
import buildPath from '@navigation/buildPath';
import buildRoute from '@navigation/buildRoute';
import CustomRoute from '@navigation/CustomRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import LanguageRedirectPage from '@pages/LanguageRedirectPage';
import LocalizationService from '@services/LocalizationService';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Redirect} from 'react-router';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LotteryPage from '@pages/LotteryPage';
import LotteriesPage from '@pages/LotteriesPage';
import TicketsPage from '@pages/TicketsPage';
import {YMInitializer} from 'react-yandex-metrika';

const AutologinPage = React.lazy(() => import('@pages/AutologinPage'));
const BalancePage = React.lazy(() => import('@pages/BalancePage'));
const ConfirmEmailPage = React.lazy(() => import('@pages/ConfirmEmailPage'));
const ChangePasswordPage = React.lazy(() =>
  import('@pages/ChangePasswordPage'),
);
const DepositRedirectPage = React.lazy(() =>
  import('@pages/DepositRedirectPage'),
);
const DocumentsPage = React.lazy(() => import('@pages/DocumentsPage'));
const GamePage = React.lazy(() => import('@pages/GamePage'));
const GamesCategoryListPage = React.lazy(() =>
  import('@pages/GamesCategoryListPage'),
);
const GamesFavoriteListPage = React.lazy(() =>
  import('@pages/GamesFavoriteListPage'),
);
const GamesProviderListPage = React.lazy(() =>
  import('@pages/GamesProviderListPage'),
);
const MainPage = React.lazy(() => import('@pages/MainPage'));
const NotificationPage = React.lazy(() => import('@pages/NotificationPage'));
const OffersPage = React.lazy(() => import('@pages/OffersPage'));
const ProfilePage = React.lazy(() => import('@pages/ProfilePage'));
const PublicationPage = React.lazy(() => import('@pages/PublicationPage'));
const PublicationsPage = React.lazy(() => import('@pages/PublicationsPage'));
const StaticInfoPage = React.lazy(() => import('@pages/StaticInfoPage'));
const TournamentPage = React.lazy(() => import('@pages/TournamentPage'));
const TournamentsPage = React.lazy(() => import('@pages/TournamentsPage'));

import GamePageSkeleton from '@components/GamePage/GamePageSkeleton';
import ProfileSkeletonSuspense from '@components/Profile/ProfileSkeletonSuspense';
import LandingListSkeleton from '@components/LandingList/LandingListSkeleton';
import PublicationsSkeleton from '@components/PublicationsPage/PublicationsSkeleton';
import PageContainer from '@components/PageContent/PageContainer';

const UnsubscribeEmailPage = React.lazy(() =>
  import('@pages/UnsubscribeEmailPage'),
);
const PromoCode = React.lazy(() =>
  import('@components/Profile/PromoCode/PromoCode'),
);
const NotFoundPage = React.lazy(() => import('@pages/NotFoundPage'));

const TestFallback = () => {
  return <div className="w-full min-h-[90vh]" />;
};

const MainNavigator = () => {
  const isBanned = useBanned();

  const staticPagesPath = useMemo(
    () =>
      buildPath(
        `/:page(${navigationRoutes
          ?.staticPages()
          ?.reduce((x, y) => x + y, '')
          ?.replaceAll('/', '|')})`,
      ),
    [],
  );

  return (
    <Router>
      <PageViewTracker />
      {window?.GlobalConfig?.yaMetrikaID && (
        <YMInitializer accounts={[window?.GlobalConfig?.yaMetrikaID]} />
      )}
      <ScrollMemory />

      <Switch>
        {isBanned && <UserBanned />}
        <CustomRoute
          exact
          layout={BaseLayout}
          useAuth={false}
          component={MainPage}
          path={buildPath(navigationRoutes.MAIN)}
          suspenseFallback={TestFallback}
        />
        <Route exact path={buildPath(navigationRoutes.EMPTY_CATEGORY)}>
          <Redirect
            to={buildRoute(navigationRoutes.GAME_CATEGORY, {
              category: categoryKeys.ALL_GAMES,
            })}
          />
        </Route>
        <CustomRoute
          layout={({children}) => (
            <BaseLayout useCarousel={false} isPermanentSticky>
              {children}
            </BaseLayout>
          )}
          useAuth={true}
          component={GamesFavoriteListPage}
          path={buildPath(navigationRoutes.FAVORITE_GAMES_CATEGORY)}
          suspenseFallback={TestFallback}
        />
        <CustomRoute
          layout={({children}) => (
            <BaseLayout useCarousel={false} isPermanentSticky>
              {children}
            </BaseLayout>
          )}
          useAuth={false}
          component={GamesCategoryListPage}
          path={buildPath(navigationRoutes.GAME_CATEGORY)}
          suspenseFallback={TestFallback}
        />
        <CustomRoute
          exact
          layout={({children}) => (
            <BaseLayout useCarousel={false} isPermanentSticky>
              {children}
            </BaseLayout>
          )}
          useAuth={false}
          component={GamesProviderListPage}
          path={buildPath(navigationRoutes.GAME_PROVIDER)}
          suspenseFallback={TestFallback}
        />
        <CustomRoute
          exact
          layout={({children}) => (
            <BaseLayout useCarousel={false} isPermanentSticky>
              {children}
            </BaseLayout>
          )}
          useAuth={false}
          component={RedirectAllGamesPage}
          path={buildPath(navigationRoutes.GAME_LIST)}
          suspenseFallback={TestFallback}
        />
        <CustomRoute
          exact
          layout={GameLayout}
          useAuth={false}
          component={GamePage}
          path={buildPath(navigationRoutes.GAME)}
          suspenseFallback={() => (
            <PageContainer>
              <GamePageSkeleton />
            </PageContainer>
          )}
        />
        <CustomRoute
          exact
          layout={ProfileLayout}
          component={ProfilePage}
          path={buildPath(navigationRoutes.PROFILE)}
          suspenseFallback={ProfileSkeletonSuspense}
        />
        <CustomRoute
          exact
          layout={ProfileLayout}
          component={BalancePage}
          path={buildPath(navigationRoutes.BALANCE)}
          suspenseFallback={ProfileSkeletonSuspense}
        />
        <CustomRoute
          exact
          layout={ProfileLayout}
          component={NotificationPage}
          path={buildPath(navigationRoutes.NOTIFICATIONS)}
          suspenseFallback={ProfileSkeletonSuspense}
        />
        <CustomRoute
          exact
          layout={ProfileLayout}
          component={OffersPage}
          path={buildPath(navigationRoutes.OFFERS)}
          suspenseFallback={ProfileSkeletonSuspense}
        />
        <CustomRoute
          exact
          layout={ProfileLayout}
          component={TicketsPage}
          path={buildPath(navigationRoutes.TICKETS)}
          suspenseFallback={ProfileSkeletonSuspense}
        />
        <CustomRoute
          exact
          layout={ProfileLayout}
          component={PromoCode}
          path={buildPath(navigationRoutes.PROMO_CODE)}
          suspenseFallback={ProfileSkeletonSuspense}
        />
        <CustomRoute
          exact
          layout={ProfileLayout}
          component={DocumentsPage}
          path={buildPath(navigationRoutes.DOCUMENTS)}
          suspenseFallback={ProfileSkeletonSuspense}
        />
        <CustomRoute
          exact
          layout={ProfileLayout}
          component={ChangePasswordPage}
          path={buildPath(navigationRoutes.PASSWORD)}
          suspenseFallback={ProfileSkeletonSuspense}
        />
        <CustomRoute
          exact
          layout={EmptyLayout}
          component={DepositRedirectPage}
          path={buildPath(navigationRoutes.DEPOSIT_REDIRECT)}
          suspenseFallback={TestFallback}
        />
        <CustomRoute
          exact
          layout={StaticLayout}
          component={ConfirmEmailPage}
          path={buildPath(navigationRoutes.CONFIRM_EMAIL)}
          suspenseFallback={TestFallback}
        />
        <CustomRoute
          exact
          layout={EmptyLayout}
          component={UnsubscribeEmailPage}
          path={buildPath(navigationRoutes.UNSUBSCRIBE_EMAIL)}
          suspenseFallback={TestFallback}
          useAuth={false}
        />
        <CustomRoute
          exact
          layout={EmptyLayout}
          component={AutologinPage}
          path={buildPath(navigationRoutes.AUTOLOGIN)}
          suspenseFallback={TestFallback}
          useAuth={false}
        />
        <CustomRoute
          exact
          layout={StaticLayout}
          component={StaticInfoPage}
          path={staticPagesPath}
          suspenseFallback={TestFallback}
          useAuth={false}
        />
        <CustomRoute
          exact
          layout={StaticLayout}
          component={TournamentPage}
          path={buildPath(navigationRoutes.TOURNAMENT_PATH)}
          suspenseFallback={TestFallback}
          useAuth={false}
        />
        <CustomRoute
          exact
          layout={StaticLayout}
          component={TournamentsPage}
          path={buildPath(navigationRoutes.TOURNAMENTS_LIST)}
          suspenseFallback={() => (
            <PageContainer>
              <LandingListSkeleton />
            </PageContainer>
          )}
          useAuth={false}
        />
        <CustomRoute
          exact
          layout={StaticLayout}
          component={LotteriesPage}
          path={buildPath(navigationRoutes.LOTTERIES_LIST)}
          suspenseFallback={() => (
            <PageContainer>
              <LandingListSkeleton />
            </PageContainer>
          )}
          useAuth={false}
        />
        <CustomRoute
          exact
          layout={StaticLayout}
          component={LotteryPage}
          path={buildPath(navigationRoutes.LOTTERY_PATH)}
          suspenseFallback={TestFallback}
          useAuth={false}
        />
        <CustomRoute
          exact
          layout={StaticLayout}
          component={PublicationPage}
          path={buildPath(navigationRoutes.PUBLICATION)}
          suspenseFallback={TestFallback}
          useAuth={false}
        />
        <CustomRoute
          exact
          layout={StaticLayout}
          component={PublicationsPage}
          path={buildPath(navigationRoutes.PUBLICATIONS_LIST)}
          suspenseFallback={() => (
            <PageContainer>
              <PublicationsSkeleton />
            </PageContainer>
          )}
          useAuth={false}
        />

        {LocalizationService.isInternalMultiLang && (
          <Route exact path={'/'}>
            <LanguageRedirectPage />
          </Route>
        )}

        <CustomRoute
          useAuth={false}
          layout={StaticLayout}
          component={NotFoundPage}
          path="*"
          suspenseFallback={TestFallback}
        />
      </Switch>

      <ModalDriver />
      <NotificationContainer />
    </Router>
  );
};

export default observer(MainNavigator);
