import Endpoints from '@api/endpoints';
import DataLoadingMessage from '@components/CashBox/DataLoadingMessage';
import FormBuilder from '@components/CashBox/FormBuilder';
import cashBoxConfig from '@constants/cashBoxConfig';
import useRequest from '@hooks/useRequest';
import LocalizationService from '@services/LocalizationService';
import PropTypes from 'prop-types';
import React from 'react';

const TransactionMethod = ({
  paymentMethodUid,
  methodType = cashBoxConfig.METHODS_TYPES.DEPOSIT,
}) => {
  const {data: paymentMethod, error} = useRequest({
    endpoint: Endpoints.GET_PAYMENT_METHOD_OPTIONS,
    params: {
      uid: paymentMethodUid,
      locale: LocalizationService.getLanguage(),
    },
    useAuth: true,
  });

  return (
    <>
      {!paymentMethod ? (
        <div className="absolute left-0 right-0 bottom-0 top-0">
          <DataLoadingMessage />
        </div>
      ) : (
        <FormBuilder {...{paymentMethod, methodType}} />
      )}
    </>
  );
};

TransactionMethod.propTypes = {
  paymentMethodUid: PropTypes.string.isRequired,
  methodType: PropTypes.string,
};
export default TransactionMethod;
