import Endpoints from '@api/endpoints';
import Button from '@components/Button';
import FeaturesFilter from '@components/GamesFilter/FeaturesFilter';
import GameCategoriesFilter from '@components/GamesFilter/GameCategoriesFilter';
import GameProvidersFilter from '@components/GamesFilter/GameProvidersFilter';
import VolatilityFilter from '@components/GamesFilter/VolatilityFilter';
import RestrictionContentBlock from '@components/Restrictions/RestrictionContentBlock';
import Overlay from '@components/Overlay';
import SubMenu from '@components/SubMenu';
import testIds from '@constants/testIds';
import useBodyOverflow from '@hooks/useBodyOverflow';
import useGamesFilter from '@hooks/useGamesFilter';
import useRequest from '@hooks/useRequest';
import close_icon from '@images/icons/close-icon.svg';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import classNames from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import regionTypes from '@constants/regionTypes';

const GamesFilter = ({
  isFilterOpen,
  toggleFilter,
  closeFilter,
  headerHeight,
}) => {
  const {restriction} = useStore();
  useBodyOverflow(isFilterOpen);
  const {applyFilter, resetFilter} = useGamesFilter();
  const {data} = useRequest({
    endpoint: Endpoints.PROVIDERS,
    params: {_sort: 'priority:DESC'},
  });
  const {data: categories} = useRequest({
    endpoint: Endpoints.CATEGORIES,
    params: {
      _sort: 'priority:DESC',
      show_in_filter: true,
    },
  });

  const handleRestrictionBlockClose = () => {
    restriction?.setRestrictionProviderFilter();
  };

  return (
    <>
      {isFilterOpen && <Overlay toggleState={toggleFilter} isSideMenu />}
      <AnimatePresence>
        {isFilterOpen && (
          <div className="relative game-filter">
            <motion.div
              key="game_filter"
              initial={{x: '-100%'}}
              animate={{
                x: 0,
              }}
              exit={{
                x: '-100%',
              }}
              transition={{type: 'spring', bounce: 0, duration: 0.4}}
              className={classNames(
                {
                  'z-50': isFilterOpen,
                },
                'fixed top-0 left-0 w-full sm:w-[306px] h-[100%] bg-midnightBlue',
              )}>
              <div
                style={{paddingTop: `${headerHeight + 18}px`}}
                className="px-[16px] pt-[21px] pb-[130px] z-[51] h-[100%]  outline-none overflow-y-auto overflow-x-hidden no-scrollbar">
                <div className="flex items-center justify-between mb-[20px]">
                  <p className="text-white text-[15px] leading-[19px] font-bold">
                    {locale?.filters}
                  </p>
                  <span
                    aria-label="click"
                    className="click-area cursor-pointer w-[11px]"
                    onClick={closeFilter}>
                    <img
                      src={close_icon}
                      alt={locale.ALT?.closeMenuIcon}
                      className="cursor-pointer"
                    />
                  </span>
                </div>
                <div className="flex flex-col">
                  <SubMenu
                    isActiveByDefault={true}
                    label={locale?.categories}
                    itemClassName="bg-midnightBlueLight py-[10px] px-[17px] rounded-[4px]"
                    contentContainerClassName="bg-transparent mt-[21px] rounded-none"
                    className="font-bold text-[14px]">
                    <GameCategoriesFilter categories={categories} />
                  </SubMenu>
                  <SubMenu
                    testid={testIds.PROVIDERS_FILTER_SELECTOR}
                    label={locale?.providers}
                    itemClassName="bg-midnightBlueLight py-[10px] px-[17px] rounded-[4px] mt-[21px]"
                    contentContainerClassName="bg-transparent mt-[21px] rounded-none"
                    className="font-bold text-[14px]">
                    <>
                      {window?.GlobalConfig?.region === regionTypes.CANADA &&
                        restriction?.providersFilter && (
                          <div className="pb-4 bg-midnightBlue">
                            <RestrictionContentBlock
                              {...{
                                handleClose: handleRestrictionBlockClose,
                                content: locale?.RESTRICTIONS?.providers,
                              }}
                            />
                          </div>
                        )}

                      <GameProvidersFilter data={data} />
                    </>
                  </SubMenu>
                  <SubMenu
                    label={locale?.features}
                    itemClassName="bg-midnightBlueLight py-[10px] px-[17px] rounded-[4px] mt-[21px]"
                    contentContainerClassName="bg-transparent mt-[21px] rounded-none"
                    className="font-bold text-[14px]">
                    <FeaturesFilter />
                  </SubMenu>
                  <SubMenu
                    label={locale?.volatility}
                    itemClassName="bg-midnightBlueLight py-[10px] px-[17px] rounded-[4px] mt-[21px]"
                    contentContainerClassName="bg-transparent mt-[21px] rounded-none"
                    className="font-bold text-[14px]">
                    <VolatilityFilter />
                  </SubMenu>
                  <div className="flex flex-col justify-between">
                    <Button
                      testid={testIds.FILTER_TRIGGER}
                      onClick={applyFilter}
                      type="primary"
                      title={locale?.applyFilter}
                      className="max-w-[240px] w-full mx-auto mt-[30px] py-[8px] uppercase"
                    />
                    <Button
                      testid={testIds.FILTER_TRIGGER}
                      onClick={resetFilter}
                      type="secondary"
                      title={locale?.resetFilter}
                      className="max-w-[240px] w-full mx-auto mt-[15px] py-[8px] uppercase"
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

GamesFilter.propTypes = {
  isFilterOpen: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  closeFilter: PropTypes.func.isRequired,
  headerHeight: PropTypes.number,
};

export default observer(GamesFilter);
