import NotificationBadges from '@components/Header/NotificationBadges';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import {useStore} from '@store/configureStore';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import Icon from '@components/Icon';

const OffersNotification = ({className}) => {
  const {offers} = useStore();

  return (
    <Link
      to={buildRoute(navigationRoutes.OFFERS)}
      className={classNames(className)}>
      {(offers?.availableOffers?.length > 0 ||
        offers?.availableWelcomeOffers?.length) > 0 && (
        <NotificationBadges count={offers?.availableCount} />
      )}
      <Icon icon="gift" size={32} className="text-default-controls-icon" />
    </Link>
  );
};

OffersNotification.propTypes = {
  className: PropTypes.string,
};

export default observer(OffersNotification);
