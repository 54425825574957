import Endpoints from '@api/endpoints';
import categoryKeys from '@constants/categoryKeys';
import gamesFilterConfig from '@constants/gamesFilterConfig';
import useGamesArraySize from '@hooks/useGamesArraySize';
import useGameSearch from '@hooks/useGameSearch';
import useMeta from '@hooks/useMeta';
import usePromoBanner from '@hooks/usePromoBanner';
import useRequestInfinite from '@hooks/useRequestInfinite';
import UrlHashParams from '@services/UrlHashParams';
import chunkArray from '@utils/chunkArray';
import getDevice from '@utils/getDevice';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';

const useGridGames = ({
  categoryKey,
  provider,
  unlimit,
  renderBanner,
  queryKey,
  isGameCarousel = false,
  slug = undefined,
  providerSlug = undefined,
  rowCount = null,
}) => {
  const banner = usePromoBanner(categoryKey);
  const history = useHistory();
  const itemsCount = useGamesArraySize(!rowCount && banner && renderBanner);
  const [games, setGames] = useState(null);
  const {queryString} = useGameSearch();
  const featuresFilter = UrlHashParams.get(
    history,
    gamesFilterConfig.AVAILABLE_FILTERS.FEATURES.SEARCH_KEY,
  );

  const composeRequest = () => {
    if (slug || providerSlug) {
      return {
        endpoint: Endpoints.GAMES,
        params: {
          preview: true,
          ['provider.slug_in']: [providerSlug],
          ['slug_ne']: slug,
          device: getDevice(),
          _sort: 'priority:DESC',
          _limit: 24,
        },
      };
    } else {
      switch (categoryKey) {
        case categoryKeys.RECENT: {
          return {
            endpoint: Endpoints.RECENT_GAMES,
            useAuth: true,
            params: {
              device: getDevice(),
              _limit: unlimit ? 24 : 12,
            },
          };
        }

        case categoryKeys.FAVORITE_GAMES: {
          return {
            endpoint: Endpoints.USER_FAVORITE_GAMES,
            useAuth: true,
            params: {
              device: getDevice(),
              _limit: unlimit ? 120 : 12,
            },
          };
        }

        default: {
          return {
            endpoint: Endpoints.GAMES,
            params: {
              preview: true,
              ['categories.slug_eq']: categoryKey || categoryKeys.ALL_GAMES,
              search: queryString || undefined,
              ['volatility']:
                UrlHashParams.get(
                  history,
                  gamesFilterConfig.AVAILABLE_FILTERS.VOLATILITY.SEARCH_KEY,
                )?.split(',') || undefined,
              ['provider.name_in']:
                UrlHashParams.get(
                  history,
                  gamesFilterConfig.AVAILABLE_FILTERS.PROVIDERS.SEARCH_KEY,
                )?.split(',') || undefined,
              ['categories.name_in']:
                UrlHashParams.get(
                  history,
                  gamesFilterConfig.AVAILABLE_FILTERS.CATEGORIES.SEARCH_KEY,
                )?.split(',') || undefined,
              ['free_spins']:
                featuresFilter &&
                featuresFilter?.indexOf(
                  gamesFilterConfig.AVAILABLE_FILTERS.FEATURES.VARIANTS[0],
                ) !== -1
                  ? 'true'
                  : undefined,
              ['respins']:
                featuresFilter &&
                featuresFilter?.indexOf(
                  gamesFilterConfig.AVAILABLE_FILTERS.FEATURES.VARIANTS[1],
                ) !== -1
                  ? 'true'
                  : undefined,
              ['bonuses']:
                featuresFilter &&
                featuresFilter?.indexOf(
                  gamesFilterConfig.AVAILABLE_FILTERS.FEATURES.VARIANTS[2],
                ) !== -1
                  ? 'true'
                  : undefined,
              ['risk']:
                featuresFilter &&
                featuresFilter?.indexOf(
                  gamesFilterConfig.AVAILABLE_FILTERS.FEATURES.VARIANTS[3],
                ) !== -1
                  ? 'true'
                  : undefined,
              ['provider.slug_eq']: provider || undefined,
              device: getDevice(),
              _sort: 'priority:DESC',
              _limit: unlimit ? 120 : 12,
            },
          };
        }
      }
    }
  };

  const {data, error, fetchNextPage} = useRequestInfinite(
    {
      ...composeRequest(),
    },
    {queryKey, cacheTime: 0},
  );

  const meta = useMeta(data?.pages?.[0]?.meta);
  const content = useMeta(data?.pages?.[0]?.content);
  const showMoreContent = useMeta(data?.pages?.[0]?.showMoreContent);
  /* TODO: refactor containsRestricted */
  const containsRestricted = data?.pages?.[0]?.containsRestricted;

  useEffect(() => {
    if (categoryKey) {
      setGames(null);
    }
  }, [categoryKey]);

  useEffect(() => {
    if (data) {
      const flattenedData = data?.pages?.flatMap((i) => i?.list);
      let chunkedData;

      if (rowCount) {
        chunkedData = chunkArray(
          flattenedData,
          rowCount,
          flattenedData?.length / rowCount,
        );
      }

      if (unlimit) {
        setGames(flattenedData);
      } else {
        if (isGameCarousel) {
          if (rowCount) {
            setGames(chunkedData);
          } else {
            setGames(flattenedData);
          }
        } else {
          setGames(flattenedData?.slice(0, itemsCount));
        }
      }
    }
  }, [data, itemsCount, unlimit, isGameCarousel, rowCount]);

  return {
    games,
    fetchNextPage,
    meta,
    content,
    showMoreContent,
    queryString,
    banner,
    itemsCount,
    containsRestricted,
    isLoading: !games && !error,
    isEmpty: !error && !games?.length,
    isError: error,
  };
};

export default useGridGames;
