import React, {useMemo} from 'react';
import classNames from 'classnames';
import lotteryConfig from '@constants/lotteryConfig';

const TicketsList = ({data}) => {
  const mapData = useMemo(
    () =>
      data?.map((item, key) => (
        <div className="w-1/2 sm:w-1/3 lg:w-1/4 xl:w-1/5 p-2" {...{key}}>
          <div className="flex flex-col items-center justify-center bg-lottery-ticket rounded-[5px]">
            <div className="py-6">
              <img
                className="max-w-[40px]"
                src={lotteryConfig?.TICKET_ICONS[item?.type]}
                alt={item?.number}
              />
            </div>
            <div className="pb-4 text-primary text-[19px] leading-[28px] font-bold">{`№ ${item?.number}`}</div>
          </div>
        </div>
      )),
    [data],
  );

  return (
    <div className="mb-4 px-2 md:px-[14px] mx-auto max-w-full xl:max-w-[1338px]">
      <div
        className={classNames('w-full flex flex-wrap', {
          'lg:justify-center': data?.length < 5,
        })}>
        {mapData}
      </div>
    </div>
  );
};

export default TicketsList;
