import React from 'react';
import PropTypes from 'prop-types';
import LotteryGetTicket from '@components/LotteryPage/LotteryGetTicket';
import LotteryRulesList from '@components/LotteryPage/LotteryRulesList';
import lotteryConfig from '@constants/lotteryConfig';

const LotteryRulesContent = ({data}) => {
  return (
    <div className="flex justify-center">
      <div className="mt-6 md:mt-[64px] max-w-[1082px] flex flex-col">
        <h2 className="text-primary text-[26px] md:text-[32px] leading-[40px] md:leading-[48px] text-center font-bold md:font-semibold">
          {data?.rules?.rules?.title}
        </h2>
        <LotteryRulesList {...{data}} />
        {data?.status === lotteryConfig?.STATUS?.IN_PROGRESS && (
          <LotteryGetTicket {...{className: 'mb-4'}} />
        )}
        <p className="text-[13px] text-center text-secondary sm:text-primary leading-[20px]">
          {data?.rules?.rules?.note}
        </p>
      </div>
    </div>
  );
};

LotteryRulesContent.propTypes = {
  data: PropTypes.any,
};

export default LotteryRulesContent;
