import modalRoutes from '@navigation/modalRoutes';

export default {
  TIMER: {
    SHORT: import.meta.env.MODE === 'development' ? 5 : 80,
    LONG: import.meta.env.MODE === 'development' ? 5 : 180,
  },
  SERVICE_SCREEN_TYPE: {
    PHONE: 'phone',
    EMAIL: 'email',
    OTP_COUNTER_ERROR: 'otpCounterError',
    FINISH_REGISTRATION: 'finishRegistration',
  },
  TAB_NAMES: {
    PHONE: 'phone',
    EMAIL: 'email',
  },
  OPEN_STATES: {
    ALL: 'all',
    UNAUTHORIZED: 'unauthorized',
    AUTHORIZED: 'authorized',
  },
  DISABLE_OVERLAY_CLOSE: [
    modalRoutes.REGISTER_SECOND_STEP,
    modalRoutes.REGISTER_THIRD_STEP,
  ],
};
