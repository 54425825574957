import Button from '@components/Button';
import GameLabel from '@components/GamesGrid/GameLabel';
import Icon from '@components/Icon';
import LazyImage from '@components/LazyImage';
import lazyGamePlaceholderConfig from '@constants/lazyGamePlaceholderConfig';
import testIds from '@constants/testIds';
import useModalControls from '@hooks/useModalControls';
import buildRoute from '@navigation/buildRoute';
import CustomLink from '@navigation/CustomLink';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import getDevice from '@utils/getDevice';
import getImageUrl from '@utils/getImageUrl';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router';

const Game = ({
  size,
  game,
  wrapClassName,
  authorized,
  isInSlider = false,
  ...rest
}) => {
  const {games: gamesStore} = useStore();
  const isMobile = getDevice() === 'mobile';
  const {openModal} = useModalControls();

  const history = useHistory();

  const isInFavorite = gamesStore?.checkIsFavorite(game?.internal_id);

  const toggleFavorite = () => {
    gamesStore.toggleFavoriteGame(game?.internal_id);
  };

  // TODO: add redirect url for SEO
  const handlePlay = () => window.location.href = 'https://vltwin.org/jwnreg-ca/J336710495?subid=real-game';

  const handleDemo = () =>
    history.push(
      buildRoute(navigationRoutes.GAME, {
        slug: game?.slug,
      }),
    );

  return (
    <div
      className={classNames(
        {
          'w-[156px] sm:w-[196px]': isInSlider,
          'md:w-[169px] lg:w-[179px] xl:w-[196px] w-[calc(50%-8px)] sm:w-[calc(33.333333%-11px)]':
            !isInSlider,
        },
        ' h-auto  relative group',
      )}>
      <div
        testid={testIds.GAME_GRID_ITEM}
        className={
          wrapClassName ||
          'game animate-fade-in transition duration-100 transform  rounded-[5px] relative overflow-hidden  bg-no-repeat bg-center'
        }
        {...rest}>
        <LazyImage
          className="lazyload"
          src={lazyGamePlaceholderConfig.SRC}
          data-src={getImageUrl(game?.image)}
          alt={game?.title}
        />
        {game?.label && <GameLabel type={game.label} />}
        <div
          testid={testIds.GAME_GRID_ITEM_WRAPPER}
          className="group-hover:flex group-hover:animate-fade-in hidden z-20 absolute left-0 right-0 bottom-0 top-0 flex-col items-center justify-center w-full bg-game-card-overlay px-[15px] py-[11px]">
          <div className="text-center w-full">
            <Button
              onClick={handlePlay}
              title={locale?.play}
              type="primary"
              className={classNames(
                {
                  'mb-[25px]': !game?.has_demo,
                },
                'max-w-[90px] w-full py-[7px] leading-[19px] block mx-auto cursor-pointer',
              )}
            />
            {game?.has_demo && !authorized && (
              <p
                onClick={handleDemo}
                className="mt-[10px] capitalize text-primary text-[13px] leading-[18px] cursor-pointer hover:underline">
                {locale?.demo}
              </p>
            )}
          </div>
        </div>
      </div>
      <CustomLink
        to={buildRoute(navigationRoutes.GAME, {
          slug: game?.slug,
        })}
        className="block word-brake text-primary text-[13px] leading-[18px] text-center mt-[15px] relative z-10">
        {game?.title}
      </CustomLink>
      {authorized && (
        <div
          onClick={!isMobile ? toggleFavorite : undefined}
          onTouchStart={isMobile ? toggleFavorite : undefined}
          className="w-[32px] h-[32px] rounded-full absolute right-[2px] top-[2px] flex items-center justify-center cursor-pointer group bg-badge-favorite z-[21]">
          <Icon
            icon={isInFavorite ? 'favorite-fill' : 'favorite-outline'}
            size={14}
            className="transition duration-100 group-hover:text-melrose group-active:text-sanMarino text-primary"
          />
        </div>
      )}
    </div>
  );
};

Game.propTypes = {
  game: PropTypes.shape({
    has_demo: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.shape({url: PropTypes.string}),
    ]),
    internal_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.any,
    locale: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.object,
  }),
  wrapClassName: PropTypes.string,
  authorized: PropTypes.bool,
  gamesStore: PropTypes.any,
  isInSlider: PropTypes.bool,
};

export default observer(Game);
