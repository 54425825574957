import hide_password_icon from '@images/icons/hide-password-icon.svg';
import show_password_icon from '@images/icons/show-password-icon.svg';
import {locale} from '@res/strings/locale';
import PropTypes from 'prop-types';
import React from 'react';

const TogglePasswordVisibility = ({
  isPasswordVisible,
  _togglePasswordVisibility,
}) => {
  return (
    <img
      className="absolute z-20 right-[12px] top-[12px] cursor-pointer"
      onClick={_togglePasswordVisibility}
      src={isPasswordVisible ? hide_password_icon : show_password_icon}
      alt={locale?.passwordVisibilityIcon}
    />
  );
};
TogglePasswordVisibility.propTypes = {
  isPasswordVisible: PropTypes.bool.isRequired,
  _togglePasswordVisibility: PropTypes.func.isRequired,
};

export default TogglePasswordVisibility;
