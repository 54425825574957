import Button from '@components/Button';
import {errorLocale} from '@res/strings/locale';
import PropTypes from 'prop-types';
import React from 'react';

const ErrorFallback = ({resetError = () => {}}) => {
  return (
    <div className={'h-screen flex items-center justify-center flex-col'}>
      <svg
        className={'animate-pulse'}
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 24 24"
        fill="#FFFFFF">
        <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
        <path d="m21.707 7.293-5-5A.996.996 0 0 0 16 2H8a.996.996 0 0 0-.707.293l-5 5A.996.996 0 0 0 2 8v8c0 .266.105.52.293.707l5 5A.996.996 0 0 0 8 22h8c.266 0 .52-.105.707-.293l5-5A.996.996 0 0 0 22 16V8a.996.996 0 0 0-.293-.707zM20 15.586 15.586 20H8.414L4 15.586V8.414L8.414 4h7.172L20 8.414v7.172z"></path>
      </svg>

      <h1 className={'text-white font-bold text-[24px] text-center'}>
        {errorLocale.internalIssue}
      </h1>
      <h2 className={'text-white text-[20px] pt-[10px] text-center'}>
        {errorLocale.teamNotified}
      </h2>

      <Button
        type={'secondary'}
        title={errorLocale.tryAgain}
        className={'px-[20px] py-[9px] mt-[20px]'}
        onClick={resetError}
      />
    </div>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.string,
  resetError: PropTypes.func.isRequired,
};

export default ErrorFallback;
