import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {
  getScrollElement,
  getScrollPage,
  isBrowser,
  scrollTo,
  scrollToElement,
} from './utils';

class ScrollMemory extends Component {
  constructor(props) {
    super(props);
    this.detectPop = this.detectPop.bind(this);
    this.url = new Map();
  }

  componentDidMount() {
    window.addEventListener('popstate', this.detectPop);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.detectPop);
  }

  shouldComponentUpdate(nextProps) {
    if (!isBrowser()) {
      return false;
    }
    const {location} = this.props;
    const actual = location;
    const next = nextProps.location;
    const key = actual.key || 'enter';

    const locationChanged = next.pathname !== actual.pathname; /*||
      (next.search !== actual.search && next.hash === '')*/

    const scroll = this.props.elementID
      ? getScrollElement(this.props.elementID)
      : getScrollPage();

    if (locationChanged) {
      this.props.elementID
        ? scrollToElement(0, this.props.elementID)
        : scrollTo(0);
      this.url.set(key, scroll);
    }
    return false;
  }

  detectPop(location) {
    if (!isBrowser()) {
      return;
    }
    const {state} = location;
    const key = state && state.key ? state.key : 'enter';
    const nextFind = this.url.get(key);

    if (nextFind) {
      this.props.elementID
        ? scrollToElement(nextFind, this.props.elementID)
        : scrollTo(nextFind);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollMemory);
