import PropTypes from 'prop-types';
import Button from '@components/Button';
import {locale} from '@res/strings/locale';
import React from 'react';

const LandingListMore = ({onClick, hasMore}) => {
  return (
    <>
      {hasMore && (
        <div className="text-center mt-8">
          <Button
            className="inline-block uppercase text-[14px] font-bold block leading-[40px] w-full max-w-[180px]"
            type="transparent"
            title={locale?.BUTTONS_TITLES?.loadMore}
            {...{onClick}}
          />
        </div>
      )}
    </>
  );
};

LandingListMore.propTypes = {
  onClick: PropTypes.func,
  hasMore: PropTypes.bool,
};

export default LandingListMore;
