import PropTypes from 'prop-types';

const CashBoxOfferItemParam = ({label, value}) => {
  return (
    <div className="text-white text-[12px] leading-[16px]">
      {label}: <b>{value}</b>
    </div>
  );
};

CashBoxOfferItemParam.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CashBoxOfferItemParam;
