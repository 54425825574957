import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import classNames from 'classnames';
import LotteryItemPrizeCard from '@components/LotteryPage/LotteryItemPrizeCard';
import LotteryPrizesCounter from '@components/LotteryPage/LotteryPrizesCounter';
import LotteryPrizesItemIcon from '@components/LotteryPage/LotteryPrizesItemIcon';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import LinesEllipsis from 'react-lines-ellipsis';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const LotteryPrizesItemList = ({data}) => {
  const mapData = useMemo(
    () =>
      data?.map((item, key) => {
        return (
          <div
            className={classNames({
              'w-full sm:w-[calc(50%-16px)] lg:w-[calc(25%-24px)]':
                data?.length === 4,
              'w-full sm:w-[calc(50%-16px)]': data?.length === 2,
              'w-full md:w-[calc(50%-16px)] lg:w-[calc(33.333%-22px)]':
                data?.length === 6 || data?.length === 3,
            })}
            {...{key}}>
            <LotteryItemPrizeCard>
              <LotteryPrizesCounter
                {...{
                  count: item?.prizes_num,
                  className: 'absolute top-[12px] right-[12px]',
                }}
              />
              <LotteryPrizesItemIcon
                {...{
                  src: `${item?.image?.full_url}?${item?.image?.component_hash}`,
                  alt: item?.prize,
                }}
              />
              <div className="w-full px-4 mb-8">
                <ResponsiveEllipsis
                  {...{
                    maxLine: 1,
                    ellipsis: '...',
                    text: item?.prize,
                    className:
                      'text-primary text-[19px] leading-[28px] font-bold text-center',
                  }}
                />
              </div>
            </LotteryItemPrizeCard>
          </div>
        );
      }),
    [data],
  );

  return (
    <div
      className={classNames('w-full mb-8 sm:mt-2 flex flex-wrap gap-[32px]', {
        'justify-center': data?.length === 1,
      })}>
      {!!data?.length && mapData}
    </div>
  );
};

LotteryPrizesItemList.propTypes = {
  data: PropTypes.any,
};

export default LotteryPrizesItemList;
