import React from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

const RangeShortDateRender = ({start, end, className}) => {
  const s = new Date(start);
  const e = new Date(end);

  return (
    <div {...{className}}>{`${format(s, 'dd')}.${format(s, 'MM')} - ${format(
      e,
      'dd',
    )}.${format(e, 'MM')}`}</div>
  );
};

RangeShortDateRender.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default RangeShortDateRender;
