import OfferCardBaseIcon from '@components/Offer/OfferCard/OfferCardBaseIcon';
import OfferCardCashbackIcon from '@components/Offer/OfferCard/OfferCardCashbackIcon';
import OfferCardGameIcon from '@components/Offer/OfferCard/OfferCardGameIcon';
import offerConstants from '@constants/offerConstants';
import PropTypes from 'prop-types';
import React from 'react';

const OfferCardIconSwitch = ({offers, data, game, className}) => {
  const SwitchIcon = (props) => {
    switch (props?.data?.type) {
      case offerConstants?.TYPE?.NO_DEPOSIT_GIFT_SPIN:
        return <OfferCardGameIcon {...{game: props?.game}} />;
      case offerConstants?.TYPE?.DEPOSIT_GIFT_SPIN:
        if (data?.status === offerConstants?.STATUS?.ACTIVE) {
          return <OfferCardGameIcon {...{game: props?.game}} />;
        }
        return <OfferCardBaseIcon {...{data: props?.data}} />;
      case offerConstants?.TYPE?.CASHBACK:
      case offerConstants?.TYPE?.CASHBACK_INFO:
        return <OfferCardCashbackIcon {...{offers: props?.offers}} />;
      default:
        return <OfferCardBaseIcon {...{data: props?.data, className}} />;
    }
  };

  return <SwitchIcon {...{offers, data, game}} />;
};

OfferCardIconSwitch.propTypes = {
  offers: PropTypes.any,
  data: PropTypes.any,
  game: PropTypes.any,
  className: PropTypes.string,
};

export default OfferCardIconSwitch;
