import './style.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
const Spinner = ({className = 'text-white'}) => {
  return (
    <svg
      className={classNames(
        'spinner z-[2] absolute top-[50%] left-[50%] mt-[-25px] ml-[-25px] w-[50px] h-[50px]',
        className,
      )}
      viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="currentColor"
        strokeWidth="5"
      />
    </svg>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
};

export default Spinner;
