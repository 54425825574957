import Button from '@components/Button';
import {errorLocale} from '@res/strings/locale';
import React from 'react';

const NoNetwork = () => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className={'h-screen flex items-center justify-center flex-col'}>
      <svg
        className={'animate-pulse'}
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 24 24"
        fill="#FFFFFF">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M21 11l2-2c-3.73-3.73-8.87-5.15-13.7-4.31l2.58 2.58c3.3-.02 6.61 1.22 9.12 3.73zm-2 2c-1.08-1.08-2.36-1.85-3.72-2.33l3.02 3.02.7-.69zM9 17l3 3 3-3c-1.65-1.66-4.34-1.66-6 0zM3.41 1.64L2 3.05 5.05 6.1C3.59 6.83 2.22 7.79 1 9l2 2c1.23-1.23 2.65-2.16 4.17-2.78l2.24 2.24C7.79 10.89 6.27 11.74 5 13l2 2c1.35-1.35 3.11-2.04 4.89-2.06l7.08 7.08 1.41-1.41L3.41 1.64z" />
      </svg>

      <h1 className={'text-white font-bold text-[24px]'}>
        {errorLocale.noNetwork}
      </h1>
      <h2 className={'text-white text-[20px] pt-[10px]'}>
        {errorLocale.siteCantWork}
      </h2>
      <h2 className={'text-white text-[20px] pt-[10px]'}>
        {errorLocale.withoutConnection}
      </h2>

      <Button
        type={'secondary'}
        title={errorLocale.refresh}
        className={'px-[20px] py-[9px] mt-[20px]'}
        onClick={handleReload}
      />
    </div>
  );
};

export default NoNetwork;
