import inboxMessagesConfig from '@constants/inboxMessagesConfig';
import useModalControls from '@hooks/useModalControls';
import modalRoutes from '@navigation/modalRoutes';
import formatDate from '@utils/formatDate';
import sliceText from '@utils/sliceText';
import classNames from 'classnames';
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {isToday, parseISO} from 'date-fns';
import gift from '@images/inbox-messages/gift.png';
import confirm from '@images/inbox-messages/confirm.png';
import info from '@images/inbox-messages/info.png';
import welcome from '@images/inbox-messages/welcome.png';
import useModalRegister from '@hooks/useModalRegister';

const InboxMessageItem = ({
  message,
  useDesktopView,
  setActiveMessageUuid,
  activeMessageUuid,
}) => {
  const {openModal} = useModalControls();
  const {params} = useModalRegister(modalRoutes.MESSAGES);

  const openMessage = (uuid) => {
    openModal(modalRoutes.MESSAGE, {
      uuid,
    });
  };

  const checkTime = (date) => {
    if (isToday(parseISO(date))) {
      return formatDate(date, true);
    }
    return formatDate(date, false, true);
  };

  const getMessageImageUrl = (type) => {
    switch (type) {
      case inboxMessagesConfig.IMAGE.GIFT:
        return gift;
      case inboxMessagesConfig.IMAGE.CONFIRM:
        return confirm;
      case inboxMessagesConfig.IMAGE.INFORMATION:
        return info;
      case inboxMessagesConfig.IMAGE.WELCOME:
        return welcome;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (params?.uuid) {
      if (useDesktopView) {
        setActiveMessageUuid(params?.uuid);
      } else {
        openMessage(params?.uuid);
      }
    }
  }, [params?.uuid]);

  return (
    <div
      className={classNames(
        {
          'bg-active-message-modal': message.uuid === activeMessageUuid,
        },
        'flex flex-row w-full px-[16px] py-[12px] border-b border-message-item-border relative cursor-pointer transition duration-300',
      )}
      onClick={() =>
        useDesktopView
          ? setActiveMessageUuid(message.uuid)
          : openMessage(message.uuid)
      }>
      <img src={getMessageImageUrl(message?.action_image)} alt="some alt" />
      <div className="ml-[8px]">
        <p
          className={classNames(
            {'font-bold': message?.new},
            'text-primary text-subheading leading-subheading mb-[4px]',
          )}>
          {sliceText(message?.title, 20)}
        </p>
        <p className="text-secondary text-body-secondary leading-body-secondary">
          {sliceText(message?.content, 25)}
        </p>
      </div>
      <div className="absolute top-[14px] right-[16px]">
        <p className="text-secondary text-footnote leading-footnote">
          {message?.available_at && checkTime(message?.available_at)}
        </p>
        {message?.new && (
          <div className="flex relative ml-auto mt-[8px] w-[12px] h-[12px]">
            <span className="animate-ping inline-flex h-full w-full bg-notification-trigger rounded-full absolute" />
            <span className="inline-flex w-[12px] h-[12px] bg-notification-trigger rounded-full relative" />
          </div>
        )}
      </div>
    </div>
  );
};

InboxMessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  useDesktopView: PropTypes.bool,
  setActiveMessageUuid: PropTypes.func,
  activeMessageUuid: PropTypes.any,
};

export default InboxMessageItem;
