import tournament_place_1 from '@images/icons/tournament-place-1.svg';
import tournament_place_2 from '@images/icons/tournament-place-2.svg';
import tournament_place_3 from '@images/icons/tournament-place-3.svg';

import tournament_prizePlaces_icon from '@images/icons/tournament-prizePlaces-icon.svg';
import tournament_minBet_icon from '@images/icons/tournament-minBet-icon.svg';
import tournament_minRounds_icon from '@images/icons/tournament-minRounds-icon.svg';
import tournament_type_icon from '@images/icons/tournament-type-icon.svg';
import tournament_mainPrize_icon from '@images/icons/tournament-mainPrize-icon.svg';
import tournament_date_icon from '@images/icons/tournament-date-icon.svg';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import format from 'date-fns/format';

export default {
  GAMES: 'games',
  RESULTS: 'results',

  STATUS: {
    NEW: 'new',
    PLANNED: 'planned',
    IN_PROGRESS: 'in_progress',
    FINISHED: 'finished',
    CANCELED: 'canceled',
    ARCHIVED: 'archived',
  },
  PREVIEW_STATUS: 'planned',
  ACTIVE_STATUS: 'in_progress',
  ARCHIVED_STATUS: 'archived',

  ICONS: {
    PLACES: [tournament_place_1, tournament_place_2, tournament_place_3],
  },

  REFETCH_INTERVAL: 5000,

  INIT_PAGINATION_LIMIT: 10,
  PAGINATION_LIMIT: 6,

  FILTER: {
    CURRENT: (i) => i?.status === 'planned' || i?.status === 'in_progress',
    COMPLETED: (i) => i?.status !== 'planned' && i?.status !== 'in_progress',
  },

  FIND: {
    IN_PROGRESS: (tournament) => tournament?.status === 'in_progress',
    PLANNED: (tournament) => tournament?.status === 'planned',
  },

  STATUS_BADGE: function (status) {
    const config = {
      [this.STATUS?.PLANNED]: {
        title: locale?.BANNER_STATUSES?.planned,
        className: 'bg-denim text-white',
      },
      [this.STATUS?.IN_PROGRESS]: {
        title: locale?.BANNER_STATUSES?.in_progress,
        className: 'bg-gold text-stratosDarker',
      },
      [this.STATUS?.FINISHED]: {
        title: locale?.BANNER_STATUSES?.finished,
        className: 'bg-prussianBlueLight text-white',
      },
      [this.STATUS?.ARCHIVED]: {
        title: locale?.BANNER_STATUSES?.finished,
        className: 'bg-prussianBlueLight text-white',
      },
      [this.STATUS?.CANCELED]: {
        title: '',
        className: 'hidden',
      },
    };
    return config[status] ?? {title: '', className: 'hidden'};
  },

  TABS: function (data, slug) {
    return [
      {
        to: buildRoute(navigationRoutes.TOURNAMENT_RULES, {slug}),
        title: locale?.BUTTONS_TITLES?.rules,
      },
      {
        to: buildRoute(navigationRoutes.TOURNAMENT, {
          slug,
          route: this?.GAMES,
        }),
        title: `${locale?.BUTTONS_TITLES?.games} ${
          data?.games?.length > 0 && `(${data?.games?.length})`
        }`,
      },
      {
        to: buildRoute(navigationRoutes.TOURNAMENT, {
          slug,
          route: this?.RESULTS,
        }),
        title: locale?.BUTTONS_TITLES?.prizes,
      },
    ];
  },

  RULES_PARAMS: function (data, currency) {
    return [
      {
        icon: tournament_mainPrize_icon,
        title: locale?.TOURNAMENT_PARAMS?.mainPrize,
        data:
          data?.prizes?.length > 0 &&
          formatAmount({
            amount: (data?.prizes[0]?.prize?.[currency] ?? 0) / 100,
            minimumFractionDigits: 0,
            currency,
          }),
      },
      {
        icon: tournament_date_icon,
        title: locale?.TOURNAMENT_PARAMS?.date,
        data: this.setDate(data?.start_at, data?.end_at),
      },
      {
        icon: tournament_prizePlaces_icon,
        title: locale?.TOURNAMENT_PARAMS?.prizePlaces,
        data: data?.prizes_count,
      },
      {
        icon: tournament_minBet_icon,
        title: locale?.TOURNAMENT_PARAMS?.minBet,
        data:
          data?.min_bet &&
          data?.min_bet?.[currency] &&
          formatAmount({
            amount: data?.min_bet?.[currency] / 100,
            minimumFractionDigits: 0,
            currency,
          }),
      },
      {
        icon: tournament_minRounds_icon,
        title: locale?.TOURNAMENT_PARAMS?.minRounds,
        data: data?.min_rounds,
      },
      {
        icon: tournament_type_icon,
        title: locale?.TOURNAMENT_PARAMS?.type,
        data: locale?.TOURNAMENT_PARAMS?.sumPoints,
      },
    ];
  },

  setDate: (start, end) => {
    const s = new Date(start);
    const e = new Date(end);

    return `${format(s, 'dd')}.${format(s, 'MM')} - ${format(e, 'dd')}.${format(
      e,
      'MM',
    )}`;
  },
};
