import facebook_logo_icon from '@images/icons/facebook-logo-icon.svg';
import mail_logo_icon from '@images/icons/mail-logo-icon.svg';
import odnoklassniki_logo_icon from '@images/icons/odnoklassniki-logo-icon.svg';
import vk_logo_icon from '@images/icons/vk-logo-icon.svg';
import yandex_logo_icon from '@images/icons/yandex-logo-icon.svg';
import {locale} from '@res/strings/locale';

export default {
  socialNetworks: [
    {
      name: 'odnoklassniki',
      image: odnoklassniki_logo_icon,
      alt: locale?.odnoklassniki,
    },
    {
      name: 'vkontakte',
      image: vk_logo_icon,
      alt: locale?.vk,
    },
    {
      name: 'yandex',
      image: yandex_logo_icon,
      alt: locale?.yandex,
    },
    {
      name: 'mailru',
      image: mail_logo_icon,
      alt: locale?.mail,
    },
    {
      name: 'facebook',
      image: facebook_logo_icon,
      alt: locale?.facebook,
    },
  ],
};
