import PropTypes from 'prop-types';

const BannerTitle = ({title}) => {
  return (
    <p className="text-white text-[22px] font-sans lg:text-[29px] xl:text-[32px] text-center">
      {title}
    </p>
  );
};

BannerTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BannerTitle;
