import regionTypes from '@constants/regionTypes';
import useDeviceProps from '@hooks/useDeviceProps';
import bonus_box_icon from '@images/icons/bonus-box-icon.png';
import {locale} from '@res/strings/locale';
import classNames from 'classnames';
import React from 'react';
import LocalizationService from '@services/LocalizationService';

const RegistrationFormBonus = () => {
  const {isLandScape} = useDeviceProps();

  return (
    <div
      className={classNames(
        {
          'bg-registrationModal':
            window?.GlobalConfig?.region === regionTypes.CIS,
          'bg-registration-bonus-ca bg-center bg-cover':
            window?.GlobalConfig?.region === regionTypes.CANADA &&
            LocalizationService.getLanguage() !== 'fr',
          'bg-registration-bonus-fr bg-center bg-cover':
            window?.GlobalConfig?.region === regionTypes.CANADA &&
            LocalizationService.getLanguage() === 'fr',
        },
        'sm:w-1/2 hidden sm:flex justify-center items-center',
      )}>
      {window?.GlobalConfig?.region === regionTypes.CIS && (
        <div className="flex-col text-center">
          <p className="text-[21px] text-cerulean">{locale?.welcomeBonus}</p>
          <p className="text-[40px] text-white font-bold">
            {locale?.welcomeBonusReward}
          </p>
          <p className="text-[24px] text-white">
            {locale?.welcomeBonusMessage}
          </p>
          <img
            className={classNames({'w-[200px] mx-auto block': isLandScape})}
            src={bonus_box_icon}
            alt={locale?.welcomeBonus}
          />
        </div>
      )}
    </div>
  );
};

export default RegistrationFormBonus;
