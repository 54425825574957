import Balance, {BalanceState} from '@store/BalanceStore';
import CashBox, {CashBoxState} from '@store/CashBoxStore';
import Filters, {FiltersState} from '@store/FiltersStore';
import Modal, {ModalState} from '@store/ModalStore';
import Offers, {OffersState} from '@store/OffersStore';
import FloatingNotification, {
  FloatingNotificationState,
} from '@store/FloatingNotificationStore';
import User, {UserState} from '@store/UserStore';
import Games, {GamesState} from '@store/GamesStore';
import PopUpNotification, {
  PopUpNotificationState,
} from '@store/PopUpNotificationStore';
import {types} from 'mobx-state-tree';
import {createContext, useContext} from 'react';
import StretchNotification, {
  StretchNotificationState,
} from '@store/StretchNotificationStore';
import Restriction, {RestrictionState} from '@store/RestrictionStore';

const RootStore = types.model({
  user: User,
  filters: Filters,
  modal: Modal,
  offers: Offers,
  balance: Balance,
  cashBox: CashBox,
  games: Games,
  popUpNotification: PopUpNotification,
  stretchNotification: StretchNotification,
  floatingNotification: FloatingNotification,
  restriction: Restriction,
});

export const rootStore = RootStore.create({
  user: UserState,
  filters: FiltersState,
  modal: ModalState,
  offers: OffersState,
  balance: BalanceState,
  cashBox: CashBoxState,
  games: GamesState,
  popUpNotification: PopUpNotificationState,
  stretchNotification: StretchNotificationState,
  floatingNotification: FloatingNotificationState,
  restriction: RestrictionState,
});

const RootStoreContext = createContext(null);
export const MSTProvider = RootStoreContext.Provider;
export function useStore() {
  const mstStore = useContext(RootStoreContext);
  if (mstStore === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return mstStore;
}
