import {Endpoints} from '@api';
import floatingNotificationEvents from '@constants/floatingNotificationEvents';
import tokenTypes from '@constants/tokenTypes';
import {locale} from '@res/strings/locale';
import StorageService from '@services/StorageService';
import {flow, types} from 'mobx-state-tree';
import {io} from 'socket.io-client';
import notificationConfig from '@constants/notificationConfig';
import NotificationService from '@services/NotificationService';
import _get from 'lodash.get';
import {apolloClient, INBOX_MESSAGES} from '@graphql';

export const FloatingNotificationState = {};

const FloatingNotification = types
  .model({})
  .volatile((self) => ({
    socket: null,
  }))
  .actions((store) => ({
    connect: flow(function* () {
      const token = yield StorageService.current?.get(tokenTypes.ACCESS);

      const socket = io.connect(window.GlobalConfig?.endpoints?.BASE, {
        path: Endpoints.FLOATING_NOTIFICATION_SOCKET,
        auth: {
          token,
        },
      });

      socket.io.on('error', (err) => {
        console.error('SOCKET_ERROR', err);
      });

      socket.io.on('reconnect_error', (err) => {
        console.error('SOCKET_RECONNECT_ERROR', err);
      });

      socket.on('connect_error', (err) => {
        console.error('SOCKET_CONNECT_ERROR', err);
      });

      const getNotification = (data) => ({
        title: _get(locale.NOTIFICATIONS, data?.title, undefined),
        message: _get(locale.NOTIFICATIONS, data?.body, undefined),
      });

      socket.on(floatingNotificationEvents.NEW_NOTIFICATION, (data) => {
        const notification = getNotification(data);

        if (data?.template === notificationConfig.TEMPLATES.INBOX_MESSAGES) {
          apolloClient.refetchQueries({
            include: [INBOX_MESSAGES.COUNT],
          });
        }

        if (
          !data?.data?.action_image?.includes(
            notificationConfig.INBOX_MESSAGE_ACTION_IMAGES.GIFT,
          ) &&
          !data?.data?.action_image?.includes(
            notificationConfig.INBOX_MESSAGE_ACTION_IMAGES.CONFIRM,
          )
        ) {
          if (notification.title || notification.body) {
            if (data?.type) {
              switch (data?.type) {
                case notificationConfig.TYPES.INFO:
                  return NotificationService.info({
                    notification,
                    isCloseButtonVisible: true,
                    autoClose: 5000,
                    template: data?.template,
                    classNames:
                      'close-button-white !bg-gradient-b-confirmationNotificationDefault',
                    data: data?.data,
                  });
                case notificationConfig.TYPES.ERROR:
                  return NotificationService.error({
                    notification,
                    isCloseButtonVisible: true,
                    autoClose: 5000,
                    template: data?.template,
                    classNames:
                      'close-button-white !bg-gradient-b-depositFailedNotification',
                    data: data?.data,
                  });
                case notificationConfig.TYPES.WARNING:
                  return NotificationService.warning({
                    notification,
                    isCloseButtonVisible: true,
                    autoClose: 5000,
                    template: data?.template,
                    classNames:
                      'close-button-white !bg-gradient-b-depositDeclinedNotification',
                    data: data?.data,
                  });
                case notificationConfig.TYPES.SUCCESS:
                  return NotificationService.success({
                    notification,
                    isCloseButtonVisible: true,
                    autoClose: 5000,
                    template: data?.template,
                    classNames:
                      'close-button-white !bg-gradient-b-depositSuccessNotification',
                    data: data?.data,
                  });
                default:
                  return NotificationService.default({
                    notification,
                    isCloseButtonVisible: true,
                    autoClose: 5000,
                    template: data?.template,
                    classNames:
                      'close-button-white !bg-gradient-b-confirmationNotificationDefault',
                    data: data?.data,
                  });
              }
            }
          }
        }
      });

      store.socket = socket;
    }),
    disconnect() {
      if (store.socket) {
        store.socket.disconnect();
      }
    },
  }));

export default FloatingNotification;
