import Balance from '@components/CashBox/Balance';
import Deposit from '@components/CashBox/Deposit/Deposit';
import History from '@components/CashBox/Histroy/History';
import Withdrawal from '@components/CashBox/Withdrawal/Withdrawal';
import cashBoxConfig from '@constants/cashBoxConfig';
import useModalControls from '@hooks/useModalControls';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

const CashBox = ({activeTab, toggleNextStep, isNextStep, resetNextStep}) => {
  const {user, balance} = useStore();
  const {openModal} = useModalControls();

  return (
    <div className="flex flex-col h-[100%] relative overflow-y-auto overflow-x-hidden">
      {!isNextStep && (
        <>
          <Balance amount={balance?.total} currency={user?.profile?.currency} />
          <div className="max-w-[320px] w-full mx-auto px-[32px] flex  relative">
            <div
              className={classNames(
                {
                  'translate-x-[3px]':
                    activeTab === cashBoxConfig.TAB_NAMES.DEPOSIT,
                  'translate-x-[90px]':
                    activeTab === cashBoxConfig.TAB_NAMES.WITHDRAWAL,
                  'translate-x-[173px]':
                    activeTab === cashBoxConfig.TAB_NAMES.HISTORY,
                },
                'absolute h-[2px] w-[79px] border-b-[2px] border-redRibbon bottom-[-4px] transform transition duration-200 ease-in-out',
              )}
            />
            <div className="h-[3px] absolute left-0 right-0 bottom-[-5px]" />

            <div
              className={classNames(
                {
                  'text-poloBlue':
                    activeTab !== cashBoxConfig.TAB_NAMES.DEPOSIT,
                  'text-resolutionBlue':
                    activeTab === cashBoxConfig.TAB_NAMES.DEPOSIT,
                },
                'transition duration-200 ease w-1/3 cursor-pointer uppercase text-center font-bold text-[12px]',
              )}
              onClick={() => openModal(modalRoutes.CASH_BOX)}>
              {locale?.deposit}
            </div>
            <div
              className={classNames(
                {
                  'text-poloBlue':
                    activeTab !== cashBoxConfig.TAB_NAMES.WITHDRAWAL,
                  'text-resolutionBlue':
                    activeTab === cashBoxConfig.TAB_NAMES.WITHDRAWAL,
                },
                'transition duration-200 ease w-1/3 cursor-pointer uppercase text-center font-bold text-[12px]',
              )}
              onClick={() => openModal(modalRoutes.CASH_BOX_WITHDRAWAL)}>
              {locale?.withdrawal}
            </div>
            <div
              className={classNames(
                {
                  'text-poloBlue':
                    activeTab !== cashBoxConfig.TAB_NAMES.HISTORY,
                  'text-resolutionBlue':
                    activeTab === cashBoxConfig.TAB_NAMES.HISTORY,
                },
                'transition duration-200 ease w-1/3 cursor-pointer uppercase text-center font-bold text-[12px]',
              )}
              onClick={() => openModal(modalRoutes.CASH_BOX_HISTORY)}>
              {locale?.history}
            </div>
          </div>
        </>
      )}
      {activeTab === cashBoxConfig.TAB_NAMES.DEPOSIT && (
        <Deposit {...{toggleNextStep, isNextStep, resetNextStep}} />
      )}
      {activeTab === cashBoxConfig.TAB_NAMES.WITHDRAWAL && (
        <Withdrawal {...{isNextStep, resetNextStep, toggleNextStep}} />
      )}
      {activeTab === cashBoxConfig.TAB_NAMES.HISTORY && <History />}
    </div>
  );
};

CashBox.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggleNextStep: PropTypes.func.isRequired,
  isNextStep: PropTypes.bool.isRequired,
  resetNextStep: PropTypes.func.isRequired,
};

export default observer(CashBox);
