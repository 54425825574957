import Carousel from '@components/Carousel/Carousel';
import CategorySelector from '@components/CategorySelector/CategorySelector';
import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import Meta from '@components/Meta';
import React, {useState} from 'react';
import PropTypes from 'prop-types';

const BaseLayout = ({
  children,
  useCarousel = true,
  isPermanentSticky = false,
}) => {
  const [headerHeight, setHeaderHeight] = useState(0);

  return (
    <main className="bg-midnightBlue relative">
      <Header {...{setHeaderHeight}} />
      <Meta />
      {useCarousel && <Carousel />}
      <CategorySelector {...{isPermanentSticky, headerHeight}} />
      {children}
      <Footer />
    </main>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
  useCarousel: PropTypes.bool,
  isPermanentSticky: PropTypes.bool,
};

export default BaseLayout;
