import AuthModal from '@components/Modals/AuthModal';
import CashBoxDepositModal from '@components/Modals/CashBoxDepositModal';
import CashBoxHistoryModal from '@components/Modals/CashBoxHistoryModal';
import CashBoxWithdrawalModal from '@components/Modals/CashBoxWithdrawalModal';
import EmailSentModal from '@components/Modals/EmailSentModal';
import FinishRegistrationModal from '@components/Modals/FinishRegistrationModal';
import ForgotPasswordModal from '@components/Modals/ForgotPasswordModal';
import MessageModal from '@components/Modals/MessageModal';
import MessagesListModal from '@components/Modals/MessagesListModal';
import ModalRoute from '@components/Modals/ModalRoute';
import ModalSwitch from '@components/Modals/ModalSwitch';
import RegistrationModal from '@components/Modals/RegistrationModal';
import ResetPasswordModal from '@components/Modals/ResetPasswordModal';
import ResetPasswordOtpModal from '@components/Modals/ResetPasswordOtpModal';
import ResetPasswordSuccessModal from '@components/Modals/ResetPasswordSuccessModal';
import SecondStepRegistrationModal from '@components/Modals/SecondStepRegistrationModal';
import ThirdStepRegistrationModal from '@components/Modals/ThirdStepRegistrationModal';
import WelcomeBonusModal from '@components/Modals/WelcomeBonusModal';
import modalConfig from '@constants/modalConfig';
import modalRoutes from '@navigation/modalRoutes';
import PropTypes from 'prop-types';
import React from 'react';
import PopUpNotification from '@components/Modals/PopUpNotification/PopUpNotification';
import OfferInfoModal from '@components/Modals/OfferInfoModal/OfferInfoModal';
import RestrictionProvidersModal from '@components/Modals/RestrictionProvidersModal';

const ModalRouter = ({activeModal, handleCloseModal}) => {
  return (
    <ModalSwitch {...{activeModal}}>
      <ModalRoute
        openState={modalConfig.OPEN_STATES.UNAUTHORIZED}
        route={modalRoutes.REGISTER}
        component={RegistrationModal}
        {...{handleCloseModal}}
      />
      <ModalRoute
        openState={modalConfig.OPEN_STATES.UNAUTHORIZED}
        route={modalRoutes.REGISTER_SECOND_STEP}
        component={SecondStepRegistrationModal}
        {...{handleCloseModal}}
      />
      <ModalRoute
        openState={modalConfig.OPEN_STATES.UNAUTHORIZED}
        route={modalRoutes.REGISTER_THIRD_STEP}
        component={ThirdStepRegistrationModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.UNAUTHORIZED}
        route={modalRoutes.AUTH}
        component={AuthModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.UNAUTHORIZED}
        route={modalRoutes.FORGOT_PASSWORD}
        component={ForgotPasswordModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.UNAUTHORIZED}
        route={modalRoutes.FORGOT_PASSWORD_EMAIL_SENT}
        component={EmailSentModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.UNAUTHORIZED}
        route={modalRoutes.FORGOT_PASSWORD_VERIFY_CODE}
        component={ResetPasswordOtpModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.UNAUTHORIZED}
        route={modalRoutes.RESET_PASSWORD}
        component={ResetPasswordModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.UNAUTHORIZED}
        route={modalRoutes.RESET_PASSWORD_SUCCESS}
        component={ResetPasswordSuccessModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.ALL}
        route={modalRoutes.WELCOME_BONUS}
        component={WelcomeBonusModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.AUTHORIZED}
        route={modalRoutes.CASH_BOX}
        component={CashBoxDepositModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.AUTHORIZED}
        route={modalRoutes.CASH_BOX_WITHDRAWAL}
        component={CashBoxWithdrawalModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.AUTHORIZED}
        route={modalRoutes.CASH_BOX_HISTORY}
        component={CashBoxHistoryModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.AUTHORIZED}
        route={modalRoutes.OFFER_INFO}
        component={OfferInfoModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.ALL}
        route={modalRoutes.RESTRICTION_PROVIDERS}
        component={RestrictionProvidersModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.AUTHORIZED}
        route={modalRoutes.POP_UP_NOTIFICATION}
        component={PopUpNotification}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.UNAUTHORIZED}
        route={modalRoutes.COMPLETE_REGISTRATION}
        component={FinishRegistrationModal}
        {...{handleCloseModal}}
      />

      <ModalRoute
        openState={modalConfig.OPEN_STATES.AUTHORIZED}
        route={modalRoutes.MESSAGES}
        component={MessagesListModal}
        {...{handleCloseModal}}
      />
      <ModalRoute
        openState={modalConfig.OPEN_STATES.AUTHORIZED}
        route={modalRoutes.MESSAGE}
        component={MessageModal}
        {...{handleCloseModal}}
      />
    </ModalSwitch>
  );
};

ModalRouter.propTypes = {
  activeModal: PropTypes.string,
  handleCloseModal: PropTypes.func,
};

export default ModalRouter;
