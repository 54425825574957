import PropTypes from 'prop-types';

const LotteryItemPrizeCard = ({children}) => {
  return (
    <div className="relative w-full rounded-[10px] shadow-lottery-prize-card bg-lottery-prize-card border-[1px] border-line-lottery-prize-card flex flex-col items-center justify-center">
      {children}
    </div>
  );
};

LotteryItemPrizeCard.propTypes = {
  children: PropTypes.node,
};

export default LotteryItemPrizeCard;
