import Button from '@components/Button';
import ModalWrapper from '@components/Modals/ModalWrapper';
import useModalRegister from '@hooks/useModalRegister';
import email_send_icon from '@images/icons/email-send-icon.svg';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import PropTypes from 'prop-types';
import React from 'react';

const EmailSentModal = ({handleCloseModal}) => {
  useModalRegister(modalRoutes.FORGOT_PASSWORD_EMAIL_SENT, {
    requiredParams: ['sent'],
    handleCloseModal,
  });

  return (
    <ModalWrapper {...{handleCloseModal}}>
      <div className="flex flex-col justify-between px-[32px] pt-[24px] pb-[50px] h-[100%]">
        <div className="mb-[24px] text-center mt-[50px]">
          <img
            src={email_send_icon}
            alt={locale?.emailIcon}
            className="mx-auto mb-[20px]"
          />
          <h3 className="text-resolutionBlue font-bold text-[19px] leading-[26px] mb-[11px]">
            {locale?.sendLetterTitle}
          </h3>
          {locale?.sendLetterMessages &&
            locale?.sendLetterMessages?.map((message, index) => (
              <p
                key={index}
                className="text-resolutionBlue text-[14px] leading-[19px] mb-[5px]">
                {message}
              </p>
            ))}
        </div>
        <Button
          onClick={handleCloseModal}
          htmlType="submit"
          title={locale?.okey}
          className="uppercase w-full py-[10px] rounded-[5px]"
          type="secondary"
        />
      </div>
    </ModalWrapper>
  );
};

EmailSentModal.propTypes = {
  handleCloseModal: PropTypes.func,
};

export default EmailSentModal;
