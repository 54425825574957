import Button from '@components/Button';
import buttonActions from '@constants/buttonActions';
import PropTypes from 'prop-types';
import React from 'react';

const ContentButton = ({data}) => {
  return (
    <>
      {data && (
        <div className="flex justify-center my-[40px]">
          <Button
            type={data?.type}
            title={data?.title}
            isLink={data?.action === buttonActions.REDIRECT}
            action={data?.action}
            actionData={data.action_data}
            className="h-[40px] min-w-[196px] max-w-full text-base font-bold leading-5 py-[10px] px-[15px] rounded-[5px] uppercase text-center"
          />
        </div>
      )}
    </>
  );
};

ContentButton.propTypes = {
  data: PropTypes.any,
};

export default ContentButton;
