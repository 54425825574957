import CashBoxOfferItem from '@components/Offer/CashBox/CashBoxOfferItem';
import offerConstants from '@constants/offerConstants';
import {locale} from '@res/strings/locale';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';

const SelectedOffers = ({
  offers,
  paymentMethod,
  user,
  getValues,
  setOfferParams,
}) => {
  const active =
    offers?.selected?.type === offerConstants?.TYPE?.WELCOME
      ? +getValues('amount') >= +offers?.selected?.bonus?.min_deposit / 100
      : +getValues('amount') >= +offers?.selected?.min_deposit_amount / 100;
  const activeOfferParams = useMemo(() => {
    let result = {};

    if (offers?.selected) {
      result.offer_id = offers?.selected?.id;
      result.offer_type = offers?.selected?.type;
    }
    return result;
  }, [offers?.selected]);

  useEffect(() => {
    setOfferParams(active ? activeOfferParams : {});
  }, [active]);

  return (
    <>
      {offers?.selected && (
        <div className="text-center">
          <div className={classNames('flex justify-center')}>
            <CashBoxOfferItem
              {...{
                active,
                data: offers?.selected,
                offers,
                user,
                isButton: false,
              }}
            />
          </div>
          {!active ? (
            <p className={classNames('text-redRibbon text-[12px] my-[6px]')}>
              {paymentMethod?.config?.bonus_available
                ? locale?.OFFER?.insufficientAmount
                : locale?.OFFER?.cannotActivateOffer}
            </p>
          ) : (
            <div className="mb-[30px]" />
          )}
        </div>
      )}
    </>
  );
};

SelectedOffers.propTypes = {
  offers: PropTypes.any,
  user: PropTypes.any,
  getValues: PropTypes.func,
  setOfferParams: PropTypes.func,
};

export default SelectedOffers;
