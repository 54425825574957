import buildRoute from '@navigation/buildRoute';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from 'react-custom-checkbox';
import * as Icon from 'react-icons/fa';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';

const TermsCheckBoxCA = ({
  label = '',
  className,
  isError = false,
  errorMessage = '',
  borderRadius = 3,
  size = 18,
  iconSize = 10,
  iconColor = '#F42147',
  onChange,
  value,
  setValue,
  name,
  ...rest
}) => {
  return (
    <>
      <div className="flex mt-[24px] items-start">
        <Checkbox
          type="checkbox"
          className={classNames(
            {
              'border-hawkesBlue': !isError,
              'border-redRibbon': isError,
            },
            className,
            'bg-red mt-[6px]',
          )}
          icon={<Icon.FaCheck color={iconColor} size={iconSize} />}
          checked={value}
          borderColor={isError ? '#F42147' : '#4E73B7'}
          {...{size, borderRadius, onChange}}
          {...rest}
        />
        <div className="pl-[10px]">
          <span
            onClick={() => setValue(name, !value, {shouldValidate: true})}
            className="text-resolutionBlue text-[14px] leading-[16px]">
            {`${label}  `}
            <a
              href={buildRoute(navigationRoutes.TERMS_AND_CONDITIONS)}
              target="_blank"
              className="mr-[5px] cursor-pointer underline text-resolutionBlue text-[14px] leading-[16px] word-brake inline-block">
              {locale?.termsLinkTextCA},
            </a>
            <a
              href={buildRoute(navigationRoutes.PRIVACY_POLICY)}
              target="_blank"
              className="mr-[5px] cursor-pointer underline text-resolutionBlue text-[14px] leading-[16px] word-brake inline-block">
              {locale?.privacyLinkText}
            </a>
            {`${locale?.and}  `}
            <a
              href={buildRoute(navigationRoutes.COOKIES)}
              target="_blank"
              className="cursor-pointer underline text-resolutionBlue text-[14px] leading-[16px] word-brake inline-block">
              {locale?.cookiesLinkText}
            </a>
          </span>
        </div>
      </div>

      {isError && (
        <span
          className={classNames(
            {block: isError, hidden: !isError},
            'text-redRibbon text-[12px] mt-[8px] mb-[16px]',
          )}>
          {errorMessage}
        </span>
      )}
    </>
  );
};

TermsCheckBoxCA.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  rest: PropTypes.any,
  size: PropTypes.number,
  borderRadius: PropTypes.number,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func,
  name: PropTypes.string,
};

export default TermsCheckBoxCA;
