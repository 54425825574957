import brandTypes from '@constants/brandTypes';
import {locale} from '@res/strings/locale';
import useRequestInfinite from '@hooks/useRequestInfinite';
import useRequest from '@hooks/useRequest';
import buildRoute from '@navigation/buildRoute';
import React, {useState} from 'react';
import queryKeys from '@constants/queryKeys';
import lotteryConfig from '@constants/lotteryConfig';
import Endpoints from '@api/endpoints';
import PageContainer from '@components/PageContent/PageContainer';
import LandingList from '@components/LandingList/LandingList';
import LandingListSkeleton from '@components/LandingList/LandingListSkeleton';
import LotteryBanner from '@components/LotteryPage/LotteryBanner';
import Meta from '@components/Meta';

const LotteriesPage = () => {
  const [params, setParams] = useState({
    limit: lotteryConfig?.INIT_PAGINATION_LIMIT,
    offset: 0,
  });
  const {data: pageData, isLoading: isLoadingCountData} = useRequest({
    endpoint: buildRoute(Endpoints.PAGE, {page: 'lotteries'}, true),
  });

  const {data, isLoading, fetchNextPage} = useRequestInfinite(
    {
      endpoint: Endpoints.LOTTERIES_LIST,
      useLocale: true,
      params: {
        _limit: lotteryConfig?.INIT_PAGINATION_LIMIT,
        brand: /*window.GlobalConfig?.brand*/ brandTypes.VOSTOK,
        region: window.GlobalConfig?.region,
      },
    },
    {queryKey: queryKeys.LOTTERIES_LIST, cacheTime: 0},
    {...params},
  );
  const {data: count, isLoading: isLoadingCount} = useRequest({
    endpoint: Endpoints.LOTTERIES_LIST_COUNT,
    useLocale: true,
  });

  return (
    <PageContainer>
      {isLoading || isLoadingCount || isLoadingCountData ? (
        <LandingListSkeleton />
      ) : (
        <>
          <Meta {...pageData?.meta} />
          <LandingList
            {...{
              data,
              count,
              fetchNextPage,
              setParams,
              constants: lotteryConfig,
              title: pageData?.meta?.h1 || locale?.lotteries,
              subtitle: locale?.completedLotteries,
              emptyText: locale?.lotteriesEmpty,
              LongBanner: LotteryBanner,
              ShortBanner: LotteryBanner,
              content: pageData?.content,
              showMoreContent: pageData?.show_more_content,
            }}
          />
        </>
      )}
    </PageContainer>
  );
};

export default LotteriesPage;
