import InputError from '@components/FormElements/InputError';
import phone_selector_icon from '@images/icons/phone-selector-icon.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Select, {components} from 'react-select';

const ReactSelect = ({
  options,
  className,
  placeholder,
  value,
  onChange,
  isSearchable = false,
  error,
  isSingleView = true,
  ...rest
}) => {
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img
            src={phone_selector_icon}
            className={classNames(
              {
                'rotate-[180deg]': props.selectProps.menuIsOpen,
                'rotate-0': !props.selectProps.menuIsOpen,
              },
              'transform',
            )}
            alt="dropdown"
          />
        </components.DropdownIndicator>
      )
    );
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: error ? '#FFECEB' : '#EDF3FE',
      height: '40px',
      border: error ? '1px solid #F42147' : 'none',
      outline: 'none',
      boxShadow: 'none',
      opacity: 1,
      padding: '0px 5px',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#9CB0D5',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#002F88',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    menu: (styles) => ({
      ...styles,
      padding: '0px 4px',
    }),
    option: (styles, {isSelected}) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#EDF3FE' : '#ffffff',
        color: '#002F88',
        borderRadius: '5px',
        ':hover': {
          backgroundColor: '#EDF3FE',
        },
      };
    },
  };

  return (
    <>
      <Select
        components={{DropdownIndicator}}
        className={classNames(
          className,
          'w-full rounded-[5px] text-resolutionBlue placeholder-poloBlue text-[14px]',
        )}
        styles={colourStyles}
        {...{options, placeholder, onChange, value, isSearchable}}
        {...rest}
      />
      {error && isSingleView && <InputError message={error?.message} />}
    </>
  );
};

ReactSelect.propTypes = {
  options: PropTypes.array,
  classNames: PropTypes.string,
  rest: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  isSingleView: PropTypes.bool,
};
export default ReactSelect;
