import regionTypes from '@constants/regionTypes';
import {format} from 'date-fns';

const formatDate = (date, useOnlyTime = false, useOnlyDate = false) => {
  if (window?.GlobalConfig?.region === regionTypes.CIS) {
    if (useOnlyTime) {
      return format(new Date(date), 'HH:mm');
    }
    if (useOnlyDate) {
      return format(new Date(date), 'dd.MM.yyyy');
    }
    return format(new Date(date), 'dd.MM.yyyy HH:mm');
  } else {
    if (useOnlyTime) {
      return format(new Date(date), 'h:mm a');
    }
    if (useOnlyDate) {
      return format(new Date(date), 'MM/dd/yyyy');
    }
    return format(new Date(date), 'MM/dd/yyyy h:mm a');
  }
};

export default formatDate;
