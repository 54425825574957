import lazyGamePlaceholderConfig from '@constants/lazyGamePlaceholderConfig';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

const OfferCardGameIcon = ({game}) => {
  return (
    <div className="flex justify-center sm:mt-[32px] py-[16px]">
      {game && game?.slug ? (
        <Link
          to={buildRoute(navigationRoutes.GAME, {slug: game?.slug})}
          className={'max-w-[100%]'}>
          <img
            {...{src: game?.image?.url ?? ''}}
            className="w-[190px] rounded-[6px]"
            alt={game?.title}
          />
        </Link>
      ) : (
        <img
          src={lazyGamePlaceholderConfig.SRC}
          className="w-[190px] rounded-[6px]"
          alt=""
        />
      )}
    </div>
  );
};

OfferCardGameIcon.propTypes = {
  game: PropTypes.any,
};

export default OfferCardGameIcon;
