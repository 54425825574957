import Overlay from '@components/Overlay';
import ChapterVideoZoomContainer from '@components/PageContent/ChapterVideoZoomContainer';
import useBodyOverflow from '@hooks/useBodyOverflow';
import useToggleState from '@hooks/useToggleState';
import {locale} from '@res/strings/locale';
import React from 'react';
import {AiFillCaretRight} from 'react-icons/ai';

const ChapterVideoZoom = ({data, button}) => {
  const imgSize = {width: 1200, height: 750};
  const [isActiveZoom, toggleActiveZoom] = useToggleState();

  useBodyOverflow(isActiveZoom);

  return (
    <ChapterVideoZoomContainer {...{button}}>
      {isActiveZoom && (
        <>
          <video
            muted={true}
            autoPlay={isActiveZoom}
            controls={true}
            src={data?.video}
            className="fixed w-auto h-auto transition-all duration-300 max-h-[68vh] max-w-[100vw] top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] z-[61]"
            width={imgSize?.width}
            height={imgSize?.height}
          />
          <Overlay
            toggleState={toggleActiveZoom}
            className="top-0 right-0 bottom-0 left-0 z-[60]"
          />
        </>
      )}
      <a
        onClick={(event) => {
          event?.preventDefault();
          toggleActiveZoom();
        }}>
        <img src={data?.placeholder} alt={locale?.ALT?.zoom} />
        <div className="max-w-[60px] max-h-[60px] w-full h-full absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] rounded-full bg-biscay opacity-95 transition-opacity hover:opacity-100 flex justify-center items-center cursor-pointer">
          <AiFillCaretRight className="scale-[2] opacity-80 absolute top-[50%] left-[53%] transform translate-x-[-50%] translate-y-[-50%]" />
        </div>
      </a>
    </ChapterVideoZoomContainer>
  );
};

export default ChapterVideoZoom;
