import GamesList from '@components/GamesGrid/GamesList';
import ContentButton from '@components/PageContent/ContentButton';
import ParagraphTitle from '@components/PageContent/ParagraphTitle';
import useGamesArraySize from '@hooks/useGamesArraySize';
import useWindowSize from '@hooks/useWindowSize';
import PropTypes from 'prop-types';
import React from 'react';

const ContentGamesList = ({games, title, button}) => {
  const size = useWindowSize();
  const itemsCount = useGamesArraySize();

  return (
    <>
      {title && (
        <div className="mb-3">
          <ParagraphTitle {...{title}} />
        </div>
      )}
      {!!games?.length && <GamesList {...{games, size, itemsCount}} />}
      <ContentButton {...{data: button}} />
    </>
  );
};

ContentGamesList.propTypes = {
  games: PropTypes.any,
  title: PropTypes.string,
  button: PropTypes.any,
};

export default ContentGamesList;
