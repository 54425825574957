import isBot from '@utils/isBot';

const LazyImage = ({...data}) => {
  if (isBot) {
    return <img {...data} src={data['data-src']} className={''} />;
  }

  return <img {...data} />;
};

export default LazyImage;
