import displayForKeys from '@constants/displayForKeys';
import gamesFilterConfig from '@constants/gamesFilterConfig';
import {useStore} from '@store/configureStore';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

const GameCategoriesFilter = ({categories}) => {
  const {
    user,
    filters: {setFilter, isFilterActive},
  } = useStore();

  return (
    <motion.div
      layout
      className="inline-flex gap-[5px] px-[15px] py-[5px] items-center flex-wrap overflow-hidden bg-midnightBlue w-full">
      {categories?.length &&
        categories?.map((category, index) => {
          if (
            (!user?.authorized &&
              category?.display_for === displayForKeys.AUTHORIZED) ||
            (user?.authorized &&
              category?.display_for === displayForKeys.UNAUTHORIZED)
          ) {
            return null;
          }

          return (
            <motion.div
              key={category?.id}
              custom={index}
              layout
              initial={{opacity: 0, x: 20}}
              animate="visible"
              variants={gamesFilterConfig.ANIMATION_CONFIG}
              whileHover={{
                scale: 1.1,
                transition: {type: 'spring', bounce: 0, duration: 0.4},
              }}
              whileTap={{
                scale: 0.95,
                transition: {type: 'spring', bounce: 0, duration: 0.4},
              }}
              onClick={() => {
                setFilter(
                  gamesFilterConfig.AVAILABLE_FILTERS.CATEGORIES.STORE_KEY,
                  category?.name,
                );
              }}
              className={classNames(
                {
                  'border-chathamsBlue': !isFilterActive(
                    gamesFilterConfig.AVAILABLE_FILTERS.CATEGORIES.STORE_KEY,
                    category?.name,
                  ),
                  'border-white': isFilterActive(
                    gamesFilterConfig.AVAILABLE_FILTERS.CATEGORIES.STORE_KEY,
                    category?.name,
                  ),
                },
                'cursor-pointer h-[40px] flex justify-between items-center px-[10px] rounded-[5px] border-[1px]',
              )}>
              <span className="text-white text-[12px] leading-[16px]">
                {category?.name}
              </span>
            </motion.div>
          );
        })}
    </motion.div>
  );
};

GameCategoriesFilter.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default observer(GameCategoriesFilter);
