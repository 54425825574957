import notificationConfig from '@constants/notificationConfig';
import failed_icon from '@images/icons/deposit-failed-icon.svg';
import declined_icon from '@images/icons/deposit-declinedpicon.svg';
import success_icon from '@images/icons/coin-icon.svg';
import PropTypes from 'prop-types';
import React from 'react';

const CashBoxNotification = ({
  type,
  notification,
  withActionButton = false,
  onClick = () => {},
}) => {
  return (
    <>
      {notification && (
        <div
          className="flex flex-row justify-start h-full items-center"
          onClick={() => (!withActionButton ? onClick() : null)}>
          {type === notificationConfig.TYPES.ERROR ? (
            <img
              className="self-center"
              src={failed_icon}
              alt={notification?.title}
            />
          ) : type === notificationConfig.TYPES.WARNING ? (
            <img
              className="self-center"
              src={declined_icon}
              alt={notification?.title}
            />
          ) : (
            <img
              className="self-center"
              src={success_icon}
              alt={notification?.title}
            />
          )}
          <p
            className="font-bold text-primary text-notification-message leading-notification-message ml-[12px]"
            dangerouslySetInnerHTML={{__html: notification?.title}}
          />
        </div>
      )}
    </>
  );
};

CashBoxNotification.propTypes = {
  type: PropTypes.string.isRequired,
  notification: PropTypes.any,
  withActionButton: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CashBoxNotification;
