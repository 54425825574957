import PropTypes from 'prop-types';

const BannerDescription = ({children}) => {
  return (
    <div className="absolute z-30 w-[100%] h-[100%] rounded-[10px] bg-gradient-b-slideSm flex flex-col items-center justify-end py-[32px] md:justify-center md:items-end md:bg-gradient-l-slideMd md:pr-[40px] lg:pr-[60px] xl:pr-[155px]">
      <div className="flex flex-col items-center">{children}</div>
    </div>
  );
};

BannerDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BannerDescription;
