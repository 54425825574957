import {locale} from '@res/strings/locale';
import gift from '@images/inbox-messages/gift.png';
import React from 'react';
import close_icon from '@images/icons/close-icon.svg';
import stretchNotificationConfig from '@constants/stretchNotificationConfig';
import formatAmount from '@utils/formatAmount';
import navigationRoutes from '@navigation/navigationRoutes';
import buildRoute from '@navigation/buildRoute';
import {Link} from 'react-router-dom';

const StretchNotificationWelcomeOffer = ({
  user,
  availableOffer,
  stretchNotification,
}) => {
  const handleClick = () => {
    stretchNotification?.setClosedFirstStretchNotifications(true);
    stretchNotification?.setClosedStretchNotifications(
      stretchNotificationConfig.TYPE?.WELCOME_OFFER,
    );
  };

  return (
    <div className="relative min-h-[40px] py-2 pr-[30px] flex justify-center items-center text-primary">
      <div className="pl-1 pr-3 sm:px-1">
        <img
          src={gift}
          alt={locale.ALT?.info}
          className="min-w-[32px] max-w-[32px]"
        />
      </div>
      <div className="text-[13px] sm:text-[14px] leading-[20px] font-semibold">
        {locale.STRETCHING_NOTIFICATIONS?.WELCOME_OFFER?.title
          ?.replace('{order}', locale?.ORDINAL_NUMBERS?.[availableOffer?.order])
          .replace('{percent}', `${availableOffer?.bonus?.deposit_percents}%`)
          .replace(
            '{amount}',
            formatAmount({
              amount: availableOffer?.bonus?.min_deposit / 100,
              currency: user?.profile?.currency,
              minimumFractionDigits: 0,
            }),
          )}{' '}
        <Link
          className="text-cerulean underline font-semibold"
          to={buildRoute(navigationRoutes.OFFERS)}>
          {locale.STRETCHING_NOTIFICATIONS?.WELCOME_OFFER?.link}
        </Link>
      </div>
      <button
        type="button"
        className="absolute p-2 right-0 top-[50%] cursor-pointer translate-y-[-50%]"
        onClick={handleClick}>
        <img
          src={close_icon}
          alt={locale.ALT?.closeMenuIcon}
          className="w-[11px] pointer-events-none"
        />
      </button>
    </div>
  );
};

export default StretchNotificationWelcomeOffer;
