import {Endpoints} from '@api';
import {fromPromise} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import tokenTypes from '@constants/tokenTypes';
import StorageService from '@services/StorageService';
import {rootStore} from '@store/configureStore';
import axios from 'axios';

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorLink = onError(
  ({graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.message) {
          case 'UNAUTHORIZED':
            let forward$;

            if (!isRefreshing) {
              isRefreshing = true;

              forward$ = fromPromise(
                (async () => {
                  try {
                    const {data} = await axios.post(Endpoints.REFRESH_TOKEN, {
                      refresh_token: await StorageService.current?.get(
                        tokenTypes.REFRESH,
                      ),
                    });

                    await rootStore.user.setTokens(
                      data.access.token,
                      data.refresh.token,
                    );

                    resolvePendingRequests();

                    return true;
                  } catch (error) {
                    pendingRequests = [];
                    isRefreshing = false;

                    window.history.pushState(null, null, '/');
                    rootStore?.user?.signOut();

                    throw error;
                  } finally {
                    isRefreshing = false;
                  }
                })(),
              ).filter((value) => Boolean(value));
            } else {
              forward$ = fromPromise(
                new Promise((resolve) => {
                  pendingRequests.push(() => resolve());
                }),
              );
            }

            operation.setContext({
              _skipRefreshToken: true,
            });

            return forward$.flatMap(() => forward(operation));
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  },
);

export default errorLink;
