export default {
  PAGE_VIEW: 'page_view',
  BUTTON_CLICK: 'button_click',
  LINK_CLICK: 'link_click',
  MODAL_OPEN: 'modal_open',
  MODAL_CLOSE: 'modal_close',
  SIGN_IN_SUCCESS: 'sign_in_success',
  SIGN_IN_FAILED: 'sign_in_failed',
  SIGN_UP_SUCCESS: 'sign_up_success',
  SIGN_UP_FAILED: 'sign_up_failed',
  AUTOLOGIN_SUCCESS: 'autologin_success',
  AUTOLOGIN_FAILED: 'autologin_failed',
  VERIFY_OTP_FAILED: 'verify_otp_failed',
  UNKNOWN_EXCEPTION: 'unknown_exception',
  GAME_FILTER_APPLY: 'game_filter_apply',
  DEPOSIT_SUCCESS: 'deposit_success',
  DEPOSIT_FAILED: 'deposit_failed',
  REGISTRATION_FORM_SHOW: 'registration_form_show',
  DEPOSIT_FORM_SHOW: 'deposit_form_show',
  WITHDRAWAL_FORM_SHOW: 'withdrawal_form_show',
  NEW_YEAR_GREETINGS_DISPLAYED: 'new_year_greetings_displayed',
  INBOX_MESSAGES_CLICK: 'inbox_messages_click',
};
