import {useState} from 'react';

const useActiveTab = (initialState = null) => {
  const [activeTab, setActiveTab] = useState(initialState);

  const switchTab = (tabName) => {
    setActiveTab(tabName);
  };

  return [activeTab, switchTab];
};

export default useActiveTab;
