import offerConstants from '@constants/offerConstants';
import {locale} from '@res/strings/locale';

const getPrefix = (data, offers) => {
  let result = '';

  switch (data?.type) {
    case offerConstants?.TYPE?.DEPOSIT_BONUS:
      if (data?.max_amount_percent > 0) {
        result = '_percent';
      }
      break;
    case offerConstants?.TYPE?.WELCOME:
      result = `_${data?.order}${data?.free_spin ? '_fs' : ''}`;
      break;
    case offerConstants?.TYPE?.CASHBACK:
      result = `_${offers?.availableCashback?.percent}_percent`;
      break;
    default:
      break;
  }

  return result;
};

const useOfferIcon = (data, offers) => {
  return `${window.GlobalConfig?.endpoints?.ASSETS}/${
    window.GlobalConfig?.brand
  }/${locale?.getLanguage()}_${window.GlobalConfig?.region}/offers/${
    data?.type
  }${getPrefix(data, offers)}.png`;
};

export default useOfferIcon;
