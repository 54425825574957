import React, {useEffect} from 'react';
import PageContainer from '@components/PageContent/PageContainer';
import {observer} from 'mobx-react-lite';
import StretchNotificationConfirmProfile from '@components/Notifications/StretchNotification/StretchNotificationConfirmProfile';
import StretchNotificationWelcomeOffer from '@components/Notifications/StretchNotification/StretchNotificationWelcomeOffer';
import stretchNotificationConfig from '@constants/stretchNotificationConfig';
import offerConstants from '@constants/offerConstants';
import {useLocation} from 'react-router';

const StretchNotification = ({user, offers, stretchNotification}) => {
  const location = useLocation();
  const availableOffer = offers?.availableWelcomeOffers?.filter(
    (i) => i?.status === offerConstants?.STATUS?.AVAILABLE,
  )?.[0];

  useEffect(() => {
    stretchNotification?.setClosedFirstStretchNotifications(false);
  }, [location.pathname]);

  return (
    <>
      {user?.authorized &&
        !stretchNotification?.isClosedFirstStretchNotifications && (
          <div
            id="stretching-notification"
            className="flex justify-center bg-stretching">
            <PageContainer>
              {!stretchNotification?.closedStretchNotifications.includes(
                stretchNotificationConfig.TYPE?.CONFIRM_PROFILE,
              ) &&
              (user?.profile?.phone_confirmed_by === 'none' ||
                !user?.profile?.is_email_confirmed) ? (
                <StretchNotificationConfirmProfile {...{stretchNotification}} />
              ) : (
                !stretchNotification?.closedStretchNotifications.includes(
                  stretchNotificationConfig.TYPE?.WELCOME_OFFER,
                ) &&
                availableOffer && (
                  <StretchNotificationWelcomeOffer
                    {...{user, availableOffer, stretchNotification}}
                  />
                )
              )}
            </PageContainer>
          </div>
        )}
    </>
  );
};

export default observer(StretchNotification);
