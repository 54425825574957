import LocalizedStrings from 'react-localization';
import en_errors from './en_errors.json';
import ru_errors from './ru_errors.json';
import fr_errors from './fr_errors.json';

export const locale = new LocalizedStrings({
  en: {},
});

export const errorLocale = new LocalizedStrings({
  en: en_errors,
  ru: ru_errors,
  fr: fr_errors,
});
