import Flicking from '@egjs/react-flicking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useCallback, useMemo} from 'react';

const ChapterImagesPagination = ({data, activeIndex, sliderRef}) => {
  const showSlide = useCallback(
    (index) => () => {
      sliderRef?.current?.moveTo(index);
    },
    [sliderRef],
  );
  const mapDots = useMemo(
    () =>
      data?.map((dot, index) => (
        <a
          key={index}
          onClick={showSlide(index)}
          className={classNames(
            {
              'bg-gorse': index === activeIndex,
              'bg-eastBay': index !== activeIndex,
            },
            'mx-[5px] w-2 h-2 rounded-[50%] ',
          )}
        />
      )),
    [sliderRef, activeIndex],
  );

  return (
    <>
      {data?.length > 1 && (
        <div className="flex flex-1 justify-center absolute z-[3] w-full h-5 bottom-0 left-0">
          {data && mapDots}
        </div>
      )}
    </>
  );
};

ChapterImagesPagination.propTypes = {
  data: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  sliderRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Flicking)}),
  ]),
};

export default ChapterImagesPagination;
