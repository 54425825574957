import classNames from 'classnames';

const InboxMessageItemSkeleton = ({isActive = false}) => {
  return (
    <div
      className={classNames(
        {'bg-active-message-modal': isActive},
        'flex flex-row w-full px-[16px] py-[12px] border-b border-message-item-border relative cursor-pointer transition duration-300',
      )}>
      <div className="w-[48px] h-[48px] rounded-full bg-stratos animate-pulse" />
      <div className="ml-[8px] w-[180px] animate-pulse">
        <div className="mb-[4px] w-full h-4 bg-stratos" />
        <div className="h-3 w-full bg-stratos" />
      </div>
      <div className="absolute w-[50px] top-[14px] right-[16px] animate-pulse">
        <div className="h-2 bg-stratos" />
      </div>
    </div>
  );
};

export default InboxMessageItemSkeleton;
