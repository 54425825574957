import RulesParamsItem from '@components/PageContent/RulesParamsItem';
import {useMemo} from 'react';
import PropTypes from 'prop-types';

const RulesParams = ({data}) => {
  const mapRules = useMemo(
    () => data.map((item, key) => <RulesParamsItem {...item} key={key} />),
    [data],
  );

  return (
    <div className="w-full inline-flex flex-wrap items-start mb-6 gap-[8px] sm:gap-[24px] relative overflow-hidden">
      {mapRules}
    </div>
  );
};

RulesParams.propTypes = {
  data: PropTypes.any.isRequired,
};

export default RulesParams;
