import {locale} from '@res/strings/locale';
import {useMemo} from 'react';
import navigationRoutes from '@navigation/navigationRoutes';
import buildRoute from '@navigation/buildRoute';
import lotteryConfig from '@constants/lotteryConfig';

const actionParams = (status, slug, user) => {
  const result = {to: '', type: '', title: ''};

  switch (status) {
    case lotteryConfig?.STATUS?.PUBLISHED:
      result.type = 'secondary';
      result.title = locale?.BUTTONS_TITLES?.more;
      result.to = buildRoute(
        navigationRoutes.LOTTERY,
        {
          slug,
          route: lotteryConfig.PRIZES,
        },
        true,
      );
      break;
    case lotteryConfig?.STATUS?.IN_PROGRESS:
      result.type = 'warning';
      result.title = locale?.BUTTONS_TITLES?.participate;
      result.to = buildRoute(
        navigationRoutes.LOTTERY_RULES,
        {
          slug,
        },
        true,
      );
      break;
    case lotteryConfig?.STATUS?.CLOSED:
      result.type = 'transparent';
      result.title = locale?.BUTTONS_TITLES?.more;
      result.to = buildRoute(
        navigationRoutes.LOTTERY,
        {
          slug,
          route: lotteryConfig.PRIZES,
        },
        true,
      );
      break;
    case lotteryConfig?.STATUS?.FINISHED:
    case lotteryConfig?.STATUS?.ARCHIVED:
      result.type = 'transparent';
      result.title = locale?.BUTTONS_TITLES?.results;
      result.to = buildRoute(
        navigationRoutes.LOTTERY,
        {
          slug,
          route: lotteryConfig.RESULTS,
        },
        true,
      );
      break;
    default:
      break;
  }

  return result;
};

const useLotteryAction = (status, slug) => {
  return useMemo(() => actionParams(status, slug), [status, slug]);
};

export default useLotteryAction;
