import LocalizationService from '@services/LocalizationService';
import format from 'date-fns/format';
import {enUS, ru} from 'date-fns/locale';

const availableLocales = {en: enUS, ru};

const formatLocale = (date, formatOptions, options) => {
  const locale =
    availableLocales?.[LocalizationService.getLanguage()] ||
    availableLocales.en;

  return format(date, formatOptions, {
    locale,
    ...options,
  });
};

export default formatLocale;
