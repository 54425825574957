import {locale} from '@res/strings/locale';
import LocalizationService from '@services/LocalizationService';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Helmet} from 'react-helmet';

const Meta = ({title, description, keywords}) => {
  const location = useMemo(
    () => window.location.href.split('#')?.[0].split('?')?.[0],
    [window.location.href],
  );

  const defaultLocation = location.replace(
    `/${LocalizationService.getLanguage()}`,
    `/${window?.GlobalConfig?.availableLanguages?.[0]?.code}`,
  );

  const alternateLinks = useMemo(() => {
    const links = [];

    window.GlobalConfig?.availableLanguages?.map((lang) => {
      const href = location.replace(
        `/${LocalizationService.getLanguage()}`,
        `/${lang.code}`,
      );

      links.push({
        hrefLang: lang.code,
        href,
      });

      links.push({
        hrefLang: `${lang.code}-${window.GlobalConfig?.region?.toUpperCase()}`,
        href,
      });
    });

    return links;
  }, [window.location.href]);

  return (
    <Helmet>
      <link rel="canonical" href={location} />

      <link rel="alternate" hrefLang="x-default" href={defaultLocation} />

      {alternateLinks?.map((link) => (
        <link rel="alternate" hrefLang={link.hrefLang} href={link.href} />
      ))}

      <title>{title || locale.defaultTitle}</title>
      <meta
        name="description"
        content={description || locale.defaultDescription}
      />

      {!!keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
};

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
};

export default Meta;
