import regionTypes from '@constants/regionTypes';
import buildRoute from '@navigation/buildRoute';
import CustomNavLink from '@navigation/CustomNavLink';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import {useEffect} from 'react';
import {useLocation} from 'react-router';

const ProfileNavigation = () => {
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname) {
      const item = document.getElementById(location?.pathname);
      if (item) {
        item.scrollIntoView(false);
      }
    }
  }, [location?.pathname]);

  return (
    <div className="bg-stratos flex items-center h-[40px] sm:h-[56px] z-50">
      <div className="container mx-auto max-w-full xl:max-w-[1296px] no-scrollbar overflow-x-scroll sm:overflow-x-hidden flex justify-start sm:justify-center items-center self-stretch">
        <CustomNavLink
          to={buildRoute(navigationRoutes.PROFILE)}
          exact
          id={navigationRoutes.PROFILE}
          className="mr-[24px] sm:mr-[60px] uppercase text-sanMarino text-[12px] leading-[16px] relative flex self-stretch items-center break-normal"
          activeClassName="text-gorse">
          {({isActive}) => (
            <>
              {locale?.profile}
              {isActive && (
                <div className="h-[2px] w-[30px] bg-gorse absolute bottom-0 left-0 right-0 mx-auto" />
              )}
            </>
          )}
        </CustomNavLink>
        {/*<CustomNavLink*/}
        {/*  to={buildRoute(navigationRoutes.NOTIFICATIONS)}*/}
        {/*  exact*/}
        {/*id={navigationRoutes.NOTIFICATIONS}*/}
        {/*  className="mr-[24px] sm:mr-[60px] uppercase text-sanMarino text-[12px] leading-[16px] relative flex self-stretch items-center break-normal"*/}
        {/*  activeClassName="text-gorse">*/}
        {/*  {({isActive}) => (*/}
        {/*    <>*/}
        {/*      {locale?.messages}*/}
        {/*      {isActive && (*/}
        {/*        <div className="h-[2px] w-[30px] bg-gorse absolute bottom-0 left-0 right-0 mx-auto" />*/}
        {/*      )}*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*</CustomNavLink>*/}
        <CustomNavLink
          to={buildRoute(navigationRoutes.BALANCE)}
          exact
          id={navigationRoutes.BALANCE}
          className="mr-[24px] sm:mr-[60px] uppercase text-sanMarino text-[12px] leading-[16px] relative flex self-stretch items-center break-normal"
          activeClassName="text-gorse">
          {({isActive}) => (
            <>
              {locale?.balance}
              {isActive && (
                <div className="h-[2px] w-[30px] bg-gorse absolute bottom-0 left-0 right-0 mx-auto" />
              )}
            </>
          )}
        </CustomNavLink>
        <CustomNavLink
          to={buildRoute(navigationRoutes.DOCUMENTS)}
          exact
          id={navigationRoutes.DOCUMENTS}
          className="mr-[24px] sm:mr-[60px] uppercase text-sanMarino text-[12px] leading-[16px] relative flex self-stretch items-center break-normal"
          activeClassName="text-gorse">
          {({isActive}) => (
            <>
              {locale?.documents}
              {isActive && (
                <div className="h-[2px] w-[30px] bg-gorse absolute bottom-0 left-0 right-0 mx-auto" />
              )}
            </>
          )}
        </CustomNavLink>
        <CustomNavLink
          to={buildRoute(navigationRoutes.OFFERS)}
          exact
          id={navigationRoutes.OFFERS}
          className="mr-[24px] sm:mr-[60px] uppercase text-sanMarino text-[12px] leading-[16px] relative flex self-stretch items-center break-normal"
          activeClassName="text-gorse">
          {({isActive}) => (
            <>
              {locale?.gifts}
              {isActive && (
                <div className="h-[2px] w-[30px] bg-gorse absolute bottom-0 left-0 right-0 mx-auto" />
              )}
            </>
          )}
        </CustomNavLink>
        <CustomNavLink
          to={buildRoute(navigationRoutes.PROMO_CODE)}
          exact
          id={navigationRoutes.PROMO_CODE}
          className="mr-[24px] sm:mr-[60px] uppercase text-sanMarino text-[12px] leading-[16px] relative flex self-stretch items-center break-normal"
          activeClassName="text-gorse">
          {({isActive}) => (
            <>
              {locale?.promoCode}
              {isActive && (
                <div className="h-[2px] w-[30px] bg-gorse absolute bottom-0 left-0 right-0 mx-auto" />
              )}
            </>
          )}
        </CustomNavLink>
        {window.GlobalConfig?.region === regionTypes.CIS && (
          <CustomNavLink
            to={buildRoute(navigationRoutes.TICKETS)}
            exact
            id={navigationRoutes.TICKETS}
            className="mr-[24px] sm:mr-[60px] sm:min-w-auto uppercase text-sanMarino text-[12px] leading-[16px] relative flex self-stretch items-center break-normal"
            activeClassName="text-gorse">
            {({isActive}) => (
              <>
                {locale?.tickets}
                {isActive && (
                  <div className="h-[2px] w-[30px] bg-gorse absolute bottom-0 left-0 right-0 mx-auto" />
                )}
              </>
            )}
          </CustomNavLink>
        )}
        <CustomNavLink
          to={buildRoute(navigationRoutes.PASSWORD)}
          exact
          id={navigationRoutes.PASSWORD}
          className="mr-[24px] sm:mr-[60px] uppercase text-sanMarino text-[12px] leading-[16px] relative flex self-stretch items-center break-normal"
          activeClassName="text-gorse">
          {({isActive}) => (
            <>
              {locale?.password}
              {isActive && (
                <div className="h-[2px] w-[30px] bg-gorse absolute bottom-0 left-0 right-0 mx-auto" />
              )}
            </>
          )}
        </CustomNavLink>
      </div>
    </div>
  );
};

export default ProfileNavigation;
