import {useHistory} from 'react-router';
import {observer} from 'mobx-react-lite';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import modalRoutes from '@navigation/modalRoutes';
import Button from '@components/Button';
import pushHash from '@utils/pushHash';
import React from 'react';

const TicketsAction = () => {
  const {user} = useStore();
  const history = useHistory();
  const clickHandler = () =>
    pushHash(
      history,
      user?.authorized ? modalRoutes.CASH_BOX : modalRoutes.REGISTER,
    );

  return (
    <div className="flex justify-center ">
      <Button
        className="text-[14px] font-bold uppercase h-[40px] w-[180px] mb-[10px]"
        onClick={clickHandler}
        title={locale?.BUTTONS_TITLES?.getTicket}
        type="primary"
      />
    </div>
  );
};

export default observer(TicketsAction);
