import offerConstants from '@constants/offerConstants';
import useOfferIcon from '@hooks/useOfferIcon';
import PropTypes from 'prop-types';

const OfferCardCashbackIcon = ({offers}) => {
  return (
    <div className="flex justify-center mt-2 sm:mt-4">
      <img
        src={useOfferIcon(
          {
            type: offerConstants?.TYPE?.CASHBACK,
          },
          offers,
        )}
        className="w-[70%] max-w-[192px]"
        alt=""
      />
    </div>
  );
};

OfferCardCashbackIcon.propTypes = {
  offers: PropTypes.any,
};

export default OfferCardCashbackIcon;
