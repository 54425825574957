import {useEffect, useState} from 'react';

const usePageOffset = () => {
  const [offset, setOffset] = useState({
    offsetX: undefined,
    offsetY: undefined,
  });
  useEffect(() => {
    const handleScroll = () => {
      setOffset({
        offsetX: window.pageXOffset || document.documentElement.scrollLeft,
        offsetY: window.pageYOffset || document.documentElement.scrollTop,
      });
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return offset;
};

export default usePageOffset;
