import {gql} from '@apollo/client';

export default {
  LIST: gql`
    query Notification($offset: NonNegativeInt, $limit: NonNegativeInt) {
      list(offset: $offset, limit: $limit) {
        uuid
        title
        content
        status
        new
        expires_at
        available_at
        action_image
      }
    }
  `,
  GET: gql`
    query Notification($uuid: UUID!) {
      get(uuid: $uuid) {
        uuid
        title
        content
        expires_at
        available_at
        action_image
        button_title
        button_action
      }
    }
  `,
  COUNT: gql`
    query Notification {
      count
    }
  `,
};
