import GameSkeleton from '@components/GamesGrid/GameSkeleton';
import getDevice from '@utils/getDevice';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';

const GamePageSkeleton = () => {
  const isMobile = getDevice() === 'mobile';
  const [gamesWrapSize, setGamesWrapSize] = useState(1960);

  useEffect(() => {
    const handleResize = () => {
      setGamesWrapSize(window.innerWidth < 640 ? 1780 : 1960);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {/* desktop screen */}
      <div className={classNames({'lg:block': !isMobile}, 'hidden')}>
        <div className="bg-stratos lg:justify-center my-[24px] lg:my-[32px] rounded-md animate-pulse">
          <canvas width="1028" height="580" />
        </div>
        <div className="flex justify-between">
          <div className="flex flex-1 justify-start" />
          <div className="flex flex-1 justify-center">
            <div className="h-[40px] w-[232px] text-[14px] font-bold leading-5 py-[10px] rounded-[5px] bg-stratos animate-pulse" />
          </div>
          <div className="flex flex-1 justify-end">
            {/* TODO: added favorite game */}
            {/*<div className="ml-2 p-0 min-h-[40px] min-w-[40px] rounded-[50%] bg-stratos animate-pulse" />*/}
            <div className="ml-2 p-0 min-h-[40px] min-w-[40px] rounded-[50%] bg-stratos animate-pulse" />
            <div className="ml-2 p-0 min-h-[40px] min-w-[40px] rounded-[50%] bg-stratos animate-pulse" />
          </div>
        </div>
      </div>
      {/* Mobile screen */}
      <div
        className={classNames(
          {'lg:hidden': !isMobile},
          'mt-[24px] lg:mt-[32px]',
        )}>
        <div className="flex flex-col md:flex-row pt-[16px] px-[16px] pb-[32px] md:py-[24px] md:pl-[16px] md:pr-0 bg-stratos animate-pulse">
          <div className="flex-1 rounded-[8px] overflow-hidden">
            <canvas width="481" height="316" className="max-w-full w-full" />
          </div>
          <div className="flex flex-col flex-1 justify-center items-center py-[16px] md:py-0">
            <div className="min-h-[104px]" />
          </div>
        </div>
      </div>

      <div className="hidden lg:block lg:mt-[40px] lg:mb-[64px] min-h-[1px] w-full  bg-stratos animate-pulse" />
      <div className="mt-[40px] lg:mt-0 mb-[14px] w-[160px] min-h-[26px]  bg-stratos animate-pulse" />
      <div className="mb-[16px] min-h-[19px] w-[120px] bg-stratos animate-pulse" />
      <div className="w-full relative mb-[40px] lg:mb-[72px] overflow-hidden">
        <div
          className="ml-[-8px] md:w-auto flex flex-nowrap gap-[16px] xl:gap-[24px] relative overflow-hidden"
          style={{minWidth: `${gamesWrapSize}px`}}>
          {[...Array(10)]?.map((_, index) => (
            <GameSkeleton key={index} />
          ))}
        </div>
      </div>
      <div className="mb-[16px] min-h-[19px] w-[130px] bg-stratos animate-pulse" />
      <div className="mx-[-4px] mb-[14px] flex flex-wrap justify-items-start">
        <div className="mx-[4px] mb-[8px] px-[16px] min-h-[32px] w-[134px] rounded-[40px] bg-stratos animate-pulse" />
        <div className="mx-[4px] mb-[8px] px-[16px] min-h-[32px] w-[110px] rounded-[40px] bg-stratos animate-pulse" />
        <div className="mx-[4px] mb-[8px] px-[16px] min-h-[32px] w-[130px] rounded-[40px] bg-stratos animate-pulse" />
      </div>
      <div className="flex flex-wrap">
        {[...Array(4)]?.map((item, index) => (
          <div
            key={index}
            className="mb-[15px] lg:mb-[32px] w-[200px] max-w-[50%]">
            <div className="mb-[4px] min-h-[20px] w-[106px] bg-stratos animate-pulse" />
            <div className="min-h-[19px] w-[86px] bg-stratos animate-pulse" />
          </div>
        ))}
      </div>
    </>
  );
};

export default GamePageSkeleton;
