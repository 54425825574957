import ModalRouter from '@components/Modals/ModalRouter';
import Overlay from '@components/Overlay';
import analyticsEvents from '@constants/analyticsEvents';
import useBodyOverflow from '@hooks/useBodyOverflow';
import useModalDriver from '@hooks/useModalDriver';
import AnalyticsService from '@services/AnalyticsService';
import {useStore} from '@store/configureStore';
import classNames from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import usePopUpNotification from '@hooks/usePopUpNotification';

const ModalDriver = () => {
  const {user, popUpNotification} = useStore();
  const [activeModal, isModalOpen, closeModal] = useModalDriver({
    authorized: user?.authorized,
  });

  useBodyOverflow(isModalOpen);
  usePopUpNotification(popUpNotification, activeModal);

  const handleCloseModal = () => {
    AnalyticsService.logEvent(analyticsEvents.MODAL_CLOSE, {
      modal_name: activeModal,
    });
    closeModal();
  };

  useEffect(() => {
    if (activeModal) {
      AnalyticsService.logEvent(analyticsEvents.MODAL_OPEN, {
        modal_name: activeModal,
      });
    }
  }, [activeModal]);

  if (!activeModal) {
    return null;
  }

  return (
    <>
      {isModalOpen && (
        <Overlay toggleState={handleCloseModal} {...{activeModal}} />
      )}
      <AnimatePresence exitBeforeEnter>
        {isModalOpen && (
          <motion.div
            key="modal_driver"
            initial={{opacity: 0, scale: 0.9}}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            exit={{
              opacity: 0,
              scale: 0.9,
            }}
            transition={{type: 'spring', bounce: 0.3, duration: 0.4}}
            className={classNames(
              {'z-[51]': isModalOpen},
              'flex items-center justify-center fixed top-0 right-0 bottom-0 left-0 pointer-events-none',
            )}>
            <div className="pointer-events-auto h-full sm:h-auto">
              <ModalRouter
                {...{
                  activeModal,
                  handleCloseModal,
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default observer(ModalDriver);
