import brandTypes from '@constants/brandTypes';
import lazy_placeholder_vostok from '@images/games/vostok/lazy-placeholder.jpg';
import lazy_placeholder_volta from '@images/games/volta/lazy-placeholder.png';

export default {
  SRC:
    window?.GlobalConfig?.brand === brandTypes.VOLTA
      ? lazy_placeholder_volta
      : lazy_placeholder_vostok,
};
