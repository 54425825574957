import {gql} from '@apollo/client';

export default {
  GET: gql`
    query Popup {
      get {
        id
        player_uuid
        expires_at
        type
        priority
        data
      }
    }
  `,
  REMOVE: gql`
    mutation RemovePopup($id: NonEmptyString) {
      remove(id: $id)
    }
  `,
};
