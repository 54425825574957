import BaseCountdown from '@components/Countdown/BaseCountdown';
import CountdownIcon from '@components/Countdown/CountdownIcon';
import DateWithHoursRender from '@components/Date/DateWithHoursRender';
import RangeShortDateRender from '@components/Date/RangeShortDateRender';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';

const BannerDate = ({
  data,
  className,
  onComplete = () => {},
  constants = {},
}) => {
  return (
    <div
      className={classNames(
        'inline-block px-[9px] py-[7px] text-left text-[0px] bg-stratos rounded-[78px] align-top',
        className,
      )}>
      {data?.status === constants?.PREVIEW_STATUS ? (
        <>
          <CountdownIcon />
          <DateWithHoursRender
            {...{
              date: data?.start_at,
              className:
                'inline-block pl-2 text-[14px] xl:text-[16px] text-white font-bold leading-[20px] align-top',
            }}
          />
        </>
      ) : data?.status === constants?.ACTIVE_STATUS ? (
        <BaseCountdown
          {...{
            date: data?.end_at,
            onComplete,
          }}
        />
      ) : (
        <>
          <CountdownIcon />
          <RangeShortDateRender
            {...{
              start: data?.start_at,
              end: data?.end_at,
              className:
                'inline-block pl-2 text-[14px] xl:text-[16px] text-white font-bold leading-[20px] align-top',
            }}
          />
        </>
      )}
    </div>
  );
};

BannerDate.propTypes = {
  data: PropTypes.any.isRequired,
  onComplete: PropTypes.func,
};

export default BannerDate;
