import CountdownRendererSwitch from '@components/Countdown/CountdownRendererSwitch';
import PropTypes from 'prop-types';
import React from 'react';
import Countdown from 'react-countdown';

const BaseCountdown = ({
  date,
  classNamesIcon,
  classNamesCountdown,
  classNamesLabel,
  isDefaultTextSize = true,
  label,
  onComplete,
}) => {
  const renderer = ({days, hours, minutes, seconds, completed}) => (
    <CountdownRendererSwitch
      {...{
        data: {days, hours, minutes, seconds, completed},
        date,
        label,
        classNamesIcon,
        isDefaultTextSize,
        classNamesCountdown,
        classNamesLabel,
      }}
    />
  );

  return <Countdown {...{date, renderer, onComplete}} />;
};

BaseCountdown.propTypes = {
  date: PropTypes.string,
  classNamesIcon: PropTypes.string,
  classNamesCountdown: PropTypes.string,
  classNamesLabel: PropTypes.string,
  isDefaultTextSize: PropTypes.bool,
  label: PropTypes.string,
  onComplete: PropTypes.func,
};

export default BaseCountdown;
