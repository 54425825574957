import brandTypes from '@constants/brandTypes';
import vostok_en_logo_inverse from '@images/logos/vostok/en/logo-inverse.svg';
import vostok_en_logo from '@images/logos/vostok/en/logo.svg';
import vostok_ru_logo_inverse from '@images/logos/vostok/ru/logo-inverse.svg';
import vostok_ru_logo from '@images/logos/vostok/ru/logo.svg';
import volta_en_logo from '@images/logos/volta/en/logo.svg';
import volta_en_logo_inverse from '@images/logos/volta/en/logo-inverse.svg';

import regionTypes from '@src/constants/regionTypes';

const getSrc = (type) => {
  if (window?.GlobalConfig?.brand === brandTypes.VOLTA) {
    if (type === 'default') {
      return volta_en_logo;
    } else {
      return volta_en_logo_inverse;
    }
  } else {
    if (window?.GlobalConfig?.region === regionTypes.CIS) {
      if (type === 'default') {
        return vostok_ru_logo;
      } else {
        return vostok_ru_logo_inverse;
      }
    } else {
      if (type === 'default') {
        return vostok_en_logo;
      } else {
        return vostok_en_logo_inverse;
      }
    }
  }
};

export default {
  SRC: getSrc('default'),
  INVERSE_SRC: getSrc('inverse'),
};
