import CashBoxOfferItem from '@components/Offer/CashBox/CashBoxOfferItem';
import CashBoxOffersListCarousel from '@components/Offer/CashBox/CashBoxOffersListCarousel';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {useState} from 'react';

const CashBoxOffersList = ({data, user, offers}) => {
  const [index, setIndex] = useState(0);

  return (
    <CashBoxOffersListCarousel {...{index}}>
      {data?.map((item, i) => (
        <CashBoxOfferItem
          {...{
            active: item?.id === offers?.selected?.id,
            data: item,
            setIndex,
            index: i,
            key: i,
            offers,
            user,
          }}
        />
      ))}
    </CashBoxOffersListCarousel>
  );
};

CashBoxOffersList.propTypes = {
  data: PropTypes.any,
  user: PropTypes.any,
  offers: PropTypes.any,
};

export default observer(CashBoxOffersList);
