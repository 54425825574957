import useCurrency from '@hooks/useCurrency';
import tournament_banner_icon from '@images/icons/tournament-banner-icon.svg';
import formatAmount from '@utils/formatAmount';
import React from 'react';
import PropTypes from 'prop-types';

const BannerAmount = ({amount}) => {
  const currency = useCurrency();

  return (
    <div className="mb-4 text-[32px] text-primary leading-[48px] font-semibold">
      <img
        src={tournament_banner_icon}
        alt=""
        className="inline-block mr-2 mt-[14px] align-top h-[22px]"
      />
      {formatAmount({
        amount: amount?.[currency] / 100,
        minimumFractionDigits: 0,
        currency,
      })}
    </div>
  );
};

BannerAmount.propTypes = {
  amount: PropTypes.any,
};

export default BannerAmount;
