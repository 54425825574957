import Endpoints from '@api/endpoints';
import DataLoadingMessage from '@components/CashBox/DataLoadingMessage';
import DepositOffers from '@components/CashBox/Deposit/DepositOffers';
import TransactionMethod from '@components/CashBox/TransactionMethod';
import useDeviceProps from '@hooks/useDeviceProps';
import useRequest from '@hooks/useRequest';
import back_icon from '@images/icons/back-icon.svg';
import {locale} from '@res/strings/locale';
import LocalizationService from '@services/LocalizationService';
import {useStore} from '@store/configureStore';
import isApplePayAvailable from '@utils/isApplePayAvailable';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

const Deposit = ({toggleNextStep, isNextStep, resetNextStep}) => {
  const {user} = useStore();
  const {isMobile} = useDeviceProps();
  const [paymentMethodUid, setPaymentMethodUid] = useState(null);
  const {data, isLoading} = useRequest({
    endpoint: Endpoints.GET_PAYMENT_METHODS,
    params: {
      transaction_type: 'deposit',
      currency: user?.profile?.currency,
      applepay_available: isApplePayAvailable,
      address: user?.profile?.address,
      locale: LocalizationService.getLanguage(),
    },
    useAuth: true,
  });

  return (
    <div className={classNames({'h-full': isLoading, 'pb-[120px]': isMobile})}>
      {isNextStep ? (
        <>
          <span className="absolute left-[16px] top-[14px] cursor-pointer z-10">
            <span className="click-area" onClick={() => resetNextStep()}>
              <img src={back_icon} alt={locale.ALT?.closeMenuIcon} />
            </span>
          </span>
          <div className="px-[30px]">
            <TransactionMethod {...{paymentMethodUid}} />
          </div>
        </>
      ) : data ? (
        <>
          <DepositOffers {...{user}} />

          <p className="text-center text-resolutionBlue text-[15px] mt-[20px] mb-[14px]">
            {locale?.selectPaymentMethod}
          </p>
          <div className="mb-[40px] flex flex-wrap justify-center">
            {data?.length &&
              data?.map((item) => (
                <div
                  key={item?.uid}
                  onClick={() => {
                    setPaymentMethodUid(item?.uid);
                    toggleNextStep();
                  }}
                  className="m-1 cursor-pointer flex w-[calc(42%-10px)] justify-center items-center h-[84px] rounded-[8px] bg-white border-[1px] border-zumthor">
                  <img
                    src={item?.image}
                    alt={item?.uid}
                    className="max-w-[90%]"
                  />
                </div>
              ))}
          </div>
        </>
      ) : (
        <DataLoadingMessage />
      )}
    </div>
  );
};

Deposit.propTypes = {
  isNextStep: PropTypes.bool.isRequired,
  resetNextStep: PropTypes.func.isRequired,
};

export default observer(Deposit);
