import {locale} from '@res/strings/locale';
import info from '@images/inbox-messages/info.png';
import close_icon from '@images/icons/close-icon.svg';
import React from 'react';
import stretchNotificationConfig from '@constants/stretchNotificationConfig';
import {Link} from 'react-router-dom';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';

const StretchNotificationConfirmProfile = ({stretchNotification}) => {
  const handleClick = () => {
    stretchNotification?.setClosedFirstStretchNotifications(true);
    stretchNotification?.setClosedStretchNotifications(
      stretchNotificationConfig.TYPE?.CONFIRM_PROFILE,
    );
  };
  return (
    <div className="relative min-h-[40px] py-2 pr-[30px] flex justify-center items-center text-primary">
      <div className="pl-1 pr-3 sm:px-1">
        <img
          src={info}
          alt={locale.ALT?.info}
          className="min-w-[32px] max-w-[32px]"
        />
      </div>
      <div className="text-[13px] sm:text-[14px] leading-[20px] font-semibold">
        {locale.STRETCHING_NOTIFICATIONS?.CONFIRMED_PROFILE?.title}{' '}
        <Link
          to={buildRoute(navigationRoutes.PROFILE)}
          className="text-cerulean underline font-semibold">
          {locale.STRETCHING_NOTIFICATIONS?.CONFIRMED_PROFILE?.link}
        </Link>
      </div>
      <button
        type="button"
        className="absolute p-2 right-0 top-[50%] cursor-pointer translate-y-[-50%]"
        onClick={handleClick}>
        <img
          src={close_icon}
          alt={locale.ALT?.closeMenuIcon}
          className="w-[11px] pointer-events-none"
        />
      </button>
    </div>
  );
};

export default StretchNotificationConfirmProfile;
