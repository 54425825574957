import {locale} from '@res/strings/locale';
import LotteryItemPrizeCard from '@components/LotteryPage/LotteryItemPrizeCard';
import React, {useMemo} from 'react';
import classNames from 'classnames';
import LotteryPrizesItemIcon from '@components/LotteryPage/LotteryPrizesItemIcon';
import LotteryTicketList from '@components/LotteryPage/LotteryTicketList';
import lotteryConfig from '@constants/lotteryConfig';

const LotteryResultsItemList = ({data}) => {
  const winnersCount = useMemo(
    () => lotteryConfig?.WINNERS_COUNT(data),
    [data],
  );
  const mapData = useMemo(
    () =>
      data?.map((item, key) => {
        return (
          <div
            className={classNames('w-full', {
              'lg:w-[calc(50%-16px)]':
                winnersCount[key] === 1 &&
                (winnersCount[key - 1] === 1 || winnersCount[key + 1] === 1),
            })}
            {...{key}}>
            <LotteryItemPrizeCard>
              <div className="w-full py-4 px-3 sm:p-6 sm:pr-2 sm:pb-2 flex flex-col sm:flex-row items-center sm:items-stretch justify-center">
                <div className="flex sm:hidden text-primary text-[19px] leading-[28px] font-bold">
                  {item?.prize}
                </div>
                <div className="flex items-center max-w-full w-[160px] md:w-[236px]">
                  <LotteryPrizesItemIcon
                    {...{
                      src: `${item?.image?.full_url}?${item?.image?.component_hash}`,
                      alt: item?.prize,
                    }}
                  />
                </div>
                <div
                  className={classNames(
                    'w-full flex justify-center items-center flex-col',
                    {
                      'sm:w-[342px]': item?.winners?.length === 1,
                      'sm:w-[calc(100%-160px)] md:w-[calc(100%-236px)]':
                        item?.winners?.length !== 1,
                    },
                  )}>
                  <div className="hidden sm:flex text-primary text-[19px] leading-[28px] font-bold">
                    {item?.prize}
                  </div>
                  <LotteryTicketList
                    {...{data: item?.winners, isItemPrize: true}}
                  />
                </div>
              </div>
            </LotteryItemPrizeCard>
          </div>
        );
      }),
    [data],
  );
  return (
    <>
      {!!data?.length && (
        <>
          <h2 className="mb-6 text-[24px] text-primary font-bold leading-[32px]">
            {locale?.LOTTERY_PARAMS?.itemPrizesTitle}
          </h2>
          <div className="w-full flex flex-wrap gap-[32px]">{mapData}</div>
        </>
      )}
    </>
  );
};

export default LotteryResultsItemList;
