import useToggleState from '@hooks/useToggleState';
import classNames from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router';

const SubMenu = ({
  children,
  label,
  icon,
  isCaretVisible = true,
  iconComponent,
  itemClassName,
  iconALt,
  isHeaderLangSwitcher = false,
  contentContainerClassName,
  className,
  isItemsAbsolute = false,
  isIconBordered = true,
  isCategorySelector = false,
  testid,
  isCategoryActive = false,
  isActiveByDefault = false,
}) => {
  const [isSubMenuActive, toggleSubMenu, close] =
    useToggleState(isActiveByDefault);
  const location = useLocation();
  useEffect(() => {
    if (!isActiveByDefault) {
      close();
    }
  }, [location]);

  const handleClick = () => {
    toggleSubMenu();
  };

  return (
    <>
      <motion.div
        className={classNames(
          itemClassName,
          'relative z-[15] inline-block text-left w-full overflow-hidden',
        )}
        initial={false}
        onClick={handleClick}>
        <button
          {...{testid}}
          type="button"
          aria-label="click"
          className={classNames(
            `flex justify-between w-full text-white items-center focus:outline-none text-[14px] leading-[19px] ${className}`,
          )}>
          <div className={classNames(className, 'flex flex-row items-center')}>
            {icon && (
              <span
                className={classNames(
                  {
                    'border-[1px] border-deepSapphire rounded-[8px]':
                      isIconBordered,
                    'mr-[10px]': !isHeaderLangSwitcher,
                    'p-[11px]': !isCategorySelector,
                  },
                  'border-box',
                )}>
                <img
                  className={classNames({
                    'active-category-option ': isCategoryActive,
                  })}
                  src={icon}
                  alt={iconALt}
                />
              </span>
            )}
            {iconComponent && iconComponent}
            <span>{label}</span>
          </div>
          {isCaretVisible && (
            <svg
              className={classNames(
                {
                  'transform rotate-180': isSubMenuActive,
                },
                'h-5 w-5 text-melrose mr-0',
              )}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill={isCategorySelector ? '#4E73B7' : 'currentColor'}
              aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>
      </motion.div>
      <AnimatePresence initial={false}>
        {isSubMenuActive && (
          <motion.div
            initial={{y: -100, height: 0, opacity: 0}}
            animate={{
              y: 0,
              height: 'auto',
              opacity: [0, 0, 1],
            }}
            exit={{
              y: -100,
              height: 0,
              opacity: [1, 0, 0, 0],
            }}
            transition={{ease: 'easeInOut', duration: 0.4}}
            className={classNames({
              'absolute w-full z-50': isItemsAbsolute,
              'top-[50px] w-[calc(100vw-32px)] left-[-17px] sm:w-[calc(100vw-38px)] sm:left-[-22px] z-30':
                isCategorySelector,
            })}>
            <motion.div
              initial={{opacity: 0}}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{ease: 'easeInOut', duration: 0.4}}
              className="content-placeholder">
              <div
                className={classNames(
                  contentContainerClassName,
                  'origin-top-left w-full left-0 mt-2 rounded-md focus:outline-none',
                )}>
                <div className="content-placeholder">{children}</div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  className: PropTypes.string,
  isIconBordered: PropTypes.bool,
  isCategorySelector: PropTypes.bool,
  iconComponent: PropTypes.element,
  itemClassName: PropTypes.string,
  contentContainerClassName: PropTypes.string,
  isCaretVisible: PropTypes.bool,
  isHeaderLangSwitcher: PropTypes.bool,
  testid: PropTypes.string,
};

export default SubMenu;
