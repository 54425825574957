import {locale} from '@res/strings/locale';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LotteryTicketInfoListItem = ({
  icon,
  title,
  amount,
  className,
  theme = 'dark',
}) => {
  return (
    <div
      {...{
        className: classNames(className, {
          'border-zumthor': theme === 'dark',
          'border-primary': theme === 'light',
        }),
      }}>
      <div className="flex flex-row pl-2 sm:pl-0">
        <img src={icon} className="w-[54px]" alt={''} />
        <div className="ml-6 text-left">
          <div
            className={classNames('font-bold', {
              'text-primary': theme === 'dark',
              'text-tertiary': theme === 'light',
            })}>
            {title}
          </div>
          <div
            className={classNames({
              'text-primary': theme === 'dark',
              'text-poloBlue': theme === 'light',
            })}>{`${locale?.LOTTERY_PARAMS?.from} ${amount}`}</div>
        </div>
      </div>
    </div>
  );
};

LotteryTicketInfoListItem.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  amount: PropTypes.string,
  className: PropTypes.string,
};

export default LotteryTicketInfoListItem;
