import CashBoxOfferItemCheckMark from '@components/Offer/CashBox/CashBoxOfferItemCheckMark';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const CashBoxOfferItemOutline = ({children, active, isButton}) => {
  return (
    <div
      className={classNames('relative p-2 rounded-[8px] border-[2px]', {
        'border-white': active,
        'border-transparent': !active && isButton,
        'border-redRibbon': !isButton,
      })}>
      <div className="flex flex-wrap">{children}</div>
      <CashBoxOfferItemCheckMark {...{active: isButton ? active : false}} />
    </div>
  );
};

CashBoxOfferItemOutline.propTypes = {
  children: PropTypes.node,
  isButton: PropTypes.bool,
  active: PropTypes.bool,
};

export default CashBoxOfferItemOutline;
