import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import LotteryPrizesMoneyListItem from '@components/LotteryPage/LotteryPrizesMoneyListItem';

const LotteryPrizesMoneyList = ({data}) => {
  const mapPrizes = useMemo(
    () =>
      data?.money_prizes?.map((item, key) => (
        <LotteryPrizesMoneyListItem {...{data: item, key}} />
      )),
    [data],
  );

  return (
    <div className="my-4 p-1 sm:p-3 bg-primary w-full flex inline-flex flex-wrap items-center relative overflow-hidden rounded-[10px]">
      {mapPrizes}
    </div>
  );
};

LotteryPrizesMoneyList.propTypes = {
  data: PropTypes.any,
};

export default LotteryPrizesMoneyList;
