import React from 'react';

import iconSet from '@res/fonts/selection.json';

export const iconList = () => {
  const list = [];
  iconSet.icons.forEach((icon) => {
    list.push(icon.properties.name.split(', ')[0]);
  });
  return list;
};

function getSvg(icon, styles, size, className, rest) {
  const find = (iconEl) => iconEl.properties.name.split(', ').includes(icon);
  const currentIcon = iconSet.icons.find(find);
  const renderPath = (iconObj) => (path, index) => {
    const attrs = (iconObj.attrs && iconObj.attrs[index]) || {};
    return <path style={styles.path} key={index} d={path} {...attrs} />;
  };

  if (currentIcon) {
    return (
      <svg
        fill={'currentColor'}
        stroke={'currentColor'}
        className={className}
        style={styles.svg}
        width={size}
        height={size}
        viewBox={`0 0 ${currentIcon.icon.width || '1024'} 1024`}
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        {currentIcon.icon.paths.map(renderPath(currentIcon.icon))}
      </svg>
    );
  }
  console.error(`ICON_${icon}_NOT_EXIST`);

  return null;
}

export const Icon = ({
  size = '100%',
  icon,
  className = '',
  style = {},
  ...rest
}) => {
  return getSvg(icon, style, size, className, rest);
};

export default Icon;
