import Button from '@components/Button';
import DateOfBirthInput from '@components/FormElements/DateOfBirthInput';
import Input from '@components/FormElements/Input';
import InputError from '@components/FormElements/InputError';
import ReactSelect from '@components/FormElements/ReactSelect';
import TelInput from '@components/FormElements/TelInput/TelInput';
import ModalWrapper from '@components/Modals/ModalWrapper';
import RegistrationStepper from '@components/Register/RegistrationStepper';
import RegistrationFormBonus from '@components/RegistrationFormBonus';
import logoConfig from '@constants/logoConfig';
import {yupResolver} from '@hookform/resolvers/yup';
import useModalControls from '@hooks/useModalControls';
import useModalRegister from '@hooks/useModalRegister';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import errorMsgHandler from '@utils/errorMsgHandler';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import RegistrationWelcomeBonus from '@components/Register/RegistrationWelcomeBonus';

const SecondStepRegistration = ({handleCloseModal}) => {
  const {user} = useStore();
  const {params} = useModalRegister(modalRoutes.REGISTER_SECOND_STEP, {
    requiredParams: ['fromHandler'],
    handleCloseModal,
  });
  const {openModal} = useModalControls();

  const registrationFormSchema = useMemo(
    () =>
      yup.object().shape({
        first_name: yup.string().required(locale?.ERROR_MESSAGES?.required),
        last_name: yup.string().required(locale?.ERROR_MESSAGES?.required),
        day: yup.string().required(locale?.ERROR_MESSAGES?.required),
        month: yup.string().required(locale?.ERROR_MESSAGES?.required),
        year: yup.string().required(locale?.ERROR_MESSAGES?.required),
        phone: yup.string().required(locale?.ERROR_MESSAGES?.required),
        gender: yup.string().required(locale?.ERROR_MESSAGES?.required),
      }),
    [],
  );

  const {
    formState: {errors},
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(registrationFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: params?.body?.first_name ?? '',
      last_name: params?.body?.last_name ?? '',
      phone: params?.body?.phone ?? '',
      day: params?.body?.day ? parseInt(params?.body?.day) : '',
      month: params?.body?.month ? parseInt(params?.body?.month) : '',
      year: params?.body?.year ? parseInt(params?.body?.year) : '',
      gender: params?.body?.gender ?? '',
    },
  });

  const watchFields = watch();

  const onSubmit = async (data) => {
    try {
      await user.signUpValidate.run(data);
      openModal(modalRoutes.REGISTER_THIRD_STEP, {
        fromHandler: true,
        body: {...params?.body, ...data},
      });
    } catch (e) {
      errorMsgHandler(e, {
        setError,
        fields: [
          'first_name',
          'last_name',
          'phone',
          'gender',
          'system',
          'day',
          'month',
          'year',
        ],
      });
    }
  };

  return (
    <ModalWrapper
      desktopStyle={
        'max-w-[720px] w-full sm:h-[600px] sm:rounded-[8px] bg-[#300e4f]'
      }
      {...{handleCloseModal}}>
      <div className="flex flex-col sm:flex-row w-full h-full">
        <RegistrationFormBonus />
        <div className="sm:w-1/2 h-[100%] flex flex-col overflow-scroll no-scrollbar bg-white pb-[80px] sm:pb-[0]">
          <img
            src={logoConfig.INVERSE_SRC}
            className="w-full max-w-[95px] mt-[20px] mb-[10px] mx-auto"
            alt={locale.ALT?.logo}
          />

          <div className="flex flex-col px-[32px] pb-[50px] h-[100%]">
            <RegistrationWelcomeBonus />

            <RegistrationStepper
              activeStep={modalRoutes.REGISTER_SECOND_STEP}
              fields={{...params?.body, ...watchFields}}
            />

            <div className="flex flex-col justify-between w-full mt-[20px] h-[100%]">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name="first_name"
                  render={({field: {onChange, value}}) => (
                    <Input
                      placeholder={locale.firstName}
                      type="text"
                      {...{onChange, value}}
                      isError={errors.first_name}
                      errorMessage={errors.first_name?.message}
                      className="text-[14px]"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="last_name"
                  render={({field: {onChange, value}}) => (
                    <Input
                      placeholder={locale.lastName}
                      type="text"
                      {...{onChange, value}}
                      isError={errors.last_name}
                      errorMessage={errors.last_name?.message}
                      className="text-[14px] mb-0"
                    />
                  )}
                />
                <div className="relative z-[11] mb-[10px]">
                  <DateOfBirthInput
                    {...{getValues, setValue, control, watch, errors}}
                  />
                </div>
                <div className="mb-[10px] relative z-[10]">
                  <Controller
                    control={control}
                    name="gender"
                    render={({field: {onChange, value, ref}}) => (
                      <ReactSelect
                        placeholder={locale.gender}
                        inputRef={ref}
                        error={errors?.gender}
                        options={locale?.genderSelector}
                        value={locale?.genderSelector?.find(
                          (c) => c.value === value,
                        )}
                        onChange={(val) => onChange(val.value)}
                      />
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name="phone"
                  render={({field: {onChange, value, ref}}) => (
                    <TelInput
                      inputRef={ref}
                      type="tel"
                      name="phone"
                      isError={errors.phone}
                      errorMessage={errors.phone?.message}
                      {...{value, onChange, setError, clearErrors, setValue}}
                    />
                  )}
                />

                {!!errors.system && (
                  <InputError message={errors.system?.message} />
                )}

                <Button
                  disabled={user.signUpValidate?.inProgress}
                  htmlType="submit"
                  title={
                    user.signUpValidate?.inProgress
                      ? locale?.processing
                      : locale?.doRegister
                  }
                  className="w-full h-[40px] text-[14px] font-bold leading-5 py-[10px] rounded-[5px] mt-[33px] uppercase"
                  type="primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default observer(SecondStepRegistration);
