import ContentButton from '@components/PageContent/ContentButton';
import ParagraphTitle from '@components/PageContent/ParagraphTitle';
import SwitchParagraphType from '@components/PageContent/SwitchParagraphType';
import PropTypes from 'prop-types';
import React from 'react';
import {Twemoji} from 'react-emoji-render';

const ChapterIconsList = ({title, data, button}) => {
  const switchItemType = (item, key) => {
    if (item?.type) {
      return <SwitchParagraphType {...{item, key}} />;
    }

    return (
      <li
        {...{key}}
        className="relative mb-[18px] text-melrose text-base leading-6 font-sans">
        <Twemoji text="👉" className={'absolute left-[-20px] top-1'} />
        <div dangerouslySetInnerHTML={{__html: item}} />
      </li>
    );
  };

  return (
    <>
      <ParagraphTitle {...{title}} />
      <ul className="pl-11 pt-6 pb-[6px] pr-4 mb-[18px] bg-stratos rounded-[8px]">
        {!!data?.length &&
          data?.map((item, index) => switchItemType(item, index))}
      </ul>
      <ContentButton {...{data: button}} />
    </>
  );
};

ChapterIconsList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any.isRequired,
  button: PropTypes.any,
};

export default ChapterIconsList;
