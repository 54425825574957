import {useCallback, useState} from 'react';

const useToggleState = (initialState = false) => {
  const [toggleState, setToggleState] = useState(initialState);

  const toggle = useCallback(() => setToggleState((v) => !v), []);

  const setFalse = useCallback(() => setToggleState(false), []);

  return [toggleState, toggle, setFalse];
};

export default useToggleState;
