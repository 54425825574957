import React from 'react';
import LotteryPrizesCounter from '@components/LotteryPage/LotteryPrizesCounter';
import useCurrency from '@hooks/useCurrency';
import formatAmount from '@utils/formatAmount';

const LotteryPrizesMoneyListItem = ({data}) => {
  const currency = useCurrency();

  return (
    <div className="w-1/2 lg:w-1/4 xl:w-1/6 p-2 sm:p-3">
      <div className="min-h-[96px] sm:min-h-[55px] flex flex-col sm:flex-row justify-center items-center sm:justify-between p-3 bg-resolutionBlue rounded-[5px]">
        <div className="mb-3 sm:mb-0 flex justify-start items-center text-primary text-[21px] leading-[22px] font-semibold">
          {formatAmount({
            amount: data?.prize?.[currency],
            minimumFractionDigits: 0,
            currency,
          })}
        </div>
        <LotteryPrizesCounter {...{count: data?.prizes_num}} />
      </div>
    </div>
  );
};

export default LotteryPrizesMoneyListItem;
