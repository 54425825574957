import {useEffect} from 'react';
import {useLocation} from 'react-router';
import modalRoutes from '@navigation/modalRoutes';
import useModalControls from '@hooks/useModalControls';

const usePopUpNotification = (store, activeModal) => {
  const location = useLocation();
  const {openModal} = useModalControls();

  useEffect(() => {
    if (!location.hash && store?.current) {
      openModal(modalRoutes.POP_UP_NOTIFICATION, {data: store?.current});
      store?.removePopUpNotification.run(store?.current?.id);
      store.clearPopUpNotification();
    }
  }, [store?.current, activeModal]);
};

export default usePopUpNotification;
