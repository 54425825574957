import React from 'react';
import PropTypes from 'prop-types';
import ChapterParagraph from '@components/PageContent/ChapterParagraph';
import ContentTitle from '@components/PageContent/ContentTitle';
import LotteryPrizesContent from '@components/LotteryPage/LotteryPrizesContent';

const LotteryPrizes = ({data}) => {
  return (
    <>
      <ContentTitle
        {...{data: data?.meta_prizes?.h1 ?? data?.title, isHide: true}}
      />
      <div className="flex flex-col justify-center items-center mt-8 md:mt-[80px] text-center">
        <h2 className="mb-4 md:mb-8 text-primary text-[26px] md:text-[32px] leading-[40px] md:leading-[48px] text-center font-bold md:font-semibold">
          {data?.rules?.prizes?.itemPrizesTitle}
        </h2>
        <ChapterParagraph
          {...{
            data: data?.rules?.prizes?.itemPrizesText,
            color: 'text-primary',
          }}
        />
        <LotteryPrizesContent {...{data}} />
      </div>
    </>
  );
};

LotteryPrizes.propTypes = {
  data: PropTypes.any,
};

export default LotteryPrizes;
