import * as Sentry from '@sentry/browser';
import {uaParser} from '@services/AnalyticsService';
import LocalStorageService from '@services/LocalStorageService';
import {CrossStorageClient} from 'cross-storage';

const timestamp = new Date().getTime();

export default {
  current: null,

  getStorage: async function () {
    if (this.current) {
      return this.current;
    }

    if (uaParser.getBrowser()?.name?.toLowerCase()?.includes('safari')) {
      this.current = LocalStorageService;
    } else {
      try {
        const storage = new CrossStorageClient(
          window.GlobalConfig?.endpoints?.CROSS_STORAGE,
          {
            timeout: 5000,
            frameId: timestamp,
          },
        );

        await storage.onConnect();
        await storage.set('timestamp', timestamp);
        this.current = storage;
      } catch (err) {
        Sentry.captureException(new Error('CROSS_STORAGE_NOT_AVAILABLE'), {
          extra: err,
          fingerprint: 'CROSS_STORAGE_NOT_AVAILABLE',
        });

        await LocalStorageService.set('timestamp', timestamp);
        this.current = LocalStorageService;
      }
    }

    return this.current;
  },
};
