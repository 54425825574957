import './style.css';
import {locale} from '@res/strings/locale';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import PhoneInput from './PhoneInput';

const TelInput = ({
  className,
  setError,
  isError,
  errorMessage,
  clearErrors,
  value,
  onChange,
  name,
  disabled,
  ...rest
}) => {
  const validation = (inputNumber, country) => {
    if (country && inputNumber) {
      return (
        inputNumber?.startsWith(country?.dialCode) ||
        country?.dialCode?.startsWith(inputNumber)
      );
    }
  };
  const handleInputChange = (inputNumber, country) => {
    const isValid = validation(inputNumber, country);
    if (!isValid) {
      setError(name, {
        type: 'manual',
        message: locale?.phoneValidationError,
      });
    } else {
      onChange(inputNumber);
      clearErrors(name);
    }
  };

  return (
    <>
      <PhoneInput
        specialLabel={false}
        containerClass={classNames(
          {
            'mb-0': isError && !rest.disabled,
            'mb-[10px]': !isError,
          },
          'allow-dropdown w-full relative',
        )}
        buttonClass={classNames(
          {
            'border-redRibbon focus:bg-white bg-fairPink':
              isError && !rest.disabled,
            'border-transparent focus:bg-white focus:border-periwinkleGray bg-hawkesBlue':
              !isError,
          },
          'border-[1px] border-r-0 rounded-l-[5px] absolute left-0 top-0 w-[64px] h-[40px] p-[11px] flex items-center z-[9] bg-hawkesBlue',
        )}
        dropdownClass="bg-white w-[212px] rounded-[5px] absolute top-[50px] left-0 z-[10] p-[3px] shadow-phoneDropDown select-none"
        inputClass={classNames(
          {
            'border-redRibbon focus:bg-white bg-fairPink':
              isError && !rest.disabled,
            'border-transparent focus:bg-white focus:border-periwinkleGray bg-hawkesBlue':
              !isError,
          },
          'w-full p-[13px] pl-[70px] h-[40px] border-[1px] focus:outline-none rounded-[5px] text-resolutionBlue placeholder-poloBlue text-[14px] disabled:mb-[10px] disabled:bg-hawkesBlue disabled:placeholder-white',
        )}
        country={window?.GlobalConfig?.phoneCountries?.[0]}
        onlyCountries={window?.GlobalConfig?.phoneCountries}
        {...{value, disabled}}
        onChange={(inputNumber, country) => {
          handleInputChange(inputNumber, country);
        }}
        {...rest}
      />
      {isError && !rest.disabled && (
        <span
          className={classNames(
            {block: isError, hidden: !isError},
            'text-redRibbon text-[12px] mt-[8px] mb-[16px]',
          )}>
          {errorMessage}
        </span>
      )}
    </>
  );
};

TelInput.propTypes = {
  setError: PropTypes.func.isRequired,
  rest: PropTypes.any,
  isError: PropTypes.object,
  errorMessage: PropTypes.string,
  initialState: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default TelInput;
