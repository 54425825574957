export default {
  MAIN: '/',

  GAME_LIST: '/games',
  GAME: '/games/:slug',

  EMPTY_CATEGORY: '/games/category',
  FAVORITE_GAMES_CATEGORY: '/games/category/favorite',
  GAME_CATEGORY: '/games/category/:category',
  GAME_PROVIDER: '/games/provider/:provider',

  LOTTERIES_LIST: '/lotteries',
  LOTTERY: '/lotteries/:slug/:route',
  LOTTERY_RULES: '/lotteries/:slug',
  LOTTERY_PATH: '/lotteries/:slug/:route(prizes|results)?',

  TOURNAMENTS_LIST: '/tournaments',
  TOURNAMENT: '/tournaments/:slug/:route',
  TOURNAMENT_RULES: '/tournaments/:slug',
  TOURNAMENT_PATH: '/tournaments/:slug/:route(games|results)?',

  PUBLICATIONS_LIST: '/news',
  PUBLICATION: '/news/:slug',

  TERMS_AND_CONDITIONS: '/terms',
  COOKIES: '/cookies',
  PAYOUTS: '/payouts',
  WITHDRAWAL: '/how-to-withdraw',
  RESPONSIBLE_GAMING: '/responsible-gaming',
  PRIVACY_POLICY: '/privacy-policy',
  BONUS: '/bonus',
  FAQ: '/faq',
  CONTACTS: '/contacts',
  ANTI_MONEY_LAUNDERING: '/anti-money-laundering',

  UNSUBSCRIBE_EMAIL: '/unsubscribe/:email',
  PROFILE: '/users/profile',
  PASSWORD: '/users/reset-password',
  OFFERS: '/users/bonuses',
  PROMO_CODE: '/users/promo-code',
  TICKETS: '/users/lottery-tickets',
  NOTIFICATIONS: '/users/notifications',
  DOCUMENTS: '/users/document',
  BALANCE: '/users/balance',

  DEPOSIT_REDIRECT: '/users/deposit-redirect',
  CONFIRM_EMAIL: '/confirm-email/:token',

  AUTOLOGIN: '/autologin/:token',

  NOT_FOUND: '/404',

  staticPages: function () {
    return [
      this.TERMS_AND_CONDITIONS,
      this.COOKIES,
      this.PAYOUTS,
      this.WITHDRAWAL,
      this.RESPONSIBLE_GAMING,
      this.PRIVACY_POLICY,
      this.BONUS,
      this.FAQ,
      this.CONTACTS,
      this.ANTI_MONEY_LAUNDERING,
    ];
  },
};
