import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import PropTypes from 'prop-types';

const CashBoxOfferItemWelcomeTitle = ({user, data}) => {
  return (
    <>{`${data?.bonus?.deposit_percents}% ${
      locale?.OFFER?.bonusUpTo
    } ${formatAmount({
      amount: data?.bonus?.max_bonus_limit / 100,
      currency: user?.profile?.currency,
    })}`}</>
  );
};

CashBoxOfferItemWelcomeTitle.propTypes = {
  data: PropTypes.any,
};

export default CashBoxOfferItemWelcomeTitle;
