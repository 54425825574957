import buildRoute from '@navigation/buildRoute';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import {useStore} from '@store/configureStore';
import {observer} from 'mobx-react-lite';
import {Redirect, Route} from 'react-router-dom';
import {Suspense} from 'react';
import {useLocation} from 'react-router';

const CustomRoute = ({
  component: Component,
  suspenseFallback: SuspenseFallback,
  layout: Layout,
  useAuth = true,
  ...rest
}) => {
  const location = useLocation();
  const {
    user: {authorized},
  } = useStore();
  const pattern = /[a-z/]*[A-Z]+[a-z/]*/;
  const path = location.pathname;

  if (pattern.test(path)) {
    // @ts-ignore
    return <Redirect to={{pathname: `${path.toLowerCase()}`, state: {status: 301}}} />;
  }
  if (path.charAt(path.length - 1) === '/') {
    // @ts-ignore
    return <Redirect to={{pathname: `${path.slice(0, -1)}`, state: {status: 301}}} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          {!useAuth ? (
            <Suspense fallback={<SuspenseFallback />}>
              <Component {...props} />
            </Suspense>
          ) : authorized ? (
            <Suspense fallback={<SuspenseFallback />}>
              <Component {...props} />
            </Suspense>
          ) : (
            <Redirect
              to={buildRoute(navigationRoutes.MAIN) + modalRoutes.AUTH}
            />
          )}
        </Layout>
      )}
    />
  );
};

export default observer(CustomRoute);
