import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import PropTypes from 'prop-types';
import React from 'react';
import OfferInfoAction from '@components/Modals/OfferInfoModal/OfferInfoAction';

const OfferInfoGiftSpin = ({user, offers, data, game}) => {
  return (
    <>
      <div>
        <p className="mt-0 mb-[10px] pr-[34px] text-resolutionBlue text-[20px] leading-[22px] font-bold">
          {locale?.OFFER_INFO?.title}
        </p>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px] font-bold">
          {locale?.OFFER_INFO?.giftSpinRulesTitle
            ?.replace('{spins_count}', data?.spins_count)
            .replace('{game_title}', game?.title)}
        </p>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px]">
          {locale?.OFFER_INFO?.termsGiftSpin}
        </p>
        <ol className="list-decimal pl-5 text-resolutionBlue text-[14px] leading-[20px]">
          <li className="mb-2">
            {locale?.OFFER_INFO?.payback} <b>x{data?.wager}</b>.
          </li>
          <li className="mb-2">
            {`${locale?.OFFER_INFO?.spinBet} `}
            <b>
              {formatAmount({
                amount: data?.bet_size / 100 ?? 0,
                currency: user?.profile?.currency,
                minimumFractionDigits: 0,
              })}
            </b>
          </li>
        </ol>
      </div>
      {data?.can_cancel && <OfferInfoAction {...{offers, id: data?.id}} />}
    </>
  );
};

OfferInfoGiftSpin.propTypes = {
  user: PropTypes.any,
  offers: PropTypes.any,
  data: PropTypes.any,
  game: PropTypes.any,
};

export default OfferInfoGiftSpin;
