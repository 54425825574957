import logoConfig from '@constants/logoConfig';
import useWindowSize from '@hooks/useWindowSize';
import {locale} from '@res/strings/locale';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import React from 'react';

const Loader = ({className}) => {
  const size = useWindowSize();

  return (
    <>
      {size?.height && (
        <motion.div
          key={'loader'}
          style={{height: size.height}}
          className={classNames(className, 'flex items-center justify-center')}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{duration: 0.3}}>
          <img
            src={logoConfig.SRC}
            alt={locale?.ALT?.loader}
            className="animate-pulse"
          />
        </motion.div>
      )}
    </>
  );
};

export default Loader;
