import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

const MetaInfo = ({title, description}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

MetaInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default MetaInfo;
