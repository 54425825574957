import Flicking from '@egjs/react-flicking';
import useDeviceProps from '@hooks/useDeviceProps';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';

const SlidePagination = ({slides, activeIndex, sliderRef}) => {
  const {isMobile} = useDeviceProps();
  const showSlide = useCallback(
    (index) => () => {
      sliderRef?.current?.moveTo(index);
    },
    [sliderRef],
  );

  return (
    <div className="flex flex-1 absolute z-[3] text-white self-end p-[16px] space-x-[5px]">
      {slides?.map((slide, index) => {
        const active = index === activeIndex;

        return (
          <React.Fragment key={index}>
            {isMobile ? (
              <span
                className={classNames(
                  'w-[9px] h-[14px] rounded-[3.5px] border-[2px] box-border border-white50',
                  active && 'bg-white shadow-activePagination',
                )}
              />
            ) : (
              <a
                aria-label="click"
                onClick={showSlide(index)}
                className={classNames(
                  'w-[9px] h-[14px] rounded-[3.5px] border-[2px] box-border border-white50 cursor-pointer',
                  active && 'bg-white shadow-activePagination',
                )}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

SlidePagination.propTypes = {
  slides: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  sliderRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Flicking)}),
  ]),
};

export default SlidePagination;
