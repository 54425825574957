import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ContentButton from '@components/PageContent/ContentButton';
import ParagraphTitle from '@components/PageContent/ParagraphTitle';

const ChapterParagraph = ({title, data, button, color = 'text-secondary'}) => {
  return (
    <>
      <ParagraphTitle {...{title}} />
      <p
        className={classNames(
          'mb-[18px] text-base leading-base font-sans',
          color,
        )}
        dangerouslySetInnerHTML={{__html: data}}
      />
      <ContentButton {...{data: button}} />
    </>
  );
};

ChapterParagraph.propTypes = {
  title: PropTypes.string,
  data: PropTypes.string,
  button: PropTypes.any,
};

export default ChapterParagraph;
