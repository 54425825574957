import Button from '@components/Button';
import {useStore} from '@store/configureStore';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import gift from '@images/inbox-messages/gift.png';

const PushSubscriptionNotification = ({notification}) => {
  const {user} = useStore();

  const openPushWindow = () => {
    const playerId = user?.profile?.id ?? '';
    window.open(
      `https://assets.notifyer.live/subscribe/index.html?playerId=${playerId}`,
      'push-sub',
      'menubar=no,location=no,resizable=no,scrollbars=no,status=no,height=571,width=657',
    );
  };
  return (
    <>
      {notification && (
        <div className="flex flex-row justify-start h-full items-center">
          <img className="self-center" src={gift} />
          <div className="flex flex-col ml-[12px]">
            <p
              className="font-bold text-primary text-notification-title leading-notification-title"
              dangerouslySetInnerHTML={{__html: notification?.title}}
            />
            <p
              className="text-primary text-notification-message leading-notification-message"
              dangerouslySetInnerHTML={{__html: notification?.message}}
            />
            <Button
              onClick={openPushWindow}
              type="transparent"
              title={notification?.actionButtonText}
              className="w-full mt-[8px] py-[8px] px-[12px] uppercase text-[12px] text-center"
            />
          </div>
        </div>
      )}
    </>
  );
};

PushSubscriptionNotification.propTypes = {
  notification: PropTypes.any,
};

export default observer(PushSubscriptionNotification);
