import analyticsEvents from '@constants/analyticsEvents';
import buttonActions from '@constants/buttonActions';
import CustomLink from '@navigation/CustomLink';
import AnalyticsService from '@services/AnalyticsService';
import pushHash from '@utils/pushHash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';

const Button = ({
  title,
  type,
  htmlType,
  onClick,
  className,
  isLink,
  smIcon,
  iconAlt,
  icon = '',
  disabled = false,
  action,
  actionData,
  ...rest
}) => {
  const [restParams, setRestParams] = useState(rest);
  const history = useHistory();

  useEffect(() => {
    if (action && actionData) {
      switch (action) {
        case buttonActions.REDIRECT: {
          if (!disabled) {
            setRestParams((v) => ({...v, to: actionData}));
          } else {
            setRestParams((v) => ({...v, to: null}));
          }
          break;
        }
      }
    }
  }, [action, actionData, disabled]);

  const Wrapper = (props) => {
    if (isLink && restParams?.to !== null) {
      return (
        <CustomLink
          disableAnalytics
          {...{...props}}
          className={classNames(
            'flex items-center justify-center',
            props.className,
          )}
        />
      );
    } else {
      return <button type={htmlType} disabled={disabled} {...{...props}} />;
    }
  };

  const handleClick = () => {
    if (!disabled) {
      if (action === buttonActions.MODAL) {
        pushHash(history, actionData);
      } else if (onClick) {
        onClick();
      }
      AnalyticsService.logEvent(analyticsEvents.BUTTON_CLICK, {
        title: title ?? '',
      });
    }
  };

  return (
    <Wrapper
      aria-label="click"
      onClick={handleClick}
      className={classNames(
        'rounded-[4px] text-white focus:outline-none duration-200',
        icon && {
          'flex justify-center': true,
        },
        type === 'primary' && {
          'bg-redRibbon': true,
          'hover:bg-amaranth': !disabled,
          'active:bg-alizarinCrimson': !disabled,
        },
        type === 'secondary' && {
          'bg-denim': true,
          'hover:bg-denimDarker': !disabled,
          'active:bg-denimDark': !disabled,
        },
        type === 'warning' && {
          'bg-gold': true,
          'hover:bg-supernova': !disabled,
          'active:bg-selectiveYellow': !disabled,
          'text-black': true,
        },
        type === 'midnight' && {
          'bg-midnightBlue': true,
          'hover:bg-midnight': !disabled,
          'active:bg-midnight': !disabled,
        },
        type === 'stratos' && {
          'bg-stratos': true,
          'hover:bg-stratosDarker': !disabled,
          'active:bg-stratosDarker': !disabled,
        },
        type === 'transparent' && {
          'bg-transparent border-[2px] border-outline-default text-primary': true,
          'hover:border-outline-hover': !disabled,
          'active:border-outline-pressed  active:text-outline-pressed':
            !disabled,
        },
        type === 'transparent-secondary' && {
          'bg-transparent border-[1px] border-sanMarino text-sanMarino': true,
          'hover:border-poloBlue': !disabled,
          'active:border-poloBlue': !disabled,
        },
        disabled && {
          'bg-periwinkleGray cursor-not-allowed': true,
        },
        className,
      )}
      {...restParams}>
      {icon ? (
        <img src={icon} className="self-center select-none" alt={iconAlt} />
      ) : smIcon ? (
        <>
          <img src={smIcon} alt={iconAlt} className="block sm:hidden" />
          <span className="hidden sm:block">{title}</span>
        </>
      ) : (
        title
      )}
    </Wrapper>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'warning',
    'midnight',
    'stratos',
    'transparent',
    'transparent-secondary',
  ]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  smIcon: PropTypes.string,
  iconAlt: PropTypes.string,
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
  name: PropTypes.string,
  action: PropTypes.string,
  actionData: PropTypes.string,
};

export default Button;
