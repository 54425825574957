import Button from '@components/Button';
import Input from '@components/FormElements/Input';
import InputError from '@components/FormElements/InputError';
import ModalWrapper from '@components/Modals/ModalWrapper';
import {yupResolver} from '@hookform/resolvers/yup';
import useModalControls from '@hooks/useModalControls';
import useModalRegister from '@hooks/useModalRegister';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import errorMsgHandler from '@utils/errorMsgHandler';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useLocation} from 'react-router';
import * as yup from 'yup';

const ResetPasswordModal = ({handleCloseModal}) => {
  useModalRegister(modalRoutes.RESET_PASSWORD);
  const {openModal} = useModalControls();

  const {user} = useStore();
  const location = useLocation();

  const params = qs.parse(location.search);

  useEffect(() => {
    if (params && location.hash === modalRoutes.RESET_PASSWORD) {
      if (
        !params.reset_token /*||
        !params.type ||
        (params.type === 'phone' && !params.phone)*/
      ) {
        handleCloseModal();
      }
    }
  }, [params]);

  const authFormSchema = yup.object().shape({
    password: yup
      .string()
      .min(4, locale?.ERROR_MESSAGES?.passwordMin)
      .required(locale?.ERROR_MESSAGES?.required),
    passwordConfirmation: yup
      .string()
      .required(locale?.ERROR_MESSAGES?.required)
      .oneOf([yup.ref('password'), null], locale?.ERROR_MESSAGES?.match),
  });
  const {
    formState: {errors, isValid},
    handleSubmit,
    control,
    setError,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(authFormSchema),
    defaultValues: {password: '', passwordConfirmation: ''},
  });

  const resetPassword = async (data) => {
    try {
      await user?.resetPasswordConfirm.run({
        phone: params.phone,
        token: params.reset_token,
        password: data.password,
      });

      openModal(modalRoutes.RESET_PASSWORD_SUCCESS, {
        token: true,
      });
    } catch (errors) {
      errorMsgHandler(errors, {
        setError,
        fields: ['password'],
      });
    }
  };

  return (
    <ModalWrapper {...{handleCloseModal}}>
      <div className="w-full h-[56px] flex items-center justify-center bg-white" />
      <div className="flex flex-col sm:flex-row w-full h-[calc(100%-56px)] mt-[-1px]">
        <div className="w-full h-[100%] sm:h-auto bg-white">
          <div className="flex flex-col px-[32px] pt-[24px] pb-[50px] h-[100%]">
            <div className="text-center mt-[15px]">
              <h3 className="text-resolutionBlue font-bold text-[19px] leading-[26px] mb-[11px]">
                {locale?.resetPasswordFormTitle}
              </h3>
              <p className="text-resolutionBlue text-[14px] leading-[19px]">
                {locale?.resetPasswordFormMessage}
              </p>
            </div>

            <div className="flex flex-col w-full mt-[20px] h-[100%]">
              <form
                onSubmit={handleSubmit(resetPassword)}
                className="flex flex-col h-[100%]">
                <div>
                  <div className="relative">
                    <Controller
                      control={control}
                      name="password"
                      render={({field: {onChange, value}}) => (
                        <Input
                          placeholder={locale?.enterPassword}
                          type="password"
                          className="text-[14px]"
                          isError={errors.password}
                          errorMessage={errors.password?.message}
                          usePasswordVisibility
                          {...{value, onChange}}
                        />
                      )}
                    />
                  </div>
                  <div className="relative">
                    <Controller
                      control={control}
                      name="passwordConfirmation"
                      render={({field: {onChange, value}}) => (
                        <Input
                          placeholder={locale?.confirmPassword}
                          type="password"
                          className="text-[14px]"
                          isError={errors.passwordConfirmation}
                          errorMessage={errors.passwordConfirmation?.message}
                          usePasswordVisibility
                          {...{value, onChange}}
                        />
                      )}
                    />
                  </div>

                  {!!errors.system && (
                    <InputError message={errors.system?.message} />
                  )}
                </div>
                <Button
                  htmlType="submit"
                  title={locale?.save}
                  className="uppercase w-full h-[40px] text-[14px] font-bold leading-5 py-[10px] rounded-[5px] mt-[33px]"
                  type="secondary"
                  disabled={user?.resetPasswordConfirm?.inProgress || !isValid}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

ResetPasswordModal.propTypes = {
  isServiceScreen: PropTypes.bool,
  toggleServiceScreen: PropTypes.func,
  handleCloseModal: PropTypes.func,
};

export default observer(ResetPasswordModal);
