import LocalizationService from '@services/LocalizationService';
import React from 'react';
import {Helmet} from 'react-helmet';
import {Redirect} from 'react-router';

const LanguageRedirectPage = () => {
  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="301" />
        <meta
          name="prerender-header"
          content={`Location: ${LocalizationService.getLanguage()}`}
        />
      </Helmet>

      <Redirect to={LocalizationService.getLanguage()} />
    </>
  );
};

export default LanguageRedirectPage;
