import modalConfig from '@constants/modalConfig';
import {useStore} from '@store/configureStore';
import {observer} from 'mobx-react-lite';
import {useEffect} from 'react';

const ModalRoute = ({
  component: Component,
  openState = modalConfig.OPEN_STATES.ALL,
  handleCloseModal,
}) => {
  const {user} = useStore();

  useEffect(() => {
    if (openState === modalConfig.OPEN_STATES.AUTHORIZED && !user.authorized) {
      handleCloseModal();
    } else if (
      openState === modalConfig.OPEN_STATES.UNAUTHORIZED &&
      user.authorized
    ) {
      handleCloseModal();
    }
  }, [user?.authorized]);

  return <Component {...{handleCloseModal}} />;
};

export default observer(ModalRoute);
