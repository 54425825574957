import {locale} from '@res/strings/locale';

const errorMsgHandler = (error, {setError, fields}) => {
  if (error?.errors) {
    fields.forEach((field) => {
      if (error?.errors?.[field]) {
        setError(field, {
          message: error.errors[field]
            ?.map((e) => locale?.API_ERRORS?.[e] || e)
            ?.join(', '),
        });
      }
    });
  } else if (error?.message) {
    setError('system', {
      message: locale?.API_ERRORS?.[error.message] || error.message,
    });
  } else {
    setError('system', {message: locale?.API_ERRORS?.DEFAULT});
  }
};

export default errorMsgHandler;
