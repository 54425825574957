import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import PropTypes from 'prop-types';
import React from 'react';

const OfferInfoTournament = ({user, data}) => {
  return (
    <>
      <div>
        <p className="mt-0 mb-[10px] pr-[34px] text-resolutionBlue text-[20px] leading-[22px] font-bold">
          {locale?.OFFER_INFO?.title}
        </p>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px] font-bold">
          {`${locale?.OFFER_INFO?.tournamentInfo?.replace(
            '{tournament_name}',
            `"${data?.tournament_name}"`,
          )}`}
        </p>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px]">
          {locale?.OFFER_INFO?.tournamentPlace?.replace(
            '{place}',
            data?.tournament_place,
          )}
        </p>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px]">
          {locale?.OFFER_INFO?.tournamentAmount?.replace(
            '{amount}',
            formatAmount({
              amount: data?.amount / 100,
              currency: user?.profile?.currency,
            }),
          )}
        </p>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px]">
          {locale?.OFFER_INFO?.tournamentScore?.replace(
            '{score}',
            data?.tournament_score,
          )}
        </p>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px]">
          {locale?.OFFER_INFO?.tournamentRulesTitle}
        </p>
        <ol className="list-decimal pl-5 text-resolutionBlue text-[14px] leading-[20px]">
          <li className="mb-2">{locale?.OFFER_INFO?.emailRule}</li>
          <li className="mb-2">{locale?.OFFER_INFO?.tournamentRulePrize}</li>
        </ol>
      </div>
    </>
  );
};

OfferInfoTournament.propTypes = {
  user: PropTypes.any,
  data: PropTypes.any,
};

export default OfferInfoTournament;
