import CashBoxOffersList from '@components/Offer/CashBox/CashBoxOffersList';
import offerConstants from '@constants/offerConstants';
import useDeviceProps from '@hooks/useDeviceProps';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import classNames from 'classnames/bind';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

const DepositOffers = ({user}) => {
  const {offers} = useStore();
  const {isMobile} = useDeviceProps();
  const getFilteredData = () => {
    let result = offers?.availableOffers?.filter(
      (i) => i?.type !== offerConstants?.TYPE?.TOURNAMENT_WIN_BONUS,
    );

    if (
      user?.profile?.phone_confirmed_by === 'none' ||
      !user?.profile?.is_email_confirmed
    ) {
      result = result?.filter(
        (i) => i?.type !== offerConstants?.TYPE?.CASHBACK,
      );
    }

    return result;
  };
  const [data, setData] = useState(getFilteredData());

  useEffect(() => {
    offers?.getOffers?.run();
    offers?.getWelcomeOffers?.run();
  }, []);

  useEffect(() => {
    setData(getFilteredData());
  }, [offers?.availableOffers]);

  return (
    <>
      {data && data?.length > 0 && (
        <>
          <p className="text-center text-resolutionBlue text-[15px] mt-[20px] mb-[14px]">
            {locale?.selectCashBoxOffer}
          </p>
          <div
            className={classNames(
              {'w-screen': isMobile, 'w-[392px]': !isMobile},
              'flex justify-center',
            )}>
            <CashBoxOffersList
              {...{
                data,
                user,
                offers,
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

DepositOffers.propTypes = {
  user: PropTypes.any,
  isMobile: PropTypes.bool,
};

export default observer(DepositOffers);
