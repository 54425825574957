import './style.css';
import testIds from '@constants/testIds';
import {locale} from '@res/strings/locale';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {useHistory} from 'react-router';
import useGameSearch from '@hooks/useGameSearch';
import Icon from '@components/Icon';

const SelectorControlsBar = ({toggleFilter, isSearchActive, toggleSearch}) => {
  const history = useHistory();
  const inputRef = useRef(null);
  const {searchInputValue, setSearchInputValue} = useGameSearch();

  const handleClick = () => {
    toggleSearch();
  };

  useEffect(() => {
    if (isSearchActive) {
      inputRef.current.focus();
    } else {
      setSearchInputValue('');
    }
  }, [isSearchActive]);

  return (
    <div
      className={classNames(
        {'border-stratos': isSearchActive},
        'flex items-center max-w-[110px] md:max-w-[128px] w-full border-l-[2px] border-deepSapphire30 relative',
      )}>
      <div
        className="flex-1 max-w-[54px] md:max-w-[64px] w-full cursor-pointer"
        testid={testIds.FILTER_SIDEBAR_TRIGGER}
        aria-label="click"
        onClick={toggleFilter}>
        {!isSearchActive && (
          <Icon
            icon="filter"
            size={28}
            className="mx-auto cursor-pointer text-default-controls-icon hover:text-primary"
          />
        )}
      </div>
      <div className="flex flex-1 bg-stratosDarker cursor-pointer self-stretch items-center relative">
        <form
          role="search"
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            history.push(history?.location);
          }}
          className={classNames(
            {
              'absolute z-20 translate-x-[-100%] opacity-100 flex':
                isSearchActive,
              'translate-x-[100%] opacity-0 hidden': !isSearchActive,
            },
            'h-[100%] flex transform delay-1000 w-[calc(100vw-85px)] sm:w-[200px] lg:flex-1 transition duration-500',
          )}>
          <input
            testid={testIds.SEARCH_INPUT}
            ref={inputRef}
            type="search"
            className="placeholder-primary text-primary text-[15px] leading-[20px] bg-stratosDarker border-none rounded-l-[4px] pl-[26px] focus:outline-none w-full"
            placeholder={locale?.searchGame}
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e?.target?.value)}
          />
        </form>
        <div
          className="w-full max-w-[54px] md:max-w-[64px]"
          onClick={handleClick}>
          {isSearchActive ? (
            <Icon
              icon="close"
              size={24}
              className="m-auto cursor-pointer text-primary"
            />
          ) : (
            <Icon
              icon="search"
              size={28}
              className="m-auto cursor-pointer text-default-controls-icon hover:text-primary active:text-primary"
            />
          )}
        </div>
      </div>
    </div>
  );
};

SelectorControlsBar.propTypes = {
  toggleFilter: PropTypes.func.isRequired,
  isSearchActive: PropTypes.bool.isRequired,
  toggleSearch: PropTypes.func.isRequired,
};

export default SelectorControlsBar;
