import React from 'react';

const PublicationsSkeleton = () => {
  return (
    <>
      <div className="flex justify-center">
        <div className="flex flex-col justify-center mt-12 mb-12 sm:mt-16 md:mt-20 sm:mb-[60px]">
          <div className="w-full mb-2 text-stratos text-[26px] leading-[32px] font-bold font-sans  sm:text-[28px]  md:text-[36px] lg:text-[40px] bg-stratos animate-pulse">
            78787878
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center xl:justify-start mt-[-8px] sm:mt-[-12px] mx-[-8px] sm:mx-[-12px] overflow-hidden">
        {[...Array(6)]?.map((item, index) => (
          <div
            key={index}
            className="w-[416px] min-h-[328px] sm:min-h-[386px] max-w-full bg-stratos m-2 sm:m-3 rounded-[8px] overflow-hidden  bg-stratos animate-pulse"
          />
        ))}
      </div>
    </>
  );
};

export default PublicationsSkeleton;
