import {locale} from '@res/strings/locale';
import NotificationService from '@services/NotificationService';
import {useStore} from '@store/configureStore';
import getDevice from '@utils/getDevice';
import {observer} from 'mobx-react-lite';
import {useEffect, useRef} from 'react';
import LiveChat from 'react-livechat';

const LiveChatWrapper = () => {
  const {user} = useStore();
  const liveChatRef = useRef();

  useEffect(() => {
    if (liveChatRef?.current) {
      if (user?.profile) {
        window.LC_API?.update_custom_variables([
          {name: 'authorized', value: true},
          {name: 'id', value: user?.profile?.id},
          {name: 'email', value: user?.profile?.email},
          {name: 'full_name', value: user?.profile?.full_name},
          {name: 'status', value: user?.profile?.status},
        ]);
      } else {
        window.LC_API?.update_custom_variables([
          {name: 'authorized', value: false},
        ]);
      }
    }
  }, [user?.profile]);

  const handleMessage = (data) => {
    if (!data?.invitation && getDevice() === 'mobile') {
      NotificationService.default({
        notification: {
          title: locale?.newSupportMessage,
          message: data?.text,
          truncate: true,
        },
        onClick: () => {
          if (typeof window.LC_API?.open_chat_window === 'function') {
            window.LC_API?.open_chat_window();
          }
        },
        customConfig: {
          autoClose: 10000,
        },
      });
    }
  };

  return (
    <LiveChat
      license={13267731}
      onChatLoaded={(ref) =>
        (ref.on_after_load = function () {
          liveChatRef.current = ref;
        })
      }
      onMessage={handleMessage}
    />
  );
};

export default observer(LiveChatWrapper);
