const getImageUrl = (image) => {
  if (!image) {
    return '';
  } else if (typeof image === 'string') {
    return image;
  } else {
    const innerWidth = window.innerWidth;
    const imageVariants = {
      default: {url: image?.url, width: image?.width},
      thumbnail: {
        url: image?.formats?.thumbnail?.url,
      },
      small: {
        url: image?.formats?.small?.url,
      },
      large: {
        url: image?.formats?.large?.url,
      },
      xLarge: {
        url: image?.formats?.xlarge?.url,
      },
    };
    if (innerWidth > 2000) {
      return (
        imageVariants?.default?.url ??
        imageVariants?.xLarge?.url ??
        imageVariants?.large?.url ??
        imageVariants?.small?.url
      );
    }
    if (innerWidth > 1000) {
      return (
        imageVariants?.xLarge?.url ??
        imageVariants?.large?.url ??
        imageVariants?.small?.url ??
        imageVariants?.default?.url
      );
    }
    if (innerWidth > 500) {
      return (
        imageVariants?.xLarge?.url ??
        imageVariants?.large?.url ??
        imageVariants?.small?.url ??
        imageVariants?.default?.url
      );
    }
    if (innerWidth > 245) {
      return (
        imageVariants?.large?.url ??
        imageVariants?.small?.url ??
        imageVariants?.thumbnail?.url ??
        imageVariants?.default?.url
      );
    }
    return (
      imageVariants?.thumbnail?.url ??
      imageVariants?.small?.url ??
      imageVariants?.large?.url ??
      imageVariants?.xLarge?.url ??
      imageVariants?.default?.url
    );
  }
};

export default getImageUrl;
