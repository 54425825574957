import PropTypes from 'prop-types';
import lotteryConfig from '@constants/lotteryConfig';
import LotteryRules from '@components/LotteryPage/LotteryRules';
import LotteryResults from '@components/LotteryPage/LotteryResults';
import LotteryPrizes from '@components/LotteryPage/LotteryPrizes';

const LotteryContent = ({data, route}) => {
  switch (route) {
    case lotteryConfig?.PRIZES:
      return <LotteryPrizes {...{data}} />;
    case lotteryConfig?.RESULTS:
      return <LotteryResults {...{data}} />;
    default:
      return <LotteryRules {...{data}} />;
  }
};

LotteryContent.propTypes = {
  data: PropTypes.any,
  route: PropTypes.string,
};

export default LotteryContent;
