export default {
  CATEGORY_TITLE: 'category-title',
  CATEGORY_BUTTON: 'category-button',
  GAME_GRID_ITEM: 'game-grid-item',
  GAME_GRID_ITEM_WRAPPER: 'game-grid-item-wrapper',
  GAME_GRID_ITEM_DEMO_BUTTON: 'game-grid-item-demo-button',
  GAME_IFRAME: 'game-iframe',
  FULLSCREEN_BUTTON: 'fullscreen-button',
  NAVIGATION_ITEMS: 'navigation-items',
  MODAL_TRIGGER: 'modal-trigger',
  MODAL_NAME: 'modal-name',
  CLOSE_MODAL: 'close-modal',
  SEARCH_INPUT: 'search-input',
  FILTER_SIDEBAR_TRIGGER: 'filter-sidebar-trigger',
  FILTER_TRIGGER: 'filter-trigger',
  PROVIDERS_FILTER_SELECTOR: 'providers-filter-selector',
  SINGLE_PROVIDER_FILTER: 'single-provider-filter',
};
