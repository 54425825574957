import Meta from '@components/Meta';
import React from 'react';

const EmptyLayout = ({children}) => {
  return (
    <main className="bg-midnightBlue">
      <Meta />
      {children}
    </main>
  );
};

export default EmptyLayout;
