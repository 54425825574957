import declined_icon from '@images/icons/deposit-declinedpicon.svg';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '@components/Button';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import {useHistory} from 'react-router';

const PromoCodeActivationNotification = ({notification}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(buildRoute(navigationRoutes.PROMO_CODE));
  };
  return (
    <>
      {notification && (
        <div className="flex flex-row justify-start h-full items-center">
          <img className="self-center" src={declined_icon} />
          <div className="flex flex-col ml-[12px]">
            <p
              className="font-bold text-primary text-notification-title leading-notification-title"
              dangerouslySetInnerHTML={{__html: notification?.title}}
            />
            <p
              className="text-primary text-notification-message leading-notification-message"
              dangerouslySetInnerHTML={{__html: notification?.message}}
            />
            <Button
              onClick={handleClick}
              type="transparent"
              title={notification?.actionButtonText}
              className="w-full mt-[8px] py-[8px] px-[12px] uppercase text-[12px] text-center"
            />
          </div>
        </div>
      )}
    </>
  );
};

PromoCodeActivationNotification.propTypes = {
  notification: PropTypes.any,
  withActionButton: PropTypes.bool,
};

export default PromoCodeActivationNotification;
