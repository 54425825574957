import PropTypes from 'prop-types';

const ChapterImagesContainer = ({children}) => {
  return (
    <div className="grid justify-items-center max-w-full mb-[18px]">
      <div className="relative w-full max-w-[290px] min-h-[211px] px-2 pt-2 pb-8 rounded-[8px] bg-stratosLight  sm:px-4 sm:pt-4 sm:w-[308px] sm:min-h-[220px]">
        {children}
      </div>
    </div>
  );
};

ChapterImagesContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChapterImagesContainer;
