import analyticsEvents from '@constants/analyticsEvents';
import categoryKeys from '@constants/categoryKeys';
import gamesFilterConfig from '@constants/gamesFilterConfig';
import AnalyticsService from '@services/AnalyticsService';
import UrlHashParams from '@services/UrlHashParams';
import {useStore} from '@store/configureStore';
import {useEffect} from 'react';
import {useHistory, useParams} from 'react-router';

const useGamesFilter = () => {
  const history = useHistory();
  const location = history?.location;
  const {category} = useParams();

  const {
    filters: {
      init,
      getActiveFilters,
      resetAllFilters,
      resetSingleFilter,
      getAllActiveFilters,
    },
  } = useStore();

  useEffect(() => {
    if (category === categoryKeys.ALL_GAMES && location?.hash) {
      init(history);
    } else {
      if (!location?.hash) {
        resetAllFilters();
      }
    }
  }, [category, location?.hash]);

  const applyFilter = () => {
    Object.values(gamesFilterConfig.AVAILABLE_FILTERS).forEach((value) => {
      const filterSearchKey = value?.SEARCH_KEY;
      const filterStoreKey = value?.STORE_KEY;
      const storedParams = getActiveFilters(filterStoreKey);
      if (storedParams?.length) {
        UrlHashParams.set(history, filterSearchKey, storedParams);
      } else {
        UrlHashParams.delete(history, filterSearchKey);
        resetSingleFilter(filterStoreKey);
      }
    });

    AnalyticsService.logEvent(analyticsEvents.GAME_FILTER_APPLY, {
      filter: {...getAllActiveFilters()},
    });
  };

  const resetFilter = () => {
    Object.keys(gamesFilterConfig.AVAILABLE_FILTERS)?.map((filter) => {
      UrlHashParams.delete(
        history,
        gamesFilterConfig.AVAILABLE_FILTERS?.[filter].SEARCH_KEY,
      );
      resetSingleFilter(
        gamesFilterConfig.AVAILABLE_FILTERS?.[filter].STORE_KEY,
      );
    });
    resetAllFilters();
  };

  return {
    applyFilter,
    resetFilter,
  };
};

export default useGamesFilter;
