import Button from '@components/Button';
import GamesGrid from '@components/GamesGrid/GamesGrid';
import PageContainer from '@components/PageContent/PageContainer';
import categoryKeys from '@constants/categoryKeys';
import bg_404 from '@images/bg-404.svg';
import buildRoute from '@navigation/buildRoute';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import LocalizationService from '@services/LocalizationService';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory, useLocation} from 'react-router';
import {Redirect} from 'react-router-dom';

const NotFoundPage = () => {
  const location = useLocation();
  const history = useHistory();

  /*  useEffect(() => {
    if (LocalizationService.isInternalMultiLang) {
      let pathLang = location.pathname?.split('/')?.[1];

      if (!LocalizationService.availableLangCodes.includes(pathLang)) {
        history.replace(
          buildRoute(
            LocalizationService.availableLangCodes[0] + location.pathname,
          ),
        );
      }
    }
  }, []);*/

  if (LocalizationService.isInternalMultiLang) {
    let pathLang = location.pathname?.split('/')?.[1];

    if (!LocalizationService.availableLangCodes.includes(pathLang)) {
      const route = buildRoute(
        LocalizationService.getLanguage() + location.pathname,
      );

      return (
        <>
          <Helmet>
            <meta name="prerender-status-code" content="301" />
            <meta
              name="prerender-header"
              content={`Location: ${window.location.origin}${route}`}
            />
          </Helmet>

          <Redirect to={route} />
        </>
      );
    }
  }

  return (
    <PageContainer>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>

      <div className="flex flex-col items-center my-[35px]">
        <div className="relative mb-[70px] sm:mb-[10px]">
          <img src={bg_404} alt={locale.pageNotFound} />
          <div className="max-w-[328px] w-full text-center absolute mb-[20px] bottom-[-80px] sm:bottom-[-20px] md:bottom-[-10px] left-[50%] transform -translate-x-1/2">
            <h3 className="text-white text-[19px] sm:text-[24px] mb-[10px] font-bold">
              {locale.pageNotFound}
            </h3>
            <span className="text-white text-[14px] text-melrose">
              {locale.pageNotFoundHint}
            </span>
          </div>
        </div>
        <Button
          className="text-white uppercase text-[14px] mt-[10px] text-center font-bold block py-[10px] max-w-[196px] w-full bg-denim rounded-[5px]"
          isCustomLink
          isLink
          to={buildRoute(navigationRoutes.MAIN)}
          title={locale.goToMainPage}
        />
      </div>
      <GamesGrid
        title={locale.recommended}
        categoryKey={categoryKeys.RECOMMENDED}
        renderBanner={false}
      />
    </PageContainer>
  );
};

export default NotFoundPage;
