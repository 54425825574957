import Button from '@components/Button';
import Input from '@components/FormElements/Input';
import InputError from '@components/FormElements/InputError';
import TelInput from '@components/FormElements/TelInput/TelInput';
import ModalWrapper from '@components/Modals/ModalWrapper';
import modalConfig from '@constants/modalConfig';
import {yupResolver} from '@hookform/resolvers/yup';
import useActiveTab from '@hooks/useActiveTab';
import useModalControls from '@hooks/useModalControls';
import useModalRegister from '@hooks/useModalRegister';
import back_icon from '@images/icons/back-icon.svg';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import errorMsgHandler from '@utils/errorMsgHandler';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';

const ForgotPasswordModal = ({handleCloseModal}) => {
  const {params} = useModalRegister(modalRoutes.FORGOT_PASSWORD);

  const {user} = useStore();
  const {openModal} = useModalControls();

  const [activeTab, switchTab] = useActiveTab(
    params?.activeTab || modalConfig.TAB_NAMES.EMAIL,
  );

  const authFormSchema = useMemo(
    () =>
      yup.object().shape({
        email:
          activeTab === modalConfig.TAB_NAMES.EMAIL
            ? yup
                .string()
                .email(locale?.ERROR_MESSAGES?.email)
                .required(locale?.ERROR_MESSAGES?.required)
            : yup.string(),
        phone:
          activeTab === modalConfig.TAB_NAMES.PHONE
            ? yup.string().required(locale?.ERROR_MESSAGES?.required)
            : yup.string(),
      }),
    [activeTab],
  );
  const {
    formState: {errors, isValid},
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    control,
  } = useForm({
    resolver: yupResolver(authFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      phone: '',
    },
  });

  useEffect(() => {
    reset();
  }, [activeTab]);

  const onSubmit = async (data) => {
    if (activeTab === modalConfig.TAB_NAMES.EMAIL) {
      try {
        await user?.resetPasswordRequest.run({
          email: data.email,
        });

        openModal(modalRoutes.FORGOT_PASSWORD_EMAIL_SENT, {sent: true});
      } catch (errors) {
        errorMsgHandler(errors, {
          setError,
          fields: ['email'],
        });
      }
    } else if (activeTab === modalConfig.TAB_NAMES.PHONE) {
      try {
        await user?.resetPasswordRequest.run({
          phone: data.phone,
        });

        openModal(modalRoutes.FORGOT_PASSWORD_VERIFY_CODE, {
          userPhone: data.phone,
        });
      } catch (errors) {
        errorMsgHandler(errors, {
          setError,
          fields: ['phone'],
        });
      }
    }
  };
  return (
    <ModalWrapper {...{handleCloseModal}}>
      <span className="absolute left-[16px] top-[14px] cursor-pointer z-10">
        <span
          className="click-area"
          onClick={() => openModal(modalRoutes.AUTH, {activeTab})}>
          <img src={back_icon} alt={locale.ALT?.closeMenuIcon} />
        </span>
      </span>
      <div className="flex flex-col px-[32px] pt-[24px] pb-[50px] h-[100%]">
        <div className="mb-[24px] text-center mt-[15px]">
          <h3 className="text-resolutionBlue font-bold text-[19px] leading-[26px] mb-[11px]">
            {locale?.resetPasswordTitle}
          </h3>
          <p className="text-resolutionBlue text-[14px] leading-[19px]">
            {locale?.resetPasswordMessage}
          </p>
        </div>
        <div className="flex relative">
          <div
            className={classNames(
              {
                'translate-x-0': activeTab === modalConfig.TAB_NAMES.EMAIL,
                'translate-x-[100%]': activeTab === modalConfig.TAB_NAMES.PHONE,
              },
              'absolute h-[2px] w-[50%]  border-b-[2px] border-denim bottom-[-4px] transform transition duration-200 ease-in-out',
            )}
          />
          <div
            className={classNames(
              'w-1/2 cursor-pointer transition uppercase text-center font-bold text-[12px] text-resolutionBlue',
            )}
            onClick={() => switchTab(modalConfig.TAB_NAMES.EMAIL)}>
            {locale?.email}
          </div>
          <div
            className={classNames(
              'transition duration-200 ease w-1/2 cursor-pointer uppercase text-center font-bold text-[12px] text-resolutionBlue',
            )}
            onClick={() => switchTab(modalConfig.TAB_NAMES.PHONE)}>
            {locale?.phone}
          </div>
        </div>

        <div className="flex flex-col justify-between w-full mt-[20px] h-[100%]">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <div>
              {activeTab === modalConfig.TAB_NAMES.PHONE && (
                <Controller
                  control={control}
                  name="phone"
                  render={({field: {onChange, value, ref}}) => (
                    <TelInput
                      inputRef={ref}
                      type="tel"
                      name="phone"
                      isError={errors.phone}
                      errorMessage={errors.phone?.message}
                      {...{value, onChange, setError, clearErrors, setValue}}
                    />
                  )}
                />
              )}

              {activeTab === modalConfig.TAB_NAMES.EMAIL && (
                <Controller
                  control={control}
                  name="email"
                  render={({field: {onChange, value}}) => (
                    <Input
                      placeholder={locale?.enterEmail}
                      type="email"
                      {...{value, onChange}}
                      isError={errors.email}
                      errorMessage={errors.email?.message}
                      className="text-[14px]"
                    />
                  )}
                />
              )}

              {!!errors.system && (
                <InputError message={errors.system?.message} />
              )}
            </div>

            <Button
              htmlType="submit"
              title={
                user?.resetPasswordRequest?.inProgress
                  ? locale?.processing
                  : locale?.sendPassword
              }
              className="uppercase w-full h-[40px] text-[14px] font-bold leading-5 py-[10px] rounded-[5px] mt-[15px]"
              type="secondary"
              disabled={user?.resetPasswordRequest?.inProgress || !isValid}
            />
          </form>
          <div className="flex flex-col self-center">
            <p className="text-[13px] text-resolutionBlue">
              {locale?.noAccount}?
              <span
                onClick={() => {
                  openModal(modalRoutes.REGISTER);
                }}
                className="cursor-pointer ml-[5px] underline">
                {locale?.doRegister}
              </span>
            </p>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default observer(ForgotPasswordModal);
