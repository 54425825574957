import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import LandingListSubtitle from './LandingListSubtitle';

const LandingListCompleted = ({data, title, constants, BannerItem}) => {
  const mapData = useMemo(
    () =>
      data?.map((item, key) => (
        <div className="w-full sm:w-1/2 lg:w-1/3 p-3 md:p-4" {...{key}}>
          <BannerItem
            {...{
              data: item,
              slug: item?.slug,
              hasBlackout: constants?.ARCHIVED_STATUS === item?.status,
              isShort: true,
              constants,
            }}
          />
        </div>
      )),
    [data],
  );

  return (
    <>
      {!!data?.length && (
        <>
          <LandingListSubtitle {...{title}} />
          {mapData}
        </>
      )}
    </>
  );
};

LandingListCompleted.propTypes = {
  data: PropTypes.any,
  title: PropTypes.string,
  constants: PropTypes.any,
  BannerItem: PropTypes.any,
};

export default LandingListCompleted;
