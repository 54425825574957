import formatLocale from '@utils/formatLocale';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React from 'react';

const BaseDateRender = ({date, className}) => {
  const currentDate = new Date(date);

  return (
    <div {...{className}}>
      {`${format(currentDate, 'dd')} ${formatLocale(
        currentDate,
        'MMMM',
      )} ${format(currentDate, 'yyyy')}`}
    </div>
  );
};

BaseDateRender.propTypes = {
  date: PropTypes.string,
  className: PropTypes.string,
};

export default BaseDateRender;
