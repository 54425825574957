import useToggleState from '@hooks/useToggleState';
import modalRoutes from '@navigation/modalRoutes';
import pushHash from '@utils/pushHash';
import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';

const useModalDriver = ({initialState = null, authorized = false}) => {
  const location = useLocation();
  const history = useHistory();
  const [isModalOpen, toggleModal, closeModal] = useToggleState();
  const [activeModal, setActiveModal] = useState(initialState);

  const handleCloseModal = () => {
    pushHash(history, '');
    setActiveModal(null);
    closeModal();
  };

  useEffect(() => {
    const modalsHashes = Object.values(modalRoutes);

    if (modalsHashes.includes(location?.hash)) {
      setActiveModal(location?.hash);
    } else if (activeModal) {
      setActiveModal(null);
      closeModal();
    }
  }, [location?.hash, authorized]);

  useEffect(() => {
    if (activeModal && authorized) {
      handleCloseModal();
    }
  }, [authorized]);

  useEffect(() => {
    closeModal();
    activeModal && toggleModal();
  }, [activeModal]);

  return [activeModal, isModalOpen, handleCloseModal];
};

export default useModalDriver;
