import Button from '@components/Button';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import openSupportChat from '@utils/openSupportChat';
import React from 'react';
import {MdBlock} from 'react-icons/md';

const UserBanned = () => {
  const {user} = useStore();

  const handleSignOut = () => {
    user?.signOut();
  };

  return (
    <div className={'h-screen flex items-center justify-center flex-col'}>
      <MdBlock className={'animate-pulse w-[80px] h-[80px] text-white'} />

      <h1 className={'text-white font-bold text-[24px]'}>
        {locale.youAreBanned}
      </h1>

      <h2 className={'text-white text-[20px] pt-[10px]'}>
        {locale.contactSupport}
      </h2>

      <Button
        className="mt-[8px] uppercase font-bold py-[11px] px-[8px] leading-[10px] text-[14px]"
        type="secondary"
        title={locale?.contactAction}
        onClick={openSupportChat}
      />

      <p
        aria-label={locale?.exit}
        className="mt-[48px] underline cursor-pointer text-center text-white text-[14px] font-bold uppercase"
        onClick={handleSignOut}>
        {locale?.exit}
      </p>
    </div>
  );
};

export default UserBanned;
