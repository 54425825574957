import countdown_icon from '@images/icons/countdown-icon.svg';
import classNames from 'classnames/dedupe';
import React from 'react';
import {locale} from '@res/strings/locale';
import PropTypes from 'prop-types';

const CountdownIcon = ({className}) => {
  return (
    <img
      src={countdown_icon}
      alt={locale?.ALT?.countdown}
      className={classNames('inline-block align-top', className)}
    />
  );
};

CountdownIcon.propTypes = {
  className: PropTypes.string,
};

export default CountdownIcon;
