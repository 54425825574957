import ContentButton from '@components/PageContent/ContentButton';
import PropTypes from 'prop-types';

const ChapterHeading = ({data, button, type}) => {
  /* TODO: set up typography */
  const heading = () => {
    switch (type) {
      case 'h1':
        return (
          <h1
            className={
              'text-white font-bold text-[19px] sm:text-[24px] leading-[24px] mb-[21px]'
            }
            dangerouslySetInnerHTML={{__html: data}}
          />
        );
      case 'h2':
        return (
          <h2
            className={
              'text-white font-bold text-[19px] sm:text-[24px] leading-[24px] mb-[21px]'
            }
            dangerouslySetInnerHTML={{__html: data}}
          />
        );
      case 'h3':
        return (
          <h3
            className={
              'mb-[18px] text-white text-base leading-6 font-semibold font-sans'
            }
            dangerouslySetInnerHTML={{__html: data}}
          />
        );
      case 'h4':
        return (
          <h4
            className={
              'mb-[18px] text-white text-base leading-6 font-semibold font-sans'
            }
            dangerouslySetInnerHTML={{__html: data}}
          />
        );
      case 'h5':
        return (
          <h5
            className={
              'mb-[18px] text-white text-base leading-6 font-semibold font-sans'
            }
            dangerouslySetInnerHTML={{__html: data}}
          />
        );
      case 'h6':
        return (
          <h6
            className={
              'mb-[18px] text-white text-base leading-6 font-semibold font-sans'
            }
            dangerouslySetInnerHTML={{__html: data}}
          />
        );
      default:
        return '';
    }
  };

  return (
    <>
      {heading()}
      <ContentButton {...{data: button}} />
    </>
  );
};

ChapterHeading.propTypes = {
  type: PropTypes.string,
  data: PropTypes.string,
  button: PropTypes.any,
};

export default ChapterHeading;
