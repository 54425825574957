export default {
  AVAILABLE_FILTERS: {
    PROVIDERS: {
      NAME: 'providers',
      STORE_KEY: 'providers',
      SEARCH_KEY: 'provider_in',
    },
    FEATURES: {
      NAME: 'features',
      STORE_KEY: 'features',
      SEARCH_KEY: 'features_in',
      VARIANTS: ['freeSpinOn', 'reSpinOn', 'bonusesOn', 'riskOn'],
    },
    VOLATILITY: {
      NAME: 'volatility',
      STORE_KEY: 'volatility',
      SEARCH_KEY: 'volatility_in',
      VARIANTS: ['low', 'medium', 'high'],
    },
    CATEGORIES: {
      NAME: 'categories',
      STORE_KEY: 'categories',
      SEARCH_KEY: 'categories_in',
    },
  },
  ANIMATION_CONFIG: {
    visible: (index) => ({
      opacity: 1,
      x: 0,
      transition: {
        ease: 'easeInOut',
        delay: index > 4 ? (index - 4) * 0.1 : index * 0.1,
      },
    }),
    hidden: {
      x: 20,
      opacity: 0,
    },
  },
};
