import classNames from 'classnames';

const InputError = ({message}) => {
  return (
    <p className={classNames('text-redRibbon text-[12px] mt-[8px] mb-[16px]')}>
      {message}
    </p>
  );
};

export default InputError;
