import Endpoints from '@api/endpoints';
import displayForKeys from '@constants/displayForKeys';
import useRequest from '@hooks/useRequest';
import {useStore} from '@store/configureStore';
import {useMemo} from 'react';

const usePromoBanner = (categoryKey) => {
  const {user} = useStore();

  const {data: promotions} = useRequest({
    endpoint: Endpoints.PROMOTIONS,
    params: {
      display_for_in: [
        displayForKeys.ALL,
        user.authorized ? 'authorized' : 'not_authorized',
      ],
    },
  });

  return useMemo(
    () => promotions?.find((value) => value?.categoryKey === categoryKey),
    [promotions, categoryKey],
  );
};

export default usePromoBanner;
