import LandingListEmpty from '@components/LandingList/LandingListEmpty';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

const LandingListCurrent = ({data, constants, BannerItem, emptyText}) => {
  const mapData = useMemo(
    () =>
      data?.map((item, key) => (
        <div {...{className: 'w-full p-3 md:p-4', key}}>
          <BannerItem
            {...{
              data: item,
              slug: item?.slug,
              hasBlackout: constants?.STATUS?.ARCHIVED === item?.status,
              constants,
            }}
          />
        </div>
      )),
    [data],
  );

  return (
    <>{data?.length ? mapData : <LandingListEmpty {...{text: emptyText}} />}</>
  );
};

LandingListCurrent.propTypes = {
  data: PropTypes.any,
  constants: PropTypes.any,
  BannerItem: PropTypes.any,
  emptyText: PropTypes.string,
};

export default LandingListCurrent;
