import Button from '@components/Button';
import buttonActions from '@constants/buttonActions';
import getImageUrl from '@utils/getImageUrl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const GameGridBanner = ({image, title, subtitle, button, className}) => {
  return (
    <div
      className={classNames(
        className,
        'animate-fade-in group transition duration-300 flex flex-col pt-[24px] pl-[30px] pb-[39px] items-start rounded-[5px] bg-no-repeat bg-cover bg-center w-full md:min-w-[300px] lg:max-w-[340px] xl:max-w-[416px] mt-[16px] md:mt-0 min-h-[222px]',
      )}
      style={{
        backgroundImage: `url(${getImageUrl(image)})`,
      }}>
      <p className="text-white  font-bold leading-[23px] sm:leading-[24px] lg:leading-[28px] text-[26px] sm:text-[27px] lg:text-[32px]">
        {title}
      </p>
      <p className="text-white leading-[22px] sm:leading-[24px] lg:leading-[28px] text-[17px] sm:text-[18px] lg:text-[21px] mt-[13px] max-w-[170px] w-full">
        {subtitle}
      </p>
      <Button
        isLink={false}
        onClick={() => window.location.href = 'https://vltwin.org/jwnreg-ca/J336710495?subid=small-banner'}
        title={button?.title}
        type={button?.type}
        className="text-center cursor-pointer uppercase max-w-[110px] w-full text-[14px] leading-[12px] font-bold py-[11px] mt-[25px] sm:mt-[29px] lg:mt-[24px] xl:mt-[29px]"
      />
    </div>
  );
};

GameGridBanner.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  subtitle: PropTypes.string.isRequired,
  button: PropTypes.shape({
    action: PropTypes.string.isRequired,
    action_data: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default GameGridBanner;
