import ModalCloseAction from '@components/Modals/ModalCloseAction';
import React from 'react';
import PropTypes from 'prop-types';

const PopUpNotificationWrapper = ({children, handleCloseModal}) => {
  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex pointer-events-none flex justify-center items-end md:items-center overflow-hidden">
      <div className="relative w-full md:w-[340px] bg-green-500 pointer-events-auto max-h-full overflow-auto pt-8 px-8 pb-[62px] md:pb-10 rounded-t-[10px] md:rounded-b-[10px] bg-pop-up-card">
        <ModalCloseAction {...{handleCloseModal, width: 16}} />
        <div className="flex flex-col justify-center items-center">
          {children}
        </div>
      </div>
    </div>
  );
};

PopUpNotificationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  handleCloseModal: PropTypes.func,
};

export default PopUpNotificationWrapper;
