import classNames from 'classnames';
import React from 'react';
import {Link, Route} from 'react-router-dom';

const CustomNavLink = ({
  to,
  exact,
  strict,
  location,
  className = '',
  activeClassName,
  inactiveClassName,
  activeStyles,
  inactiveStyles,
  isActive: getIsActive,
  children,
  ...rest
}) => (
  <Route
    path={typeof to === 'object' ? to.pathname : to}
    exact={exact}
    strict={strict}
    location={location}>
    {({match}) => {
      const isActive = !!(getIsActive ? getIsActive(match, location) : match);
      const moddedClassName = classNames(
        className,
        `${isActive ? activeClassName : inactiveClassName}`,
      );
      const moddedStyles = isActive ? activeStyles : inactiveStyles;
      return (
        <Link
          to={to}
          className={moddedClassName}
          style={moddedStyles}
          {...rest}>
          {typeof children === 'function' ? children({isActive}) : children}
        </Link>
      );
    }}
  </Route>
);

export default CustomNavLink;
