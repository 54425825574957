export default {
  TYPE: {
    DEPOSIT_BONUS: 'deposit_bonus',
    NO_DEPOSIT_BONUS: 'no_deposit_bonus',
    DEPOSIT_GIFT_SPIN: 'deposit_gift_spin',
    NO_DEPOSIT_GIFT_SPIN: 'no_deposit_gift_spin',
    TOURNAMENT_WIN_BONUS: 'tournament_win_bonus',
    GIFT_SPIN_WIN_BONUS: 'gift_spin_win_bonus',
    CASHBACK_INFO: 'cashback_info',
    CASHBACK: 'cashback',
    WELCOME: 'welcome',
  },
  STATUS: {
    AVAILABLE: 'available',
    AWAITING: 'awaiting',
    ACTIVE: 'active',
    FINISHED: 'finished',
    WASTED: 'wasted',
    IGNORED: 'ignored',
    REJECTED: 'rejected',
    CANCELED: 'canceled',
    EXPIRED: 'expired',
    WAGER_IGNORED: 'wager_ignored',
  },
  monthly: 'monthly',
};
