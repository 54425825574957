import PropTypes from 'prop-types';
import React from 'react';

const LotteryPrizesItemIcon = ({src, alt = ''}) => {
  return <img {...{src}} alt={alt} />;
};

LotteryPrizesItemIcon.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default LotteryPrizesItemIcon;
