import {locale} from '@res/strings/locale';
import Button from '@components/Button';
import useModalControls from '@hooks/useModalControls';
import PropTypes from 'prop-types';
import React from 'react';

const OfferInfoAction = ({offers, id}) => {
  const {closeModal} = useModalControls();

  return (
    <div className="flex flex-col items-center">
      <Button
        title={locale?.BUTTONS_TITLES?.reject}
        className="h-[40px] w-[196px] max-w-full text-[14px] font-bold leading-5 py-[10px] rounded-[5px] uppercase text-center "
        type="transparent-secondary"
        onClick={async () => {
          if (id) {
            try {
              await offers?.rejectOffer.run(id);
              closeModal();
            } catch (e) {
              //   TODO: add error notification
            }
          }
        }}
      />
    </div>
  );
};

OfferInfoAction.propTypes = {
  offers: PropTypes.any,
  id: PropTypes.string,
};

export default OfferInfoAction;
