import cahsbox_data_loading_icon from '@images/icons/cahsbox-data-loading-icon.svg';
import {locale} from '@res/strings/locale';
import React from 'react';

const DataLoadingMessage = () => {
  return (
    <div className="flex flex-col h-[100%] w-full justify-center items-center animate-pulse">
      <img width="50" src={cahsbox_data_loading_icon} />
      <p className="text-center text-poloBlue text-[13px] mt-[13px]">
        {locale?.dataLoading}
      </p>
    </div>
  );
};

export default DataLoadingMessage;
