import Button from '@components/Button';
import ModalWrapper from '@components/Modals/ModalWrapper';
import useModalControls from '@hooks/useModalControls';
import useModalRegister from '@hooks/useModalRegister';
import lock_icon_large from '@images/icons/lock-icon-large.svg';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import PropTypes from 'prop-types';
import React from 'react';

const ResetPasswordSuccessModal = ({handleCloseModal}) => {
  useModalRegister(modalRoutes.RESET_PASSWORD_SUCCESS, {
    requiredParams: ['token'],
    handleCloseModal,
  });

  const {openModal} = useModalControls();

  const handleOpenAuthModal = () => {
    openModal(modalRoutes.AUTH);
  };

  return (
    <ModalWrapper {...{handleCloseModal}}>
      <div className="flex flex-col justify-between px-[32px] pt-[24px] pb-[50px] h-[100%]">
        <div className="mb-[24px] text-center mt-[50px]">
          <img
            src={lock_icon_large}
            alt={locale?.lockIcon}
            className="mx-auto mb-[20px]"
          />
          <h3 className="text-resolutionBlue font-bold text-[19px] leading-[26px] mb-[11px]">
            {locale?.resetPasswordSuccessTitle}
          </h3>
          <p className="text-resolutionBlue text-[14px] leading-[19px] mb-[5px]">
            {locale?.resetPasswordSuccessMessage}
          </p>
        </div>
        <Button
          onClick={handleOpenAuthModal}
          htmlType="submit"
          title={locale?.startPlaying}
          className="uppercase w-full py-[10px] rounded-[5px]"
          type="secondary"
        />
      </div>
    </ModalWrapper>
  );
};

ResetPasswordSuccessModal.propTypes = {
  login: PropTypes.func.isRequired,
};

export default ResetPasswordSuccessModal;
