import {locale} from '@res/strings/locale';
import empty_icon from '@images/icons/empty-secondary-icon.svg';
import Button from '@components/Button';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import React from 'react';
import TicketsTitle from '@components/Profile/Tickets/TicketsTitle';

const TicketsEmpty = () => {
  return (
    <>
      <TicketsTitle />
      <div className="w-full flex justify-center items-center">
        <div className="w-[600px] max-w-full mb-6 mt-2 px-4 flex flex-col justify-center items-center">
          <div className="inline-block w-[80px] h-[80px] mb-6 flex justify-center items-center rounded-[50%] bg-badge-secondary-empty">
            <img src={empty_icon} alt="" />
          </div>
          <div className="text-center text-tertiary text-base leading-base">
            {locale?.LOTTERY_PARAMS?.noLotteries}
          </div>
        </div>
      </div>
      <div className="flex justify-center ">
        <Button
          className="text-[14px] font-bold uppercase h-[40px] w-[260px] mb-[10px]"
          isLink
          to={buildRoute(navigationRoutes.LOTTERIES_LIST)}
          title={locale?.BUTTONS_TITLES?.lotteryList}
          type="primary"
        />
      </div>
    </>
  );
};

export default TicketsEmpty;
