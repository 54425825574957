import Icon from '@components/Icon';
import SubMenu from '@components/SubMenu';
import displayForKeys from '@constants/displayForKeys';
import buildRoute from '@navigation/buildRoute';
import CustomNavLink from '@navigation/CustomNavLink';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useHistory} from 'react-router';

const MobileCategorySelector = ({categories, authorized}) => {
  const history = useHistory();
  const activeCategoryData = useMemo(
    () =>
      categories
        ?.filter((category) =>
          history?.location?.pathname?.includes(category?.slug),
        )
        ?.pop() || null,
    [history?.location],
  );

  return (
    <div className="relative w-full flex md:hidden pr-[20px]">
      <SubMenu
        className={classNames(
          {
            'text-default-category-link': !activeCategoryData,
            'text-active-category-link': activeCategoryData,
          },
          'uppercase text-[13px] leading-[18px] font-bold',
        )}
        label={
          activeCategoryData ? activeCategoryData?.name : locale?.gamesCategory
        }
        isCategoryActive={!!activeCategoryData}
        iconComponent={
          <Icon
            icon={activeCategoryData?.slug || 'all-games'}
            size={28}
            className={classNames(
              {
                'text-default-category-link': !activeCategoryData,
                'text-active-category-link': activeCategoryData,
              },
              'mr-[10px]',
            )}
          />
        }
        isItemsAbsolute
        isIconBordered={false}
        isCategorySelector>
        <div className="p-[4px] bg-stratos">
          {categories?.length &&
            categories?.map((category) => {
              if (
                (!authorized &&
                  category?.display_for === displayForKeys.AUTHORIZED) ||
                (authorized &&
                  category?.display_for === displayForKeys.UNAUTHORIZED)
              ) {
                return null;
              }
              return (
                <CustomNavLink
                  key={category?.id}
                  to={buildRoute(navigationRoutes.GAME_CATEGORY, {
                    category: category?.slug,
                  })}
                  exact
                  className="flex flex-row text-default-category-link focus:outline-none hover:text-primary active:text-primary items-center px-[15px] rounded-[4px] py-[15px] text-[13px] leading-[18px] uppercase group">
                  <Icon
                    icon={category?.slug}
                    size={28}
                    className={classNames(
                      'mr-[10px] text-default-category-icon group-hover:text-primary group-active:text-primary',
                    )}
                  />
                  {category?.name}
                </CustomNavLink>
              );
            })}
        </div>
      </SubMenu>
    </div>
  );
};

MobileCategorySelector.propTypes = {
  categories: PropTypes.array,
  authorized: PropTypes.bool,
};

export default MobileCategorySelector;
