import useToggleState from '@hooks/useToggleState';
import LocalizationService from '@services/LocalizationService';
import classNames from 'classnames';
import {useMemo} from 'react';
import en_flag from '@images/icons/en-flag.svg';
import fr_flag from '@images/icons/fr-flag.svg';
import chevron_down from '@images/icons/chevron-down.svg';
import {AnimatePresence, motion} from 'framer-motion';
import {useLocation} from 'react-router';

const icons = {
  en: en_flag,
  fr: fr_flag,
};

const LanguageSwitch = ({className}) => {
  const location = useLocation();
  const [isPickerOpen, togglePicker] = useToggleState();

  const languages = useMemo(
    () =>
      window.GlobalConfig?.availableLanguages?.filter(
        (i) => i.code !== LocalizationService.getLanguage(),
      ),
    [],
  );

  const handleSelect = (code) => {
    LocalizationService.selectLanguage(code);
  };

  if (languages) {
    return (
      <div className={classNames(className, 'relative')}>
        <div
          onClick={togglePicker}
          className={classNames(
            'flex bg-badge-favorite hover:bg-stretching container items-center px-[4px] py-[4px] rounded-t-[5px] cursor-pointer w-[80px] select-none relative z-[11]',
            {
              'rounded-[5px]': !isPickerOpen,
            },
          )}>
          <img
            src={icons?.[LocalizationService.getLanguage()]}
            alt={LocalizationService.getLanguage()}
          />
          <div className={'text-base text-white font-bold uppercase ml-[2px]'}>
            {LocalizationService.getLanguage()}
          </div>

          <img
            src={chevron_down}
            className={classNames('ml-[8px]', {
              'rotate-[180deg]': !isPickerOpen,
            })}
          />
        </div>

        <AnimatePresence>
          {isPickerOpen && (
            <motion.div
              key="language_switcher"
              initial={{y: '-100%', opacity: 0}}
              animate={{
                y: 0,
                opacity: 1,
              }}
              exit={{
                y: '-100%',
                opacity: 0,
              }}
              transition={{type: 'spring', bounce: 0, duration: 0.4}}
              className="absolute z-[10] top-[32px]">
              {languages.map((lang) => (
                <a
                  href={`${location.pathname?.replace((lang.code === 'en' ? '/fr' : '/en'), `/${lang.code}`)}`}
                  onClick={() => handleSelect(lang.code)}
                  className="flex bg-badge-favorite hover:bg-stretching container items-center px-[4px] py-[4px] rounded-b-[5px] cursor-pointer w-[80px] select-none">
                  <img src={icons?.[lang.code]} alt={lang.code} />
                  <div className="text-base text-white font-bold uppercase ml-[2px]">
                    {lang.code}
                  </div>
                </a>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
};

export default LanguageSwitch;
