import React from 'react';

const TournamentRulesSkeleton = () => {
  return (
    <>
      <div className="bg-stratos animate-pulse relative w-auto h-[348px] mt-4 lg:mt-6 rounded-[10px] overflow-hidden md:h-[238px] lg:h-[250px] xl:h-[320px]">
        <div className=" min-h-[440px] md:min-h-[238px] xl:min-h-[282px] lg:min-h-[250px] h-full bg-second-bg-size bg-top md:bg-cover bg-no-repeat md:bg-center bg-stratos animate-pulse" />
      </div>
      <div className="flex flew-row min-h-[64px] overflow-hidden items-center mb-6 rounded-[8px] bg-stratos animate-pulse" />
      <div className="w-full inline-flex flex-wrap items-start mb-10 gap-[16px] lg:gap-[24px] relative overflow-hidden">
        {[...Array(6)]?.map((_, index) => (
          <div
            key={index}
            className="bg-stratos animate-pulse rounded-[8px] relative overflow-hidden w-[calc(50%-8px)] sm:w-[calc(33.333333%-11px)] md:w-[169px] lg:w-[179px] xl:w-[196px] pt-[46px] px-2 text-center items-center h-[112px] md:h-[114px]"
          />
        ))}
      </div>
      <div>
        <div className="w-[200px] min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      </div>
      <div className="w-[80%] min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      <div className="w-full min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      <div className="w-[86] min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      <br />
      <div className="w-[39%] min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      <div className="pl-6">
        <div className="w-[49%] min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      </div>
      <div className="pl-6">
        <div className="w-[90%] min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      </div>
      <div className="pl-6">
        <div className="w-[70%] min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      </div>
      <div className="pl-6">
        <div className="w-[55%] min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      </div>
      <div className="w-[80%] min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      <div className="w-full min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
      <div className="w-[86] min-h-[24px] mb-[18px] bg-stratos animate-pulse" />
    </>
  );
};

export default TournamentRulesSkeleton;
