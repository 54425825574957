import {types} from 'mobx-state-tree';

export const StretchNotificationState = {
  isClosedFirstStretchNotifications: false,
  closedStretchNotifications: [],
  showMoreContent: false,
};

const StretchNotification = types
  .model({
    isClosedFirstStretchNotifications: types.maybeNull(types.frozen()),
    closedStretchNotifications: types.maybeNull(types.frozen()),
    showMoreContent: types.maybeNull(types.frozen()),
  })
  .views((store) => ({
    get availablePopUpNotification() {
      return [];
    },
  }))
  .actions((store) => ({
    setClosedStretchNotifications(type) {
      if (store.closedStretchNotifications.includes(type)) {
        return;
      }
      store.closedStretchNotifications = [
        ...store.closedStretchNotifications,
        ...[type],
      ];
    },
    setClosedFirstStretchNotifications(bool) {
      store.isClosedFirstStretchNotifications = bool;
    },
    setShowMoreContent() {
      store.showMoreContent = true;
    },
    clearClosedStretchNotifications() {
      store.closedStretchNotifications = [];
    },
  }));

export default StretchNotification;
