import offer_check_mark_icon from '@images/icons/offer-check-mark-icon.svg';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const CashBoxOfferItemCheckMark = ({active}) => {
  return (
    <div
      className={classNames(
        'absolute z-20 w-6 h-6 bottom-0 right-0 flex justify-center items-center bg-white rounded-tl-[8px]',
        {hidden: !active},
      )}>
      <div className="pt-[2px] pl-[2px]">
        {/* TODO: add alt */}
        <img src={offer_check_mark_icon} alt="" />
      </div>
    </div>
  );
};

CashBoxOfferItemCheckMark.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default CashBoxOfferItemCheckMark;
