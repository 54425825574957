import {locale} from '@res/strings/locale';
import gift_xl from '@images/inbox-messages/gift-xl.png';
import modalRoutes from '@navigation/modalRoutes';
import Button from '@components/Button';
import useModalControls from '@hooks/useModalControls';
import PropTypes from 'prop-types';

const PopUpNotificationVipOffer = ({data}) => {
  const {openModal} = useModalControls();
  const openMessage = () => {
    openModal(modalRoutes.MESSAGE, {
      uuid: data?.message_uuid,
    });
  };

  return (
    <>
      <img src={gift_xl} alt="" className="w-[144px] md:w-[112px] mb-4" />
      <div className=" mb-2 text-[19px] text-primary text-center leading-[28px] font-bold">
        {locale?.POP_UP_NOTIFICATION?.PERSONAL_GIFT?.title}
      </div>
      <div className=" mb-4 text-primary text-base leading-[24px] text-center">
        {locale?.POP_UP_NOTIFICATION?.PERSONAL_GIFT?.text}
      </div>
      <Button
        type="primary"
        title={locale?.POP_UP_NOTIFICATION?.PERSONAL_GIFT?.actionTitle}
        className="leading-[54px] md:leading-[44px] w-[180px] md:w-[156px] text-[14px] font-bold uppercase"
        onClick={openMessage}
      />
    </>
  );
};

PopUpNotificationVipOffer.propTypes = {
  data: PropTypes.any,
};

export default PopUpNotificationVipOffer;
