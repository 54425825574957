import PropTypes from 'prop-types';

const LandingListContainer = ({children}) => {
  return (
    <div className="flex flex-wrap m-[-12px] md:m-[-16px]">{children}</div>
  );
};

LandingListContainer.propTypes = {
  children: PropTypes.node,
};

export default LandingListContainer;
