import Button from '@components/Button';
import CircularProgressBar from '@components/CircularProgressBar';
import LanguageSwitch from '@components/LanguageSwitch';
import OffersNotification from '@components/Offer/OffersNotification';
import SideMenu from '@components/Header/SideMenu';
import UserBalance from '@components/Header/UserBalance';
import categoryKeys from '@constants/categoryKeys';
import logoConfig from '@constants/logoConfig';
import regionTypes from '@constants/regionTypes';
import testIds from '@constants/testIds';
import useToggleState from '@hooks/useToggleState';
import useWindowSize from '@hooks/useWindowSize';
import close_icon from '@images/icons/close-icon.svg';
import menu_icon from '@images/icons/menu-icon.svg';
import buildRoute from '@navigation/buildRoute';
import CustomLink from '@navigation/CustomLink';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import pushHash from '@utils/pushHash';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef} from 'react';
import {useHistory, useLocation} from 'react-router';
import {useLazyQuery} from '@apollo/client';
import {Endpoints} from '@api';
import {INBOX_MESSAGES} from '@graphql';
import InboxNotification from '@components/Header/InboxNotification';
import useElementOffsetHeight from '@hooks/useElementOffsetHeight';
import StretchNotification from '@components/Notifications/StretchNotification/StretchNotification';
import {Link} from 'react-router-dom';

const Header = ({setHeaderHeight}) => {
  const {user, balance, offers, stretchNotification} = useStore();
  const containerRef = useRef();
  const [isMenuOpen, toggleMenu, closeMenu] = useToggleState();
  const location = useLocation();
  const history = useHistory();
  const size = useWindowSize();

  const [getInboxMessageCount, {data: inboxMessageCount}] = useLazyQuery(
    INBOX_MESSAGES.COUNT,
    {
      context: {
        useAuth: true,
        uri: Endpoints.INBOX_MESSAGES,
      },
    },
  );

  const getNavigationItemName = (name) => {
    return name?.replace('/', '') ?? '';
  };

  useElementOffsetHeight(containerRef, setHeaderHeight);

  useEffect(() => {
    closeMenu();
  }, [location]);

  useEffect(() => {
    if (size.width > 768) {
      closeMenu();
    }
  }, [size]);

  useEffect(() => {
    if (user?.authorized) {
      getInboxMessageCount();
    }
  }, [user?.authorized]);

  return (
    <>
      <nav className="bg-stratos sticky top-0 z-[51]" ref={containerRef}>
        <StretchNotification {...{user, offers, stretchNotification}} />
        <div className="container mx-auto max-w-full xl:max-w-[1296px] pr-[8px] sm:pr-[12px] md:pr-[12px] xl:pr-[0px]">
          <div className="flex items-center justify-between flex-wrap h-[56px]">
            <div className="text-white flex-1 xl:hidden">
              <div className="flex sm:items-center">
                <div
                  className="w-[24px] self-center cursor-pointer click-area relative"
                  onClick={toggleMenu}>
                  {isMenuOpen ? (
                    <img src={close_icon} alt={locale.ALT?.closeMenuIcon} />
                  ) : (
                    <>
                      <img
                        src={menu_icon}
                        alt={locale.ALT?.openMenuIcon}
                        width="24"
                        height="19"
                      />
                      {inboxMessageCount?.count > 0 && (
                        <div className="block sm:hidden absolute top-[-6px] right-[-6px]">
                          <div className="flex relative w-[10px] h-[10px]">
                            <span className="inline-flex h-full w-full bg-notification-trigger rounded-full absolute" />
                            <span className="inline-flex w-[10px] h-[10px] bg-notification-trigger rounded-full relative" />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                {isMenuOpen && <LanguageSwitch className={'ml-[16px]'} />}

                {!isMenuOpen && user?.authorized && (
                  <>
                    <OffersNotification className={'relative ml-[20px]'} />
                    <InboxNotification
                      isAnimated={false}
                      className="relative ml-[20px] hidden sm:block"
                      count={inboxMessageCount?.count}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="max-w-full flex flex-1 justify-between hidden xl:block">
              <CustomLink
                testid={`${testIds.NAVIGATION_ITEMS}-${categoryKeys.ALL_GAMES}`}
                to={buildRoute(navigationRoutes.GAME_CATEGORY, {
                  category: categoryKeys.ALL_GAMES,
                })}
                className="text-white cursor-pointer mr-[40px]">
                {locale?.games}
              </CustomLink>
              <CustomLink
                aria-label="click"
                testid={`${testIds.NAVIGATION_ITEMS}-${getNavigationItemName(
                  locale?.REDIRECT_URLS?.news,
                )}`}
                to={buildRoute(navigationRoutes.PUBLICATIONS_LIST)}
                className="text-white cursor-pointer mr-[40px]">
                {locale?.news}
              </CustomLink>
              {window.GlobalConfig?.region === regionTypes.CIS && (
                <CustomLink
                  aria-label="click"
                  testid={`${testIds.NAVIGATION_ITEMS}-${getNavigationItemName(
                    locale?.REDIRECT_URLS?.tournaments,
                  )}`}
                  to={buildRoute(navigationRoutes?.TOURNAMENTS_LIST)}
                  className="text-white cursor-pointer mr-[40px] relative">
                  {locale?.tournaments}
                </CustomLink>
              )}
              {window.GlobalConfig?.region === regionTypes.CIS && (
                <CustomLink
                  aria-label="click"
                  testid={`${testIds.NAVIGATION_ITEMS}-${getNavigationItemName(
                    locale?.REDIRECT_URLS?.lotteries,
                  )}`}
                  to={buildRoute(navigationRoutes?.LOTTERIES_LIST)}
                  className="text-white cursor-pointer mr-[40px] relative">
                  {locale?.lotteries}
                </CustomLink>
              )}
            </div>
            <div>
              <CustomLink
                aria-label="click"
                to={buildRoute(navigationRoutes.MAIN)}
                className="focus:outline-none">
                <img
                  src={logoConfig.SRC}
                  className="max-w-[70px] sm:max-w-[95px] lg:max-w-[112px]"
                  alt={locale.ALT?.logo}
                />
              </CustomLink>
            </div>
            {user?.authorized ? (
              <div className="flex flex-1 justify-end items-center">
                <LanguageSwitch className={'mr-[28px] hidden xl:flex'} />

                <OffersNotification className="relative mr-[28px] hidden xl:flex" />

                <InboxNotification
                  isAnimated={false}
                  className="relative mr-[28px] hidden xl:flex"
                  count={inboxMessageCount?.count}
                />

                <UserBalance
                  balanceTotal={balance?.total}
                  balanceReal={balance?.real}
                  balanceBonus={balance?.bonus}
                  currency={user?.profile?.currency}
                />

                <Link
                  className={'flex items-center'}
                  to={buildRoute(navigationRoutes.PROFILE)}>
                  {user?.profile && (
                    <>
                      <div className="hidden xl:flex">
                        <div className="max-w-[31px] h-[31px] relative">
                          <CircularProgressBar percents={100} isAnimated={true}>
                            <img
                              src={`/images/user-status/${window.GlobalConfig?.brand}/${user?.profile?.status}.png`}
                              className="w-[21px] h-[21px] absolute top-[5px] left-[5px]"
                              alt={locale.ALT?.userStatusIcon}
                            />
                          </CircularProgressBar>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="pl-[23px] lg:pl-[11px] hidden xl:flex">
                    <div className="text-malibu text-[14px] leading-[19px] relative cursor-pointer">
                      {locale.profile}
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="flex flex-1 justify-end items-center">
                <span
                  testid={`${testIds.MODAL_TRIGGER}-${modalRoutes.AUTH.replace(
                    '#',
                    '',
                  )}`}
                  aria-label="click"
                  className="text-white hidden md:flex cursor-pointer"
                  onClick={() => pushHash(history, modalRoutes.AUTH)}>
                  {locale?.login}
                </span>
                <Button
                  testid={`${
                    testIds.MODAL_TRIGGER
                  }-${modalRoutes.REGISTER.replace('#', '')}`}
                  type={'primary'}
                  title={locale?.register}
                  className={classNames(
                    {hidden: user?.authorized, flex: !user?.authorized},
                    'sm:ml-[12px] mr-[10px] sm:mr-0 px-[12px] py-[6px]',
                  )}
                  onClick={() => pushHash(history, modalRoutes.REGISTER)}
                />

                <div className={'hidden xl:block'}>
                  <LanguageSwitch className={'ml-[8px]'} />
                </div>

                {/*<div className="flex items-center ml-[25px] border-[1px] border-deepSapphire rounded-[2px] relative pr-[10px]">*/}
                {/*  <SubMenu*/}
                {/*    label={locale.LANGUAGES?.isoRu}*/}
                {/*    isIconBordered={false}*/}
                {/*    isItemsAbsolute*/}
                {/*    isHeaderLangSwitcher*/}
                {/*    isCaretVisible={false}*/}
                {/*    icon="/images/icons/ru-lang-icon.svg"*/}
                {/*    iconAlt={locale.ALT?.ruLangIcon}*/}
                {/*    className="uppercase font-bold">*/}
                {/*    <a*/}
                {/*      aria-label="click"*/}
                {/*      href="#"*/}
                {/*      className="block px-4 py-2 text-[14px] leading-[19px]">*/}
                {/*      Link*/}
                {/*    </a>*/}
                {/*  </SubMenu>*/}
                {/*</div>*/}
              </div>
            )}
          </div>
        </div>
      </nav>
      <SideMenu
        authorized={user?.authorized}
        inboxMessageCount={inboxMessageCount?.count}
        {...{toggleMenu, isMenuOpen, closeMenu}}
      />
    </>
  );
};

export default observer(Header);
