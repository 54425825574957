const CaptchaService = {
  execute: async function () {
    return await new Promise((res, rej) => {
      let tries = 0;

      const getCaptcha = () => {
        window.grecaptcha.ready(function () {
          window.grecaptcha
            .execute('6LfcX_kcAAAAACaXFOfGA2U--dXQGMXpqSw-peEp', {
              action: 'form',
            })
            .then(function (token) {
              return res(token);
            })
            .catch((e) => {
              console.warn('ERROR CAPTCHA:', e);
              if (tries === 5) {
                console.error('CAPTCHA_ERROR: ', e);

                rej();
              } else {
                tries += 1;
                setTimeout(getCaptcha, 500);
              }
            });
        });
      };

      getCaptcha();
    });
  },
};

export default CaptchaService;
