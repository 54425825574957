import usePageOffset from '@hooks/usePageOffset';
import useScrollTop from '@hooks/useScrollTop';
import useToggleState from '@hooks/useToggleState';
import arrow_up_icon from '@images/icons/arrow-up-icon.svg';
import {locale} from '@res/strings/locale';
import {AnimatePresence, motion} from 'framer-motion';
import React, {useEffect} from 'react';

const ScrollTopButton = () => {
  const {offsetY} = usePageOffset();
  const [isButtonVisible, toggleButton, hide] = useToggleState();
  const scrollToTop = useScrollTop();

  useEffect(() => {
    if (!isButtonVisible) {
      offsetY > 400 && toggleButton();
    } else {
      offsetY < 400 && hide();
    }
  }, [offsetY]);

  return (
    <AnimatePresence>
      {isButtonVisible && (
        <motion.div
          className="fixed cursor-pointer flex justify-center items-center right-[16px] sm:right-[20px] xl:right-[50px] bottom-[110px] rounded-full z-50 w-[48px] h-[48px] bg-funBlue70"
          onClick={() => scrollToTop()}
          initial={{display: 'none', scale: 0}}
          animate={{
            display: 'flex',
            scale: [0, 1, 1.3, 1],
          }}
          exit={{
            scale: [1, 1.3, 1, 0],
            transitionEnd: {display: 'none'},
          }}
          transition={{
            type: 'spring',
            bounce: 1,
            duration: 0.3,
          }}>
          <img src={arrow_up_icon} alt={locale?.arrowUp} className="h-[19px]" />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScrollTopButton;
