import {types} from 'mobx-state-tree';

export const ModalState = {
  params: {},
};

const Modal = types
  .model({
    params: types.maybeNull(types.frozen()),
  })
  .actions((store) => ({
    setParams(modalName, params) {
      if (params) {
        const newParams = {...store.params};
        newParams[modalName] = params;

        store.params = newParams;
      }
    },
    clearParams(modalName) {
      const newParams = {...store.params};
      delete newParams?.[modalName];

      store.params = newParams;
    },
  }));

export default Modal;
