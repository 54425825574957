import {apiCall, Endpoints} from '@api';
import Button from '@components/Button';
import Input from '@components/FormElements/Input';
import InputError from '@components/FormElements/InputError';
import ReactAsyncSelect from '@components/FormElements/ReactAsyncSelect';
import ToggleSwitchCheckbox from '@components/FormElements/ToggleSwitchCheckbox';
import ModalWrapper from '@components/Modals/ModalWrapper';
import RegistrationStepper from '@components/Register/RegistrationStepper';
import RegistrationFormBonus from '@components/RegistrationFormBonus';
import autocompleteConfig from '@constants/autocompleteConfig';
import logoConfig from '@constants/logoConfig';
import {yupResolver} from '@hookform/resolvers/yup';
import useModalRegister from '@hooks/useModalRegister';
import promocode_icon from '@images/icons/promocode-icon.svg';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import PromoCodeService from '@services/PromoCodeService';
import {useStore} from '@store/configureStore';
import errorMsgHandler from '@utils/errorMsgHandler';
import _debounce from 'lodash.debounce';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import classNames from 'classnames';
import RegistrationWelcomeBonus from '@components/Register/RegistrationWelcomeBonus';

const ThirdStepRegistration = ({handleCloseModal}) => {
  const {user} = useStore();
  const {params} = useModalRegister(modalRoutes.REGISTER_THIRD_STEP, {
    requiredParams: ['fromHandler'],
    handleCloseModal,
  });

  const registrationFormSchema = useMemo(
    () =>
      yup.object().shape({
        city: yup.string().required(locale?.ERROR_MESSAGES?.required),
        address: yup.string().required(locale?.ERROR_MESSAGES?.required),
        postal_code: yup.string().required(locale?.ERROR_MESSAGES?.required),
        promo_code: yup.string(),
        receive_email: yup.bool().notRequired(),
        receive_sms: yup.bool().notRequired(),
      }),
    [],
  );

  const {
    formState: {errors},
    handleSubmit,
    setError,
    getValues,
    control,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(registrationFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      city: params?.body?.city ?? '',
      address: params?.body?.address ?? '',
      postal_code: params?.body?.postal_code ?? '',
      promo_code:
        params?.body?.promo_code || PromoCodeService.getPromoCode() || '',
      receive_email: params?.body?.receive_email ?? false,
      receive_sms: params?.body?.receive_sms ?? false,
    },
  });

  const watchFields = watch();

  const onSubmit = async (data) => {
    try {
      await user.signUp.run({...params?.body, ...data});
    } catch (errors) {
      errorMsgHandler(errors, {
        setError,
        fields: ['city', 'address', 'postal_code', 'promo_code', 'system'],
      });
    }
  };

  const getAutocompleteOptions = async (value, type, callback) => {
    const watchCity = watch('city');

    if (type === autocompleteConfig.TYPES.CITIES) {
      value = `${autocompleteConfig.BASE_COUNTRY} ${value}`;
    } else {
      value = `${value} ${watchCity}`;
    }
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.AUTOCOMPLETE,
        params: {
          input: value,
          type:
            type === autocompleteConfig.TYPES.CITIES
              ? autocompleteConfig.TYPES.CITIES
              : autocompleteConfig.TYPES.ADDRESS,
        },
        useAuth: false,
        useLocale: false,
      });
      if (data) {
        callback(
          data?.map((item) => ({
            value: item,
            label: item,
          })),
        );
      }
    } catch (e) {
      console.error('GET_AUTOCOMPLETE_OPTIONS_ERROR', e);
    }
  };

  const loadOptionsCity = useCallback(
    _debounce((value, callback) => {
      getAutocompleteOptions(value, autocompleteConfig.TYPES.CITIES, callback);
    }, 500),
    [],
  );

  const loadOptionsAddress = useCallback(
    _debounce((value, callback) => {
      getAutocompleteOptions(value, autocompleteConfig.TYPES.ADDRESS, callback);
    }, 500),
    [],
  );

  return (
    <ModalWrapper
      desktopStyle={
        'max-w-[720px] w-full sm:h-[600px] sm:rounded-[8px] bg-[#300e4f]'
      }
      {...{handleCloseModal}}>
      <div className="flex flex-col sm:flex-row w-full h-full">
        <RegistrationFormBonus />
        <div className="sm:w-1/2 h-[100%] flex flex-col overflow-scroll no-scrollbar bg-white pb-[80px] sm:pb-[0]">
          <img
            src={logoConfig.INVERSE_SRC}
            className="w-full max-w-[95px] mt-[20px] mb-[10px] mx-auto"
            alt={locale.ALT?.logo}
          />

          <div className="flex flex-col px-[32px] pb-[50px] h-[100%]">
            <RegistrationWelcomeBonus />

            <RegistrationStepper
              activeStep={modalRoutes.REGISTER_THIRD_STEP}
              fields={{...params?.body, ...watchFields}}
            />

            <div className="flex flex-col justify-between w-full mt-[20px] h-[100%]">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-[10px]">
                  <ReactAsyncSelect
                    placeholder={locale.city}
                    defaultInputValue={getValues('city')}
                    defaultOptions={false}
                    loadOptions={loadOptionsCity}
                    onChange={(option) =>
                      setValue('city', option.value, {shouldValidate: false})
                    }
                  />
                </div>
                <div className="mb-[10px]">
                  <ReactAsyncSelect
                    placeholder={locale.address}
                    defaultInputValue={getValues('address')}
                    defaultOptions={false}
                    loadOptions={loadOptionsAddress}
                    onChange={(option) =>
                      setValue('address', option.value, {shouldValidate: false})
                    }
                  />
                </div>
                <Controller
                  control={control}
                  name="postal_code"
                  render={({field: {onChange, value}}) => (
                    <Input
                      placeholder={locale.postalCode}
                      type="text"
                      {...{onChange, value}}
                      isError={errors.postal_code}
                      errorMessage={errors.postal_code?.message}
                      className="text-[14px]"
                    />
                  )}
                />
                <div className="relative">
                  <Controller
                    control={control}
                    name="promo_code"
                    render={({field: {onChange, value}}) => (
                      <Input
                        placeholder={locale?.PLACEHOLDER?.enterPromoCode}
                        type="text"
                        {...{onChange, value}}
                        isError={errors.promo_code}
                        errorMessage={errors.promo_code?.message}
                        className={classNames(
                          {uppercase: value},
                          'text-[14px] pl-[45px]',
                        )}
                      />
                    )}
                  />
                  <img
                    className="absolute top-[11px] left-[15px]"
                    src={promocode_icon}
                    alt="promocode"
                  />
                </div>
                <div className="relative">
                  <div className="mt-[20px]">
                    <Controller
                      control={control}
                      name="receive_sms"
                      render={({field: {onChange, value, ref}}) => (
                        <ToggleSwitchCheckbox
                          inputRef={ref}
                          labelText={locale?.reciveNewsViaEmail}
                          labelClassName="text-resolutionBlue text-[11px] ml-[5px]"
                          id="receive_sms"
                          {...{value, onChange}}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-[13px]">
                    <Controller
                      control={control}
                      name="receive_email"
                      render={({field: {onChange, value, ref}}) => (
                        <ToggleSwitchCheckbox
                          inputRef={ref}
                          labelText={locale?.reciveNewsViaSms}
                          labelClassName="text-resolutionBlue text-[11px] ml-[5px]"
                          id="receive_email"
                          {...{value, onChange}}
                        />
                      )}
                    />
                  </div>
                </div>

                {!!errors.system && (
                  <InputError message={errors.system?.message} />
                )}

                <Button
                  disabled={user.signUp?.inProgress}
                  htmlType="submit"
                  title={
                    user.signUp?.inProgress
                      ? locale?.processing
                      : locale?.doRegister
                  }
                  className="w-full h-[40px] text-[14px] font-bold leading-5 py-[10px] rounded-[5px] mt-[33px] uppercase"
                  type="primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default observer(ThirdStepRegistration);
