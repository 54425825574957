import {useStore} from '@store/configureStore';

import React from 'react';
import PropTypes from 'prop-types';
import queryKeys from '@constants/queryKeys';
import lotteryConfig from '@constants/lotteryConfig';
import queryClient from '@components/ReactQueryProvider';
import classNames from 'classnames';
import useLotteryAction from '@hooks/useLotteryAction';
import Banner from '@components/Banner/Banner';
import BannerStatus from '@components/Banner/BannerStatus';
import BannerDate from '@components/Banner/BannerDate';
import BannerAmount from '@components/Banner/BannerAmount';
import BannerAction from '@components/Banner/BannerAction';
import BannerPromoTitle from '@components/Banner/BannerPromoTitle';
import BannerPromoDescription from '@components/Banner/BannerPromoDescription';

const LotteryBanner = ({data, slug, rounded, hasBlackout = false, isShort}) => {
  const {user} = useStore();
  const {to, type, title} = useLotteryAction(data?.status, slug, user);
  const onComplete = () => {
    queryClient.invalidateQueries(queryKeys.LOTTERIES_LIST);
    queryClient.invalidateQueries(queryKeys.LOTTERY);
  };

  return (
    <Banner
      {...{
        image: data?.banner?.url,
        hasBlackout,
        isShort,
        rounded,
        to,
        height: classNames({
          'h-[418px] md:h-[320px]': !isShort,
          'h-[418px]': isShort,
        }),
      }}>
      <BannerStatus {...lotteryConfig?.STATUS_BADGE(data?.status)} />
      {isShort && (
        <BannerDate
          {...{
            data,
            slug,
            onComplete,
            constants: lotteryConfig,
            className: 'position-inherit top-4 right-4 xl:top-6 xl:right-6',
          }}
        />
      )}
      <BannerPromoDescription
        {...{
          wrapperClassName: classNames('justify-center', {
            'md:justify-end': !isShort,
          }),
          className: classNames({
            'flex flex-col items-center justify-end md:justify-center pb-[32px] md:pb-0':
              !isShort,
            'flex flex-col items-center justify-end pb-[32px] md:pb-[32px]':
              isShort,
          }),
        }}>
        {!isShort && (
          <BannerDate
            {...{
              data,
              slug,
              onComplete,
              constants: lotteryConfig,
              className: 'mb-[16px]',
            }}
          />
        )}
        <BannerPromoTitle {...{title: data?.title}} />
        <BannerAmount {...{amount: data?.prize_fund ?? 0}} />
        <BannerAction {...{to, type, title}} />
      </BannerPromoDescription>
    </Banner>
  );
};

LotteryBanner.propTypes = {
  data: PropTypes.any.isRequired,
  slug: PropTypes.string,
  rounded: PropTypes.string,
  hasBlackout: PropTypes.bool,
  isShort: PropTypes.bool,
};

export default LotteryBanner;
