import {locale} from '@res/strings/locale';
import pushHash from '@utils/pushHash';
import {useStore} from '@store/configureStore';
import {useHistory} from 'react-router';
import {observer} from 'mobx-react-lite';

import React from 'react';
import modalRoutes from '@navigation/modalRoutes';
import Button from '@components/Button';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const LotteryGetTicket = ({className}) => {
  const {user} = useStore();
  const history = useHistory();
  const clickHandler = () =>
    pushHash(
      history,
      user?.authorized ? modalRoutes.CASH_BOX : modalRoutes.REGISTER,
    );

  return (
    <div className={classNames('flex justify-center', className)}>
      <Button
        title={locale?.BUTTONS_TITLES?.getTicket}
        type="warning"
        className="min-w-[222px] px-2 uppercase text-[14px] font-bold leading-[40px]"
        iconAlt={locale.ALT?.cahsbox}
        onClick={clickHandler}
      />
    </div>
  );
};

LotteryGetTicket.propTypes = {
  className: PropTypes.string,
};

export default observer(LotteryGetTicket);
