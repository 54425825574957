import LocalizationService from '@services/LocalizationService';

const buildRoute = (route, params = {}, ignoreLang) => {
  let changed = route;

  if (!ignoreLang) {
    if (route && route?.indexOf && route?.indexOf('/') !== 0) {
      route = `/${route}`;
    }

    let selectedLanguage = '';
    if (LocalizationService.isInternalMultiLang) {
      let pathLang = window.location.pathname?.split('/')?.[1];
      if (LocalizationService.availableLangCodes.includes(pathLang)) {
        selectedLanguage = pathLang;
      }
    }

    changed = `${selectedLanguage ? `/${selectedLanguage}` : ''}${
      route === '/' ? '' : route
    }`;
  }

  Object.keys(params)?.map((key) => {
    changed = changed.replace(`:${key}`, params[key]);
  });

  return changed;
};

export default buildRoute;
