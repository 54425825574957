import React from 'react';
import popUpNotificationConfig from '@constants/popUpNotificationConfig';
import PopUpNotificationWelcomeOffer from '@components/Modals/PopUpNotification/PopUpNotificationWelcomeOffer';
import PopUpNotificationVipOffer from '@components/Modals/PopUpNotification/PopUpNotificationVipOffer';
import PropTypes from 'prop-types';

const PopUpNotificationSwitch = ({data, offers, user}) => {
  const SwitchNotification = (props) => {
    switch (props?.data?.type) {
      case popUpNotificationConfig?.TYPE?.PERSONAL_GIFT:
        return <PopUpNotificationVipOffer {...{data: data?.data}} />;
      case popUpNotificationConfig?.TYPE?.WELCOME_BONUS:
        return (
          <PopUpNotificationWelcomeOffer
            {...{
              user,
              offers,
              data: data?.data,
            }}
          />
        );
      default:
        break;
    }
    return <></>;
  };

  return <SwitchNotification {...{data}} />;
};

PopUpNotificationSwitch.propTypes = {
  data: PropTypes.any,
  user: PropTypes.any,
  offers: PropTypes.any,
};

export default PopUpNotificationSwitch;
