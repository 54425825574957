import Endpoints from '@api/endpoints';
import useRequest from '@hooks/useRequest';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import React from 'react';
import {Link} from 'react-router-dom';

const GameProviders = () => {
  const {data, error} = useRequest({
    endpoint: Endpoints.PROVIDERS,
    params: {_sort: 'priority:DESC'},
  });

  return (
    <div className="inline-flex gap-x-[34px] gap-y-[30px] flex-wrap justify-center items-center">
      {data?.length &&
        data?.map((provider) => (
          <Link
            key={provider?.id}
            to={buildRoute(navigationRoutes.GAME_PROVIDER, {
              provider: provider?.slug,
            })}>
            <img src={provider?.large_image?.url} alt={provider?.name} />
          </Link>
        ))}
    </div>
  );
};

export default GameProviders;
