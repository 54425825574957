import {toast} from 'react-toastify';
import PushSubscriptionNotification from '@components/Notifications/PushSubscriptionNotification';
import ServiceNotification from '@components/Notifications/ServiceNotification';
import notificationConfig from '@constants/notificationConfig';
import CashBoxNotification from '@components/Notifications/CashBoxNotification';
import ConfirmationNotification from '@components/Notifications/ConfirmationNotification';
import PromoCodeActivationNotification from '@components/Notifications/PromoCodeActivationNotification';
import InboxMessage from '@components/Notifications/InboxMessage';

const getConfig = ({
  className,
  autoClose = 5000,
  isCloseButtonVisible = false,
}) => {
  return {
    className: `${className} w-full sm:w-[320px] !rounded-[5px] flex overflow-hidden cursor-pointer pl-[16px] pr-[20px] py-[10px] mt-[8px] pointer-events-auto items-center`,
    position: 'top-right',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 20,
    progress: undefined,
    closeButton: isCloseButtonVisible,
    autoClose,
  };
};

const getTemplate = ({
  template = notificationConfig.TEMPLATES.SERVICE,
  type,
  notification,
  withActionButton,
  action,
  actionData,
  onClick,
  data,
}) => {
  switch (template) {
    case notificationConfig.TEMPLATES.INBOX_MESSAGES:
      return (
        <InboxMessage
          {...{
            notificationData: data,
            notification,
            onClick,
            type,
            withActionButton,
            action,
            actionData,
          }}
        />
      );
    case notificationConfig.TEMPLATES.SERVICE:
      return (
        <ServiceNotification
          {...{
            notificationData: data,
            notification,
            onClick,
            type,
            withActionButton,
            action,
            actionData,
          }}
        />
      );
    case notificationConfig.TEMPLATES.PUSH_SUBSCRIPTION:
      return (
        <PushSubscriptionNotification
          {...{
            notificationData: data,
            notification,
            onClick,
            type,
            withActionButton,
            action,
            actionData,
          }}
        />
      );
    case notificationConfig.TEMPLATES.CASH_BOX:
      return (
        <CashBoxNotification
          {...{
            notificationData: data,
            notification,
            onClick,
            type,
            withActionButton,
            action,
            actionData,
          }}
        />
      );
    case notificationConfig.TEMPLATES.CONFIRMATION:
      return (
        <ConfirmationNotification
          {...{
            notificationData: data,
            notification,
            onClick,
            type,
            withActionButton,
            action,
            actionData,
          }}
        />
      );
    case notificationConfig.TEMPLATES.PROMO_CODE_ACTIVATION:
      return (
        <PromoCodeActivationNotification
          {...{
            notificationData: data,
            notification,
            onClick,
            type,
            withActionButton,
            action,
            actionData,
          }}
        />
      );
    default:
      return (
        <ServiceNotification
          {...{
            notificationData: data,
            notification,
            onClick,
            type,
            withActionButton,
            action,
            actionData,
          }}
        />
      );
  }
};

export default {
  default: ({
    notification,
    isCloseButtonVisible,
    onClick,
    customConfig,
    withActionButton,
    action,
    actionData,
    template,
    autoClose,
    classNames,
    data,
  }) => {
    toast(
      getTemplate({
        type: notificationConfig.TYPES.DEFAULT,
        template,
        notification,
        onClick,
        withActionButton,
        action,
        actionData,
        data,
      }),
      {
        ...getConfig({
          className: classNames ?? '!bg-white',
          ...{isCloseButtonVisible, autoClose},
        }),
        ...customConfig,
      },
    );
  },
  info: ({
    notification,
    isCloseButtonVisible,
    onClick,
    customConfig,
    withActionButton,
    action,
    actionData,
    template,
    autoClose,
    classNames,
    data,
  }) => {
    toast.info(
      getTemplate({
        type: notificationConfig.TYPES.INFO,
        template,
        notification,
        onClick,
        withActionButton,
        action,
        actionData,
        data,
      }),
      {
        ...getConfig({
          className: classNames ?? '!bg-prussianBlue',
          ...{isCloseButtonVisible, autoClose},
        }),
        ...customConfig,
      },
    );
  },
  error: ({
    notification,
    isCloseButtonVisible,
    onClick,
    customConfig,
    withActionButton,
    action,
    actionData,
    template,
    autoClose,
    classNames,
    data,
  }) => {
    toast.error(
      getTemplate({
        type: notificationConfig.TYPES.ERROR,
        template,
        notification,
        onClick,
        withActionButton,
        action,
        actionData,
        data,
      }),
      {
        ...getConfig({
          className: classNames ?? '!bg-redRibbon',
          ...{isCloseButtonVisible, autoClose},
        }),
        ...customConfig,
      },
    );
  },
  success: ({
    notification,
    isCloseButtonVisible,
    onClick,
    customConfig,
    withActionButton,
    action,
    actionData,
    template,
    autoClose,
    classNames,
    data,
  }) => {
    toast.success(
      getTemplate({
        type: notificationConfig.TYPES.SUCCESS,
        template,
        notification,
        onClick,
        withActionButton,
        action,
        actionData,
        data,
      }),
      {
        ...getConfig({
          className: classNames ?? '!bg-white',
          ...{isCloseButtonVisible, autoClose},
        }),
        ...customConfig,
      },
    );
  },
  warning: ({
    notification,
    isCloseButtonVisible,
    onClick,
    customConfig,
    withActionButton,
    action,
    actionData,
    template,
    autoClose,
    classNames,
    data,
  }) => {
    toast.warning(
      getTemplate({
        type: notificationConfig.TYPES.WARNING,
        template,
        notification,
        onClick,
        withActionButton,
        action,
        actionData,
        data,
      }),
      {
        ...getConfig({
          className: classNames ?? '!bg-white',
          ...{isCloseButtonVisible, autoClose},
        }),
        ...customConfig,
      },
    );
  },
};
