import info from '@images/inbox-messages/info.png';
import gift from '@images/inbox-messages/gift.png';
import confirm from '@images/inbox-messages/confirm.png';
import welcome from '@images/inbox-messages/welcome.png';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {locale} from '@res/strings/locale';
import modalRoutes from '@navigation/modalRoutes';
import useModalControls from '@hooks/useModalControls';
import notificationConfig from '@constants/notificationConfig';

const InboxMessage = ({notificationData, notification}) => {
  const {openModal} = useModalControls();

  const openMessage = (uuid) => {
    openModal(modalRoutes.MESSAGES, {
      uuid,
    });
  };

  const getNotificationIcon = useMemo(() => {
    switch (notificationData?.action_image) {
      case notificationConfig.INBOX_MESSAGE_ACTION_IMAGES.WELCOME:
        return welcome;
      case notificationConfig.INBOX_MESSAGE_ACTION_IMAGES.GIFT:
        return gift;
      case notificationConfig.INBOX_MESSAGE_ACTION_IMAGES.CONFIRM:
        return confirm;
      default:
        return info;
    }
  }, [notificationData?.action_image]);

  return (
    <>
      {notification && (
        <div className="flex flex-row justify-start h-full items-center">
          <img className="self-center" src={getNotificationIcon} />
          <div>
            <p
              className="text-primary text-notification-message leading-notification-message ml-[12px]"
              dangerouslySetInnerHTML={{__html: notification?.title}}
            />
            <p
              onClick={() => openMessage(notificationData?.message_uuid)}
              className="font-bold text-primary text-notification-message leading-notification-message ml-[12px] underline">
              {locale?.more}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

InboxMessage.propTypes = {
  notificationData: PropTypes.object,
  notification: PropTypes.object.isRequired,
};

export default InboxMessage;
