import EmptySearchResult from '@components/GamesGrid/EmptySearchResult';
import GameGridBannerWrapper from '@components/GamesGrid/GameGridBannerWrapper';
import GamesList from '@components/GamesGrid/GamesList';
import MetaInfo from '@components/MetaInfo';
import categoryKeys from '@constants/categoryKeys';
import testIds from '@constants/testIds';
import useGridGames from '@hooks/useGridGames';
import useWindowSize from '@hooks/useWindowSize';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import RestrictionContentBlock from '@components/Restrictions/RestrictionContentBlock';
import ContentBlock from '../PageContent/ContentBlock';

const GamesGrid = ({
  title,
  className,
  provider,
  categoryKey,
  unlimit = false,
  renderMeta = false,
  renderBanner = true,
  renderEmpty = false,
  renderWhileLoading = true,
  queryKey,
  showRestrictionBlock = false,
}) => {
  const size = useWindowSize();
  const {restriction} = useStore();

  const {
    games,
    fetchNextPage,
    meta,
    queryString,
    isEmpty,
    isLoading,
    isError,
    banner,
    itemsCount,
    containsRestricted,
    content,
    showMoreContent,
  } = useGridGames({
    categoryKey,
    provider,
    unlimit,
    renderBanner,
    queryKey,
  });

  if (
    (!isLoading && isEmpty && !renderEmpty) ||
    (!renderWhileLoading && isLoading) ||
    isError
  ) {
    return null;
  }

  const handleRestrictionBlockClose = () => {
    restriction?.setRestrictionProviderCategory();
  };

  return (
    <>
      {restriction?.providersCategory &&
        showRestrictionBlock &&
        containsRestricted && (
          <div className="container md:p-0 mx-auto max-w-full sm:max-w-[724px] lg:max-w-[959px]  xl:max-w-[1296px]">
            <RestrictionContentBlock
              {...{
                handleClose: handleRestrictionBlockClose,
                content: locale?.RESTRICTIONS?.providers,
              }}
            />
          </div>
        )}
      {renderMeta && <MetaInfo {...meta} />}
      <div
        className={classNames(
          className,
          'container md:p-0 mx-auto max-w-full sm:max-w-[724px] lg:max-w-[959px]  xl:max-w-[1296px]  mt-[29px] sm:mt-[30px] md:mt-[29px] xl:mt-[35px]',
        )}>
        {title && (
          <h2
            testid={`${testIds.CATEGORY_TITLE}-${categoryKey}`}
            className="text-white uppercase font-bold text-[14px] leading-[19px] mb-[16px] sm:mb-[15px] md:mb-[16px] xl:mb-[20px]">
            {title}
          </h2>
        )}

        {!!queryString &&
          !!games?.length &&
          categoryKey !== categoryKeys.FAVORITE_GAMES && (
            <>
              <h2 className="text-white uppercase font-bold text-[14px] leading-[19px] mb-[16px] sm:mb-[15px] md:mb-[16px] xl:mb-[20px]">
                {locale?.searchResultsTitle}
              </h2>
            </>
          )}

        {isEmpty && !isLoading && <EmptySearchResult {...{categoryKey}} />}

        <div
          className={classNames(
            {
              'md:flex-row': banner?.position === 'right',
              'md:flex-row-reverse': banner?.position === 'left',
            },
            'flex flex-col md:flex-row overflow-hidden',
          )}>
          {unlimit ? (
            <InfiniteScroll
              loader={null}
              hasChildren
              scrollThreshold={0.55}
              className="w-full"
              style={{overflow: 'hidden'}}
              dataLength={games?.length || 0}
              next={fetchNextPage}
              hasMore={unlimit}>
              <GamesList {...{games, size, itemsCount, categoryKey}} />
            </InfiniteScroll>
          ) : (
            <GamesList {...{games, size, itemsCount, categoryKey}} />
          )}

          <GameGridBannerWrapper {...{renderBanner, banner, games}} />
        </div>
      </div>
      <ContentBlock
        {...{data: content, title: meta?.h1, showMore: showMoreContent}}
      />
    </>
  );
};

GamesGrid.propTypes = {
  title: PropTypes.string,
  categoryKey: PropTypes.string,
  provider: PropTypes.string,
  className: PropTypes.string,
  unlimit: PropTypes.bool,
  renderMeta: PropTypes.bool,
  renderBanner: PropTypes.bool,
  renderEmpty: PropTypes.bool,
  renderWhileLoading: PropTypes.bool,
  showRestrictionBlock: PropTypes.bool,
  queryKey: PropTypes.string,
};

export default observer(GamesGrid);
