import {apiCall} from '@api';
import {useInfiniteQuery} from 'react-query';

const infiniteFetcher = ({request, pageParam = 0}, {limit, offset}) => {
  if (request.params) {
    const currentLimit = limit ? limit : request.params._limit;

    request.params._start = pageParam * currentLimit + offset;
    request.params._limit = currentLimit;
  }

  return apiCall(request).then(({data}) => {
    data.prev_pageParam = pageParam;

    return data;
  });
};

export default function useRequestInfinite(request, options, extra) {
  // TODO: refactor
  extra = extra || {limit: null, offset: 0};

  return useInfiniteQuery(
    [options?.queryKey, JSON.stringify(request)],
    (props) => infiniteFetcher({request, ...props}, extra),
    {
      getNextPageParam: (lastPage) => lastPage.prev_pageParam + 1,
      ...options,
    },
  );
}
