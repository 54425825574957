import CashBoxOfferItemBonusTitle from '@components/Offer/CashBox/CashBoxOfferItemBonusTitle';
import CashBoxOfferItemCashbackTitle from '@components/Offer/CashBox/CashBoxOfferItemCashbackTitle';
import CashBoxOfferItemGiftSpinTitle from '@components/Offer/CashBox/CashBoxOfferItemGiftSpinTitle';
import CashBoxOfferItemWelcomeTitle from '@components/Offer/CashBox/CashBoxOfferItemWelcomeTitle';
import offerConstants from '@constants/offerConstants';
import PropTypes from 'prop-types';

const CashBoxOfferItemTitle = ({data, user}) => {
  return (
    <div className="mb-1 text-white text-[12px] leading-[16px] font-bold uppercase">
      {data?.type === offerConstants?.TYPE?.DEPOSIT_BONUS && (
        <CashBoxOfferItemBonusTitle {...{data, user}} />
      )}
      {data?.type === offerConstants?.TYPE?.DEPOSIT_GIFT_SPIN && (
        <CashBoxOfferItemGiftSpinTitle {...{data}} />
      )}
      {data?.type === offerConstants?.TYPE?.WELCOME && (
        <CashBoxOfferItemWelcomeTitle {...{user, data}} />
      )}
      {data?.type === offerConstants?.TYPE?.CASHBACK && (
        <CashBoxOfferItemCashbackTitle {...{data, user}} />
      )}
    </div>
  );
};

CashBoxOfferItemTitle.propTypes = {
  data: PropTypes.any.isRequired,
  user: PropTypes.any,
};

export default CashBoxOfferItemTitle;
