import Button from '@components/Button';
import Overlay from '@components/Overlay';
import useBodyOverflow from '@hooks/useBodyOverflow';
import useToggleState from '@hooks/useToggleState';
import zoom_icon from '@images/icons/zoom-icon.svg';
import {locale} from '@res/strings/locale';
import PropTypes from 'prop-types';

const ChapterImagesZoom = ({data, index}) => {
  const imgSize = {width: 1200, height: 750};
  const [isActiveZoom, toggleActiveZoom] = useToggleState();

  useBodyOverflow(isActiveZoom);

  return (
    <>
      {isActiveZoom && (
        <>
          <img
            src={data?.[index]}
            className="w-auto h-auto transition-all duration-300 fixed max-h-[68vh] max-w-[100vw] top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] z-[61]"
            alt={locale?.ALT?.zoom}
            width={imgSize?.width}
            height={imgSize?.height}
          />
          <Overlay
            toggleState={toggleActiveZoom}
            className="top-0 right-0 bottom-0 left-0 z-[60]"
          />
        </>
      )}
      <div className="absolute z-[2] right-2 bottom-2">
        <Button
          type="midnight"
          className="p-0 min-h-[48px] min-w-[48px]"
          icon={zoom_icon}
          iconAlt={locale?.ALT?.zoom}
          onClick={toggleActiveZoom}
        />
      </div>
    </>
  );
};

ChapterImagesZoom.propTypes = {
  data: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};

export default ChapterImagesZoom;
