import Tickets from '@components/Profile/Tickets/Tickets';
import TicketsEmpty from '@components/Profile/Tickets/TicketsEmpty';
import TicketsSkeleton from '@components/Profile/Tickets/TicketsSkeleton';
import PageContainer from '@components/PageContent/PageContainer';
import brandTypes from '@constants/brandTypes';
import useRequest from '@hooks/useRequest';
import Endpoints from '@api/endpoints';
import queryKeys from '@constants/queryKeys';

const TicketsPage = () => {
  const {data, isLoading, error} = useRequest(
    {
      endpoint: Endpoints.LOTTERY_TICKETS,
      useAuth: true,
      useLocale: true,
      params: {
        brand: /*window.GlobalConfig?.brand*/ brandTypes.VOSTOK,
        region: window.GlobalConfig?.region,
      },
    },
    {
      retry: 0,
      queryKey: queryKeys.LOTTERY_TICKETS,
    },
  );

  return (
    <div className="bg-hawkesBlue pt-6 sm:pt-[32px] pb-[35px] sm:pb-[100px]">
      {isLoading ? (
        <PageContainer>
          <TicketsSkeleton />
        </PageContainer>
      ) : (
        <>
          {error ? (
            <PageContainer>
              <TicketsEmpty />
            </PageContainer>
          ) : (
            <>{data && <Tickets {...{data}} />}</>
          )}
        </>
      )}
    </div>
  );
};

export default TicketsPage;
