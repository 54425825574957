import {useStore} from '@store/configureStore';

const useCurrency = () => {
  const {user} = useStore();

  return user?.profile
    ? user?.profile?.currency
    : window?.GlobalConfig?.currencies[0];
};
export default useCurrency;
