import React from 'react';
import PropTypes from 'prop-types';
import LotteryGetTicket from '@components/LotteryPage/LotteryGetTicket';
import LotteryPrizesMoneyList from '@components/LotteryPage/LotteryPrizesMoneyList';
import LotteryTicketInfoList from '@components/LotteryPage/LotteryTicketInfoList';
import LotteryPrizesItemList from '@components/LotteryPage/LotteryPrizesItemList';
import lotteryConfig from '@constants/lotteryConfig';

const LotteryPrizesContent = ({data}) => {
  return (
    <>
      <LotteryPrizesItemList {...{data: data?.item_prizes}} />
      <div className="w-full p-4 sm:p-8 rounded-[10px] bg-secondary">
        <h3 className="mb-4 text-primary text-[24px] leading-[32px] font-semibold sm:font-bold">
          {data?.rules?.prizes?.moneyPrizesTitle}
        </h3>
        <p className="m-0 text-primary text-base leading-base">
          {data?.rules?.prizes?.moneyPrizesText}
        </p>
        <LotteryPrizesMoneyList {...{data}} />
        <LotteryTicketInfoList {...{data: data?.tickets_info}} />
        <p className="m-0 text-primary text-base leading-base">
          {data?.rules?.prizes?.moneyPrizesNote}
        </p>
        {data?.status === lotteryConfig?.STATUS?.IN_PROGRESS && (
          <LotteryGetTicket {...{className: 'mt-6'}} />
        )}
      </div>
    </>
  );
};

LotteryPrizesContent.propTypes = {
  data: PropTypes.any,
};

export default LotteryPrizesContent;
