import LandingListCompleted from '@components/LandingList/LandingListCompleted';
import LandingListContainer from '@components/LandingList/LandingListContainer';
import LandingListCurrent from '@components/LandingList/LandingListCurrent';
import LandingListMore from '@components/LandingList/LandingListMore';
import PageTitle from '@components/PageContent/PageTitle';
import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import ContentBlock from '@components/PageContent/ContentBlock';

const LandingList = ({
  data,
  count,
  fetchNextPage,
  setParams,
  constants,
  title,
  subtitle,
  emptyText,
  LongBanner,
  ShortBanner,
  content,
  showMoreContent,
}) => {
  const currentData = useMemo(
    () => data?.pages?.flat()?.filter((i) => constants?.FILTER?.CURRENT(i)),
    [data],
  );

  const completedData = useMemo(() => {
    return data?.pages
      ?.map((item, index) => {
        const filtered = item?.filter((i) => constants?.FILTER?.COMPLETED(i));

        return index > 0
          ? filtered
          : filtered.slice(0, constants?.PAGINATION_LIMIT);
      })
      ?.flat();
  }, [data]);

  const hasMore = useMemo(
    () => count > completedData?.length + currentData?.length,
    [count, completedData, currentData],
  );

  useEffect(
    () =>
      setParams({
        limit: constants?.PAGINATION_LIMIT,
        offset: currentData?.length,
      }),
    [],
  );

  return (
    <>
      <PageTitle {...{title}} />
      <LandingListContainer>
        <LandingListCurrent
          {...{data: currentData, constants, emptyText, BannerItem: LongBanner}}
        />
        <LandingListCompleted
          {...{
            data: completedData,
            title: subtitle,
            constants,
            BannerItem: ShortBanner,
          }}
        />
      </LandingListContainer>
      <LandingListMore {...{onClick: fetchNextPage, hasMore}} />
      <ContentBlock
        {...{data: content, title: null, showMore: showMoreContent}}
      />
    </>
  );
};

LandingList.propTypes = {
  data: PropTypes.any,
  count: PropTypes.number,
  fetchNextPage: PropTypes.func,
  setParams: PropTypes.func,
  constants: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  emptyText: PropTypes.string,
  LongBanner: PropTypes.any,
  ShortBanner: PropTypes.any,
};

export default LandingList;
