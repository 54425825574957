import {PoolingWorker} from '@src/webWorkers';
import {proxy, wrap} from 'comlink';
import {types} from 'mobx-state-tree';
import Endpoints from '@api/endpoints';
import POP_UP_NOTIFICATION from '@graphql/scheme/popUpNotification.scheme';
import apolloClient from '@graphql/ApolloClient';
import asyncAction from '@store/helpers/asyncAction';

const popupPoolingWorker = wrap(new PoolingWorker());

export const PopUpNotificationState = {
  current: null,
};

function getPopUpNotification() {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.query({
        query: POP_UP_NOTIFICATION.GET,
        context: {
          useAuth: true,
          uri: Endpoints.POP_UP_NOTIFICATION,
        },
      });
      store.setPopUpNotification(data?.get);
    } catch (error) {
      console.error('GET_POP_UP_NOTIFICATION_ERROR', error);
    }
  };
}

function removePopUpNotification(id) {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.mutate({
        mutation: POP_UP_NOTIFICATION.REMOVE,
        variables: {id},
        context: {
          useAuth: true,
          uri: Endpoints.POP_UP_NOTIFICATION,
        },
      });
    } catch (error) {
      console.error('REMOVE_POP_UP_NOTIFICATION_ERROR', error);
    }
  };
}

const PopUpNotifications = types
  .model({
    current: types.maybeNull(types.frozen()),

    getPopUpNotification: asyncAction(getPopUpNotification),
    removePopUpNotification: asyncAction(removePopUpNotification),
  })
  .views((store) => ({
    get availablePopUpNotification() {
      return [];
    },
  }))
  .actions((store) => ({
    initPopUpUpdater() {
      store.getPopUpNotification.run();

      popupPoolingWorker.start(
        proxy(() => {
          if (!store.current) {
            store.getPopUpNotification.run();
          }
        }),
        proxy(store.setPopUpUpdaterCtx),
      );
    },
    setPopUpUpdaterCtx(ctx) {
      store.popUpRunner = ctx;
    },
    destructPopUpUpdater() {
      popupPoolingWorker.finish(
        store.popUpRunner,
        proxy(store.setPopUpUpdaterCtx),
      );
    },

    setPopUpNotification(data) {
      store.current = data;
    },
    clearPopUpNotification() {
      store.current = null;
      store.removePopUpNotification.run();
    },
  }));

export default PopUpNotifications;
