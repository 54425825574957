import Button from '@components/Button';
import ModalWrapper from '@components/Modals/ModalWrapper';
import useModalRegister from '@hooks/useModalRegister';
import buildRoute from '@navigation/buildRoute';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import PropTypes from 'prop-types';
import React from 'react';

/* TODO: remove modal */
const WelcomeBonusModal = ({handleCloseModal}) => {
  useModalRegister(modalRoutes.WELCOME_BONUS, {
    requiredParams: ['fromHandler'],
    handleCloseModal,
  });
  return (
    <ModalWrapper
      {...{handleCloseModal}}
      className="sm:max-w-[360px] w-full sm:max-h-[474px] px-[20px] sm:px-0"
      style={{
        background: 'url(/images/b-modal-bg-x3.png) no-repeat center',
        backgroundSize: 'cover',
      }}>
      <div className="flex justify-center items-end h-full pb-[150px] sm:pb-[50px]">
        <div>
          <p className="text-white text-[18px]  sm:text-[12px] bg-stratos px-[10px] py-[6px] uppercase rounded-[4px] mb-[20px] text-center">
            {locale?.WELCOME_BONUS_MODAL?.thanksMessage}
          </p>
          <p className="text-white text-[28px] sm:text-[22px] text-center">
            {locale?.WELCOME_BONUS_MODAL?.welcomeTo}
          </p>
          <p className="text-white text-[32px] sm:text-[26px] text-center font-bold">
            {locale?.WELCOME_BONUS_MODAL?.vostokCasino}
          </p>
          <p
            className="text-white mt-[15px] text-[21px] sm:text-[15px] text-center"
            dangerouslySetInnerHTML={{
              __html: locale?.WELCOME_BONUS_MODAL?.message,
            }}
          />
          <Button
            aria-label="click"
            isLink
            to={buildRoute(navigationRoutes.OFFERS)}
            title={locale?.getBonus}
            className="uppercase w-full h-[40px] text-[20px] sm:text-[14px] font-bold leading-5 py-[10px] rounded-[5px] mt-[33px]"
            type="primary"
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

WelcomeBonusModal.propTypes = {
  handleCloseModal: PropTypes.func,
};

export default WelcomeBonusModal;
