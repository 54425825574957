import gamesFilterConfig from '@constants/gamesFilterConfig';
import UrlHashParams from '@services/UrlHashParams';
import {values} from 'mobx';
import {types} from 'mobx-state-tree';

export const FiltersState = {
  categories: [],
  providers: [],
  features: [],
  volatility: [],
};

const toggleFilter = (arr, item) => {
  return arr?.length
    ? arr.filter((el) => el.toLowerCase() !== item.toLowerCase())
    : arr;
};

const Filters = types
  .model({
    providers: types.array(types.string),
    features: types.array(types.string),
    volatility: types.array(types.string),
    categories: types.array(types.string),
  })
  .views((self) => ({
    getActiveFilters(type) {
      return values(self[type]);
    },
    getAllActiveFilters() {
      return self;
    },
    isFilterActive(type, filter) {
      return self[type].includes(filter);
    },
  }))
  .actions((store) => ({
    init(history) {
      Object.values(gamesFilterConfig.AVAILABLE_FILTERS).forEach((value) => {
        const filterStoreKey = value?.STORE_KEY;
        const filterUrlParams = UrlHashParams.get(history, value?.SEARCH_KEY);
        if (filterUrlParams) {
          store[filterStoreKey] = filterUrlParams?.split(',');
        }
      });
    },
    setFilter(storeKey, filter) {
      if (store?.[storeKey]?.includes(filter)) {
        store[storeKey] = toggleFilter(store[storeKey], filter);
      } else {
        store?.[storeKey]?.push(filter);
      }
    },
    resetSingleFilter(type) {
      store[type] = [];
    },
    resetAllFilters() {
      store.providers = [];
      store.features = [];
      store.volatility = [];
      store.categories = [];
    },
  }));

export default Filters;
