import Loader from '@components/Loader/Loader';
import AnalyticsService from '@services/AnalyticsService';
import ConfigService from '@services/ConfigService';
import LocalizationService from '@services/LocalizationService';
import PromoCodeService from '@services/PromoCodeService';
import ReferrerService from '@services/ReferrerService';
import StorageService from '@services/StorageService';
import {rootStore, useStore} from '@store/configureStore';
import persist from '@store/helpers/persist';
import {UserState} from '@store/UserStore';
import {AnimatePresence, motion} from 'framer-motion';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';

const LoadingGate = ({children}) => {
  const [rehydrated, setRehydrated] = useState(false);
  const {user} = useStore();

  useEffect(() => {
    const init = async () => {
      try {
        await AnalyticsService.init('vostok');
        await PromoCodeService.init();
        await ConfigService.init();
        await LocalizationService.init();
        await ReferrerService.init();

        await persist({
          jsonify: true,

          whitelist: {
            user: Object.keys(UserState),
            games: ['favoriteGames'],
            stretchNotification: ['closedStretchNotifications'],
            restriction: [
              'providersFilter',
              'providersCategory',
              'providersGamePage',
            ],
          },

          storage: await StorageService.getStorage(),
        });

        if (rootStore?.user?.authorized) {
          rootStore?.user?.getUserProfile?.run();
          rootStore?.balance?.initBalanceUpdater();
          rootStore?.offers?.getOffers?.run();
          rootStore?.offers?.getWelcomeOffers?.run();
          rootStore?.offers?.getCashback?.run();
          rootStore.games?.getFavoriteGames?.run();
          rootStore.popUpNotification?.initPopUpUpdater();
          rootStore.floatingNotification?.connect();
        }
      } catch (e) {
        console.error('ERR_INIT_GLOBAL: ', e);
      } finally {
        setRehydrated(true);
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (!user.authorized) {
      rootStore?.balance?.destructBalanceUpdater();
    }
  }, [user.authorized]);

  return (
    <AnimatePresence exitBeforeEnter>
      {rehydrated ? (
        <motion.div
          key={'content'}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{duration: 0.5}}>
          {children}
        </motion.div>
      ) : (
        <Loader />
      )}
    </AnimatePresence>
  );
};

export default observer(LoadingGate);
