import useModalControls from '@hooks/useModalControls';
import b_gift_icon from '@images/icons/b-gift-icon.png';
import modalRoutes from '@navigation/modalRoutes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {BsCheck} from 'react-icons/bs';

const RegistrationStepper = ({activeStep, fields}) => {
  const steps = [
    modalRoutes.REGISTER,
    modalRoutes.REGISTER_SECOND_STEP,
    modalRoutes.REGISTER_THIRD_STEP,
    modalRoutes.WELCOME_BONUS,
  ];
  const {openModal} = useModalControls();
  const activeStepIndex = steps.indexOf(activeStep);

  const changeStep = (step) => {
    if (steps.indexOf(activeStep) > steps.indexOf(step)) {
      openModal(step, {
        fromHandler: true,
        body: fields,
      });
    }
  };

  return (
    <div
      className={
        'max-w-[296px] w-full mx-auto mt-[20px] flex flex-row items-center justify-between'
      }>
      {steps?.map((step, index) => {
        return (
          <React.Fragment key={index}>
            {step === modalRoutes.WELCOME_BONUS ? (
              <div className="bg-hawkesBlue p-[5px] rounded-full w-[50px] h-[50px] relative">
                <img
                  className="absolute left-[4px] top-[6px] w-[42px] h-[38px]"
                  src={b_gift_icon}
                  alt="welcome bonus"
                />
              </div>
            ) : (
              <div
                className={classNames(
                  'w-[18px] h-[18px] rounded-full border-[2px] border-periwinkleGray cursor-pointer',
                  {
                    'bg-white border-denim': index === activeStepIndex,
                    'bg-denim border-denim': index <= activeStepIndex,
                  },
                )}
                onClick={() => changeStep(step)}>
                {index < activeStepIndex && (
                  <BsCheck
                    size="18"
                    className="ml-[-2px] mt-[-1px] text-white"
                  />
                )}
              </div>
            )}

            {index + 1 < steps?.length && (
              <div
                className={classNames('w-[40px] h-[2px] rounded-full', {
                  'bg-denim': index <= activeStepIndex,
                  'bg-periwinkleGray': index >= activeStepIndex,
                })}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

RegistrationStepper.porpTypes = {
  activeStep: PropTypes.string.isRequired,
  fields: PropTypes.object,
};

export default RegistrationStepper;
