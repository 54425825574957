import socialNetworksConfig from '@constants/socialNetworksConfig';
import React from 'react';

const SocialAuth = ({clickHandler}) => {
  const {socialNetworks} = socialNetworksConfig;
  const handleClick = (name) => {
    clickHandler(name);
  };
  return (
    <>
      {socialNetworks?.length &&
        socialNetworks?.map((item) => (
          <img
            className="cursor-pointer"
            key={item?.name}
            onClick={() => handleClick(item?.name)}
            src={item?.image}
            alt={item?.alt}
          />
        ))}
    </>
  );
};

export default SocialAuth;
