import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@store/configureStore';
import useModalRegister from '@hooks/useModalRegister';
import modalRoutes from '@navigation/modalRoutes';
import PopUpNotificationWrapper from '@components/Modals/PopUpNotification/PopUpNotificationWrapper';
import PopUpNotificationSwitch from '@components/Modals/PopUpNotification/PopUpNotificationSwitch';
import PropTypes from 'prop-types';

const PopUpNotification = ({handleCloseModal}) => {
  const {user, offers} = useStore();
  const {params} = useModalRegister(modalRoutes.POP_UP_NOTIFICATION, {
    requiredParams: ['data'],
    handleCloseModal,
  });

  return (
    <PopUpNotificationWrapper {...{handleCloseModal}}>
      <PopUpNotificationSwitch {...{data: params?.data, offers, user}} />
    </PopUpNotificationWrapper>
  );
};

PopUpNotification.propTypes = {
  handleCloseModal: PropTypes.func,
};

export default observer(PopUpNotification);
