import CustomNavLink from '@navigation/CustomNavLink';
import React, {useMemo} from 'react';
import StickyPageContainer from '@components/StickyPageContainer';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PageTabs = ({
  data,
  rounded = 'rounded-b-[10px]',
  isSticky = true,
  headerHeight = 0,
}) => {
  const Wrapper = (props) => {
    return isSticky ? <StickyPageContainer {...props} /> : <div {...props} />;
  };
  const mapTabs = useMemo(
    () =>
      data?.map((item, key) => (
        <CustomNavLink
          {...{
            className: classNames(
              'flex-1 block min-h-[54px] uppercase text-white text-[13px] sm:text-[14px] leading-[54px] font-bold text-center',
              item?.className,
            ),
            to: item?.to,
            activeClassName: classNames(
              'bg-resolutionBlue',
              item?.activeClassName,
            ),
            exact: true,
            key,
          }}>
          {item?.title}
        </CustomNavLink>
      )),
    [data],
  );

  return (
    <Wrapper {...{headerHeight}}>
      {({isSticky}) => {
        return (
          <div
            className={classNames(
              'flex flew-row overflow-hidden mb-6 bg-stratos',
              {[rounded]: !isSticky, 'rounded-0': isSticky},
            )}>
            {!!data?.length && mapTabs}
          </div>
        );
      }}
    </Wrapper>
  );
};

PageTabs.propTypes = {
  data: PropTypes.any.isRequired,
  rounded: PropTypes.string,
  isSticky: PropTypes.bool,
};

export default PageTabs;
