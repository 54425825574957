import Endpoints from '@api/endpoints';
import Transaction from '@components/CashBox/Histroy/Transaction';
import NoTransactionsMessage from '@components/CashBox/NoTransactionsMessage';
import useDeviceProps from '@hooks/useDeviceProps';
import useRequest from '@hooks/useRequest';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import React from 'react';

const History = () => {
  const {user} = useStore();
  const {isMobile} = useDeviceProps();
  const {data: transactionsList, isLoading} = useRequest(
    {
      endpoint: Endpoints.GET_TRANSACTIONS,
      params: {
        start: 0,
      },
      useAuth: true,
      useLocale: false,
    },
    {refetchInterval: 4000, refetchOnWindowFocus: true},
  );

  return (
    <div
      className={classNames(
        {'h-full': isLoading, 'pb-[120px]': isMobile},
        'px-[16px]',
      )}>
      {transactionsList?.transactions?.length ? (
        <div className="flex flex-col w-full sm:pb-0 sm:h-[470px]">
          {transactionsList?.transactions?.map((transaction) => (
            <Transaction key={transaction?.id} {...{transaction, user}} />
          ))}
        </div>
      ) : isLoading ? (
        <div className="flex justify-center items-center h-[100%]">
          <p className="animate-pulse text-[20px] text-poloBlue">
            {locale?.transactionsLoaderText}
          </p>
        </div>
      ) : (
        <NoTransactionsMessage />
      )}
    </div>
  );
};

export default observer(History);
