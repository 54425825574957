import React from 'react';
import {locale} from '@res/strings/locale';
import {Link} from 'react-router-dom';
import OfferCardIconSwitch from '@components/Offer/OfferCard/OfferCardIconSwitch';
import formatAmount from '@utils/formatAmount';
import Button from '@components/Button';
import buildRoute from '@navigation/buildRoute';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import useModalControls from '@hooks/useModalControls';
import PropTypes from 'prop-types';

const PopUpNotificationWelcomeOffer = ({data, offers, user}) => {
  const {openModal} = useModalControls();
  const openCashBox = () => {
    openModal(modalRoutes.CASH_BOX);
  };

  return (
    <>
      <OfferCardIconSwitch
        {...{offers, data, className: 'w-[176px] md:w-[134px] mb-6'}}
      />
      <div className="mb-2 text-gold text-[19px] leading-[28px] font-bold uppercase">{`${locale?.POP_UP_NOTIFICATION?.WELCOME_BONUS?.title} ${data?.bonus?.deposit_percents}%`}</div>
      <div className="mb-4 text-primary text-base leading-[24px]">
        {locale?.POP_UP_NOTIFICATION?.WELCOME_BONUS?.text.replace(
          '{order}',
          locale?.ORDINAL_NUMBERS?.[data?.order],
        )}
        {formatAmount({
          amount: data?.bonus?.min_deposit / 100,
          currency: user?.profile?.currency,
          minimumFractionDigits: 0,
        })}
      </div>
      <Button
        type="primary"
        title={locale?.POP_UP_NOTIFICATION?.WELCOME_BONUS?.actionTitle}
        className="leading-[54px] md:leading-[44px] w-[180px] md:w-[156px] text-[14px] font-bold uppercase"
        onClick={openCashBox}
      />
      {(user?.profile?.phone_confirmed_by === 'none' ||
        !user?.profile?.is_email_confirmed) && (
        <div className="mt-4 text-primary text-[13px] text-center leading-[20px]">
          {`${locale?.OFFER?.mustBeConfirmed} `}
          <br />
          <Link
            to={buildRoute(navigationRoutes.PROFILE)}
            className="text-primary text-[13px] leading-[20px] underline">
            {locale?.OFFER?.emailAndPhone}
          </Link>
        </div>
      )}
    </>
  );
};

PopUpNotificationWelcomeOffer.propTypes = {
  data: PropTypes.any,
  user: PropTypes.any,
  offers: PropTypes.any,
};

export default PopUpNotificationWelcomeOffer;
