import Button from '@components/Button';
import CircularProgressBar from '@components/CircularProgressBar';
import Overlay from '@components/Overlay';
import SubMenu from '@components/SubMenu';
import analyticsEvents from '@constants/analyticsEvents';
import buttonActions from '@constants/buttonActions';
import categoryKeys from '@constants/categoryKeys';
import regionTypes from '@constants/regionTypes';
import useBodyOverflow from '@hooks/useBodyOverflow';
import cup_icon from '@images/icons/cup-icon.svg';
import buildRoute from '@navigation/buildRoute';
import CustomLink from '@navigation/CustomLink';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import formatAmount from '@utils/formatAmount';
import openSupportChat from '@utils/openSupportChat';
import pushHash from '@utils/pushHash';
import classNames from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import AnalyticsService from '@services/AnalyticsService';
import {format} from 'date-fns';
import inboxMessagesEventTypes from '@constants/inboxMessagesEventTypes';

const SideMenu = ({isMenuOpen, toggleMenu, inboxMessageCount}) => {
  const {user, balance} = useStore();
  const history = useHistory();
  const initialOffset = 56;
  const [offsetTop, setOffsetTop] = useState(null);

  useBodyOverflow(isMenuOpen);

  const showInboxMessages = () => {
    AnalyticsService.logEvent(analyticsEvents.INBOX_MESSAGES_CLICK, {
      user_id: user?.profile?.id ?? '',
      time: format(new Date(), 'dd.MM.yyyy HH:mm'),
      type: inboxMessagesEventTypes.SIDE_MENU,
    });
    pushHash(history, modalRoutes.MESSAGES);
  };

  //TODO: fix offset if menu open and stretching-notification was closed
  useEffect(() => {
    const stretchingNotification = document.getElementById(
      'stretching-notification',
    );
    if (stretchingNotification) {
      setOffsetTop(initialOffset + stretchingNotification.offsetHeight);
    } else {
      setOffsetTop(initialOffset);
    }

    if (!isMenuOpen) {
      setOffsetTop(null);
    }
  }, [user?.authorized, isMenuOpen]);

  return (
    <>
      {isMenuOpen && <Overlay toggleState={toggleMenu} isSideMenu />}
      <AnimatePresence>
        {isMenuOpen && (
          <div className="relative">
            <motion.div
              key="side_menu"
              initial={{x: '-100%'}}
              animate={{
                x: 0,
              }}
              exit={{
                x: '-100%',
              }}
              transition={{type: 'spring', bounce: 0, duration: 0.4}}
              style={{top: `${offsetTop}px`}}
              className={classNames(
                {
                  'z-[50]': isMenuOpen,
                },
                'fixed left-0 w-[306px] h-[100%] bg-midnightBlue',
              )}>
              <div className="px-[24px] pt-[31px] pb-[130px] h-[100%]  outline-none overflow-scroll no-scrollbar">
                {user?.authorized && (
                  <>
                    <div className="flex max-w-full">
                      <div className="flex flex-1 items-center">
                        <div className="mr-[12px]">
                          <img src={cup_icon} alt={locale.ALT?.cupIcon} />
                        </div>
                        <p className="text-white text-[14px] leading-[19px] uppercase font-bold">
                          {locale?.USER_STATUSES?.[user?.profile?.status]}
                        </p>
                      </div>
                      <div className="flex-2">
                        <div className="max-w-[38px] h-[38px] relative">
                          <CircularProgressBar
                            percents={100}
                            size={37}
                            radius={50}>
                            <img
                              src={`/images/user-status/${window.GlobalConfig?.brand}/${user?.profile?.status}.png`}
                              className="w-[31px] h-[31px] absolute top-[3px] left-[3px]"
                              alt={locale.ALT?.userStatusIcon}
                            />
                          </CircularProgressBar>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center mt-[22px] mb-[17px] border-[1px] border-biscay rounded-[4px]">
                      <div className="flex-1 pt-[9px] pl-[16px] pb-[12px] border-r-[0.5px] border-biscay">
                        <p className="text-pigeonPost51 text-[14px] leading-[19px]">
                          {locale.money}
                        </p>
                        <p className="text-white text-[14px] leading-[19px]">
                          {formatAmount({
                            amount: balance?.real,
                            currency: user?.profile?.currency,
                          })}
                        </p>
                      </div>
                      <div className="flex-1 pt-[9px] pl-[16px] pb-[12px] border-l-[0.5px] border-biscay">
                        <p className="text-pigeonPost51 text-[14px] leading-[19px]">
                          {locale.bonuses}
                        </p>
                        <p className="text-white text-[14px] leading-[19px]">
                          {formatAmount({
                            amount: balance?.bonus,
                            currency: user?.profile?.currency,
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 mb-[34px]">
                      <Button
                        className="w-full block uppercase font-bold py-[11px] leading-[10px] text-[14px]"
                        type="primary"
                        title={locale.topUpAccount}
                        onClick={() => pushHash(history, modalRoutes.CASH_BOX)}
                      />
                    </div>

                    <hr className="border-biscay60 mt-[32px] mb-[26px]" />
                  </>
                )}
                <div className="flex flex-col">
                  <div className="flex-1 mb-[22px]">
                    <div className="flex-1 mb-[22px]">
                      <CustomLink
                        aria-label="click"
                        to={buildRoute(navigationRoutes.GAME_CATEGORY, {
                          category: categoryKeys.ALL_GAMES,
                        })}
                        className="text-white focus:outline-none text-[14px] leading-[19px] block">
                        {locale?.games}
                      </CustomLink>
                    </div>
                    {window.GlobalConfig?.region === regionTypes.CIS && (
                      <div className="flex-1 mb-[22px]">
                        <CustomLink
                          aria-label="click"
                          to={buildRoute(navigationRoutes?.TOURNAMENTS_LIST)}
                          className="text-white focus:outline-none text-[14px] leading-[19px] block">
                          {locale?.tournaments}
                        </CustomLink>
                      </div>
                    )}
                    {window.GlobalConfig?.region === regionTypes.CIS && (
                      <div className="flex-1 mb-[22px]">
                        <CustomLink
                          aria-label="click"
                          to={buildRoute(navigationRoutes?.LOTTERIES_LIST)}
                          className="text-white focus:outline-none text-[14px] leading-[19px] block">
                          {locale?.lotteries}
                        </CustomLink>
                      </div>
                    )}

                    {user?.authorized && (
                      <>
                        <div className="flex flex-row flex-1 mb-[22px] relative items-center">
                          <p
                            aria-label="click"
                            onClick={showInboxMessages}
                            className="text-white focus:outline-none text-[14px] leading-[19px] block cursor-pointer">
                            {locale.messages}
                          </p>
                          {inboxMessageCount > 0 && (
                            <div className="flex relative ml-[8px] w-[16px] h-[16px]">
                              <span className="inline-flex h-full w-full bg-notification-trigger rounded-full absolute" />
                              <span className="inline-flex w-[16px] h-[16px] bg-notification-trigger rounded-full relative">
                                {inboxMessageCount < 10 && (
                                  <span className="relative translate-x-[4px] translate-y-[-3px] text-base">
                                    {inboxMessageCount}
                                  </span>
                                )}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="flex-1 mb-[22px]">
                          <CustomLink
                            aria-label="click"
                            to={buildRoute(navigationRoutes.PROFILE)}
                            className="text-white focus:outline-none text-[14px] leading-[19px] block">
                            {locale.profile}
                          </CustomLink>
                        </div>
                      </>
                    )}

                    <div className="flex-1 mb-[22px]">
                      <CustomLink
                        aria-label="click"
                        to={buildRoute(navigationRoutes.PUBLICATIONS_LIST)}
                        className="text-white focus:outline-none text-[14px] leading-[19px] block">
                        {locale?.news}
                      </CustomLink>
                    </div>

                    <SubMenu
                      label={locale.information}
                      contentContainerClassName={'bg-white'}>
                      <CustomLink
                        aria-label="click"
                        to={buildRoute(navigationRoutes.TERMS_AND_CONDITIONS)}
                        className="block px-4 py-2 text-[14px] leading-[19px] text-stratos">
                        {locale?.rules}
                      </CustomLink>
                      <CustomLink
                        aria-label="click"
                        to={buildRoute(navigationRoutes.PAYOUTS)}
                        className="block px-4 py-2 text-[14px] leading-[19px] text-stratos">
                        {locale?.payoutsPage}
                      </CustomLink>

                      {window.GlobalConfig?.region === regionTypes.CIS && (
                        <CustomLink
                          aria-label="click"
                          to={buildRoute(navigationRoutes.PRIVACY_POLICY)}
                          className="block px-4 py-2 text-[14px] leading-[19px] text-stratos">
                          {locale?.privacyPolicy}
                        </CustomLink>
                      )}
                      <CustomLink
                        aria-label="click"
                        to={buildRoute(navigationRoutes.BONUS)}
                        className="block px-4 py-2 text-[14px] leading-[19px] text-stratos">
                        {locale?.bonusPage}
                      </CustomLink>
                      <CustomLink
                        aria-label="click"
                        to={buildRoute(navigationRoutes.WITHDRAWAL)}
                        className="block px-4 py-2 text-[14px] leading-[19px] text-stratos">
                        {locale?.withdrawalPage}
                      </CustomLink>
                      <CustomLink
                        aria-label="click"
                        to={buildRoute(navigationRoutes.RESPONSIBLE_GAMING)}
                        className="block px-4 py-2 text-[14px] leading-[19px] text-stratos">
                        {locale?.responsibleGaming}
                      </CustomLink>
                      <CustomLink
                        aria-label="click"
                        to={buildRoute(navigationRoutes.COOKIES)}
                        className="block px-4 py-2 text-[14px] leading-[19px] text-stratos">
                        {locale?.cookiesPolicy}
                      </CustomLink>
                      {/*<CustomLink
                        aria-label="click"
                        to={doRedirect(
                          locale?.REDIRECT_URLS?.main +
                            locale?.REDIRECT_URLS?.sitemap,
                        )}
                        className="block px-4 py-2 text-[14px] leading-[19px]">
                        {locale?.siteMap}
                      </CustomLink>*/}
                    </SubMenu>
                  </div>
                  <div className="flex-1 mb-[22px] hidden sm:block">
                    <a
                      aria-label="click"
                      href={locale?.REDIRECT_URLS?.partners}
                      target="_blank"
                      className="text-white focus:outline-none text-[14px] leading-[19px] block">
                      {locale?.forPartners}
                    </a>
                  </div>
                  <div className="flex-1 mb-[22px]">
                    <CustomLink
                      aria-label="click"
                      to={buildRoute(navigationRoutes.CONTACTS)}
                      className="text-white focus:outline-none text-[14px] leading-[19px] block">
                      {locale?.contactUs}
                    </CustomLink>
                  </div>

                  {window.GlobalConfig?.region === regionTypes.CIS && (
                    <div className="flex-1">
                      <CustomLink
                        aria-label="click"
                        to={buildRoute(navigationRoutes.FAQ)}
                        className="text-white focus:outline-none text-[14px] leading-[19px] block">
                        {locale?.faq}
                      </CustomLink>
                    </div>
                  )}
                </div>
                {user?.authorized && (
                  <div className="flex-1 mt-[28px]">
                    <Button
                      className="w-full block uppercase font-bold py-[11px] leading-[10px] text-[14px]"
                      type="transparent"
                      title={locale?.activatePromoCode}
                      isLink
                      action={buttonActions.REDIRECT}
                      actionData={buildRoute(navigationRoutes?.PROMO_CODE)}
                    />
                  </div>
                )}
                <div className="flex-1 mt-[16px]">
                  <Button
                    className="w-full block uppercase font-bold py-[11px] leading-[10px] text-[14px]"
                    type="secondary"
                    title={locale?.onlineSupport}
                    onClick={openSupportChat}
                  />
                </div>

                {/*<div className="flex-1">*/}
                {/*  <SubMenu*/}
                {/*    label={locale?.LANGUAGES?.ru}*/}
                {/*    icon="/images/icons/ru-lang-icon.svg"*/}
                {/*    iconAlt={locale.ALT?.ruLangIcon}*/}
                {/*    className="uppercase font-bold">*/}
                {/*    <CustomLink aria-label="click" to="#">*/}
                {/*      Link*/}
                {/*    </CustomLink>*/}
                {/*  </SubMenu>*/}
                {/*</div>*/}
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

SideMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  inboxMessageCount: PropTypes.number,
};

export default observer(SideMenu);
