import analyticsEvents from '@constants/analyticsEvents';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import * as Sentry from '@sentry/browser';
import {UAParser} from 'ua-parser-js';
import ReactGA from 'react-ga4';
import ym from 'react-yandex-metrika';

export const uaParser = new UAParser();

const ConfigService = {
  initialized: false,

  defaultParams: {
    name: undefined,
    brand: window?.GlobalConfig?.brand,
    region: window?.GlobalConfig?.region,
    payload: {},

    platform: navigator.platform,
    operating_system: uaParser.getOS().name,
    os_version: uaParser.getOS().version,
    browser: uaParser.getBrowser().name,
    screen_resolution:
      parseInt(window.screen.width * window.devicePixelRatio, 10) +
      'x' +
      parseInt(window.screen.height * window.devicePixelRatio, 10),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,

    is_https: window.location.protocol === 'https:',
    host: window.location.host.replace('www.', '').toLowerCase(),
    path: window.location.pathname.toLowerCase(),
    query: window.location.search,
    hash: window.location.hash,

    fingerprint: null,
  },

  init: async function (tracker_uid) {
    if (!this.initialized) {
      try {
        if (window?.GlobalConfig?.gaID) {
          ReactGA.initialize(window?.GlobalConfig?.gaID);
        }

        const fingerprintData = await (await FingerprintJS.load())?.get();

        this.defaultParams = {
          ...this.defaultParams,
          ...{
            fingerprint: fingerprintData?.visitorId,
            fingerprint_score: fingerprintData?.confidence?.score,
          },
          tracker_uid,
        };

        this.initialized = true;
      } catch (e) {
        console.error('ERR_INIT_ANALYTICS:', e);
      }
    }
  },

  setPlayerId: function (player_id) {
    this.defaultParams = {...this.defaultParams, player_id};
  },

  config: function (params) {
    this.defaultParams = {...this.defaultParams, ...params};
  },

  sendData: function (data) {
    fetch('https://api.notifyer.live/event', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).catch((err) => {
      Sentry.captureException(new Error('ANALYTICS_SEND_FAILED'), {
        extra: err,
        fingerprint: 'ANALYTICS_SEND_FAILED',
      });
    });
  },

  logEvent: async function (name, payload = {}) {
    if (import.meta.env.MODE !== 'development') {
      if (this.initialized) {
        payload.type = 'event';

        const data = {...this.defaultParams, name, payload};

        if (name === analyticsEvents.PAGE_VIEW) {
          const page = window.location.pathname + window.location.search;
          if (window?.GlobalConfig?.gaID) {
            ReactGA.send({hitType: 'pageview', page});
          }
          if (window?.GlobalConfig?.yaMetrikaID) {
            ym('hit', page);
          }
        }

        await this.sendData(data);
      } else {
        throw 'Service not initialized';
      }
    }
  },

  logException: async function (name, payload = {}) {
    if (import.meta.env.MODE !== 'development') {
      if (this.initialized) {
        payload.name = name;
        payload.type = 'exception';

        const data = {...this.defaultParams, payload};

        await this.sendData(data);
      } else {
        throw 'Service not initialized';
      }
    }
  },
};

export default ConfigService;
