import React, {useEffect, useRef, useState} from 'react';
import {useStore} from '@store/configureStore';
import {locale} from '@res/strings/locale';

const ContentBlockShowMore = ({children, showMore}) => {
  const {stretchNotification} = useStore();
  const [divHeight, setDivHeight] = useState(0);
  const [wrapHeight, setWrapHeight] = useState('auto');
  const ref = useRef(null);
  const onClick = () => {
    setWrapHeight(divHeight);
    stretchNotification?.setShowMoreContent();
  };

  useEffect(() => {
    setDivHeight(ref.current.clientHeight);
    setWrapHeight(
      stretchNotification?.showMoreContent
        ? 'auto'
        : `${showMore?.max_height}px`,
    );
  }, []);

  return (
    <div
      className="relative w-full rounded-[10px] overflow-hidden"
      style={{
        height: wrapHeight,
      }}>
      <div
        ref={ref}
        className="bg-stratos px-[24px] lg:px-[43px] py-[32px] lg:pt-[43px] lg:pb-[48px] rounded-[10px]">
        {children}
      </div>
      {!stretchNotification?.showMoreContent &&
        divHeight > showMore?.max_height && (
          <div className="absolute z-10 bottom-0 left-0 right-0 w-full px-[24px] lg:px-[43px] h-[56px]">
            <div
              className="h-[4px]"
              style={{
                backgroundImage:
                  'linear-gradient(to bottom, rgba(0,27,74,0), rgba(0,27,74,100))',
              }}
            />
            <div className="bg-stratos pt-[6px] h-[52px]">
              <button
                onClick={onClick}
                className="underline text-cerulean text-base font-[400]"
                type="button">
                {locale?.more}
              </button>
            </div>
          </div>
        )}
    </div>
  );
};

export default ContentBlockShowMore;
