import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import Meta from '@components/Meta';
import React, {useMemo, useState} from 'react';

const StaticLayout = ({children}) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const childrenWithProps = useMemo(() =>
    React.Children.map(
      children,
      (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {headerHeight});
        }
        return child;
      },
      [children, headerHeight],
    ),
  );

  return (
    <main className="bg-midnightBlue">
      <Header {...{setHeaderHeight}} />
      <Meta />
      {childrenWithProps}
      <Footer />
    </main>
  );
};

export default StaticLayout;
