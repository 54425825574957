import notificationConfig from '@constants/notificationConfig';
import info_icon from '@images/icons/info-notification-icon.svg';
import success_icon from '@images/icons/confirm-notification-icon.svg';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const ConfirmationNotification = ({type, notification}) => {
  return (
    <>
      {notification && (
        <div className="flex flex-row justify-start h-full items-center">
          {type === notificationConfig.TYPES.INFO ? (
            <img className="self-center" src={info_icon} />
          ) : (
            <img className="self-center" src={success_icon} />
          )}
          <div className="ml-[12px]">
            {notification?.title && (
              <p
                className={classNames(
                  'font-bold text-primary text-notification-message leading-notification-message',
                )}
                dangerouslySetInnerHTML={{__html: notification?.title}}
              />
            )}
            <p
              className={classNames(
                {'font-bold': !notification?.title},
                'text-primary text-notification-message leading-notification-message',
              )}
              dangerouslySetInnerHTML={{__html: notification?.message}}
            />
          </div>
        </div>
      )}
    </>
  );
};

ConfirmationNotification.propTypes = {
  type: PropTypes.string.isRequired,
  notification: PropTypes.any,
};

export default ConfirmationNotification;
