import gift_game_icon from '@images/icons/gift-game-icon.svg';
import {locale} from '@res/strings/locale';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import recommended_game_icon from '@images/icons/recommended-game-icon.svg';

const GameLabel = ({type}) => {
  const getTagContent = () => {
    switch (type) {
      case 'new':
        return (
          <span className="text-white text-center font-bold text-[12px] leading-[16px] uppercase">
            {locale.GAME_TAG?.new}
          </span>
        );
      case 'hot':
        return (
          <span className="text-white text-center font-bold text-[12px] leading-[16px] uppercase">
            {locale.GAME_TAG?.hot}
          </span>
        );
      case 'gift':
        return <img src={gift_game_icon} alt={locale.ALT?.giftGame} />;
      case 'recommended':
        return (
          <img src={recommended_game_icon} alt={locale.ALT?.recommendedGame} />
        );
      default:
        return <></>;
    }
  };
  return (
    <div
      className={classNames(
        {
          'bg-harlequin': type === 'new',
          'bg-electricViolet': type === 'gift',
          'bg-dodgerBlue': type === 'recommended',
          'bg-scarlet': type === 'hot',
        },
        'flex justify-center items-center rounded-full absolute z-10 left-[5px] top-[5px] w-[36px] h-[36px]',
      )}>
      {type && getTagContent()}
    </div>
  );
};

GameLabel.propTypes = {
  type: PropTypes.oneOf(['new', 'gift', 'recommended', 'hot']).isRequired,
};

export default GameLabel;
