import {apiCall, Endpoints} from '@api';
import {locale} from '@res/strings/locale';
import LocalStorageService from '@services/LocalStorageService';
import isBot from '@utils/isBot';

const LocalizationService = {
  isInternalMultiLang: null,
  availableLangCodes: null,

  init: async function () {
    try {
      this.isInternalMultiLang =
        window.GlobalConfig?.availableLanguages.filter(
          (i) => i.type === 'internal',
        ).length > 1;

      this.availableLangCodes = window.GlobalConfig?.availableLanguages?.map(
        (i) => i.code,
      );

      const interfaceLanguage = locale.getInterfaceLanguage()?.split('-')[0];
      const savedLanguage = await LocalStorageService.get('language');
      const folderLanguage = window.location.pathname?.split('/')?.[1];
      const fallbackLanguage = this.availableLangCodes[0];

      let selectedLanguage;

      if (savedLanguage && this.availableLangCodes?.includes(savedLanguage)) {
        selectedLanguage = savedLanguage;
      } else if (
        folderLanguage &&
        this.availableLangCodes?.includes(folderLanguage)
      ) {
        selectedLanguage = folderLanguage;
      } else if (
        interfaceLanguage &&
        this.availableLangCodes?.includes(interfaceLanguage)
      ) {
        selectedLanguage = interfaceLanguage;
      } else {
        selectedLanguage = fallbackLanguage;
      }

      await this.fetchTranslation(selectedLanguage);
      locale.setLanguage(selectedLanguage);
    } catch (e) {
      console.error('ERR_INIT_LOCALIZATION:', e);
    }
  },

  selectLanguage: function (selectedLanguage) {
    if (this.isInternalMultiLang) {
      if (
        selectedLanguage &&
        this.availableLangCodes.includes(selectedLanguage)
      ) {
        LocalStorageService.set('language', selectedLanguage);
        window.location.href = `/${selectedLanguage}`;
      }
    } else {
      window.location.href = selectedLanguage;
    }
  },

  fetchTranslation: async function (lang) {
    try {
      /*const params = {
        region: window.GlobalConfig?.region,
      };

      if (isBot) {
        params.nsvddkfm3kcmascnaxl = 'asdvxa123a';
      }

      const {data} = await apiCall({
        endpoint: Endpoints.TRANSLATION,
        params,
        useLocale: false,
      });*/

      const {data} = await apiCall({
        endpoint: Endpoints.TRANSLATION.replace('{lang}', lang),
        useLocale: false,
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });

      locale.setContent({
        [lang]: data,
      });
    } catch (e) {}
  },

  getLanguage: function () {
    return locale.getLanguage();
  },
};

export default LocalizationService;
