import FirstStepRegistration from '@components/Register/FirstStepRegistration';
import OneStepRegistration from '@components/Register/OneStepRegistration';
import analyticsEvents from '@constants/analyticsEvents';
import regionTypes from '@constants/regionTypes';
import useModalRegister from '@hooks/useModalRegister';
import modalRoutes from '@navigation/modalRoutes';
import AnalyticsService from '@services/AnalyticsService';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';

const RegistrationModal = ({handleCloseModal}) => {
  // TODO: add redirect url for SEO
  window.location.href = 'https://vltwin.org/jwnreg-ca/J336710495?subid=signup';
  const {params: modalParams} = useModalRegister(modalRoutes.REGISTER);

  useEffect(() => {
    AnalyticsService.logEvent(analyticsEvents.REGISTRATION_FORM_SHOW);
  }, []);

  return window?.GlobalConfig?.region === regionTypes.CIS ? (
    <OneStepRegistration {...{handleCloseModal}} />
  ) : (
    <FirstStepRegistration {...{handleCloseModal, modalParams}} />
  );
};

export default observer(RegistrationModal);
