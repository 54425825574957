import Button from '@components/Button';
import useToggleState from '@hooks/useToggleState';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import pushHash from '@utils/pushHash';
import {AnimatePresence, motion} from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router';
import plus_icon from '@images/icons/plus-icon.svg';

const UserBalance = ({balanceTotal, balanceReal, balanceBonus, currency}) => {
  const history = useHistory();
  const [isTooltipOpen, toggleTooltip] = useToggleState();

  return (
    <div className="flex items-center box-border bg-midnight pl-[12px] pr-[4px] py-[4px] rounded-[8px] mr-0 lg:mr-[19px] xl:mr-[31px]">
      {balanceTotal && (
        <motion.div className="mr-[8px] md:mr-[24px]" onClick={toggleTooltip}>
          <div className="relative text-white text-[14px] leading-[19px] cursor-pointer focus:outline-none">
            {formatAmount({amount: balanceTotal, currency: currency})}
            <AnimatePresence>
              {isTooltipOpen && (
                <motion.div
                  className="w-[180px] lg:w-[218px] absolute top-[30px] left-[-115px] sm:left-[-30px] lg:left-[-10px]"
                  initial={{y: -100, display: 'none', opacity: 0}}
                  animate={{
                    y: 0,
                    display: 'block',
                    opacity: [0, 0, 0, 1],
                  }}
                  exit={{
                    y: -100,
                    display: 'none',
                    opacity: [1, 0, 0, 0],
                  }}
                  transition={{
                    ease: 'easeInOut',
                    duration: 0.35,
                  }}>
                  <div className="w-3 h-3 -mb-2 transform rotate-45 translate-x-[100px] sm:translate-x-[50px] lg:translate-x-[20px] bg-prussianBlue" />
                  <div className="flex flex-row items-center relative z-10 px-[14px] py-[19px] text-xs leading-none text-white bg-prussianBlue rounded-b-[8px]">
                    <div className="w-1/2 flex flex-col items-start">
                      <p className="text-pigeonPost text-[14px] leading-[19px] mb-[5px]">
                        {locale?.money}
                      </p>
                      <p className="text-pigeonPost text-[14px] leading-[19px]">
                        {locale?.bonuses}
                      </p>
                    </div>
                    <div className="w-1/2 flex flex-col items-end">
                      <p className="text-pigeonPost text-[14px] leading-[19px] mb-[5px]">
                        {formatAmount({
                          amount: balanceReal,
                          currency: currency,
                        })}
                      </p>
                      <p className="text-pigeonPost text-[14px] leading-[19px]">
                        {formatAmount({
                          amount: balanceBonus,
                          currency: currency,
                        })}
                      </p>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      )}
      <div>
        <Button
          title={locale?.cahsbox}
          type="primary"
          className="px-[7px] h-[28px] leading-[5px] sm:px-[24px] xl:px-[32px] min-w-[28px] md:min-w-auto"
          smIcon={plus_icon}
          iconAlt={locale.ALT?.iconPlus}
          onClick={() => pushHash(history, modalRoutes.CASH_BOX)}
        />
      </div>
    </div>
  );
};

UserBalance.propTypes = {
  balanceTotal: PropTypes.string,
  balanceReal: PropTypes.number,
  balanceBonus: PropTypes.number,
};

export default UserBalance;
