import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import Meta from '@components/Meta';
import ProfileNavigation from '@components/Profile/ProfileNavigation';
import React from 'react';

const ProfileLayout = ({children}) => {
  return (
    <main className="bg-midnightBlue">
      <Header />
      <Meta />
      <ProfileNavigation />
      {children}
      <Footer />
    </main>
  );
};

export default ProfileLayout;
