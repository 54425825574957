import CashBoxOfferItemBtn from '@components/Offer/CashBox/CashBoxOfferItemBtn';
import CashBoxOfferItemDescription from '@components/Offer/CashBox/CashBoxOfferItemDescription';
import CashBoxOfferItemIcon from '@components/Offer/CashBox/CashBoxOfferItemIcon';
import CashBoxOfferItemOutline from '@components/Offer/CashBox/CashBoxOfferItemOutline';
import PropTypes from 'prop-types';
import {useEffect} from 'react';

const CashBoxOfferItem = ({
  index,
  offers,
  data,
  user,
  active,
  setIndex = () => {},
  isButton = true,
}) => {
  const toggleActive = () => {
    offers?.setSelectedOffer(active ? null : data);
  };

  useEffect(() => {
    if (active) {
      setIndex(index);
    }
  }, [offers?.selected]);

  return (
    <CashBoxOfferItemBtn
      {...{
        data,
        active,
        isButton,
        onClick: isButton ? toggleActive : () => {},
      }}>
      <CashBoxOfferItemOutline {...{active, isButton}}>
        <CashBoxOfferItemIcon {...{data, offers}} />
        <CashBoxOfferItemDescription {...{data, user, offers}} />
      </CashBoxOfferItemOutline>
    </CashBoxOfferItemBtn>
  );
};

CashBoxOfferItem.propTypes = {
  isButton: PropTypes.bool,
  index: PropTypes.number,
  offers: PropTypes.any,
  data: PropTypes.any,
  user: PropTypes.any,
  active: PropTypes.bool,
  setIndex: PropTypes.func,
};

export default CashBoxOfferItem;
