import Game from '@components/GamesGrid/Game';
import GameSkeleton from '@components/GamesGrid/GameSkeleton';
import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@store/configureStore';

const GamesList = ({games, size, itemsCount, categoryKey}) => {
  const {user} = useStore();

  return (
    <div className="w-full inline-flex flex-wrap items-start gap-[16px] xl:gap-[24px] relative overflow-hidden sm:pb-[24px]">
      {!games
        ? [...Array(itemsCount || 30)]?.map((_, index) => (
            <GameSkeleton key={index} />
          ))
        : games?.map((game) => (
            <Game
              key={`${game?.id}-${categoryKey || 'game'}`}
              authorized={user?.authorized}
              {...{size, game}}
            />
          ))}
    </div>
  );
};

export default observer(GamesList);
