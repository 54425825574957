import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const CashBoxOfferItemBtn = ({children, active, isButton, onClick}) => {
  return (
    <div className="px-[8px]">
      <button
        aria-label="click"
        className={classNames(
          'w-[312px] p-2 rounded-[16px] animate-fade-in-blur duration-200',
          {
            'opacity-70': !active,
            'cursor-default': !isButton,
          },
        )}
        style={{
          background:
            'linear-gradient(90deg, rgba(19, 114, 231, 0) 0%, rgba(19, 114, 231, 0.5) 100%), linear-gradient(0deg, rgba(51, 48, 195, 0.4), rgba(51, 48, 195, 0.4)), #002F88',
        }}
        {...{onClick, type: 'button'}}>
        {children}
      </button>
    </div>
  );
};

CashBoxOfferItemBtn.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CashBoxOfferItemBtn;
