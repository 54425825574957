import ChapterIconsList from '@components/PageContent/ChapterIconsList';
import ChapterImages from '@components/PageContent/ChapterImages';
import ChapterOrderedList from '@components/PageContent/ChapterOrderedList';
import ChapterParagraph from '@components/PageContent/ChapterParagraph';
import ChapterHeading from '@components/PageContent/ChapterHeading';
import ChapterTable from '@components/PageContent/ChapterTable';
import ChapterUnorderedList from '@components/PageContent/ChapterUnorderedList';
import ChapterVideoZoom from '@components/PageContent/ChapterVideoZoom';
import ContentGamesList from '@components/PageContent/ContentGamesList';
import PropTypes from 'prop-types';
import React from 'react';

const SwitchParagraphType = ({item, games}) => {
  const params = {
    data: item?.data,
    title: item?.title,
    button: item?.button,
    type: item?.type,
  };

  if (item?.type) {
    switch (item?.type) {
      case 'base':
        return <ChapterParagraph {...params} />;
      case 'list':
        return <ChapterUnorderedList {...params} />;
      case 'iconList':
        return <ChapterIconsList {...params} />;
      case 'orderedList':
        return <ChapterOrderedList {...params} />;
      case 'table':
        return <ChapterTable {...params} />;
      case 'images':
        return <ChapterImages {...params} />;
      case 'video':
        return <ChapterVideoZoom {...params} />;
      case 'games':
        return <ContentGamesList {...params} games={games} />;
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
        return <ChapterHeading {...params} />;
      default:
        break;
    }
  }
  return <ChapterParagraph {...params} />;
};

SwitchParagraphType.propTypes = {
  item: PropTypes.any.isRequired,
  games: PropTypes.any,
};

export default SwitchParagraphType;
