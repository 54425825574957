import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import PropTypes from 'prop-types';
import React from 'react';

const Balance = ({amount, currency}) => {
  return (
    <>
      <p className="text-center text-poloBlue text-[12px] font-bold mt-[16px] mb-0">
        {locale?.cahsboxBalance}
      </p>
      <p className="text-center text-resolutionBlue text-[19px] font-bold mb-[20px]">
        {formatAmount({
          currency,
          amount,
        })}
      </p>
    </>
  );
};

Balance.propTypes = {
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

export default Balance;
