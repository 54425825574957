import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import SwitchParagraphType from '@components/PageContent/SwitchParagraphType';
import ContentTitle from '@components/PageContent/ContentTitle';
import ContentBlockShowMore from '@components/PageContent/ContentBlockShowMore';
import classNames from 'classnames';

const ContentBlock = ({
  data = [],
  title,
  showMore = null,
  hasContainer = true,
}) => {
  const Wrapper = (props) => {
    return props?.showMore && props?.showMore?.switch ? (
      <ContentBlockShowMore {...props} />
    ) : (
      <div
        {...props}
        className="w-full bg-stratos px-[24px] lg:px-[43px] py-[32px] lg:pt-[43px] lg:pb-[48px] rounded-[10px] overflow-hidden"
      />
    );
  };
  const mapItems = useMemo(
    () =>
      data?.map((item, i) => {
        return (
          <SwitchParagraphType
            {...{
              item: {
                data: item?.data?.data,
                type: item?.type,
                title: item?.title,
                button: item?.button,
              },
              games: [],
              key: i,
            }}
          />
        );
      }),
    [data, title],
  );

  return (
    <>
      {((data && data?.length > 0) || title) && (
        <div
          className={classNames(
            {container: hasContainer, 'mb-[36px]': !hasContainer},
            'mx-auto max-w-full xl:max-w-[1296px] mt-[29px] sm:mt-[75px]',
          )}>
          <Wrapper {...{showMore}}>
            <ContentTitle {...{data: title}} />
            <div className="test-class text-melrose text-[14px] leading-[22px] mb-[18px] max-w-[1073px] whitespace-pre-line">
              {mapItems}
            </div>
          </Wrapper>
        </div>
      )}
    </>
  );
};

ContentBlock.propTypes = {
  data: PropTypes.any,
  title: PropTypes.string,
  showMore: PropTypes.any,
  hasContainer: PropTypes.bool,
};

export default ContentBlock;
