import {locale} from '@res/strings/locale';

const formatAmount = ({
  amount,
  currency = 'RUB',
  locales = 'ru-Ru',
  minimumFractionDigits = 2,
  useCurrency = false,
}) => {
  var formatedAmount = new Intl.NumberFormat('ru-Ru', {
    minimumFractionDigits: minimumFractionDigits,
    style: useCurrency ? 'decimal' : 'currency',
    currency: currency,
  }).format(amount);

  return useCurrency
    ? `${formatedAmount} ${locale?.currencies?.[currency]}`
    : formatedAmount;
};

export default formatAmount;
