import cashBoxConfig from '@constants/cashBoxConfig';

const useFieldsWatch = (watch, fields) => {
  const values = {};
  if (fields?.length) {
    fields?.map((field) => {
      if (
        field?.type === cashBoxConfig.FORM_FIELDS.TYPES.TEXT ||
        field?.type === cashBoxConfig.FORM_FIELDS.TYPES.EMAIL ||
        field?.type === cashBoxConfig.FORM_FIELDS.TYPES.PHONE ||
        field?.type === cashBoxConfig.FORM_FIELDS.TYPES.CHOICE
      ) {
        if (
          field?.field_values?.length > 0 &&
          field?.field_name === cashBoxConfig.FORM_FIELDS.NAMES.WALLET
        ) {
          values[cashBoxConfig.FORM_FIELDS.NAMES.SAVED] = watch(
            cashBoxConfig.FORM_FIELDS.NAMES.SAVED,
          );
        } else {
          field?.field_name
            ? (values[field?.field_name] = watch(field?.field_name))
            : (values[field?.name] = watch(field?.name));
        }
      }
    });
  }
  return values;
};

export default useFieldsWatch;
