import React from 'react';
import {locale} from '@res/strings/locale';
import ModalCloseAction from '@components/Modals/ModalCloseAction';
import restriction_icon from '@images/icons/restriction-icon.png';

const RestrictionProvidersModal = ({handleCloseModal}) => {
  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex pointer-events-none flex justify-center items-end md:items-center overflow-hidden">
      <div className="relative w-full md:w-[340px] bg-green-500 pointer-events-auto max-h-full overflow-auto pt-8 px-8 pb-[62px] md:pb-10 rounded-t-[10px] md:rounded-b-[10px] bg-pop-up-card">
        <ModalCloseAction {...{handleCloseModal, width: 16}} />
        <div className="flex flex-col justify-center items-center">
          <img
            src={restriction_icon}
            alt=""
            className="w-[112px] md:w-[112px] mb-4"
          />
          <div className="mb-2 text-white text-[19px] font-semibold leading-[28px] text-center">
            {locale?.sorry}
          </div>
          <div
            className="text-base text-white text-center leading-[24px]"
            dangerouslySetInnerHTML={{__html: locale?.RESTRICTIONS?.providers}}
          />
        </div>
      </div>
    </div>
  );
};

export default RestrictionProvidersModal;
