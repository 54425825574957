import Button from '@components/Button';
import CheckBox from '@components/FormElements/CheckBox';
import Input from '@components/FormElements/Input';
import InputError from '@components/FormElements/InputError';
import TelInput from '@components/FormElements/TelInput/TelInput';
import ModalWrapper from '@components/Modals/ModalWrapper';
import RegistrationFormBonus from '@components/RegistrationFormBonus';
import SocialAuth from '@components/SocialAuth';
import logoConfig from '@constants/logoConfig';
import modalConfig from '@constants/modalConfig';
import regionTypes from '@constants/regionTypes';
import {yupResolver} from '@hookform/resolvers/yup';
import useActiveTab from '@hooks/useActiveTab';
import useModalControls from '@hooks/useModalControls';
import mobile_bonus_box_icon from '@images/icons/mobile-bonus-box-icon.png';
import promocode_icon from '@images/icons/promocode-icon.svg';
import modalRoutes from '@navigation/modalRoutes';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import PromoCodeService from '@services/PromoCodeService';
import {useStore} from '@store/configureStore';
import errorMsgHandler from '@utils/errorMsgHandler';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';

const OneStepRegistration = ({handleCloseModal}) => {
  const {user} = useStore();
  const {openModal} = useModalControls();
  const [activeTab, switchTab] = useActiveTab(modalConfig.TAB_NAMES.EMAIL);

  const registrationFormSchema = useMemo(
    () =>
      yup.object().shape({
        email:
          activeTab === modalConfig.TAB_NAMES.EMAIL
            ? yup
                .string()
                .email(locale?.ERROR_MESSAGES?.email)
                .required(locale?.ERROR_MESSAGES?.required)
            : yup.string(),
        phone:
          activeTab === modalConfig.TAB_NAMES.PHONE
            ? yup.string().required(locale?.ERROR_MESSAGES?.required)
            : yup.string(),
        password: yup
          .string()
          .min(4, locale?.ERROR_MESSAGES?.passwordMin)
          .required(locale?.ERROR_MESSAGES?.required),
        promo_code: yup.string(),
        terms: yup
          .bool()
          .required(locale?.ERROR_MESSAGES?.required)
          .oneOf([true], locale?.ERROR_MESSAGES?.termsNotChecked),
        currency: yup.string(),
      }),
    [activeTab],
  );

  const {
    formState: {errors},
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(registrationFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      currency: window?.GlobalConfig?.currencies[0],
      email: '',
      phone: '',
      password: '',
      promo_code: PromoCodeService.getPromoCode() ?? '',
      terms: true,
      receive_email: true,
      receive_sms: true,
    },
  });

  useEffect(() => {
    clearErrors();
    reset();
  }, [activeTab]);

  const onSubmit = async (data) => {
    const phone = data.phone
      ?.replaceAll(' ', '')
      ?.replace('+', '')
      ?.replaceAll('-', '');

    try {
      await user.signUp.run({
        email: data.email ? data.email : undefined,
        phone: phone ? phone : undefined,
        promo_code: data.promo_code,
        password: data.password,
        currency: data.currency,
        receive_email: data.receive_email,
        receive_sms: data.receive_sms,
      });
    } catch (errors) {
      errorMsgHandler(errors, {
        setError,
        fields: [
          'email',
          'phone',
          'password',
          'currency',
          'promo_code',
          'system',
        ],
      });
    }
  };

  const handleSocialRegistration = (name) => {
    user.socialAuth.run(name);
  };

  return (
    <ModalWrapper
      desktopStyle={'max-w-[720px] w-full sm:h-[600px] sm:rounded-[8px]'}
      className={''}
      {...{handleCloseModal}}>
      <div className="flex flex-col sm:flex-row w-full h-full">
        <RegistrationFormBonus />
        <div className="sm:w-1/2 h-[100%] flex flex-col overflow-scroll no-scrollbar bg-white pb-[80px] sm:pb-[0]">
          <img
            src={logoConfig.INVERSE_SRC}
            className="w-full max-w-[95px] mt-[20px] mb-[10px] mx-auto"
            alt={locale.ALT?.logo}
          />
          <div className="flex flex-col px-[32px] pt-[24px] pb-[50px] h-[100%]">
            <div className="block mb-[24px] sm:hidden h-[88px] rounded-[8px] flex justify-evenly bg-registration-mobile-banner">
              <img
                src={mobile_bonus_box_icon}
                className="registration-banner-image"
                alt={locale?.welcomeBonus}
              />
              <div className="flex flex-col self-center text-center">
                <p className="text-[12px] text-cerulean">
                  {locale?.welcomeBonus}
                </p>
                <p className="text-[19px] text-white font-bold">
                  {locale?.welcomeBonusReward}
                </p>
                <p className="text-[12px] text-white">
                  {locale?.welcomeBonusMessage}
                </p>
              </div>
            </div>
            <div className="flex relative">
              <div
                className={classNames(
                  {
                    'translate-x-0': activeTab === modalConfig.TAB_NAMES.EMAIL,
                    'translate-x-[100%]':
                      activeTab === modalConfig.TAB_NAMES.PHONE,
                  },
                  'absolute h-[2px] w-[50%]  border-b-[2px] border-redRibbon bottom-[-4px] transform transition duration-200 ease-in-out',
                )}
              />
              <div
                className={classNames(
                  'w-1/2 cursor-pointer transition uppercase text-center font-bold text-[12px] text-resolutionBlue',
                )}
                onClick={() => switchTab(modalConfig.TAB_NAMES.EMAIL)}>
                {locale?.email}
              </div>
              <div
                className={classNames(
                  'transition duration-200 ease w-1/2 cursor-pointer uppercase text-center font-bold text-[12px] text-resolutionBlue',
                )}
                onClick={() => switchTab(modalConfig.TAB_NAMES.PHONE)}>
                {locale?.phone}
              </div>
            </div>

            <div className="flex flex-col justify-between w-full mt-[20px] h-[100%]">
              <form onSubmit={handleSubmit(onSubmit)}>
                {activeTab === modalConfig.TAB_NAMES.PHONE && (
                  <Controller
                    control={control}
                    name="phone"
                    render={({field: {onChange, value, ref}}) => (
                      <TelInput
                        inputRef={ref}
                        type="tel"
                        name="phone"
                        isError={errors.phone}
                        errorMessage={errors.phone?.message}
                        {...{value, onChange, setError, clearErrors, setValue}}
                      />
                    )}
                  />
                )}
                {activeTab === modalConfig.TAB_NAMES.EMAIL && (
                  <Controller
                    control={control}
                    name="email"
                    render={({field: {onChange, value}}) => (
                      <Input
                        placeholder={locale?.enterEmail}
                        type="email"
                        {...{onChange, value}}
                        isError={errors.email}
                        errorMessage={errors.email?.message}
                        className="text-[14px]"
                      />
                    )}
                  />
                )}
                <div className="relative">
                  <Controller
                    control={control}
                    name="password"
                    render={({field: {onChange, value}}) => (
                      <Input
                        placeholder={locale?.enterPassword}
                        type="password"
                        {...{onChange, value}}
                        className="text-[14px]"
                        isError={errors.password}
                        errorMessage={errors.password?.message}
                        usePasswordVisibility
                      />
                    )}
                  />
                </div>
                <div className="relative">
                  <Controller
                    control={control}
                    name="promo_code"
                    render={({field: {onChange, value}}) => (
                      <Input
                        placeholder={locale?.PLACEHOLDER?.enterPromoCode}
                        type="text"
                        {...{onChange, value}}
                        isError={errors.promo_code}
                        errorMessage={errors.promo_code?.message}
                        className={classNames(
                          {uppercase: value},
                          'text-[14px] pl-[45px]',
                        )}
                      />
                    )}
                  />
                  <img
                    className="absolute top-[11px] left-[15px]"
                    src={promocode_icon}
                    alt="promocode"
                  />
                </div>
                {/*<Controller*/}
                {/*  control={control}*/}
                {/*  name="currency"*/}
                {/*  render={({field: {onChange, value, ref}}) => (*/}
                {/*    <ReactSelect*/}
                {/*      inputRef={ref}*/}
                {/*      options={currencyOptions}*/}
                {/*      value={currencyOptions.find((c) => c.value === value)}*/}
                {/*      onChange={(val) => onChange(val.value)}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*/>*/}
                <Controller
                  control={control}
                  name="terms"
                  render={({field: {onChange, value, ref}}) => (
                    <CheckBox
                      label={locale?.termsLabel}
                      inputRef={ref}
                      isError={errors.terms}
                      errorMessage={errors.terms?.message}
                      linkText={locale?.termsLinkText}
                      linkUrl={navigationRoutes.TERMS_AND_CONDITIONS}
                      {...{value, onChange}}
                    />
                  )}
                />

                {!!errors.system && (
                  <InputError message={errors.system?.message} />
                )}

                <Button
                  disabled={user.signUp?.inProgress}
                  htmlType="submit"
                  title={
                    user.signUp?.inProgress
                      ? locale?.processing
                      : locale?.doRegister
                  }
                  className="w-full h-[40px] text-[14px] font-bold leading-5 py-[10px] rounded-[5px] mt-[33px] uppercase"
                  type="primary"
                />
              </form>
              <div className="flex flex-col self-center">
                {window?.GlobalConfig?.region === regionTypes.CIS && (
                  <div className="flex flex-row flex-wrap inline-flex justify-center gap-x-[15px] mt-[10px] mb-[30px]">
                    <SocialAuth clickHandler={handleSocialRegistration} />
                  </div>
                )}
                <p className="text-center text-[13px] text-resolutionBlue">
                  {locale?.hasAccount}
                  <span
                    onClick={() => openModal(modalRoutes.AUTH)}
                    className="ml-[5px] underline cursor-pointer">
                    {locale?.login}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default observer(OneStepRegistration);
