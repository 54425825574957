import {Endpoints, Methods} from '@api';
import useRequest from '@hooks/useRequest';
import getImageUrl from '@utils/getImageUrl';
import React from 'react';

const PaymentMethods = () => {
  const {data} = useRequest({
    method: Methods.GET,
    endpoint: Endpoints.PAYMENT_METHODS,
  });

  return (
    <>
      {data?.icons?.map((icon) => (
        <img
          key={icon.id}
          width={icon.width}
          height={icon.height}
          src={getImageUrl(icon.url)}
          alt={icon?.alternativeText}
        />
      ))}
    </>
  );
};

export default PaymentMethods;
