import Flicking from '@egjs/react-flicking';
import useWindowSize from '@hooks/useWindowSize';
import {useEffect, useRef, useState} from 'react';

const CashBoxOffersListCarousel = ({children, index}) => {
  const [isLoading, setIsLoading] = useState(true);
  const sliderRef = useRef(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      sliderRef?.current?.resize();
      sliderRef?.current?.moveTo(index);
      setIsLoading(false);
    }, 100);
  }, [windowSize, index, isLoading]);

  return (
    <>
      {isLoading ? (
        <div className="w-[312px] min-h-[116px] p-2 rounded-[16px] bg-transparent" />
      ) : (
        <Flicking
          ref={sliderRef}
          zIndex={1}
          overflow={false}
          style={{width: '100%'}}
          onChange={(e) => {}}
          collectStatistics={false}>
          {children}
        </Flicking>
      )}
    </>
  );
};

export default CashBoxOffersListCarousel;
