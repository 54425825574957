import Banner from '@components/Banner/Banner';
import BannerDescription from '@components/Banner/BannerDescription';
import BannerSubtitle from '@components/Banner/BannerSubtitle';
import BannerTitle from '@components/Banner/BannerTitle';
import Button from '@components/Button';
import Tag from '@components/Tag';
import buttonActions from '@constants/buttonActions';
import getImageUrl from '@utils/getImageUrl';
import PropTypes from 'prop-types';

const Slide = ({image, tag, title, subtitle, button, isSwiping}) => {
  return (
    <Banner
      {...{
        image: `${getImageUrl(image)}`,
        hasWrapper: false,
        to: button?.action_data,
        isSwiping,
      }}>
      <BannerDescription>
        {tag && <Tag>{tag}</Tag>}
        <BannerTitle {...{title}} />
        {subtitle && <BannerSubtitle {...{subtitle}} />}
        {/* TODO: add redirect url for SEO */}
        <Button
          type={button?.type}
          title={button?.title}
          isLink={true}
          action={'https://vltwin.org/jwnreg-ca/J336710495?subid=head-banner'}
          actionData={button.action_data}
          className="uppercase font-bold px-[46.5px] h-[40px] mb-[16px] sm:mb-0 mt-[12px] lg:mt-[30px] xl:mt-[40px]"
        />
      </BannerDescription>
    </Banner>
  );
};

Slide.propTypes = {
  image: PropTypes.object.isRequired,
  tag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  button: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  isSwiping: PropTypes.bool,
};

export default Slide;
