import NoNetwork from '@components/NoNetwork';
import useNetworkStatus from '@hooks/useNetworkStatus';

const NetworkGate = ({children}) => {
  const isOnline = useNetworkStatus();

  if (isOnline) {
    return children;
  } else {
    return <NoNetwork />;
  }
};

export default NetworkGate;
