export default {
  TYPES: {
    DEFAULT: 'DEFAULT',
    INFO: 'INFO',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
  },
  TEMPLATES: {
    SERVICE: 'SERVICE',
    PUSH_SUBSCRIPTION: 'PUSH_SUBSCRIPTION',
    CASH_BOX: 'CASH_BOX',
    CONFIRMATION: 'CONFIRMATION',
    PROMO_CODE_ACTIVATION: 'PROMO_CODE_ACTIVATION',
    INBOX_MESSAGES: 'INBOX_MESSAGES',
  },
  INBOX_MESSAGE_ACTION_IMAGES: {
    GIFT: 'GIFT',
    INFORMATION: 'INFORMATION',
    WELCOME: 'WELCOME',
    CONFIRM: 'CONFIRM',
  },
  HELPERS: {
    truncateMessage: (notification) => {
      if (notification?.message.length > 30) {
        return notification?.message?.substring(0, 30) + '...';
      }
      return notification?.message;
    },
  },
};
