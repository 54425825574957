import {apiCall, Endpoints, Methods} from '@api';
import endpoints from '@api/endpoints';
import analyticsEvents from '@constants/analyticsEvents';
import brandTypes from '@constants/brandTypes';
import notificationConfig from '@constants/notificationConfig';
import promocodeTypes from '@constants/promocodeTypes';
import tokenTypes from '@constants/tokenTypes';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {locale} from '@res/strings/locale';
import AnalyticsService, {uaParser} from '@services/AnalyticsService';
import CaptchaService from '@services/CaptchaService';
import NotificationService from '@services/NotificationService';
import StorageService from '@services/StorageService';
import asyncAction, {returningAsyncAction} from '@store/helpers/asyncAction';
import Cookies from 'js-cookie';
import {getRoot, types} from 'mobx-state-tree';

export const UserState = {
  authorized: false,
  banned: false,
  profile: null,
  documents: null,
  notifications: null,
};

const completeAuth = async (data, store, root) => {
  await store.setTokens(data.access.token, data.refresh.token);

  store.getUserProfile.run();

  root?.balance?.initBalanceUpdater();
  root?.popUpNotification?.initPopUpUpdater();
  root?.floatingNotification?.connect();

  root?.offers?.getOffers.run();
  root?.offers?.getWelcomeOffers.run();

  root?.games?.getFavoriteGames.run();

  store.setAuthorized(true);
};

function signIn({email, phone, password}) {
  return async function flow(store, _, root) {
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.SIGN_IN,
        method: Methods.POST,
        data: {
          email: email ? email : undefined,
          phone: phone ? phone : undefined,
          password,
          region: window?.GlobalConfig?.region,
          brand: /*window?.GlobalConfig?.brand*/ brandTypes.VOSTOK,
          recaptcha_token: await CaptchaService.execute(),
        },
        useLocale: false,
      });

      await completeAuth(data, store, root);

      NotificationService.default({
        notification: locale?.NOTIFICATIONS?.loginSuccess,
      });

      setTimeout(() => {
        if (window.GlobalConfig?.brand === brandTypes.VOSTOK) {
          if (uaParser.getOS()?.name !== 'iOS') {
            NotificationService.info({
              notification: locale?.NOTIFICATIONS?.pushSubscription,
              isCloseButtonVisible: true,
              autoClose: 5000,
              template: notificationConfig.TEMPLATES.PUSH_SUBSCRIPTION,
              classNames: 'close-button-white !bg-gradient-b-pushNotification',
            });
          }
        }
      }, 5000);

      AnalyticsService.logEvent(analyticsEvents.SIGN_IN_SUCCESS, {
        email,
        phone,
      });
    } catch (e) {
      AnalyticsService.logEvent(analyticsEvents.SIGN_IN_FAILED, {
        email,
        phone,
        password,
      });

      throw e?.response?.data;
    }
  };
}

function signUp(fields, isSocial) {
  return async function flow(store, _, root) {
    try {
      const promoCode = fields?.promo_code;
      delete fields?.promo_code;

      const {data} = await apiCall({
        endpoint: isSocial ? Endpoints.SOCIAL_SIGN_UP : Endpoints.SIGN_UP,
        method: Methods.POST,
        data: {
          ...fields,

          referrer: Cookies.get('tr') || window.location.href,
          landing_page: Cookies.get('tl') || window.location.href,
          time_offset: new Date().getTimezoneOffset(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,

          fingerprint: (await (await FingerprintJS.load())?.get())?.visitorId,

          region: window?.GlobalConfig?.region,
          brand: /*window?.GlobalConfig?.brand*/ brandTypes.VOSTOK,
          recaptcha_token: await CaptchaService.execute(),
        },
        useLocale: false,
      });

      await completeAuth(data, store, root);

      if (promoCode) {
        root.offers?.activatePromoCode.run(
          promoCode,
          false,
          promocodeTypes.REGISTER,
        );
      }

      AnalyticsService.logEvent(analyticsEvents.SIGN_UP_SUCCESS, {
        fields,
      });
    } catch (e) {
      AnalyticsService.logEvent(analyticsEvents.SIGN_UP_FAILED, {
        fields,
        error: JSON.stringify(e?.response?.data),
      });

      throw e?.response?.data;
    }
  };
}

function autologin(token) {
  return async function flow(store, _, root) {
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.AUTOLOGIN,
        method: Methods.POST,
        data: {
          token,
        },
        useAuth: false,
        useLocale: false,
      });

      await completeAuth(data, store, root);
    } catch (e) {
      AnalyticsService.logEvent(analyticsEvents.AUTOLOGIN_FAILED, {
        error: JSON.stringify(e?.response?.data),
      });

      throw e?.response?.data;
    }
  };
}

function signUpValidate(fields) {
  return async function flow(store, _, root) {
    try {
      await apiCall({
        endpoint: Endpoints.SIGN_UP_VALIDATE,
        method: Methods.POST,
        data: {
          ...fields,
          referrer: Cookies.get('tr') || window.location.href,
          landing_page: Cookies.get('tl') || window.location.href,
          time_offset: new Date().getTimezoneOffset(),

          fingerprint: (await (await FingerprintJS.load())?.get())?.visitorId,

          region: window?.GlobalConfig?.region,
          brand: /*window?.GlobalConfig?.brand*/ brandTypes.VOSTOK,
          recaptcha_token: await CaptchaService.execute(),
        },
        useLocale: false,
      });
    } catch (e) {
      throw e?.response?.data;
    }
  };
}

function socialAuth(provider) {
  return async function flow() {
    window.open(
      `${endpoints.SOCIAL_SIGN_IN}/${
        /*window?.GlobalConfig?.brand*/ brandTypes.VOSTOK
      }/${window?.GlobalConfig?.region}/${provider}?r=${
        window.location.origin
      }`,
      '_blank',
    );
  };
}

function getUserProfile() {
  return async function flow(store) {
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.PROFILE,
        useAuth: true,
        useLocale: false,
      });
      store.setProfile(data);
    } catch (e) {}
  };
}

function updateUserProfile(profile) {
  return async function flow(store) {
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.PROFILE,
        method: Methods.POST,
        data: profile,
        useAuth: true,
        useLocale: false,
      });
      store.setProfile(data);
    } catch (e) {
      throw e?.response?.data;
    }
  };
}

function sendVerifyEmail() {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.SEND_CONFIRM_EMAIL,
        useAuth: true,
        useLocale: false,
      });
    } catch (e) {
      NotificationService.error({
        notification: locale?.NOTIFICATIONS?.sendVerifyEmailError,
      });

      throw e?.response?.data;
    }
  };
}

function sendPhoneConfirmationMessage({phone}) {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.SEND_PHONE_CONFIRMATION_MESSAGE,
        method: Methods.POST,
        data: {phone},
        useAuth: true,
        useLocale: false,
      });
    } catch (e) {
      NotificationService.error({
        notification: locale?.NOTIFICATIONS?.verifyPhoneError,
      });

      throw e?.response?.data;
    }
  };
}

function checkPhoneConfirmationMessage({code}) {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.CHECK_PHONE_CONFIRMATION_MESSAGE,
        method: Methods.POST,
        data: {code},
        useAuth: true,
        useLocale: false,
      });

      NotificationService.success({
        notification: locale?.NOTIFICATIONS?.verifyPhoneSuccess,
        isCloseButtonVisible: true,
        autoClose: 5000,
        template: notificationConfig.TEMPLATES.CONFIRMATION,
        classNames:
          'close-button-white !bg-gradient-b-confirmationNotificationDefault',
      });

      store.getUserProfile.run();
    } catch (e) {
      NotificationService.error({
        notification: locale?.NOTIFICATIONS?.verifySmsCodeError,
        classNames: '!bg-gradient-b-defaultErrorNotification',
      });

      throw e?.response?.data;
    }
  };
}

function sendDocuments(data) {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.SEND_DOCUMENTS,
        method: Methods.POST,
        data: data,
        useAuth: true,
        headers: {'Content-Type': 'multipart/form-data'},
      });

      await store.getDocuments.run();
    } catch (e) {
      NotificationService.error({
        notification: locale?.NOTIFICATIONS?.sendDocumentError,
      });
    }
  };
}

function getDocuments() {
  return async function flow(store) {
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.GET_DOCUMENTS,
        useAuth: true,
      });
      store.setDocuments(data);
    } catch (e) {}
  };
}

function getNotifications() {
  return async function flow(store) {
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.GET_NOTIFICATIONS,
        useAuth: true,
      });
      store.setNotifications(data);
    } catch (e) {}
  };
}

function changePassword({old_password, new_password}) {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.CHANGE_PASSWORD,
        useAuth: true,
        method: Methods.POST,
        data: {old_password, new_password},
        useLocale: false,
      });
      NotificationService.default({
        notification: locale?.NOTIFICATIONS?.changePasswordSuccess,
        isCloseButtonVisible: true,
      });
    } catch (e) {
      throw e?.response?.data;
    }
  };
}

function resetPasswordRequest({email, phone}) {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.RESET_PASSWORD_REQUEST,
        useAuth: true,
        method: Methods.POST,
        data: {
          email,
          phone,
          region: window?.GlobalConfig?.region,
          brand: /*window?.GlobalConfig?.brand*/ brandTypes.VOSTOK,
          recaptcha_token: await CaptchaService.execute(),
        },
        useLocale: false,
      });
    } catch (e) {
      throw e?.response?.data;
    }
  };
}

function resetPasswordVerifyPhone({phone, token}) {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.RESET_PASSWORD_CONFIRM,
        method: Methods.POST,
        data: {
          phone,
          token,
          region: window?.GlobalConfig?.region,
          brand: /*window?.GlobalConfig?.brand*/ brandTypes.VOSTOK,
          recaptcha_token: await CaptchaService.execute(),
        },
        useAuth: true,
        useLocale: false,
      });
    } catch (e) {
      throw e?.response?.data;
    }
  };
}

function resetPasswordConfirm({phone, token, password}) {
  return async function flow(store) {
    try {
      await apiCall({
        endpoint: Endpoints.RESET_PASSWORD_CONFIRM,
        method: Methods.POST,
        data: {
          phone,
          token,
          password,
          region: window?.GlobalConfig?.region,
          brand: /*window?.GlobalConfig?.brand*/ brandTypes.VOSTOK,
        },
        useAuth: true,
        useLocale: false,
      });
    } catch (e) {
      throw e?.response?.data;
    }
  };
}

const User = types
  .model({
    authorized: types.boolean,
    profile: types.maybeNull(types.frozen()),
    documents: types.maybeNull(types.frozen()),
    notifications: types.maybeNull(types.frozen()),

    signIn: returningAsyncAction(signIn),
    autologin: returningAsyncAction(autologin),
    signUp: returningAsyncAction(signUp),
    signUpValidate: returningAsyncAction(signUpValidate),
    socialAuth: returningAsyncAction(socialAuth),
    getUserProfile: returningAsyncAction(getUserProfile),
    updateUserProfile: returningAsyncAction(updateUserProfile),
    sendVerifyEmail: asyncAction(sendVerifyEmail),
    sendPhoneConfirmationMessage: returningAsyncAction(
      sendPhoneConfirmationMessage,
    ),
    checkPhoneConfirmationMessage: returningAsyncAction(
      checkPhoneConfirmationMessage,
    ),
    sendDocuments: returningAsyncAction(sendDocuments),
    getDocuments: asyncAction(getDocuments),
    getNotifications: asyncAction(getNotifications),
    changePassword: returningAsyncAction(changePassword),
    resetPasswordRequest: returningAsyncAction(resetPasswordRequest),
    resetPasswordVerifyPhone: returningAsyncAction(resetPasswordVerifyPhone),
    resetPasswordConfirm: returningAsyncAction(resetPasswordConfirm),
  })
  .actions((store) => ({
    setProfile(profile) {
      store.profile = profile;
    },
    setDocuments(documents) {
      store.documents = documents;
    },
    setNotifications(notifications) {
      store.notifications = notifications;
    },
    async setTokens(token, refreshToken) {
      await StorageService.current?.set(tokenTypes.ACCESS, token);
      await StorageService.current?.set(tokenTypes.REFRESH, refreshToken);
    },
    setAuthorized(value) {
      store.authorized = value;
    },
    async signOut() {
      Object.entries(UserState).forEach(([key, value]) => (store[key] = value));

      await StorageService.current?.clear(tokenTypes.ACCESS);
      await StorageService.current?.clear(tokenTypes.REFRESH);

      getRoot(store)?.balance?.destructBalanceUpdater();
      getRoot(store)?.popUpNotification?.destructPopUpUpdater();
      getRoot(store)?.games?.clearFavoriteGames();
      getRoot(store)?.stretchNotification?.clearClosedStretchNotifications();
      getRoot(store)?.floatingNotification?.disconnect();
    },
  }));

export default User;
