import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import tournament_banner_icon from '@images/icons/tournament-banner-icon.svg';

const LotteryPrizesCounter = ({count, className}) => {
  return (
    <div
      className={classNames(
        'flex justify-between items-center w-[62px] py-1 px-2 bg-secondary rounded-[5px]',
        className,
      )}>
      <span className="text-warning text-[15px]">x{count}</span>
      <img src={tournament_banner_icon} alt="" className="h-[16px]" />
    </div>
  );
};

LotteryPrizesCounter.propTypes = {
  count: PropTypes.any,
  className: PropTypes.string,
};

export default LotteryPrizesCounter;
