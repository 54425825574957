import GameGridBanner from '@components/GamesGrid/GameGridBanner';
import GameGridBannerSkeleton from '@components/GamesGrid/GameGridBannerSkeleton';
import classNames from 'classnames';
import React from 'react';

const GameGridBannerWrapper = ({renderBanner, banner, games}) => {
  return (
    <>
      {!!renderBanner &&
        !!banner &&
        (games ? (
          <GameGridBanner
            image={banner.image}
            title={banner.title}
            subtitle={banner.subtitle}
            button={banner.button}
            className={classNames({
              'mr-0 md:mr-[16px] xl:mr-[24px]': banner.position === 'left',
              'ml-0 md:ml-[16px] xl:ml-[24px]': banner.position === 'right',
            })}
          />
        ) : (
          <GameGridBannerSkeleton
            className={classNames({
              'mr-0 md:mr-[16px] xl:mr-[24px]': banner.position === 'left',
              'ml-0 md:ml-[16px] xl:ml-[24px]': banner.position === 'right',
            })}
          />
        ))}
    </>
  );
};

export default GameGridBannerWrapper;
