import ContentButton from '@components/PageContent/ContentButton';
import ParagraphTitle from '@components/PageContent/ParagraphTitle';
import SimpleTable from '@components/Tables/SimpleTable';
import PropTypes from 'prop-types';
import React from 'react';

const ChapterTable = ({data, title, button}) => {
  return (
    <>
      <ParagraphTitle {...{title}} />
      {data?.length === 1 && <SimpleTable {...{data: data?.[0]}} />}
      {data?.length > 1 && (
        <div className="flex flex-wrap mx-[-12px] flex-col  lg:flex-row">
          {data?.map((item, key) => (
            <div {...{key}} className="mx-[12px] flex-1">
              <SimpleTable {...{data: item}} />
            </div>
          ))}
        </div>
      )}
      <ContentButton {...{data: button}} />
    </>
  );
};

ChapterTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any.isRequired,
  button: PropTypes.any,
};

export default ChapterTable;
