import React from 'react';
import PropTypes from 'prop-types';
import lotteryConfig from '@constants/lotteryConfig';
import LotteryRulesItem from '@components/LotteryPage/LotteryRulesItem';

const LotteryRulesList = ({data}) => {
  return (
    <div className="flex flex-col md:flex-row my-6 md:my-8">
      {lotteryConfig?.RULES_LIST(data)?.map((item, key) => (
        <LotteryRulesItem {...item} key={key} />
      ))}
    </div>
  );
};

LotteryRulesList.propTypes = {
  data: PropTypes.any,
};

export default LotteryRulesList;
