import {locale} from '@res/strings/locale';
import OfferInfoAction from '@components/Modals/OfferInfoModal/OfferInfoAction';
import formatAmount from '@utils/formatAmount';
import PropTypes from 'prop-types';
import React from 'react';

const OfferInfoBonus = ({user, offers, data}) => {
  return (
    <>
      <div>
        <p className="mt-0 mb-[10px] pr-[34px] text-resolutionBlue text-[20px] leading-[22px] font-bold">
          {locale?.OFFER_INFO?.title}
        </p>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px] font-bold">
          {locale?.OFFER_INFO?.bonusRulesTitle?.replace(
            '{bonus_amount}',
            data?.max_amount_percent > 0
              ? `${data?.max_amount_percent}%`
              : formatAmount({
                  amount: data?.max_amount / 100 ?? 0,
                  currency: user?.profile?.currency,
                  minimumFractionDigits: 0,
                }),
          )}
        </p>
        <ol className="list-decimal pl-5 text-resolutionBlue text-[14px] leading-[20px]">
          <li className="mb-2">{locale?.OFFER_INFO?.emailRule}</li>
          <li className="mb-2">{locale?.OFFER_INFO?.cashBoxRule}</li>
          <li className="mb-2">
            {`${locale?.OFFER?.makeDeposit} 
            ${formatAmount({
              amount: data?.min_deposit_amount / 100 ?? 0,
              currency: user?.profile?.currency,
              minimumFractionDigits: 0,
            })}`}
          </li>
          <li className="mb-2">
            {locale?.OFFER_INFO?.termsBonus}
            <ul className="list-disc pl-6 mt-2">
              <li className="mb-2">
                {locale?.OFFER_INFO?.wager} <b>x{data?.wager}</b>.
              </li>
              <li className="mb-2">
                {`${locale?.OFFER_INFO?.maxBonusAmount} `}
                <b>
                  {formatAmount({
                    amount: data?.max_amount / 100 ?? 0,
                    currency: user?.profile?.currency,
                    minimumFractionDigits: 0,
                  })}
                </b>
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <OfferInfoAction {...{offers, id: data?.id}} />
    </>
  );
};

OfferInfoBonus.propTypes = {
  user: PropTypes.any,
  offers: PropTypes.any,
  data: PropTypes.any,
};

export default OfferInfoBonus;
