import {useEffect, useState} from 'react';

const useElementOffsetHeight = (ref, setHeight = () => {}) => {
  const [offsetHeight, setOffsetHeight] = useState(0);

  useEffect(() => {
    const checkHeight = () => {
      setOffsetHeight(ref?.current?.offsetHeight);
      setHeight(ref?.current?.offsetHeight);
    };
    checkHeight();
    window.addEventListener('resize', checkHeight);
    window.addEventListener('click', checkHeight);
    return () => {
      window.removeEventListener('resize', checkHeight);
      window.removeEventListener('click', checkHeight);
    };
  }, [ref?.current?.offsetHeight]);

  return offsetHeight;
};

export default useElementOffsetHeight;
