export default {
  set: (key, value) => {
    return callWithPromise(() => localStorage.setItem(key, value));
  },
  get: (key) => {
    return callWithPromise(() => localStorage.getItem(key));
  },
  clear: (key) => {
    return callWithPromise(() => localStorage.removeItem(key));
  },
};

function callWithPromise(func, ...args) {
  try {
    return Promise.resolve(func(...args));
  } catch (err) {
    return Promise.reject(err);
  }
}
