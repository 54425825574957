import Input from '@components/FormElements/Input';
import minus_icon from '@images/icons/minus-icon.svg';
import plus_icon from '@images/icons/plus-icon.svg';
import {locale} from '@res/strings/locale';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';

const SuggestionsInput = ({
  min,
  max,
  defaultStep,
  errors,
  control,
  setValue,
  suggestions,
  getValues,
}) => {
  const handleCLick = (isAdd) => {
    let amount = getValues('amount');
    const add = +amount + +defaultStep;
    const subtract = +amount - +defaultStep;
    if (isAdd) {
      if (add <= max) {
        setValue('amount', add.toString(), {shouldValidate: true});
      }
    } else {
      if (subtract >= min) {
        setValue('amount', subtract.toString(), {shouldValidate: true});
      }
    }
  };

  return (
    <>
      <div className="relative w-full text-center">
        <div
          className={classNames(
            {
              'bg-periwinkleGray cursor-not-allowed':
                getValues('amount') == min,
            },
            'absolute select-none left-[12px] top-[12px] bottom-0 flex items-center justify-center w-[32px] h-[32px] bg-redRibbon rounded-[5px] cursor-pointer',
          )}
          onClick={() => handleCLick(false)}>
          <img
            width="12"
            height="12"
            src={minus_icon}
            alt={locale?.iconMinus}
          />
        </div>
        <div
          className={classNames(
            {
              'bg-periwinkleGray cursor-not-allowed':
                getValues('amount') === max,
            },
            'absolute select-none right-[12px] top-[12px] bottom-0 flex items-center justify-center w-[32px] h-[32px] bg-redRibbon rounded-[5px] cursor-pointer',
          )}
          onClick={() => handleCLick(true)}>
          <img width="12" height="12" src={plus_icon} alt={locale?.iconPlus} />
        </div>
        <Controller
          control={control}
          name="amount"
          render={({field: {onChange, value}}) => (
            <Input
              type="number"
              pattern="\d*"
              value={value ?? ''}
              {...{onChange}}
              isError={errors.amount}
              errorMessage={errors.amount?.message}
              className="h-[56px] font-bold text-center text-[19px] leading-[32px] text-resolutionBlue"
            />
          )}
        />
      </div>

      <div className="w-full inline-flex justify-center flex-wrap gap-[8px] mt-[24px]">
        {suggestions?.map((suggestion) => (
          <div
            key={suggestion}
            onClick={() =>
              setValue('amount', suggestion, {shouldValidate: true})
            }
            className={classNames(
              {
                'text-white bg-redRibbon border-[1px] border-redRibbon':
                  getValues('amount') === suggestion,
                'text-poloBlue bg-white border-[1px] border-periwinkleGray':
                  getValues('amount') !== suggestion,
              },
              'transition select-none duration-300 w-[90px] h-[40px] flex justify-center items-center rounded-[8px] text-[15px] cursor-pointer',
            )}>
            {suggestion}
          </div>
        ))}
      </div>
    </>
  );
};

SuggestionsInput.propTypes = {
  amount: PropTypes.any,
  min: PropTypes.string,
  max: PropTypes.string,
  defaultStep: PropTypes.string,
  errors: PropTypes.object,
  control: PropTypes.any,
  setValue: PropTypes.func,
  suggestions: PropTypes.array,
  fields: PropTypes.array,
};

export default SuggestionsInput;
