import {Endpoints} from '@api';
import {queryClient} from '@components/ReactQueryProvider';
import queryKeys from '@constants/queryKeys';
import {returningAsyncAction} from '@store/helpers/asyncAction';
import {types} from 'mobx-state-tree';
import {apolloClient, FAVORITE_GAMES} from '@graphql';

export const GamesState = {
  favoriteGames: [],
};

function getFavoriteGames() {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.query({
        query: FAVORITE_GAMES.LIST,
        context: {
          useAuth: true,
          uri: Endpoints.FAVORITE_GAMES,
        },
      });

      if (data) {
        store.setFavoriteGames(data?.list);
      }
    } catch (error) {
      console.error('GET_FAVORITE_GAMES_ERROR', error);
    }
  };
}

function addGameToFavorite(game_id) {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.mutate({
        mutation: FAVORITE_GAMES.ADD,
        variables: {game_id: game_id},
        context: {
          useAuth: true,
          uri: Endpoints.FAVORITE_GAMES,
        },
      });
      if (data) {
        store.setFavoriteGames(data?.add);
        queryClient.invalidateQueries(queryKeys.FAVORITE_GAMES_LIST);
      }
    } catch (error) {
      console.error('ADD_FAVORITE_GAME_ERROR', error);
    }
  };
}

function removeGameFromFavorite(game_id) {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.mutate({
        mutation: FAVORITE_GAMES.REMOVE,
        variables: {game_id: game_id},
        context: {
          useAuth: true,
          uri: Endpoints.FAVORITE_GAMES,
        },
      });
      if (data) {
        store.setFavoriteGames(data?.remove);
        queryClient.invalidateQueries(queryKeys.FAVORITE_GAMES_LIST);
      }
    } catch (error) {
      console.error('REMOVE_FAVORITE_GAME_ERROR', error);
    }
  };
}

const Games = types
  .model({
    favoriteGames: types.array(types.number),

    getFavoriteGames: returningAsyncAction(getFavoriteGames),
    addGameToFavorite: returningAsyncAction(addGameToFavorite),
    removeGameFromFavorite: returningAsyncAction(removeGameFromFavorite),
  })
  .views((self) => ({
    checkIsFavorite: (gameId) => {
      if (gameId) {
        return self.favoriteGames?.includes(parseInt(gameId));
      }
    },
  }))
  .actions((store) => ({
    clearFavoriteGames() {
      store.favoriteGames = GamesState.favoriteGames;
    },
    setFavoriteGames(games) {
      store.favoriteGames = games;
    },
    toggleFavoriteGame(gameId) {
      if (store.checkIsFavorite(gameId)) {
        if (!store?.removeGameFromFavorite?.inProgress) {
          store?.removeGameFromFavorite.run(gameId);
        }
      } else {
        if (!store?.addGameToFavorite?.inProgress) {
          store?.addGameToFavorite.run(gameId);
        }
      }
    },
  }));

export default Games;
