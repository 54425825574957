import analyticsEvents from '@constants/analyticsEvents';
import AnalyticsService from '@services/AnalyticsService';
import React, {memo, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const CustomLink = ({children, to, disableAnalytics = false, ...props}) => {
  const [isEternal, setIsEternal] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    let parser = document.createElement('a');
    parser.href = to;
    setLocation(parser);
  }, [to]);

  useEffect(() => {
    if (location !== null) {
      setIsEternal(window.location.host === location.host);
    }
  }, [location]);

  return (
    <>
      {isEternal ? (
        <Link
          to={to}
          onClick={() => {
            !disableAnalytics &&
              AnalyticsService.logEvent(analyticsEvents.LINK_CLICK, {
                url: to,
              });
            props?.onClick && props.onClick();
          }}
          {...props}>
          {children}
        </Link>
      ) : (
        <a
          href={to}
          //target={`${checkIsServiceLink() ? '_self' : '_blank'}`}
          rel="noopener noreferrer"
          onClick={() => {
            !disableAnalytics &&
              AnalyticsService.logEvent(analyticsEvents.LINK_CLICK, {
                url: to,
              });
            props?.onClick && props.onClick();
          }}
          {...props}>
          {children}
        </a>
      )}
    </>
  );
};

export default memo(CustomLink);
