import buildRoute from '@navigation/buildRoute';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from 'react-custom-checkbox';
import * as Icon from 'react-icons/fa';

const CheckBox = ({
  label = '',
  className,
  isError = false,
  errorMessage = '',
  borderRadius = 3,
  size = 18,
  iconSize = 10,
  iconColor = '#F42147',
  linkText = '',
  linkUrl = '#',
  onChange,
  value,
  ...rest
}) => {
  return (
    <>
      <div className="flex mt-[24px] items-center">
        <Checkbox
          type="checkbox"
          className={classNames(
            {
              'border-hawkesBlue': !isError,
              'border-redRibbon': isError,
            },
            className,
            'bg-red',
          )}
          icon={<Icon.FaCheck color={iconColor} size={iconSize} />}
          checked={value}
          labelClassName="ml-[8px] text-resolutionBlue text-[14px] leading-[16px]"
          borderColor={isError ? '#F42147' : '#4E73B7'}
          {...{size, borderRadius, onChange}}
          {...rest}
        />
        {linkText && (
          <div className="pl-[10px]">
            <a
              href={buildRoute(linkUrl)}
              target="_blank"
              className="ml-[5px] cursor-pointer underline text-resolutionBlue text-[14px] leading-[16px]">
              {linkText}
            </a>
          </div>
        )}
      </div>

      {isError && (
        <span
          className={classNames(
            {block: isError, hidden: !isError},
            'text-redRibbon text-[12px] mt-[8px] mb-[16px]',
          )}>
          {errorMessage}
        </span>
      )}
    </>
  );
};

CheckBox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  rest: PropTypes.any,
  size: PropTypes.number,
  borderRadius: PropTypes.number,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default CheckBox;
