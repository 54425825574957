import BannerWrapper from '@components/Banner/BannerWrapper';
import buildRoute from '@navigation/buildRoute';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router';

const Banner = ({
  image,
  gradient = 'bg-gradient-b-slideSm md:bg-gradient-l-slideMd',
  gradientShort = 'bg-gradient-b-slideSm',
  height,
  rounded,
  children,
  hasBlackout = false,
  hasWrapper = true,
  to,
  isSwiping,
  isShort,
}) => {
  const history = useHistory();

  // TODO: add redirect url for SEO
  const clickHandler = () => {
    window.location.href =
      'https://vltwin.org/jwnreg-ca/J336710495?subid=head-banner';
  };

  return (
    <BannerWrapper {...{hasBlackout, hasWrapper, height, rounded}}>
      <div
        className={classNames({'cursor-pointer': to}, 'flex w-[100%] h-[100%]')}
        onClick={() => (to ? clickHandler() : null)}>
        <div
          style={{
            backgroundImage: `url("${image}")`,
          }}
          className={classNames('bg-cover bg-no-repeat bg-center', {
            'w-[100%] h-[70%]': isShort,
            'w-[100%] h-[70%] md:h-[100%] md:w-[75%]': !isShort,
          })}>
          <div
            className={classNames(
              'absolute top-0 right-0 bottom-0 left-0 z-10',
              {[gradient]: !isShort, [gradientShort]: isShort},
            )}>
            {children}
          </div>
        </div>
      </div>
    </BannerWrapper>
  );
};

Banner.propTypes = {
  image: PropTypes.string.isRequired,
  gradient: PropTypes.string,
  gradientShort: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasWrapper: PropTypes.bool,
  hasBlackout: PropTypes.bool,
  to: PropTypes.string,
  isSwiping: PropTypes.bool,
  isShort: PropTypes.bool,
  height: PropTypes.string,
  rounded: PropTypes.string,
};

export default Banner;
