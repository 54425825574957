import useOfferIcon from '@hooks/useOfferIcon';
import PropTypes from 'prop-types';

const OfferCardBaseIcon = ({data, className = 'w-[70%] max-w-[192px]'}) => {
  return (
    <div className="flex justify-center mt-2 sm:mt-4">
      <img {...{className, src: useOfferIcon(data)}} alt="" />
    </div>
  );
};

OfferCardBaseIcon.propTypes = {
  data: PropTypes.any,
};

export default OfferCardBaseIcon;
