import ModalWrapper from '@components/Modals/ModalWrapper';
import useModalRegister from '@hooks/useModalRegister';
import useWindowSize from '@hooks/useWindowSize';
import modalRoutes from '@navigation/modalRoutes';
import classNames from 'classnames';
import React, {useEffect, useMemo, useState} from 'react';
import InboxMessageItem from '@components/InboxMessages/InboxMessageItem';
import InboxMessageBuilder from '@components/InboxMessages/InboxMessageBuilder';
import InboxMessageItemSkeleton from '@components/InboxMessages/InboxMessageItemSkeleton';
import Spinner from '@components/Spinner/Spinner';
import {useQuery} from '@apollo/client';
import {INBOX_MESSAGES} from '@graphql';
import {Endpoints} from '@api';
import InfiniteScroll from 'react-infinite-scroll-component';
import empty_inbox_icon from '@images/icons/empty-inbox-icon.svg';
import {locale} from '@res/strings/locale';

const MessagesListModal = ({handleCloseModal}) => {
  useModalRegister(modalRoutes.MESSAGES, {
    handleCloseModal,
  });
  const size = useWindowSize();
  const useDesktopView = useMemo(() => size?.width >= 768, [size?.width]);

  const {
    loading,
    data: messages,
    refetch,
    fetchMore,
  } = useQuery(INBOX_MESSAGES.LIST, {
    variables: {
      offset: 0,
      limit: 1000,
    },
    context: {
      useAuth: true,
      uri: Endpoints.INBOX_MESSAGES,
    },
  });
  const [activeMessageUuid, setActiveMessageUuid] = useState(null);

  useEffect(() => {
    if (activeMessageUuid) {
      refetch();
    }
  }, [activeMessageUuid]);

  return (
    <ModalWrapper
      {...{handleCloseModal}}
      desktopStyle={
        useDesktopView
          ? 'lg:max-w-[1024px] w-full xl:h-[600px] sm:rounded-[8px]'
          : 'sm:w-[360px] sm:h-[600px] sm:rounded-[8px]'
      }>
      <div className="flex flex-col xl:h-[600px] items-center bg-messages-modal">
        <p className="text-h5 w-full text-center py-[12px] text-primary border-b border-message-item-border">
          {locale?.INBOX_MESSAGES?.title}
        </p>
        <div className="flex flex-row w-full xl:h-[546px]">
          {!messages?.list?.length ? (
            <div className="w-full h-[calc(100vh-55px)] xl:h-auto bg-active-message-modal">
              {loading ? (
                <Spinner />
              ) : (
                <div className="flex flex-col items-center justify-center h-full text-center lg:px-[40px] max-w-[358px] mx-auto">
                  <img src={empty_inbox_icon} alt="empty_inbox_icon" />
                  <p className="text-primary text-body-primary leading-body-primary mt-[30px]">
                    {locale?.INBOX_MESSAGES?.no_messages}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <>
              <div
                className={classNames(
                  {'max-w-[330px]': useDesktopView},
                  'w-full h-[calc(100vh-55px)] xl:h-auto flex-col overflow-y-scroll no-scrollbar',
                )}>
                {loading ? (
                  [...Array(10)]?.map((_, index) => (
                    <InboxMessageItemSkeleton
                      key={index}
                      isActive={index === 0}
                    />
                  ))
                ) : (
                  <InfiniteScroll
                    loader={null}
                    hasChildren
                    scrollThreshold={0.55}
                    className="w-full"
                    style={{overflow: 'hidden'}}
                    dataLength={messages?.list?.length || 0}
                    next={fetchMore({
                      variables: {
                        offset: messages?.list?.length,
                      },
                    })}
                    hasMore={true}>
                    {messages?.list.map((message) => (
                      <InboxMessageItem
                        key={message?.uuid}
                        {...{
                          message,
                          useDesktopView,
                          setActiveMessageUuid,
                          activeMessageUuid,
                        }}
                      />
                    ))}
                  </InfiniteScroll>
                )}
              </div>
              <div
                className={classNames(
                  {
                    hidden: !useDesktopView,
                    'overflow-y-scroll no-scrollbar': useDesktopView,
                  },
                  'max-w-[695px] w-full bg-active-message-modal',
                )}>
                <InboxMessageBuilder uuid={activeMessageUuid} />
              </div>
            </>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default MessagesListModal;
