import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {default as Otp} from 'react-otp-input';

const OtpInput = ({
  isError = false,
  errorMessage = '',
  onChange,
  value,
  ...rest
}) => {
  return (
    <div className="relative flex flex-col items-center">
      <Otp
        className="otp-input ml-[8px] text-resolutionBlue text-[26px]"
        hasErrored={isError}
        numInputs={6}
        type="number"
        isInputNum
        {...{value, onChange}}
        inputStyle={{
          border: 'none',
          borderRadius: 0,
          borderBottom: '2px solid #E3EBF9',
          width: '32px',
        }}
        focusStyle={{
          outline: 'none',
          borderBottom: '2px solid #002F88',
        }}
        errorStyle={{
          borderBottom: '2px solid #F42147',
          color: '#F42147',
        }}
        {...rest}
      />
      {isError && !rest.disabled && (
        <span
          className={classNames(
            'text-redRibbon text-[12px] mt-[8px] text-center',
          )}>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

OtpInput.propTypes = {
  rest: PropTypes.any,
  isError: PropTypes.object,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default OtpInput;
