import Button from '@components/Button';
import useModalControls from '@hooks/useModalControls';
import empty_history_icon from '@images/icons/empty-history-icon.svg';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import React from 'react';

const NoTransactionsMessage = () => {
  const {openModal} = useModalControls();

  return (
    <div className="flex flex-col justify-center items-center h-[100%] mt-[13px]">
      <img src={empty_history_icon} alt={locale?.emtyHistoryIcon} />
      <p className="text-[14px] leading-[21px] text-resolutionBlue mt-[8px]">
        {locale?.noTransactions}
      </p>
      <Button
        className="uppercase h-[40px] w-[196px] mt-[16px]"
        onClick={() => openModal(modalRoutes.CASH_BOX)}
        title={locale?.makeDeposit}
        type="primary"
      />
    </div>
  );
};

NoTransactionsMessage.propTypes = {};

export default NoTransactionsMessage;
