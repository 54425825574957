import classNames from 'classnames/dedupe';
import {locale} from '@res/strings/locale';
import React from 'react';
import PropTypes from 'prop-types';

const formatValue = (value) => (value < 10 ? `0${value}` : value);

const CountdownRenderer = ({
  isDefaultTextSize,
  classNamesCountdown,
  classNamesLabel,
  label,
  date,
}) => {
  return (
    <div
      className={classNames(
        {'text-[14px] xl:text-[16px]': isDefaultTextSize},
        'inline-block pl-2 text-white font-bold leading-[20px] align-top',
        classNamesCountdown,
      )}>
      {label && <span className={classNames(classNamesLabel)}> {label} </span>}
      {`${formatValue(date?.days)}${locale?.dayAbbr}:${formatValue(
        date?.hours,
      )}:${formatValue(date?.minutes)}:${formatValue(date?.seconds)}`}
    </div>
  );
};

CountdownRenderer.propTypes = {
  classNamesCountdown: PropTypes.string,
  classNamesLabel: PropTypes.string,
  isDefaultTextSize: PropTypes.bool,
  label: PropTypes.string,
  date: PropTypes.any,
};

export default CountdownRenderer;
