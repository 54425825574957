import {locale} from '@res/strings/locale';
import OfferInfoAction from '@components/Modals/OfferInfoModal/OfferInfoAction';
import formatAmount from '@utils/formatAmount';
import PropTypes from 'prop-types';
import React from 'react';

const OfferInfoGiftSpinOffer = ({user, offers, data, game}) => {
  return (
    <>
      <div>
        <p className="mt-0 mb-[10px] pr-[34px] text-resolutionBlue text-[20px] leading-[22px] font-bold">
          {locale?.OFFER_INFO?.title}
        </p>
        <p className="mt-0 mb-[10px] text-resolutionBlue text-[14px] leading-[20px] font-bold">
          {locale?.OFFER_INFO?.giftSpinOfferRulesTitle
            ?.replace('{spins_count}', data?.spins_count)
            .replace('{game_title}', game?.title)}
        </p>
        <ol className="list-decimal pl-5 text-resolutionBlue text-[14px] leading-[20px]">
          <li className="mb-2">{locale?.OFFER_INFO?.emailRule}</li>
          <li className="mb-2">{locale?.OFFER_INFO?.cashBoxRule}</li>
          <li className="mb-2">
            {`${locale?.OFFER_INFO?.depositFrom} `}
            {formatAmount({
              amount: data?.min_deposit_amount / 100 ?? 0,
              currency: user?.profile?.currency,
              minimumFractionDigits: 0,
            })}
          </li>
          <li className="mb-2">
            {locale?.OFFER_INFO?.termsGiftSpin}
            <ul className="list-disc pl-6 mt-2">
              <li className="mb-2">
                {locale?.OFFER_INFO?.payback} <b>x{data?.wager}</b>.
              </li>
              <li className="mb-2">
                {`${locale?.OFFER_INFO?.spinBet} `}
                <b>
                  {formatAmount({
                    amount: data?.bet_size / 100 ?? 0,
                    currency: user?.profile?.currency,
                    minimumFractionDigits: 0,
                  })}
                </b>
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <OfferInfoAction {...{offers, id: data?.id}} />
    </>
  );
};

OfferInfoGiftSpinOffer.propTypes = {
  user: PropTypes.any,
  offers: PropTypes.any,
  data: PropTypes.any,
  game: PropTypes.any,
};

export default OfferInfoGiftSpinOffer;
