import {useEffect, useState} from 'react';

const useElementOffset = (ref) => {
  const [offset, setOffset] = useState({
    offsetTop: null,
    offsetLeft: null,
  });

  useEffect(() => {
    const defaultOffsetTop = ref?.current?.offsetTop;
    const defaultOffsetLeft = ref?.current?.offsetLeft;
    const checkOffset = () => {
      setOffset({
        offsetTop: ref?.current?.offsetTop - defaultOffsetTop,
        offsetLeft: ref?.current?.offsetLeft - defaultOffsetLeft,
      });
    };
    window.addEventListener('scroll', checkOffset);
    checkOffset();
    return () => window.removeEventListener('scroll', checkOffset);
  }, [ref]);

  return offset;
};

export default useElementOffset;
