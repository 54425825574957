import useElementOffset from '@hooks/useElementOffset';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useRef} from 'react';

const StickyPageContainer = ({
  children,
  headerHeight = 56,
  isPermanentSticky,
}) => {
  const containerRef = useRef();
  const offset = useElementOffset(containerRef);

  return (
    <div
      ref={containerRef}
      style={{top: `${headerHeight - 2}px`}}
      className={classNames(
        {
          'bg-stratos max-w-full': offset.offsetTop > 0 || isPermanentSticky,
          'xl:max-w-[1296px]': offset.offsetTop <= 0 && !isPermanentSticky,
        },
        'mx-auto sticky z-[49] transition duration-300',
      )}>
      <div className="container mx-auto max-w-full xl:max-w-[1296px]">
        {typeof children === 'function'
          ? children({isSticky: offset.offsetTop > 0})
          : children}
      </div>
    </div>
  );
};

StickyPageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isPermanentSticky: PropTypes.bool,
  top: PropTypes.number,
};

export default StickyPageContainer;
