import {apiCall} from '@api';
import {useQuery} from 'react-query';

const fetcher = (request) => {
  return apiCall(request).then(({data}) => {
    return data;
  });
};

export default function useRequest(request, options) {
  return useQuery(
    [options?.queryKey, JSON.stringify(request)],
    () => fetcher(request),
    options,
  );
}
