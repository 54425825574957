import {locale} from '@res/strings/locale';
import useCurrency from '@hooks/useCurrency';
import formatAmount from '@utils/formatAmount';
import React, {useMemo} from 'react';
import classNames from 'classnames';
import LotteryTicketList from '@components/LotteryPage/LotteryTicketList';
import lotteryConfig from '@constants/lotteryConfig';

const LotteryResultsMoneyList = ({data}) => {
  const currency = useCurrency();
  const winnersCount = useMemo(
    () => lotteryConfig?.WINNERS_COUNT(data),
    [data],
  );
  const mapData = useMemo(
    () =>
      data?.map((item, key) => {
        const isShort =
          winnersCount[key] < 4 &&
          ((key === 0 && winnersCount[key + 1] < 4) ||
            (key !== 0 && key % 2 === 0 && winnersCount[key + 1] < 4) ||
            (key !== 0 && key % 2 !== 0 && winnersCount[key - 1] < 4));

        return (
          <div
            className={classNames('w-full', {
              'xl:w-[calc(50%-16px)]': isShort,
            })}
            {...{key}}>
            <div className="bg-secondary">
              <div className="w-full py-4 px-3 sm:t-6 sm:px-2 sm:pb-2 flex flex-col items-center justify-center">
                <div className="flex text-primary text-[21px] leading-[32px] font-semibold">
                  {formatAmount({
                    amount: item?.prize?.[currency] ?? 0,
                    currency,
                    minimumFractionDigits: 0,
                  })}
                </div>
                <div className="w-full flex justify-center items-center flex-col">
                  <LotteryTicketList {...{data: item?.winners, isShort}} />
                </div>
              </div>
            </div>
          </div>
        );
      }),
    [data],
  );

  return (
    <>
      {!!data?.length && (
        <>
          <h2 className="mb-6 mt-6 sm:mt-8 text-[24px] text-primary font-bold leading-[32px]">
            {locale?.LOTTERY_PARAMS?.moneyPrizes}
          </h2>
          <div className="w-full flex flex-wrap gap-[32px]">{mapData}</div>
        </>
      )}
    </>
  );
};

export default LotteryResultsMoneyList;
