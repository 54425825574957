import useRequest from '@hooks/useRequest';
import Endpoints from '@api/endpoints';
import queryKeys from '@constants/queryKeys';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import TicketsSkeleton from '@components/Profile/Tickets/TicketsSkeleton';
import TicketsAction from '@components/Profile/Tickets/TicketsAction';
import LotteryTicketInfoList from '@components/LotteryPage/LotteryTicketInfoList';
import TicketsTitle from '@components/Profile/Tickets/TicketsTitle';
import TicketsList from '@components/Profile/Tickets/TicketsList';
import PageContainer from '@components/PageContent/PageContainer';
import React from 'react';
import {Link} from 'react-router-dom';

const Tickets = ({data}) => {
  const {data: lottery, isLoading} = useRequest(
    {
      endpoint: buildRoute(
        Endpoints?.LOTTERY_ID,
        {id: data?.lottery?.id},
        true,
      ),
      useAuth: true,
      useLocale: true,
    },
    {
      retry: 1,
      queryKey: queryKeys.LOTTERY_ID,
    },
  );

  return (
    <>
      {isLoading ? (
        <TicketsSkeleton />
      ) : (
        <>
          <PageContainer>
            <TicketsTitle />
            <p className="m-0 mb-2 text-tertiary text-base leading-base text-center">
              {lottery?.rules?.tickets?.info1}
              <Link
                to={buildRoute(navigationRoutes.LOTTERY_RULES, {
                  slug: lottery?.slug,
                })}
                className="text-denim underline">
                {lottery?.title}
              </Link>
              {lottery?.rules?.tickets?.info2}
            </p>
          </PageContainer>
          <TicketsList {...{data: data?.tickets}} />
          <PageContainer>
            <div className="p-4 md:p-6 bg-white rounded-[10px]">
              <div className="mb-4 px-2 sm:px-0 bg-badge-secondary-empty rounded-[5px]">
                <LotteryTicketInfoList
                  {...{data: data?.lottery?.data, theme: 'light'}}
                />
              </div>
              <p className="m-0 mb-4 text-tertiary text-base leading-base text-center">
                {lottery?.rules?.rules?.note}
              </p>
              <TicketsAction />
            </div>
          </PageContainer>
        </>
      )}
    </>
  );
};

export default Tickets;
