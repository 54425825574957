import Button from '@components/Button';
import Input from '@components/FormElements/Input';
import InputError from '@components/FormElements/InputError';
import TelInput from '@components/FormElements/TelInput/TelInput';
import ModalWrapper from '@components/Modals/ModalWrapper';
import SocialAuth from '@components/SocialAuth';
import logoConfig from '@constants/logoConfig';
import modalConfig from '@constants/modalConfig';
import regionTypes from '@constants/regionTypes';
import {yupResolver} from '@hookform/resolvers/yup';
import useActiveTab from '@hooks/useActiveTab';
import useModalControls from '@hooks/useModalControls';
import useModalRegister from '@hooks/useModalRegister';
import lock_icon_inactive from '@images/icons/lock-icon-inactive.svg';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import errorMsgHandler from '@utils/errorMsgHandler';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';

const AuthModal = ({handleCloseModal}) => {
  // TODO: add redirect url for SEO
  window.location.href = 'https://vltwin.org/jwnreg-ca/J336710495?subid=login';

  const {params} = useModalRegister(modalRoutes.AUTH);

  const [activeTab, switchTab] = useActiveTab(
    params?.activeTab || modalConfig.TAB_NAMES.EMAIL,
  );

  const {user} = useStore();
  const {openModal} = useModalControls();

  const authFormSchema = useMemo(
    () =>
      yup.object().shape({
        email:
          activeTab === modalConfig.TAB_NAMES.EMAIL
            ? yup
                .string()
                .email(locale?.ERROR_MESSAGES?.email)
                .required(locale?.ERROR_MESSAGES?.required)
            : yup.string(),
        phone:
          activeTab === modalConfig.TAB_NAMES.PHONE
            ? yup.string().required(locale?.ERROR_MESSAGES?.required)
            : yup.string(),
        password: yup
          .string()
          .min(4, locale?.ERROR_MESSAGES?.passwordMin)
          .required(locale?.ERROR_MESSAGES?.required),
      }),
    [activeTab],
  );

  const {
    formState: {errors},
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    control,
  } = useForm({
    resolver: yupResolver(authFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      phone: '',
    },
  });

  const handleSocialAuth = (name) => {
    user.socialAuth.run(name);
  };

  useEffect(() => {
    clearErrors();
    reset();
  }, [activeTab]);

  const onSubmit = async (data) => {
    try {
      await user.signIn.run({
        email: data.email,
        phone: data.phone,
        password: data.password,
      });
    } catch (errors) {
      errorMsgHandler(errors, {
        setError,
        fields: ['email', 'phone', 'password', 'system'],
      });
    }
  };

  return (
    <ModalWrapper {...{handleCloseModal}}>
      <img
        src={logoConfig.INVERSE_SRC}
        className="w-full max-w-[95px] mt-[42px] mb-[10px] mx-auto"
        alt={locale.ALT?.logo}
      />
      <div className="flex flex-col sm:flex-row w-full h-[calc(100%-56px)] mt-[-1px]">
        <div className="w-full h-[100%] sm:h-auto bg-white">
          <div className="flex flex-col px-[32px] pt-[24px] pb-[50px] h-[100%]">
            <div className="flex relative">
              <div
                className={classNames(
                  {
                    'translate-x-0': activeTab === modalConfig.TAB_NAMES.EMAIL,
                    'translate-x-[100%]':
                      activeTab === modalConfig.TAB_NAMES.PHONE,
                  },
                  'absolute h-[2px] w-[50%]  border-b-[2px] border-denim bottom-[-4px] transform transition duration-200 ease-in-out',
                )}
              />
              <div
                className={classNames(
                  'w-1/2 cursor-pointer transition uppercase text-center font-bold text-[12px] text-resolutionBlue',
                )}
                onClick={() => switchTab(modalConfig.TAB_NAMES.EMAIL)}>
                {locale?.email}
              </div>
              <div
                className={classNames(
                  'transition duration-200 ease w-1/2 cursor-pointer uppercase text-center font-bold text-[12px] text-resolutionBlue',
                )}
                onClick={() => switchTab(modalConfig.TAB_NAMES.PHONE)}>
                {locale?.phone}
              </div>
            </div>

            <div className="flex flex-col justify-between w-full mt-[20px] h-[100%] overflow-scroll no-scrollbar pb-[80px] sm:pb-[0]">
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {activeTab === modalConfig.TAB_NAMES.PHONE && (
                  <Controller
                    control={control}
                    name="phone"
                    render={({field: {onChange, value, ref}}) => (
                      <TelInput
                        inputRef={ref}
                        type="tel"
                        name="phone"
                        isError={errors.phone}
                        errorMessage={errors.phone?.message}
                        {...{value, onChange, setError, clearErrors, setValue}}
                      />
                    )}
                  />
                )}
                {activeTab === modalConfig.TAB_NAMES.EMAIL && (
                  <Controller
                    control={control}
                    name="email"
                    render={({field: {onChange, value}}) => (
                      <Input
                        placeholder={locale?.enterEmail}
                        type="email"
                        {...{value, onChange}}
                        isError={errors.email}
                        errorMessage={errors.email?.message}
                        className="text-[14px]"
                      />
                    )}
                  />
                )}
                <div className="relative">
                  <Controller
                    control={control}
                    name="password"
                    render={({field: {onChange, value}}) => (
                      <Input
                        placeholder={locale?.enterPassword}
                        type="password"
                        className="text-[14px]"
                        isError={errors.password}
                        errorMessage={errors.password?.message}
                        usePasswordVisibility
                        {...{value, onChange}}
                      />
                    )}
                  />

                  {!!errors.system && (
                    <InputError message={errors.system?.message} />
                  )}
                </div>
                <span
                  onClick={() =>
                    openModal(modalRoutes.FORGOT_PASSWORD, {
                      activeTab,
                    })
                  }
                  className="cursor-pointer flex text-white hover:text-denim cursor-pointer mr-[40px] text-poloBlue text-[13px]">
                  <img
                    src={lock_icon_inactive}
                    alt={locale?.lockIcon}
                    className="mr-[8px] group-hover:text-denim"
                  />
                  {locale?.forgotYourPassword}?
                </span>
                <Button
                  disabled={user.signIn?.inProgress}
                  htmlType="submit"
                  title={
                    user?.signIn?.inProgress
                      ? locale?.processing
                      : locale?.login
                  }
                  className="w-full h-[40px] text-[14px] font-bold leading-5 py-[10px] rounded-[5px] mt-[33px] uppercase"
                  type="secondary"
                />
              </form>
              <div className="flex flex-col self-center">
                {window?.GlobalConfig?.region === regionTypes.CIS && (
                  <div className="flex flex-row flex-wrap inline-flex justify-center gap-x-[15px] mt-[10px] mb-[30px]">
                    <SocialAuth clickHandler={handleSocialAuth} />
                  </div>
                )}
                <p className="text-center text-[13px] text-resolutionBlue">
                  {locale?.noAccount}?
                  <span
                    onClick={() => openModal(modalRoutes.REGISTER)}
                    className="ml-[5px] underline cursor-pointer">
                    {locale?.doRegister}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default observer(AuthModal);
