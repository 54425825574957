import {useMemo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import lotteryConfig from '@constants/lotteryConfig';

const LotteryTicketList = ({data, isShort, isItemPrize}) => {
  const mapData = useMemo(() => {
    return data?.map((item, key) => {
      return (
        <div
          className={classNames('max-w-full w-[280px] p-2 sm:p-3', {
            'sm:w-full lg:w-full xl:w-full': data?.length === 1 && isItemPrize,
            'sm:w-1/2 lg:w-1/3 xl:w-1/3':
              data?.length < 4 && data?.length !== 1 && isItemPrize,
            'sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/3':
              data?.length < 4 && data?.length !== 1 && isShort,
            'sm:w-1/2 lg:w-1/3 xl:w-1/4': data?.length === 4 && isItemPrize,
            'sm:w-1/2 lg:w-1/3 xl:w-1/5': data?.length > 4 && isItemPrize,
            'sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5': !isItemPrize && !isShort,
          })}
          {...{key}}>
          <div
            className={classNames(
              'flex-row justify-start items-center p-4 box-border bg-lottery-ticket border-[1px] border-sanMarino rounded-[5px] flex',
              {
                'sm:pr-2 md:pr-4 lg:flex-col lg:justify-center lg:items-center':
                  isItemPrize,
                'xl:flex-col xl:justify-center xl:items-center': isShort,
                'flex-row justify-start items-center': !isItemPrize && !isShort,
              },
            )}>
            <div className="w-[33px]">
              <img
                className=""
                src={lotteryConfig?.TICKET_ICONS?.[item?.ticket_group]}
                alt={''}
              />
            </div>
            <div
              className={classNames('w-[calc(100%-33px)] flex flex-col', {
                'pl-4 sm:pl-2 lg:pl-0 justify-center lg:w-full lg:mt-4 lg:text-center':
                  isItemPrize,
                'pl-4 xl:pl-0 justify-start w-[calc(100%-33px)] xl:justify-center xl:w-full xl:mt-4 xl:text-center':
                  isShort,
                'pl-4 justify-start w-[calc(100%-33px)]':
                  !isItemPrize && !isShort,
              })}>
              <div className="mb-1 text-secondary text-[14px] leading-[22px]">
                {item?.public_name}
              </div>
              <div className="text-primary text-[19px] leading-[28px] font-bold">{`№ ${item?.ticket_number}`}</div>
            </div>
          </div>
        </div>
      );
    });
  }, [data]);

  return (
    <div
      className={classNames(
        'w-full flex inline-flex flex-wrap items-center justify-center sm:justify-start relative',
        {
          'lg:justify-center':
            data?.length < 5 && data?.length !== 1 && isItemPrize,
          'lg:justify-center ': data?.length < 5,
          'justify-center': data?.length === 1,
        },
      )}>
      {mapData}
    </div>
  );
};

LotteryTicketList.propTypes = {
  data: PropTypes.any,
  isShort: PropTypes.bool,
  isItemPrize: PropTypes.bool,
};

export default LotteryTicketList;
