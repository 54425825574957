import useWindowSize from '@hooks/useWindowSize';
import {useEffect, useState} from 'react';

const useGamesArraySize = (renderBanner = false, initialItemsCount = 12) => {
  const [itemsCount, setItemsCount] = useState(initialItemsCount);
  const size = useWindowSize();

  useEffect(() => {
    const {width} = size;
    if (width >= 1296) {
      setItemsCount(renderBanner ? 8 : 12);
    }
    if (width < 1296 && width >= 1024) {
      setItemsCount(renderBanner ? 6 : 10);
    }
    if (width < 1024) {
      setItemsCount(renderBanner ? 4 : 8);
    }
    if (width < 768) {
      setItemsCount(renderBanner ? 3 : 6);
    }
    if (width < 640) {
      setItemsCount(4);
    }
  }, [size, initialItemsCount, renderBanner]);
  return itemsCount;
};

export default useGamesArraySize;
