import InputError from '@components/FormElements/InputError';
import ReactSelect from '@components/FormElements/ReactSelect';
import {locale} from '@res/strings/locale';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';

const DateOfBirthInput = ({control, setValue, getValues, watch, errors}) => {
  const date = new Date();
  const watchFields = watch();
  const isError = !!errors?.day || !!errors?.month || !!errors?.year;

  const getDaysInMonth = (month) => {
    return new Date(
      watchFields?.year || date.getFullYear(),
      month,
      0,
    ).getDate();
  };

  const getDeyOptions = () => {
    const days = getDaysInMonth(watchFields?.month || date.getMonth() + 1);
    const options = [];

    if (days) {
      for (let i = 0; i < +days; i++) {
        options.push({value: i + 1, label: i + 1});
      }
    }

    return options;
  };

  const getMonthOptions = () => {
    const month = locale?.MONTH;
    const options = [];
    if (month) {
      month?.map((item, index) => {
        options.push({value: index + 1, label: item});
      });
    }

    return options;
  };

  const getYearOptions = () => {
    const startYear = date.getFullYear() - 18;
    const options = [{value: startYear, label: startYear}];
    for (let i = 0; i < 100; i++) {
      const year = startYear - (i + 1);
      options.push({value: year, label: year});
    }
    return options;
  };

  const renderErrors = () => {
    if (
      errors?.day?.type === 'required' ||
      errors?.month?.type === 'required' ||
      errors?.month?.type === 'required'
    ) {
      return (
        <InputError
          message={
            errors?.day?.message ||
            errors?.month?.message ||
            errors?.year?.message
          }
        />
      );
    } else {
      if (errors?.day) {
        return <InputError message={errors?.day?.message} />;
      }
      if (errors?.month) {
        return <InputError message={errors?.month?.message} />;
      }
      if (errors?.year) {
        return <InputError message={errors?.year?.message} />;
      }
    }
  };

  return (
    <>
      <div className="bg-hawkesBlue flex  rounded-[5px] mt-[10px]">
        <Controller
          control={control}
          name="day"
          render={({field: {onChange, value, ref}}) => (
            <ReactSelect
              className="w-[30%]"
              inputRef={ref}
              placeholder={locale?.day}
              options={getDeyOptions()}
              value={getDeyOptions().find((c) => c.value === value)}
              onChange={(val) => onChange(val.value)}
              error={errors?.day}
              isSingleView={false}
            />
          )}
        />
        <div className="w-[45%] relative">
          <Controller
            control={control}
            name="month"
            render={({field: {onChange, value, ref}}) => (
              <ReactSelect
                inputRef={ref}
                className="capitalize border-l-0 border-r-0"
                placeholder={locale?.month}
                {...{setValue, getValues}}
                options={getMonthOptions()}
                value={getMonthOptions().find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                error={errors?.month}
                isSingleView={false}
              />
            )}
          />
          <div className="absolute left-0 top-[10px] h-[20px] w-[1px] bg-sanMarino33" />
          <div className="absolute right-0 top-[10px] h-[20px] w-[1px] bg-sanMarino33" />
        </div>
        <Controller
          control={control}
          name="year"
          render={({field: {onChange, value, ref}}) => (
            <ReactSelect
              className="w-[35%]"
              inputRef={ref}
              placeholder={locale?.year}
              options={getYearOptions()}
              value={getYearOptions().find((c) => c.value === value)}
              onChange={(val) => onChange(val.value)}
              error={errors?.year}
              isSingleView={false}
            />
          )}
        />
      </div>
      {isError && renderErrors()}
    </>
  );
};

DateOfBirthInput.propTypes = {
  control: PropTypes.any.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func,
  errors: PropTypes.object,
};

export default DateOfBirthInput;
