import CashBoxOfferItemParam from '@components/Offer/CashBox/CashBoxOfferItemParam';
import offerConstants from '@constants/offerConstants';
import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import PropTypes from 'prop-types';

const CashBoxOfferItemSwitchDescription = ({data, user, offers}) => {
  // TODO: add offer scheme
  const getMinDeposit = () => {
    return data?.min_deposit_amount ?? data?.bonus?.min_deposit;
  };

  const getWager = () => {
    let result = (data?.wager ?? data?.bonus?.wager) || 0;

    if (data?.type === offerConstants?.TYPE?.CASHBACK) {
      result = offers?.availableCashback?.wager;
    }

    return result;
  };

  return (
    <>
      <CashBoxOfferItemParam
        {...{
          label: locale?.OFFER?.depositFrom,
          value: formatAmount({
            amount: getMinDeposit() / 100,
            currency: user?.profile?.currency,
          }),
        }}
      />
      <CashBoxOfferItemParam
        {...{
          label: locale?.OFFER?.wager,
          value: `x${getWager()}`,
        }}
      />
    </>
  );
};

CashBoxOfferItemSwitchDescription.propTypes = {
  data: PropTypes.any.isRequired,
  user: PropTypes.any,
  offers: PropTypes.any,
};

export default CashBoxOfferItemSwitchDescription;
