import lotteryConfig from '@constants/lotteryConfig';
import useNotFoundPage from '@hooks/useNotFoundPage';
import useRequest from '@hooks/useRequest';
import getDevice from '@utils/getDevice';
import {useParams} from 'react-router';
import React, {useMemo} from 'react';
import buildRoute from '@navigation/buildRoute';
import Endpoints from '@api/endpoints';
import queryKeys from '@constants/queryKeys';
import NotFoundPage from '@pages/NotFoundPage';
import Lottery from '@components/LotteryPage/Lottery';
import PageContainer from '@components/PageContent/PageContainer';
import TournamentPageSkeleton from '@components/TournamentPage/TournamentPageSkeleton';
import Meta from '@components/Meta';

const LotteryPage = ({headerHeight}) => {
  const {route, slug} = useParams();

  const {data, isLoading, error} = useRequest(
    {
      endpoint: buildRoute(Endpoints.LOTTERY, {slug}, true),
      useAuth: true,
      useLocale: true,
      params: {device: getDevice()},
    },
    {
      retry: 1,
      queryKey: queryKeys.LOTTERY,
    },
  );

  const meta = useMemo(() => {
    switch (route) {
      case lotteryConfig?.PRIZES:
        return data?.meta_prizes;
      case lotteryConfig?.RESULTS:
        return data?.meta_results;
      default:
        return data?.meta_rules;
    }
  }, [data, route]);

  if (useNotFoundPage(error)) {
    return <NotFoundPage />;
  }

  return (
    <>
      {isLoading ? (
        <PageContainer>
          <TournamentPageSkeleton {...{route}} />
        </PageContainer>
      ) : (
        <>
          {data && (
            <>
              <Meta {...meta} />
              <Lottery
                {...{
                  data,
                  route,
                  slug,
                  headerHeight,
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default LotteryPage;
