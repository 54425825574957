import PropTypes from 'prop-types';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const BannerPromoTitle = ({title}) => {
  return (
    <div>
      <ResponsiveEllipsis
        {...{
          maxLine: 2,
          ellipsis: '...',
          text: title,
          className:
            'mb-1 md:px-4 text-[21px] leading-[32px] text-primary font-semibold',
        }}
      />
    </div>
  );
};

BannerPromoTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BannerPromoTitle;
