import classNames from 'classnames';
import React from 'react';

const RulesParamsItem = ({
  data,
  icon,
  title,
  width = 'w-[calc(50%-4px)] sm:w-[calc(33.333333%-16px)] md:w-[169px] lg:w-[179px] xl:w-[196px]',
  height = 'h-[112px] md:h-[114px]',
}) => {
  return (
    <div
      className={classNames(
        'bg-secondary rounded-[8px] transition duration-100 transform relative overflow-hidden pt-[52px] px-2 text-center items-center animate-fade-in',
        height,
        width,
      )}>
      <div className="flex flex-col items-center">
        <img src={icon} alt={''} className="absolute top-[14px] max-h-[24px]" />
        <div className="mb-[8px] font-bold text-melrose text-[12px] leading-[18px] uppercase">
          {title}
        </div>
        <div className="font-bold text-primary text-[16px] sm:text-[19px] leading-[18px]">
          {data || '-'}
        </div>
      </div>
    </div>
  );
};

export default RulesParamsItem;
