import LocalizationService from '@services/LocalizationService';

const buildPath = (path) => {
  return `${
    LocalizationService.isInternalMultiLang
      ? `/:lang(${LocalizationService.availableLangCodes?.join('|')})`
      : ''
  }${path}`;
};

export default buildPath;
