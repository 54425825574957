import PropTypes from 'prop-types';
import Button from '@components/Button';
import React from 'react';

const BannerAction = ({to, type, title}) => {
  return (
    <div className="inline-block">
      <Button
        {...{
          className:
            'min-w-[180px] px-2 uppercase text-[14px] font-bold leading-[40px]',
          isLink: true,
          type,
          title,
          to,
        }}
      />
    </div>
  );
};

BannerAction.propTypes = {
  to: PropTypes.any,
  type: PropTypes.string,
  title: PropTypes.string,
};

export default BannerAction;
