import PropTypes from 'prop-types';

const Tag = ({children}) => {
  return (
    <p className="text-white px-[10px] py-[3.6px] bg-stratos uppercase rounded-[4px] text-[11px] font-bold font-sans lg:text-[14px]">
      {children}
    </p>
  );
};

Tag.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Tag;
