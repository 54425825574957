import React from 'react';
import PropTypes from 'prop-types';

const ChapterImage = ({url}) => {
  return (
    <div
      className="max-w-[274px] w-full min-h-[170px] bg-no-repeat bg-center bg-cover  sm:max-w-[276px] sm:min-h-[172px]"
      style={{
        backgroundImage: `url('${url}')`,
      }}
    />
  );
};

ChapterImage.propTypes = {
  url: PropTypes.string,
};

export default ChapterImage;
