export default {
  RESET_PASSWORD: '#reset-password',
  RESET_PASSWORD_SUCCESS: '#reset-password-success',
  COMPLETE_REGISTRATION: '#complete-registration',
  AUTH: '#auth',
  FORGOT_PASSWORD: '#forgot-password',
  FORGOT_PASSWORD_EMAIL_SENT: '#forgot-password-email-sent',
  FORGOT_PASSWORD_VERIFY_CODE: '#forgot-password-verify-code',
  REGISTER: '#register',
  CASH_BOX: '#cashbox',
  CASH_BOX_WITHDRAWAL: '#cashbox-withdrawal',
  CASH_BOX_HISTORY: '#cashbox-history',
  OFFER_INFO: '#offer-info',
  REGISTER_SECOND_STEP: '#register-2',
  REGISTER_THIRD_STEP: '#register-3',
  REGISTER_BONUS_STEP: '#register-4',
  WELCOME_BONUS: '#welcome-bonus',
  MESSAGES: '#messages',
  MESSAGE: '#message',
  POP_UP_NOTIFICATION: '#pop-up-notification',
  RESTRICTION_PROVIDERS: '#restriction-providers',
};
