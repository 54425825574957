import useDeviceProps from '@hooks/useDeviceProps';
import classNames from 'classnames';
import React from 'react';
import ModalCloseAction from '@components/Modals/ModalCloseAction';

const ModalWrapper = ({
  children,
  handleCloseModal,
  desktopStyle = 'sm:w-[360px] sm:h-[600px] sm:rounded-[8px]',
  mobileStyle = 'h-screen',
  landScapeStyle = 'sticky w-full h-screen',
  className = 'bg-white',
  style,
}) => {
  const {isMobile, isLandScape} = useDeviceProps();

  return (
    <div
      className={classNames('relative w-screen overflow-hidden', className, {
        [landScapeStyle]: isMobile && isLandScape,
        [mobileStyle]: isMobile && !isLandScape,
        [desktopStyle]: !isMobile,
      })}
      {...{style}}>
      <ModalCloseAction {...{handleCloseModal}} />
      {children}
    </div>
  );
};

export default ModalWrapper;
