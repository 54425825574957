import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import PropTypes from 'prop-types';

const CashBoxOfferItemCashbackTitle = ({data, user}) => {
  return (
    <>
      {`${locale?.OFFER?.bonusIn} `}
      {formatAmount({
        amount: data?.amount / 100,
        currency: user?.profile?.currency,
      })}
    </>
  );
};

CashBoxOfferItemCashbackTitle.propTypes = {
  data: PropTypes.any,
  user: PropTypes.any,
};

export default CashBoxOfferItemCashbackTitle;
