import Endpoints from '@api/endpoints';
import DataLoadingMessage from '@components/CashBox/DataLoadingMessage';
import TransactionMethod from '@components/CashBox/TransactionMethod';
import regionTypes from '@constants/regionTypes';
import useDeviceProps from '@hooks/useDeviceProps';
import useRequest from '@hooks/useRequest';
import back_icon from '@images/icons/back-icon.svg';
import notify_icon from '@images/icons/notify-icon.svg';
import CustomLink from '@navigation/CustomLink';
import {locale} from '@res/strings/locale';
import LocalizationService from '@services/LocalizationService';
import {useStore} from '@store/configureStore';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {BsGift, BsShieldCheck} from 'react-icons/bs';

const Withdrawal = ({isNextStep, resetNextStep, toggleNextStep}) => {
  const {user} = useStore();
  const {isMobile} = useDeviceProps();
  const [paymentMethodUid, setPaymentMethodUid] = useState(null);
  const [isWithdrawalActive, setWithdrawalActive] = useState(null);
  const {data, isLoading} = useRequest({
    endpoint: Endpoints.GET_PAYMENT_METHODS,
    params: {
      transaction_type: 'withdrawal',
      currency: user?.profile?.currency,
      locale: LocalizationService.getLanguage(),
    },
    useAuth: true,
  });

  const isTripleTurnOn = useRequest({
    endpoint: Endpoints.TRIPLE_TURN,
    useAuth: true,
  });

  useEffect(() => {
    if (
      (!user?.profile?.is_doc_verified &&
        window.GlobalConfig?.region !== regionTypes.CIS) ||
      !user?.profile?.is_email_confirmed ||
      user?.profile?.phone_confirmed_by === 'none' ||
      user?.profile?.balance_bonus !== 0 ||
      (isTripleTurnOn?.data?.amount !== 'OK' &&
        isTripleTurnOn?.data?.amount !== 0)
    ) {
      setWithdrawalActive(false);
    } else {
      setWithdrawalActive(true);
    }
  }, [isTripleTurnOn]);

  return (
    <div
      className={classNames(
        {'h-full': isLoading, 'pb-[120px]': isMobile},
        'px-[30px]',
      )}>
      {isNextStep ? (
        <>
          <span className="absolute left-[16px] top-[14px] cursor-pointer z-10">
            <span className="click-area" onClick={() => resetNextStep()}>
              <img src={back_icon} alt={locale.ALT?.closeMenuIcon} />
            </span>
          </span>
          <TransactionMethod
            {...{paymentMethodUid, resetNextStep}}
            methodType="withdrawal"
          />
        </>
      ) : data ? (
        <>
          {isWithdrawalActive ? (
            <>
              {window?.GlobalConfig?.region === regionTypes.CIS && (
                <div className={'mt-[16px] bg-[#EDF3FE] rounded-[8px]'}>
                  <div className={'flex flex-row p-[12px]'}>
                    <img src={notify_icon} className={'mr-[16px]'} />
                    <p className={'text-[12px] leading-[18px] text-tertiary'}>
                      {locale?.rusnyaSosat}
                    </p>
                  </div>
                </div>
              )}

              <p className="text-center text-resolutionBlue text-[15px] mt-[20px] mb-[14px]">
                {locale?.selectWithdrawalMethod}
              </p>
              <div className="mb-[40px] flex flex-wrap justify-center">
                {data?.length &&
                  data?.map((item) => (
                    <div
                      key={item?.uid}
                      onClick={() => {
                        setPaymentMethodUid(item?.uid);
                        toggleNextStep();
                      }}
                      className="m-1 cursor-pointer flex w-[calc(50%-10px)] justify-center items-center h-[84px] rounded-[8px] bg-white border-[1px] border-zumthor">
                      <img
                        src={item?.image}
                        alt={item?.uid}
                        className="max-w-[90%]"
                      />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              {(!user?.profile?.is_doc_verified ||
                !user?.profile?.is_email_confirmed ||
                user?.profile?.phone_confirmed_by === 'none') && (
                <div className="flex flex-col text-center mt-[20px] max-w-[290px] mx-auto">
                  <p className="text-resolutionBlue text-[14px]">
                    <span className="flex items-center justify-center m-auto w-[56px] h-[56px] rounded-full bg-zumthor mb-[10px]">
                      <BsShieldCheck size="30" />
                    </span>
                    {locale?.WITHDRAWAL_ERRORS?.profile?.message}
                    <br />
                    <CustomLink
                      aria-label="click"
                      className="underline ml-1 break-normal"
                      to={locale?.WITHDRAWAL_ERRORS?.profile?.link?.url}>
                      {locale?.WITHDRAWAL_ERRORS?.profile?.link?.text}
                    </CustomLink>
                  </p>
                </div>
              )}
              {user?.profile?.balance_bonus !== 0 && (
                <div className="flex flex-col text-center mt-[20px] max-w-[290px] mx-auto">
                  <p className="text-resolutionBlue text-[14px]">
                    <span className="flex items-center justify-center m-auto w-[56px] h-[56px] rounded-full bg-zumthor mb-[10px]">
                      <BsGift size="30" />
                    </span>
                    {locale?.WITHDRAWAL_ERRORS?.bonus?.message}
                    <br />
                    <CustomLink
                      aria-label="click"
                      className="underline ml-1 break-normal"
                      to={locale?.WITHDRAWAL_ERRORS?.bonus?.link?.url}>
                      {locale?.WITHDRAWAL_ERRORS?.bonus?.link?.text}
                    </CustomLink>
                  </p>
                </div>
              )}
              {isTripleTurnOn?.data?.amount !== 'OK' &&
                isTripleTurnOn?.data?.amount !== 0 && (
                  <div className="flex flex-col text-center mt-[20px] max-w-[290px] mx-auto">
                    <p className="text-resolutionBlue text-[14px]">
                      <span className="flex items-center justify-center m-auto w-[56px] h-[56px] rounded-full bg-zumthor mb-[10px]">
                        <BsGift size="30" />
                      </span>
                      {locale?.WITHDRAWAL_ERRORS?.triple_turn?.message}
                      <br />
                      <span className="font-bold">
                        {`${
                          locale?.WITHDRAWAL_ERRORS?.triple_turn?.amount_message
                        }: ${isTripleTurnOn?.data?.amount / 100}`}
                      </span>
                    </p>
                  </div>
                )}
            </>
          )}
        </>
      ) : (
        <DataLoadingMessage />
      )}
    </div>
  );
};

Withdrawal.propTypes = {
  isNextStep: PropTypes.bool.isRequired,
  resetNextStep: PropTypes.func.isRequired,
  toggleNextStep: PropTypes.func.isRequired,
};

export default observer(Withdrawal);
