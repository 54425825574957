import {useCallback} from 'react';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const useScrollTop = () => {
  return useCallback((top = 0) => {
    if (top === 0) {
      window.location.hash = 'to-top';
    }
    window.scrollTo({
      top: top,
      behavior: 'smooth',
    });
  }, []);
};

export default useScrollTop;
