import PropTypes from 'prop-types';
import React from 'react';

const ParagraphTitle = ({title}) => {
  return (
    <>
      {title && (
        <h3 className="mb-[18px] text-white text-base leading-6 font-semibold font-sans">
          {title}
        </h3>
      )}
    </>
  );
};

ParagraphTitle.propTypes = {
  title: PropTypes.string,
};

export default ParagraphTitle;
