import {types} from 'mobx-state-tree';

export const RestrictionState = {
  providersFilter: true,
  providersCategory: true,
  providersGamePage: [],
};

const Restriction = types
  .model({
    providersFilter: types.maybeNull(types.frozen()),
    providersCategory: types.maybeNull(types.frozen()),
    providersGamePage: types.maybeNull(types.frozen()),
  })
  .views((store) => ({}))
  .actions((store) => ({
    setRestrictionProviderFilter() {
      store.providersFilter = false;
    },
    setRestrictionProviderCategory() {
      store.providersCategory = false;
    },
    setRestrictionProviderGamePage(slug) {
      if (!store.providersGamePage?.includes(slug)) {
        store.providersGamePage = [...store.providersGamePage, ...[slug]];
      }
    },
  }));

export default Restriction;
