import {apiCall, Endpoints} from '@api';
import {PoolingWorker} from '@src/webWorkers';
import {returningAsyncAction} from '@store/helpers/asyncAction';
import {proxy, wrap} from 'comlink';
import {types} from 'mobx-state-tree';

const balancePoolingWorker = wrap(new PoolingWorker());

export const BalanceState = {
  real: null,
  bonus: null,
  total: null,
};

function getUserBalance() {
  return async function flow(store) {
    try {
      const {data} = await apiCall({
        endpoint: Endpoints.BALANCE,
        useAuth: true,
        useLocale: false,
      });

      store.setBalance(data.realBalance, data.bonusBalance);
    } catch (e) {}
  };
}

const Balance = types
  .model({
    real: types.maybeNull(types.number),
    bonus: types.maybeNull(types.number),
    total: types.maybeNull(types.string),

    getUserBalance: returningAsyncAction(getUserBalance),
  })
  .actions((store) => ({
    setBalance(realBalance, bonusBalance) {
      let real = 0;
      let bonus = 0;
      let total = 0;

      if (realBalance) {
        const formattedBalance = Number(realBalance / 100).toFixed(2);

        real = +formattedBalance;
        total += +formattedBalance;
      }
      if (bonusBalance) {
        const formattedBalance = Number(bonusBalance / 100).toFixed(2);

        bonus = +formattedBalance;
        total += +formattedBalance;
      }

      total = parseFloat(total).toFixed(2);

      store.real = real;
      store.bonus = bonus;
      store.total = total;
    },
    initBalanceUpdater() {
      store.getUserBalance.run();

      balancePoolingWorker.start(
        proxy(store.getUserBalance.run),
        proxy(store.setBalanceUpdaterCtx),
      );
    },
    setBalanceUpdaterCtx(ctx) {
      store.balanceRunner = ctx;
    },
    destructBalanceUpdater() {
      balancePoolingWorker.finish(
        store.balanceRunner,
        proxy(store.setBalanceUpdaterCtx),
      );
    },
  }));

export default Balance;
