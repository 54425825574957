import React from 'react';
import {locale} from '@res/strings/locale';

const TicketsTitle = () => {
  return (
    <h1 className="mb-4 text-tertiary text-center text-[24px] leading-[32px] font-bold">
      {locale?.LOTTERY_PARAMS?.myTickets}
    </h1>
  );
};

export default TicketsTitle;
