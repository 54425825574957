import PropTypes from 'prop-types';
import {useMemo} from 'react';

const SimpleTable = ({data}) => {
  const mapRows = useMemo(
    () =>
      data?.map((item, index) => {
        return (
          <div
            key={index}
            className="flex flex-wrap text-melrose text-[14px]  first:text-white first:font-bold  odd:bg-stratosLight hover:bg-stratosDarker  even:bg-prussianBlueLight">
            {item &&
              item?.map((i, key) => {
                return (
                  <div
                    key={key}
                    className={`flex-1 ${
                      index === 0 ? 'py-4' : 'py-3'
                    } px-4 md:px-6 border-midnightBlueMiddleLight border-l-[1px] leading-5 break-all first:border-l-0`}
                    dangerouslySetInnerHTML={{__html: i}}
                  />
                );
              })}
          </div>
        );
      }),
    [data],
  );
  return (
    <div className="mb-[18px] rounded-[8px] overflow-hidden">
      {data && mapRows}
    </div>
  );
};

SimpleTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
};

export default SimpleTable;
