export default {
  BUTTON_ACTION: {
    CASHBOX: 'CASHBOX',
    SUPPORT_CHAT: 'SUPPORT_CHAT',
  },
  IMAGE: {
    GIFT: 'GIFT',
    INFORMATION: 'INFORMATION',
    WELCOME: 'WELCOME',
    CONFIRM: 'CONFIRM',
  },
};
