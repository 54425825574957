import {Endpoints} from '@api';
import useRequest from '@hooks/useRequest';
import {useStore} from '@store/configureStore';

const useBanned = () => {
  const {user} = useStore();

  const {data: banned} = useRequest(
    {
      endpoint: Endpoints.CHECK_BAN,
      useAuth: true,
    },
    {
      enabled: user?.authorized,
    },
  );

  return banned?.result && user?.authorized;
};

export default useBanned;
