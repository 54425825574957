import ContentButton from '@components/PageContent/ContentButton';
import ParagraphTitle from '@components/PageContent/ParagraphTitle';
import SwitchParagraphType from '@components/PageContent/SwitchParagraphType';
import PropTypes from 'prop-types';
import React from 'react';

const ChapterOrderedList = ({title, data, button}) => {
  const switchItemType = (item, key) => {
    if (item?.type) {
      return <SwitchParagraphType {...{item, key}} />;
    }

    return (
      <li
        {...{key}}
        className="mb-[18px] text-melrose text-base leading-6 font-sans"
        dangerouslySetInnerHTML={{__html: item}}
      />
    );
  };

  return (
    <>
      <ParagraphTitle {...{title}} />
      <ol className="list-none pl-6">
        {!!data?.length &&
          data?.map((item, index) => switchItemType(item, index))}
      </ol>
      <ContentButton {...{data: button}} />
    </>
  );
};

ChapterOrderedList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any.isRequired,
  button: PropTypes.any,
};

export default ChapterOrderedList;
