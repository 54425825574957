import {useStore} from '@store/configureStore';
import {useEffect} from 'react';

const useModalRegister = (
  modalRoute,
  {requiredParams, handleCloseModal} = {},
) => {
  const {
    modal: {clearParams, params},
  } = useStore();

  const modalParams = params?.[modalRoute];

  useEffect(() => {
    if (requiredParams) {
      if (
        modalParams &&
        requiredParams?.every((v) => Object.keys(modalParams)?.includes(v))
      ) {
        return;
      }

      handleCloseModal();
    }
  }, [params]);

  useEffect(() => {
    return () => clearParams(modalRoute);
  }, []);

  return {
    params: modalParams || {},
  };
};

export default useModalRegister;
