import InputError from '@components/FormElements/InputError';
import TogglePasswordVisibility from '@components/FormElements/TogglePasswordVisibility';
import useToggleState from '@hooks/useToggleState';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';

const Input = ({
  className,
  usePasswordVisibility = false,
  isError = false,
  errorMessage = '',
  formHook,
  activeTab,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [type, setType] = useState(rest?.type);
  const [isPasswordVisible, togglePasswordVisibility] = useToggleState();

  const _togglePasswordVisibility = () => {
    setType((current) => (type === 'password' ? 'text' : 'password'));
    togglePasswordVisibility();
    inputRef.current.focus();
  };

  useEffect(() => {
    if (usePasswordVisibility) {
      inputRef.current.selectionStart = inputRef.current.value?.length;
      inputRef.current.selectionEnd = inputRef.current.value?.length;
    }
  }, [type]);

  useEffect(() => {
    inputRef.current.blur();
  }, []);

  return (
    <>
      <input
        className={classNames(
          {
            'border-redRibbon focus:bg-white bg-fairPink mb-0':
              isError && !rest.disabled,
            'border-transparent focus:bg-white focus:border-periwinkleGray bg-hawkesBlue mb-[10px]':
              !isError,
          },
          'w-full p-[13px] h-[40px] border-[1px] focus:outline-none rounded-[5px] text-resolutionBlue placeholder-poloBlue disabled:mb-[10px] disabled:bg-periwinkleGray disabled:placeholder-white',
          className,
        )}
        {...formHook}
        {...rest}
        ref={inputRef}
        type={type}
      />
      {isError && !rest.disabled && <InputError message={errorMessage} />}
      {usePasswordVisibility && (
        <TogglePasswordVisibility
          {...{
            isPasswordVisible,
            _togglePasswordVisibility,
          }}
        />
      )}
    </>
  );
};

Input.propTypes = {
  formHook: PropTypes.object,
  rest: PropTypes.any,
  isError: PropTypes.object,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  usePasswordVisibility: PropTypes.bool,
  activeTab: PropTypes.string,
};

export default Input;
