const ConfigService = {
  init: async function () {
    return new Promise(async (res) => {
      if (!window.GlobalConfig) {
        setTimeout(() => {
          this.init();
        }, 1000);
      } else {
        res();
      }
    });
  },
};

export default ConfigService;
