import tournamentConfig from '@constants/tournamentConfig';
import PropTypes from 'prop-types';
import React from 'react';
import TournamentGamesSkeleton from '@components/TournamentPage/TournamentGamesSkeleton';
import TournamentResultsSkeleton from '@components/TournamentPage/TournamentResultsSkeleton';
import TournamentRulesSkeleton from '@components/TournamentPage/TournamentRulesSkeleton';

const TournamentPageSkeleton = ({route}) => {
  switch (route) {
    case tournamentConfig.GAMES:
      return <TournamentGamesSkeleton />;
    case tournamentConfig.RESULTS:
      return <TournamentResultsSkeleton />;
    default:
      return <TournamentRulesSkeleton />;
  }
};

TournamentPageSkeleton.propTypes = {
  route: PropTypes.string,
};

export default TournamentPageSkeleton;
