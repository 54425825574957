import NotificationBadges from '@components/Header/NotificationBadges';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@components/Icon';
import pushHash from '@utils/pushHash';
import modalRoutes from '@navigation/modalRoutes';
import {useHistory} from 'react-router';
import AnalyticsService from '@services/AnalyticsService';
import analyticsEvents from '@constants/analyticsEvents';
import {format} from 'date-fns';
import {useStore} from '@store/configureStore';
import inboxMessagesEventTypes from '@constants/inboxMessagesEventTypes';

const InboxNotification = ({className, count, isAnimated}) => {
  const history = useHistory();
  const {user} = useStore();

  const showInboxMessages = () => {
    AnalyticsService.logEvent(analyticsEvents.INBOX_MESSAGES_CLICK, {
      user_id: user?.profile?.id ?? '',
      time: format(new Date(), 'dd.MM.yyyy HH:mm'),
      type: inboxMessagesEventTypes.HEADER,
    });
    pushHash(history, modalRoutes.MESSAGES);
  };

  return (
    <span
      onClick={showInboxMessages}
      className={classNames(className, 'cursor-pointer')}>
      {count > 0 && <NotificationBadges {...{count, isAnimated}} />}
      <Icon icon="mail" size={30} className="text-default-controls-icon" />
    </span>
  );
};

InboxNotification.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  isAnimated: PropTypes.bool,
};

export default InboxNotification;
