import offerConstants from '@constants/offerConstants';
import OfferInfoWelcome from '@components/Modals/OfferInfoModal/OfferInfoWelcome';
import OfferInfoGiftSpin from '@components/Modals/OfferInfoModal/OfferInfoGiftSpin';
import OfferInfoGiftSpinOffer from '@components/Modals/OfferInfoModal/OfferInfoGiftSpinOffer';
import OfferInfoCashback from '@components/Modals/OfferInfoModal/OfferInfoCashback';
import OfferInfoTournament from '@components/Modals/OfferInfoModal/OfferInfoTournament';
import OfferInfoBonus from '@components/Modals/OfferInfoModal/OfferInfoBonus';
import React from 'react';

const OfferInfoModalSwitch = ({user, offers, params}) => {
  switch (params?.data?.type) {
    case offerConstants?.TYPE?.WELCOME:
      return (
        <OfferInfoWelcome
          {...{user, offers, data: params?.data, game: params?.game}}
        />
      );
    case offerConstants?.TYPE?.NO_DEPOSIT_GIFT_SPIN:
      return (
        <OfferInfoGiftSpin
          {...{user, offers, data: params?.data, game: params?.game}}
        />
      );
    case offerConstants?.TYPE?.DEPOSIT_GIFT_SPIN:
      if (params?.data?.status === offerConstants?.STATUS?.ACTIVE) {
        return (
          <OfferInfoGiftSpin
            {...{user, offers, data: params?.data, game: params?.game}}
          />
        );
      }
      return (
        <OfferInfoGiftSpinOffer
          {...{user, offers, data: params?.data, game: params?.game}}
        />
      );
    case offerConstants?.TYPE?.CASHBACK:
    case offerConstants?.TYPE?.CASHBACK_INFO:
      return <OfferInfoCashback {...{user, offers, data: params?.data}} />;
    case offerConstants?.TYPE?.TOURNAMENT_WIN_BONUS:
      return <OfferInfoTournament {...{user, data: params?.data}} />;
    default:
      return <OfferInfoBonus {...{user, offers, data: params?.data}} />;
  }
};

export default OfferInfoModalSwitch;
