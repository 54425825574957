import Button from '@components/Button';
import Input from '@components/FormElements/Input';
import InputError from '@components/FormElements/InputError';
import ModalWrapper from '@components/Modals/ModalWrapper';
import RegistrationStepper from '@components/Register/RegistrationStepper';
import RegistrationFormBonus from '@components/RegistrationFormBonus';
import logoConfig from '@constants/logoConfig';
import {yupResolver} from '@hookform/resolvers/yup';
import useModalControls from '@hooks/useModalControls';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import errorMsgHandler from '@utils/errorMsgHandler';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import TermsCheckBoxCA from '@components/FormElements/TermsCheckBoxCA';
import RegistrationWelcomeBonus from '@components/Register/RegistrationWelcomeBonus';

const FirstStepRegistration = ({handleCloseModal, modalParams}) => {
  const {user} = useStore();
  const {openModal} = useModalControls();
  // const currencyOptions = useMemo(
  //   () =>
  //     window?.GlobalConfig?.currencies?.map((curr) => ({
  //       value: curr,
  //       label: curr,
  //     })),
  //   [],
  // );

  // const countryOptions = useMemo(
  //   () =>
  //     window?.GlobalConfig?.countries?.map((country) => ({
  //       value: country?.value,
  //       label: country?.label,
  //     })),
  //   [],
  // );

  const registrationFormSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(locale?.ERROR_MESSAGES?.email)
          .required(locale?.ERROR_MESSAGES?.required),
        password: yup
          .string()
          .min(4, locale?.ERROR_MESSAGES?.passwordMin)
          .required(locale?.ERROR_MESSAGES?.required),
        terms: yup
          .bool()
          .required(locale?.ERROR_MESSAGES?.required)
          .oneOf([true], locale?.ERROR_MESSAGES?.termsNotChecked),
        //currency: yup.string().required(locale?.ERROR_MESSAGES?.required),
        //country: yup.string().required(locale?.ERROR_MESSAGES?.required),
      }),
    [],
  );

  const {
    formState: {errors},
    handleSubmit,
    setError,
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(registrationFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      currency:
        modalParams?.body?.currency ?? window?.GlobalConfig?.currencies?.[0],
      country:
        modalParams?.body?.country ??
        window?.GlobalConfig?.countries?.[0]?.value,
      email: modalParams?.body?.email ?? '',
      password: modalParams?.body?.password ?? '',
      terms: modalParams?.body?.terms ?? false,
    },
  });

  const watchFields = watch();

  const onSubmit = async (data) => {
    try {
      await user.signUpValidate.run(data);
      openModal(modalRoutes.REGISTER_SECOND_STEP, {
        fromHandler: true,
        body: {...modalParams?.body, ...data},
      });
    } catch (errors) {
      errorMsgHandler(errors, {
        setError,
        fields: ['email', 'password', 'system'],
      });
    }
  };

  return (
    <ModalWrapper
      desktopStyle={
        'max-w-[720px] w-full sm:h-[600px] sm:rounded-[8px] bg-[#300e4f]'
      }
      {...{handleCloseModal}}>
      <div className="flex flex-col sm:flex-row w-full h-full">
        <RegistrationFormBonus />
        <div className="sm:w-1/2 h-[100%] flex flex-col overflow-scroll no-scrollbar bg-white pb-[80px] sm:pb-[0]">
          <img
            src={logoConfig.INVERSE_SRC}
            className="w-full max-w-[95px] mt-[20px] mb-[10px] mx-auto"
            alt={locale.ALT?.logo}
          />

          <div className="flex flex-col px-[32px] pb-[50px] h-[100%]">
            <RegistrationWelcomeBonus />

            <RegistrationStepper
              activeStep={modalRoutes.REGISTER}
              fields={{...modalParams?.body, ...watchFields}}
            />

            <div className="flex flex-col justify-between w-full mt-[20px] h-[100%]">
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Controller
                  control={control}
                  name="email"
                  render={({field: {onChange, value}}) => (
                    <Input
                      placeholder={locale?.enterEmail}
                      type="email"
                      {...{onChange, value}}
                      isError={errors.email}
                      errorMessage={errors.email?.message}
                      className="text-[14px]"
                    />
                  )}
                />
                <div className="relative">
                  <Controller
                    control={control}
                    name="password"
                    render={({field: {onChange, value}}) => (
                      <Input
                        placeholder={locale?.enterPassword}
                        type="password"
                        {...{onChange, value}}
                        className="text-[14px]"
                        isError={errors.password}
                        errorMessage={errors.password?.message}
                        usePasswordVisibility
                      />
                    )}
                  />
                </div>
                {/*<div className="mb-[10px]">*/}
                {/*  <Controller*/}
                {/*    control={control}*/}
                {/*    name="country"*/}
                {/*    render={({field: {onChange, value, ref}}) => (*/}
                {/*      <ReactSelect*/}
                {/*        inputRef={ref}*/}
                {/*        options={countryOptions}*/}
                {/*        value={countryOptions.find((c) => c.value === value)}*/}
                {/*        onChange={(val) => onChange(val.value)}*/}
                {/*      />*/}
                {/*    )}*/}
                {/*  />*/}
                {/*</div>*/}

                {/*<Controller*/}
                {/*  control={control}*/}
                {/*  name="currency"*/}
                {/*  render={({field: {onChange, value, ref}}) => (*/}
                {/*    <ReactSelect*/}
                {/*      inputRef={ref}*/}
                {/*      options={currencyOptions}*/}
                {/*      value={currencyOptions.find((c) => c.value === value)}*/}
                {/*      onChange={(val) => onChange(val.value)}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*/>*/}
                <Controller
                  control={control}
                  name="terms"
                  render={({field: {onChange, value, name}}) => (
                    <TermsCheckBoxCA
                      label={locale?.termsLabelCA}
                      isError={errors.terms}
                      errorMessage={errors.terms?.message}
                      {...{value, onChange, setValue, name}}
                    />
                  )}
                />

                {!!errors.system && (
                  <InputError message={errors.system?.message} />
                )}

                <Button
                  disabled={user.signUpValidate?.inProgress}
                  htmlType="submit"
                  title={
                    user.signUpValidate?.inProgress
                      ? locale?.processing
                      : locale?.doRegister
                  }
                  className="w-full h-[40px] text-[14px] font-bold leading-5 py-[10px] rounded-[5px] mt-[33px] uppercase"
                  type="primary"
                />
              </form>
              <div className="flex flex-col self-center">
                <p className="text-center text-[13px] text-resolutionBlue">
                  {locale?.hasAccount}
                  <span
                    onClick={() => openModal(modalRoutes.AUTH)}
                    className="ml-[5px] underline cursor-pointer">
                    {locale?.login}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default observer(FirstStepRegistration);
