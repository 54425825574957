//import {apiCall, Endpoints} from '@api';
import Cookies from 'js-cookie';

export default {
  init: async () => {
    const urlParams = new URLSearchParams(window.location?.search);
    const promoCode = urlParams.get('promocode');
    const promoCodeCookie = Cookies.get('promocode');
    if (!promoCodeCookie && promoCode) {
      try {
        // await apiCall({
        //   endpoint: `${Endpoints.VALIDATE_PROMO_CODE}/${promoCode}`,
        //   useLocale: false,
        // });
        Cookies.set('promocode', promoCode, {expires: 30});
      } catch (error) {
        console.error('INVALID_PROMO_CODE_FROM_URL_PARAMS: ', error);
      }
    }
  },
  getPromoCode: () => {
    const promoCodeCookie = Cookies.get('promocode');
    if (promoCodeCookie) {
      return promoCodeCookie;
    }
  },
  removePromoCode: () => {
    Cookies.remove('promocode');
  },
};
