import {useEffect} from 'react';

const useBodyOverflow = (toggleState) => {
  useEffect(() => {
    if (toggleState) {
      document.body.style.overflow = 'hidden';
    }
    return () => (document.body.style.overflow = 'unset');
  }, [toggleState]);
};

export default useBodyOverflow;
