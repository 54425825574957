import {locale} from '@res/strings/locale';
import close_icon from '@images/icons/close-icon.svg';
import React from 'react';

const RestrictionContentBlock = ({content, handleClose}) => {
  return (
    <div className="w-full flex justify-between py-[10px] rounded-[5px] bg-resolutionBlue">
      <div className="flex w-[40px] flex-none justify-center items-center">
        <div className="flex justify-center items-center min-w-[20px] h-[20px] bg-badge-info rounded-[50%] text-center">
          <span className="text-[14px] text-resolutionBlue font-bold">i</span>
        </div>
      </div>
      <div
        className="grow text-[11px] text-left text-white"
        dangerouslySetInnerHTML={{__html: content}}
      />
      <div className="w-[28px] h-[20px] pl-[2px] flex-none">
        <button
          type="button"
          className="flex pt-[4px] cursor-pointer"
          onClick={handleClose}>
          <img
            src={close_icon}
            alt={locale.ALT?.closeMenuIcon}
            className="w-[11px] h-[11px] pointer-events-none"
          />
        </button>
      </div>
    </div>
  );
};

export default RestrictionContentBlock;
