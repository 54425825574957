import React from 'react';
import lotteryConfig from '@constants/lotteryConfig';
import PageTabs from '@components/Tabs/PageTabs';
import LotteryContent from '@components/LotteryPage/LotteryContent';
import LotteryBanner from '@components/LotteryPage/LotteryBanner';
import PageContainer from '@components/PageContent/PageContainer';

const Lottery = ({data, route, slug, headerHeight}) => {
  return (
    <>
      <PageContainer className={'mt-4 lg:mt-6'}>
        <LotteryBanner {...{data, slug, rounded: 'rounded-t-[10px]'}} />
      </PageContainer>
      <PageTabs {...{data: lotteryConfig?.TABS(data, slug), headerHeight}} />
      <PageContainer>
        <LotteryContent {...{data, slug, route}} />
      </PageContainer>
    </>
  );
};

export default Lottery;
