import PropTypes from 'prop-types';

const BannerSubtitle = ({subtitle}) => {
  return (
    <p className="text-white text-[26px] font-bold font-sans lg:text-[36px] xl:text-[40px] text-center leading-[36px] sm:leading-none">
      {subtitle}
    </p>
  );
};

BannerSubtitle.propTypes = {
  subtitle: PropTypes.string.isRequired,
};

export default BannerSubtitle;
