import Button from '@components/Button';
import InputError from '@components/FormElements/InputError';
import OtpInput from '@components/FormElements/OtpInput';
import ModalWrapper from '@components/Modals/ModalWrapper';
import {yupResolver} from '@hookform/resolvers/yup';
import useModalRegister from '@hooks/useModalRegister';
import modalRoutes from '@navigation/modalRoutes';
import {locale} from '@res/strings/locale';
import {useStore} from '@store/configureStore';
import errorMsgHandler from '@utils/errorMsgHandler';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import * as yup from 'yup';

const ResetPasswordOtpModal = ({
  handleCloseModal,
  buttonType = 'secondary',
}) => {
  const {user} = useStore();
  const history = useHistory();
  const {params} = useModalRegister(modalRoutes.FORGOT_PASSWORD_VERIFY_CODE, {
    requiredParams: ['userPhone'],
    handleCloseModal,
  });

  const authFormSchema = yup.object().shape({
    otp: yup
      .string()
      .required(locale?.ERROR_MESSAGES?.required)
      .matches(/^[0-9]+$/, locale?.ERROR_MESSAGES?.onlyDigits)
      .min(6, locale?.ERROR_MESSAGES?.otp)
      .max(6, locale?.ERROR_MESSAGES?.otp),
  });

  const {
    formState: {errors, isValid},
    handleSubmit,
    control,
    setError,
  } = useForm({
    resolver: yupResolver(authFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data) => {
    try {
      await user?.resetPasswordVerifyPhone.run({
        token: data.otp,
        phone: params.userPhone,
      });

      history.push({
        search: qs.stringify({
          type: 'phone',
          reset_token: data.otp,
          phone: params.userPhone,
        }),
        hash: modalRoutes.RESET_PASSWORD,
      });
    } catch (errors) {
      errorMsgHandler(errors, {
        setError,
        fields: ['otp'],
      });
    }
  };

  return (
    <ModalWrapper {...{handleCloseModal}}>
      <div className="flex flex-col px-[32px] pt-[24px] pb-[50px] h-[100%]">
        <div className="mb-[24px] text-center mt-[15px]">
          <h3 className="text-resolutionBlue font-bold text-[19px] leading-[26px] mb-[11px]">
            {locale?.otpTitle}
          </h3>
          <p className="text-resolutionBlue text-[14px] leading-[19px]">
            {locale?.otpMessage}
            <span className="block">{params.userPhone}</span>
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-center h-[100%]">
          <Controller
            control={control}
            name="otp"
            render={({field: {onChange, value, ref}}) => (
              <OtpInput
                inputRef={ref}
                isError={errors.otp}
                errorMessage={errors.otp?.message}
                {...{onChange, value}}
              />
            )}
          />

          {!!errors.system && <InputError message={errors.system?.message} />}

          <Button
            htmlType="submit"
            disabled={user?.resetPasswordVerifyPhone?.inProgress || !isValid}
            title={locale?.login}
            className="uppercase max-w-[198px] w-full py-[10px] rounded-[5px] mt-[40px]"
            type={buttonType}
          />
        </form>
      </div>
    </ModalWrapper>
  );
};

ResetPasswordOtpModal.propTypes = {
  buttonType: PropTypes.oneOf(['primary', 'secondary']),
};

export default observer(ResetPasswordOtpModal);
