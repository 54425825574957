import categoryKeys from '@constants/categoryKeys';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import qs from 'query-string';

const stringifyParams = (params) => {
  let newParams = '';
  const paramsLength = Object.keys(params).length;

  Object.entries(params).map(([key, value], index) => {
    newParams += `${key}=${value}${
      paramsLength > 0 && index !== paramsLength - 1 ? '&' : ''
    }`;
  });

  return newParams;
};

const getSearchPageRoute = () =>
  buildRoute(navigationRoutes?.GAME_CATEGORY, {
    category: categoryKeys?.ALL_GAMES,
  });

const UrlHashParams = {
  get: (history, pName) => {
    const params = qs.parse(history?.location?.hash);

    return params?.[pName] ?? null;
  },
  set: (history, pName, pValue) => {
    const params = qs.parse(history?.location?.hash) ?? {};
    params[pName] = pValue;

    return history.push({
      pathname: getSearchPageRoute(),
      hash: stringifyParams(params),
      search: history?.location?.search,
    });
  },
  delete: (history, pName) => {
    const params = qs.parse(history?.location?.hash);
    if (params && params?.[pName]) {
      delete params?.[pName];

      return history.push({
        pathname: getSearchPageRoute(),
        hash: stringifyParams(params),
        search: history?.location?.search,
      });
    }
  },
};

export default UrlHashParams;
