import categoryKeys from '@constants/categoryKeys';
import buildRoute from '@navigation/buildRoute';
import navigationRoutes from '@navigation/navigationRoutes';
import {Redirect} from 'react-router';

const RedirectAllGamesPage = () => {
  return (
    <Redirect
      to={buildRoute(navigationRoutes.GAME_CATEGORY, {
        category: categoryKeys.ALL_GAMES,
      })}
    />
  );
};

export default RedirectAllGamesPage;
