import Flicking from '@egjs/react-flicking';
import chevron_left from '@images/icons/chevron-left.svg';
import chevron_right from '@images/icons/chevron-right.svg';
import PropTypes from 'prop-types';
import {useCallback} from 'react';

const SlideChanger = ({sliderRef}) => {
  const next = useCallback(() => {
    sliderRef?.current?.next();
  }, [sliderRef]);

  const prev = useCallback(() => {
    sliderRef?.current?.prev();
  }, [sliderRef]);

  return (
    <div className="max-w-[120px] sm:max-w-[150px] w-full flex flex-row absolute bottom-0 right-0 z-30 text-white self-end justify-end items-center">
      <a
        onClick={prev}
        aria-label="click"
        className="pb-[16px] pt-[24px] px-[24px] sm:pt-[32px] sm:px-[32px] cursor-pointer">
        <img
          className="w-[6.96px] h-[12.75px] select-none"
          src={chevron_left}
          alt={'Prev'}
        />
      </a>

      <div className="w-[1px] h-[22px] mt-[8px] sm:mt-[16px] bg-midnightBlue" />

      <a
        aria-label="click"
        onClick={next}
        className="pb-[16px] pt-[24px] px-[24px] sm:pt-[32px] sm:px-[32px] cursor-pointer">
        <img
          className="w-[6.96px] h-[12.75px] select-none"
          src={chevron_right}
          alt={'Next'}
        />
      </a>
    </div>
  );
};

SlideChanger.propTypes = {
  sliderRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Flicking)}),
  ]),
};

export default SlideChanger;
