import modalConfig from '@constants/modalConfig';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

const Overlay = ({toggleState, className, isSideMenu = false, activeModal}) => {
  const handleClose = () => {
    if (!modalConfig.DISABLE_OVERLAY_CLOSE.includes(activeModal)) {
      toggleState();
    }
  };

  return (
    <motion.div
      onClick={handleClose}
      initial={{opacity: 0}}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{ease: 'easeInOut', duration: 0.1}}
      className={classNames(
        className,
        {'z-[49]': isSideMenu, 'z-[51]': !isSideMenu},
        'w-full h-screen  fixed top-0 bg-midnight70',
      )}
    />
  );
};

Overlay.poprTypes = {
  toggleState: PropTypes.func.isRequired,
  classNames: PropTypes.string,
  isSideMenu: PropTypes.bool,
};

export default Overlay;
