import useWindowSize from '@hooks/useWindowSize';
import getDevice from '@utils/getDevice';
import {useEffect, useState} from 'react';

const useDeviceProps = () => {
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(null);
  const [isLandScape, setIsLandScape] = useState(null);

  useEffect(() => {
    setIsMobile(getDevice() === 'mobile' || size?.width < 640);
    setIsLandScape(size?.height < size?.width);
  }, [size, isLandScape]);

  return {isMobile, isLandScape};
};

export default useDeviceProps;
