import PropTypes from 'prop-types';

const PageTitle = ({title, subtitle}) => {
  return (
    <div className="my-8 text-center md:mt-14 md:mb-10 lg:mt-16">
      <h1 className="text-white text-[26px] sm:text-[40px] leading-[40px] sm:leading-[48px] md:leading-[60px] font-bold font-sans">
        {title}
      </h1>
      {subtitle && (
        <p
          className="text-melrose text-base leading-[24px] font-sans"
          dangerouslySetInnerHTML={{__html: subtitle}}
        />
      )}
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default PageTitle;
