import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const PageContainer = ({className, children}) => {
  return (
    <div
      className={classNames(
        'container mx-auto max-w-full xl:max-w-[1296px]',
        className,
      )}>
      {children}
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PageContainer;
