import Endpoints from '@api/endpoints';
import GamesFilter from '@components/GamesFilter/GamesFilter';
import Icon from '@components/Icon';
import MobileCategorySelector from '@components/CategorySelector/MobileCategorySelector';
import SelectorControlsBar from '@components/CategorySelector/SelectorControlsBar';
import StickyPageContainer from '@components/StickyPageContainer';
import displayForKeys from '@constants/displayForKeys';
import testIds from '@constants/testIds';
import useToggleState from '@hooks/useToggleState';
import useRequest from '@hooks/useRequest';
import buildRoute from '@navigation/buildRoute';
import CustomNavLink from '@navigation/CustomNavLink';
import navigationRoutes from '@navigation/navigationRoutes';
import classNames from 'classnames';
import React, {useEffect, useMemo} from 'react';
import {useLocation} from 'react-router';
import {useStore} from '@store/configureStore';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

const CategorySelector = ({isPermanentSticky, headerHeight}) => {
  const {user} = useStore();
  const location = useLocation();
  const urlParams = useMemo(
    () => new URLSearchParams(location?.search),
    [location?.search],
  );
  const q = urlParams.get('q');
  const [isFilterOpen, toggleFilter, closeFilter] = useToggleState();
  const [isSearchActive, toggleSearch] = useToggleState(!!q);

  useEffect(() => {
    closeFilter();
  }, [location]);

  const {data: categories} = useRequest({
    endpoint: Endpoints.CATEGORIES,
    params: {
      _sort: 'priority:DESC',
      show_on_bar: true,
    },
  });

  return (
    <>
      <StickyPageContainer {...{isPermanentSticky, headerHeight}}>
        {({isSticky}) => {
          return (
            <div
              className={classNames(
                {
                  'overflow-x-hidden': isSearchActive,
                  'mt-[24px] ': !isPermanentSticky,
                  'rounded-[8px]': !isSticky && !isPermanentSticky,
                },
                'flex justify-between bg-stratos mb-[35px] pl-[17px] sm:pl-[25px] xl:pl-[22px] md:overflow-hidden relative',
              )}>
              <div className="flex relative max-w-full md:max-w-[570px] lg:max-w-[807px] xl:max-w-[900px] w-full h-[64px] items-center">
                {!isSearchActive && (
                  <MobileCategorySelector
                    {...{categories}}
                    authorized={user?.authorized}
                  />
                )}
                <div className="hidden md:flex items-center">
                  {categories?.length &&
                    categories?.map((category) => {
                      if (
                        (!user?.authorized &&
                          category?.display_for ===
                            displayForKeys.AUTHORIZED) ||
                        (user?.authorized &&
                          category?.display_for === displayForKeys.UNAUTHORIZED)
                      ) {
                        return null;
                      }
                      return (
                        <CustomNavLink
                          key={category?.id}
                          testid={`${testIds.CATEGORY_BUTTON}-${category?.slug}`}
                          to={buildRoute(navigationRoutes.GAME_CATEGORY, {
                            category: category?.slug,
                          })}
                          exact
                          className="flex flex-row md:flex-col lg:flex-row text-default-category-link focus:outline-none hover:text-primary active:text-primary mr-0 md:mr-[34px] lg:mr-[40px] xl:mr-[54px] group"
                          activeClassName="text-active-category-link">
                          {({isActive}) => (
                            <>
                              {category?.icon && (
                                <Icon
                                  icon={category?.slug}
                                  size={28}
                                  className={classNames(
                                    {
                                      'text-active-category-link': isActive,
                                      'text-default-category-icon': !isActive,
                                    },
                                    'mr-[10px] md:mb-[4px] md:mr-0 md:self-center lg:mr-[10px] group-hover:text-primary group-active:text-primary',
                                  )}
                                />
                              )}
                              <span className="uppercase text-[13px] md:text-[12px] lg:text-[13px] leading-[18px] font-semibold self-center">
                                {category?.name}
                              </span>
                            </>
                          )}
                        </CustomNavLink>
                      );
                    })}
                </div>
              </div>
              <SelectorControlsBar
                {...{toggleFilter, isSearchActive, toggleSearch}}
              />
            </div>
          );
        }}
      </StickyPageContainer>
      <GamesFilter
        {...{isFilterOpen, toggleFilter, closeFilter, headerHeight}}
      />
    </>
  );
};

CategorySelector.propTypes = {
  isPermanentSticky: PropTypes.bool,
  headerHeight: PropTypes.number,
};

export default observer(CategorySelector);
