import Button from '@components/Button';
import regionTypes from '@constants/regionTypes';
import buildRoute from '@navigation/buildRoute';
import CustomLink from '@navigation/CustomLink';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import Cookies from 'js-cookie';
import React, {useEffect, useState} from 'react';

const CookieModal = () => {
  const [isCookieModalVisible, setCookieModalVisibility] = useState(
    Cookies.get('isCookieModalVisible'),
  );
  useEffect(() => {
    if (isCookieModalVisible === null || isCookieModalVisible === undefined) {
      Cookies.set('isCookieModalVisible', 'true', {expires: 1000});
      setCookieModalVisibility('true');
    }
  }, []);

  const hideModal = () => {
    Cookies.set('isCookieModalVisible', 'false', {expires: 1000});
    setCookieModalVisibility('false');
  };

  return (
    <>
      {window?.GlobalConfig?.region === regionTypes.CANADA &&
        isCookieModalVisible === 'true' && (
          <div className="bg-midnight86 rounded-[8px] md:rounded-[0px] px-[20px] py-[20px] md:py-[25px] flex flex-col md:flex-row md:justify-center md:items-center fixed bottom-[70px] md:bottom-0 w-[calc(100%-30px)] md:w-full z-50 left-0 right-0 m-auto">
            <p className="text-white text-[12px] md:text-[14px] md:max-w-[513px] md:mr-[52px]">
              <span className="font-bold mr-[5px]">
                {locale?.COOKIE_MODAL?.weUseCookies}
              </span>
              {locale?.COOKIE_MODAL?.message}
              <CustomLink
                to={buildRoute(navigationRoutes.PRIVACY_POLICY)}
                className="underline text-white mx-[5px]">
                {locale?.COOKIE_MODAL?.privacyLinkText}
              </CustomLink>
              {locale?.COOKIE_MODAL?.and}
              <CustomLink
                to={buildRoute(navigationRoutes.COOKIES)}
                className="underline text-white mx-[5px]">
                {locale?.COOKIE_MODAL?.cookieLinkText}
              </CustomLink>
              {locale?.COOKIE_MODAL?.terms}.
            </p>
            <Button
              className="text-white uppercase text-[12px] md:text-[14px] text-center font-bold block py-[10px] max-w-[96px] md:max-w-[196px] w-full bg-denim rounded-[5px] mt-[10px] md:mt-0 mx-auto md:mx-0"
              type="secondary"
              onClick={() => hideModal()}
              title={locale?.accept}
            />
          </div>
        )}
    </>
  );
};

export default CookieModal;
