import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

const CircularProgressBar = ({
  percents,
  stroke = '#6FB7FF',
  emptyStroke = '#001740',
  emptyStrokeOpacity = 1,
  duration = 2,
  delay = 0.2,
  size = 31,
  radius = 43,
  strokeWidth = 6,
  children,
  isAnimated = false,
}) => {
  const circumference = useMemo(
    () => Math.ceil(2 * Math.PI * radius),
    [radius],
  );
  const fillPercents = useMemo(
    () => Math.abs(Math.ceil((circumference / 100) * (percents - 100))),
    [circumference, percents],
  );
  const transition = {
    duration: isAnimated ? duration : 0,
    delay: isAnimated ? delay : 0,
    ease: 'easeIn',
  };

  const variants = {
    hidden: {
      strokeDashoffset: circumference,
      transition,
    },
    show: {
      strokeDashoffset: fillPercents,
      transition,
    },
  };

  return (
    <>
      <div>
        <svg
          viewBox="0 0 100 100"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}>
          <circle
            cx="50"
            cy="50"
            r={radius}
            className="absolute left-0 top-0"
            strokeWidth={strokeWidth}
            stroke={emptyStroke}
            strokeOpacity={emptyStrokeOpacity}
            fill="transparent"
          />
        </svg>
        <svg
          viewBox="0 0 100 100"
          width={size}
          height={size}
          className="absolute left-0 top-0  overflow-visible transform rotate-[-90deg]">
          <motion.circle
            cx="50"
            cy="50"
            r={radius}
            strokeWidth={strokeWidth}
            stroke={stroke}
            fill="transparent"
            strokeDashoffset={fillPercents}
            strokeDasharray={circumference}
            variants={variants}
            initial="hidden"
            animate="show"
          />
        </svg>
      </div>
      {children}
    </>
  );
};

CircularProgressBar.propTypes = {
  percents: PropTypes.number.isRequired,
  stroke: PropTypes.string,
  emptyStroke: PropTypes.string,
  emptyStrokeOpacity: PropTypes.number,
  duration: PropTypes.number,
  delay: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  children: PropTypes.element,
  isAnimated: PropTypes.bool,
  radius: PropTypes.number,
};

export default CircularProgressBar;
