import empty_icon from '@images/icons/empty-icon.svg';

const LandingListEmpty = ({text}) => {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-[600px] max-w-full mb-6 mt-4 px-4 flex flex-col justify-center items-center">
        <div className="inline-block w-[80px] h-[80px] mb-6 flex justify-center items-center rounded-[50%] bg-badge-empty">
          <img src={empty_icon} alt="" />
        </div>
        <div className="text-center text-melrose text-[16px] leading-[24px]">
          {text}
        </div>
      </div>
    </div>
  );
};

export default LandingListEmpty;
