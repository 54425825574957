import classNames from 'classnames';
import regionTypes from '@constants/regionTypes';
import mobile_bonus_box_icon_ca from '@images/icons/mobile-bonus-box-icon-ca.png';
import {locale} from '@res/strings/locale';
import registration_bonus_small_image from '@images/registration-bonus-small-image.jpg';
import registration_bonus_small_image_fr from '@images/registration-bonus-small-image-fr.jpeg';
import React from 'react';
import LocalizationService from '@services/LocalizationService';

const RegistrationWelcomeBonus = () => {
  return (
    <div
      className={classNames(
        {
          'bg-registration-mobile-banner h-[88px]':
            window?.GlobalConfig?.region === regionTypes.CIS,
        },
        'block sm:hidden rounded-[8px] flex justify-evenly',
      )}>
      {window?.GlobalConfig?.region === regionTypes.CIS ? (
        <>
          <img
            src={mobile_bonus_box_icon_ca}
            className="registration-banner-image"
            alt={locale?.welcomeBonus}
          />
          <div className="flex flex-col self-center text-center">
            <p className="text-[12px] text-cerulean">{locale?.welcomeBonus}</p>
            <p className="text-[19px] text-white font-bold">
              {locale?.welcomeBonusReward}
            </p>
            <p className="text-[12px] text-white">
              {locale?.welcomeBonusMessage}
            </p>
          </div>
        </>
      ) : window?.GlobalConfig?.region === regionTypes.CANADA &&
        LocalizationService.getLanguage() !== 'fr' ? (
        <img src={registration_bonus_small_image} alt={locale?.welcomeBonus} />
      ) : (
        <img
          src={registration_bonus_small_image_fr}
          alt={locale?.welcomeBonus}
        />
      )}
    </div>
  );
};

export default RegistrationWelcomeBonus;
