import useOfferIcon from '@hooks/useOfferIcon';
import PropTypes from 'prop-types';

const CashBoxOfferItemIcon = ({data, offers}) => {
  return (
    <div
      className="flex items-center justify-center w-[80px] h-[80px] bg-no-repeat bg-center bg-cover"
      style={{
        backgroundImage: `url('${useOfferIcon(data, offers)}')`,
        backgroundSize: '90px',
      }}
    />
  );
};

CashBoxOfferItemIcon.propTypes = {
  data: PropTypes.any.isRequired,
  offers: PropTypes.any,
};

export default CashBoxOfferItemIcon;
