import CashBox from '@components/CashBox/CashBox';
import ModalWrapper from '@components/Modals/ModalWrapper';
import cashBoxConfig from '@constants/cashBoxConfig';
import useToggleState from '@hooks/useToggleState';
import React from 'react';

const CashBoxDepositModal = ({handleCloseModal}) => {
  const [isNextStep, toggleNextStep, resetNextStep] = useToggleState();

  return (
    <ModalWrapper
      desktopStyle={'sm:w-[380px] sm:h-[600px] sm:rounded-[8px]'}
      {...{handleCloseModal}}>
      <div className="flex flex-col sm:flex-row w-full h-full mt-[-1px]">
        <div className="w-full h-[100%] sm:h-auto bg-white">
          <CashBox
            {...{
              activeTab: cashBoxConfig.TAB_NAMES.DEPOSIT,
              toggleNextStep,
              isNextStep,
              resetNextStep,
            }}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CashBoxDepositModal;
