import BannerBaseWrapper from '@components/Banner/BannerBaseWrapper';
import BannerBlackoutWrapper from '@components/Banner/BannerBlackoutWrapper';
import PropTypes from 'prop-types';
import React from 'react';

const BannerWrapper = ({
  children,
  hasBlackout,
  hasWrapper,
  rounded,
  height = 'h-[418px] md:h-[320px]',
}) => {
  return (
    <>
      {hasBlackout ? (
        <BannerBlackoutWrapper {...{height, rounded}}>
          {children}
        </BannerBlackoutWrapper>
      ) : hasWrapper ? (
        <BannerBaseWrapper {...{height, rounded}}>{children}</BannerBaseWrapper>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

BannerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasWrapper: PropTypes.bool,
  hasBlackout: PropTypes.bool,
  height: PropTypes.string,
  rounded: PropTypes.string,
};

export default BannerWrapper;
