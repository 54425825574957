import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ToggleSwitchCheckbox = ({
  rest,
  id,
  labelClassName,
  labelText,
  onChange,
  value,
}) => {
  return (
    <>
      <div className="relative inline-block w-[25px] mr-2 align-middle select-none transition duration-500 ease-in">
        <input
          type="checkbox"
          checked={value}
          className="toggle-checkbox absolute left-[1px] top-[1px] block w-[13px] h-[13px] rounded-full bg-white appearance-none cursor-pointer"
          {...{id, onChange}}
          {...rest}
        />
        <label
          htmlFor={id}
          className="toggle-label block overflow-hidden h-[15px] rounded-[16px] bg-hawkesBlue cursor-pointer"
        />
      </div>
      {labelText && (
        <label
          htmlFor={id}
          className={classNames(labelClassName, 'cursor-pointer')}>
          {labelText}
        </label>
      )}
    </>
  );
};

ToggleSwitchCheckbox.propTypes = {
  rest: PropTypes.any,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
  labelText: PropTypes.string,
};

export default ToggleSwitchCheckbox;
