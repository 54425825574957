export default {
  TRANSLATION:
    'https://localestore.s3.ca-central-1.amazonaws.com/locale/{lang}.json',

  PUBLICATIONS_LIST: '/publications',
  PUBLICATIONS_LIST_COUNT: '/publications/count',
  PUBLICATION: '/publications/:slug',

  TOURNAMENTS_LIST: '/tournaments',
  TOURNAMENTS_LIST_COUNT: '/tournaments/count',
  TOURNAMENT: '/tournaments/:slug',

  LOTTERIES_LIST: '/lotteries',
  LOTTERIES_LIST_COUNT: '/lotteries/count',
  LOTTERY: '/lotteries/:slug',
  LOTTERY_ID: '/lotteries/id/:id',
  LOTTERY_TICKETS:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/lotteries/tickets',

  PAGE: '/page-:page',

  BANNERS: '/banners',
  GAMES: '/games',
  USER_FAVORITE_GAMES: '/games/favorite',
  RECENT_GAMES: '/games/recent',
  GAME: '/games/:slug',
  PROMOTIONS: '/promos',
  WINNERS: '/winners',
  CATEGORIES: '/categories',
  PROVIDERS: '/providers',
  PAYMENT_METHODS: '/payment-methods',

  OFFERS: window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/offers',
  OFFER_REJECT:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/offers/reject',
  WELCOME_OFFERS:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/offers/welcome-bonus',
  WELCOME_OFFERS_ALL:
    window.GlobalConfig?.endpoints?.BASE +
    '/gw/api/v1/offers/welcome-bonus/all',
  ACTIVATE_PROMO_CODE:
    window.GlobalConfig?.endpoints?.BASE +
    '/gw/api/v1/offers/promo-code/activate',
  VALIDATE_PROMO_CODE:
    window.GlobalConfig?.endpoints?.BASE +
    '/gw/api/v1/offers/promo-code/validate',
  WELCOME_OFFERS_REJECT:
    window.GlobalConfig?.endpoints?.BASE +
    '/gw/api/v1/offers/welcome-bonus/refuse',
  WELCOME_OFFERS_ACTIVATE:
    window.GlobalConfig?.endpoints?.BASE +
    '/gw/api/v1/offers/welcome-bonus/accept',
  TOURNAMENT_OFFERS_ACTIVATE:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/offers/activate',
  CASHBACK: window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/cashback',
  PROFILE: window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/profile',
  BALANCE: window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/profile/balance',
  SEND_CONFIRM_EMAIL:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/profile/verify-email',
  CONFIRM_EMAIL:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/profile/confirm-email/',
  SEND_PHONE_CONFIRMATION_MESSAGE:
    window.GlobalConfig?.endpoints?.BASE +
    '/gw/api/v1/profile/send-confirm-phone-message',
  CHECK_PHONE_CONFIRMATION_MESSAGE:
    window.GlobalConfig?.endpoints?.BASE +
    '/gw/api/v1/profile/check-confirm-phone-code',
  SEND_DOCUMENTS: window.GlobalConfig?.endpoints?.BASE + '/kyc/upload',
  GET_DOCUMENTS:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/profile/documents',
  GET_NOTIFICATIONS:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/profile/notifications',

  UNSUBSCRIBE_EMAIL:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/profile/unsubscribe',

  GET_TRANSACTIONS:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/transactions',

  SIGN_IN: window.GlobalConfig?.endpoints?.BASE + '/auth/api/authenticate',

  AUTOLOGIN: window.GlobalConfig?.endpoints?.BASE + '/email-token/token',

  REFRESH_TOKEN: window.GlobalConfig?.endpoints?.BASE + '/auth/api/refresh',
  SIGN_UP: window.GlobalConfig?.endpoints?.BASE + '/signup/api/signup',
  SIGN_UP_VALIDATE:
    window.GlobalConfig?.endpoints?.BASE + '/signup/api/signup/validate',

  CHANGE_PASSWORD:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/profile/change-password',

  RESET_PASSWORD_REQUEST:
    window.GlobalConfig?.endpoints?.BASE + '/auth/api/reset-password-request',
  RESET_PASSWORD_CONFIRM:
    window.GlobalConfig?.endpoints?.BASE + '/auth/api/reset-password/confirm',

  GET_PAYMENT_METHODS: window.GlobalConfig?.endpoints?.MERCHANT + '/cashbox',
  GET_PAYMENT_METHOD_OPTIONS:
    window.GlobalConfig?.endpoints?.MERCHANT + '/cashbox/item-options',
  CREATE_PAYMENT: window.GlobalConfig?.endpoints?.MERCHANT + '/pay',

  TRIPLE_TURN:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/profile/triple-turn',

  CANCEL_TRANSACTION:
    window.GlobalConfig?.endpoints?.BASE +
    '/gw/api/v1/cashbox/withdrawal-cancel',

  AUTOCOMPLETE: window?.GlobalConfig?.endpoints?.BASE + '/places/autocomplete',

  CHECK_BAN:
    window.GlobalConfig?.endpoints?.BASE + '/gw/api/v1/profile/check-ban',

  SOCIAL_SIGN_UP:
    window.GlobalConfig?.endpoints?.BASE + '/social-auth/api/signup',
  SOCIAL_SIGN_IN:
    window.GlobalConfig?.endpoints?.BASE + '/social-auth/api/signin',

  FAVORITE_GAMES: window.GlobalConfig?.endpoints?.BASE + '/favorite-games',

  INBOX_MESSAGES: window.GlobalConfig?.endpoints?.BASE + '/messages/graphql',
  POP_UP_NOTIFICATION: window.GlobalConfig?.endpoints?.BASE + '/popups/graphql',

  FLOATING_NOTIFICATION_SOCKET: '/floating-notifications/socket.io',
};
