import {locale} from '@res/strings/locale';
import formatAmount from '@utils/formatAmount';
import PropTypes from 'prop-types';

const CashBoxOfferItemBonusTitle = ({data, user}) => {
  return (
    <>
      {data?.max_amount_percent > 0
        ? `${data?.max_amount_percent}% ${
            locale?.OFFER?.bonusUpTo
          } ${formatAmount({
            amount: data?.max_amount / 100,
            currency: user?.profile?.currency,
          })}`
        : `${locale?.OFFER?.bonusIn} ${formatAmount({
            amount: data?.max_amount / 100,
            currency: user?.profile?.currency,
          })}`}
    </>
  );
};

CashBoxOfferItemBonusTitle.propTypes = {
  data: PropTypes.any,
  user: PropTypes.any,
};

export default CashBoxOfferItemBonusTitle;
