import brandTypes from '@constants/brandTypes';
import regionTypes from '@constants/regionTypes';
import testIds from '@constants/testIds';
import buildRoute from '@navigation/buildRoute';
import CustomLink from '@navigation/CustomLink';
import navigationRoutes from '@navigation/navigationRoutes';
import {locale} from '@res/strings/locale';
import React, {useMemo} from 'react';

const FooterMenu = () => {
  const footerMenu = useMemo(
    () => [
      {
        label: locale?.rules,
        url: buildRoute(navigationRoutes.TERMS_AND_CONDITIONS),
        display: true,
      },
      {
        label: locale?.payoutsPage,
        url: buildRoute(navigationRoutes.PAYOUTS),
        display: true,
      },
      {
        label: locale?.privacyPolicy,
        url: buildRoute(navigationRoutes.PRIVACY_POLICY),
        display: true,
      },
      {
        label: locale?.bonusPage,
        url: buildRoute(navigationRoutes.BONUS),
        display: true,
      },
      {
        label: locale?.withdrawalPage,
        url: buildRoute(navigationRoutes.WITHDRAWAL),
        display: window.GlobalConfig?.region === regionTypes.CIS,
      },
      {
        label: locale?.faq,
        url: buildRoute(navigationRoutes.FAQ),
        display: window.GlobalConfig?.region === regionTypes.CIS,
      },
      {
        label: locale?.responsibleGaming,
        url: buildRoute(navigationRoutes.RESPONSIBLE_GAMING),
        display: true,
      },
      {
        label: locale?.forPartners,
        url: locale?.REDIRECT_URLS?.partners,
        urlTestId: 'bananza',
        display: true,
      },
      {
        label: locale?.contactUs,
        url: buildRoute(navigationRoutes.CONTACTS),
        display: true,
      },
      {
        label: locale?.cookiesPolicy,
        url: buildRoute(navigationRoutes.COOKIES),
        display: true,
      },
      window.GlobalConfig?.brand === brandTypes.VOLTA && {
        label: locale?.antiMoneyLaundering,
        url: buildRoute(navigationRoutes.ANTI_MONEY_LAUNDERING),
        display: true,
      },

      /* {
        label: locale?.siteMap,
        url: locale?.REDIRECT_URLS?.main + locale?.REDIRECT_URLS?.sitemap,
        urlTestId: locale?.REDIRECT_URLS?.sitemap,
        display: true,
      },*/
    ],
    [locale],
  );

  const getNavigationItemName = (name) => {
    if (typeof name === 'string') {
      return name?.replace('/', '') || '';
    }
  };

  return (
    <div className="grid grid-flow-col grid-cols-3 grid-rows-3 gap-x-[40px]">
      {footerMenu?.length &&
        footerMenu?.map(
          (item, index) =>
            item?.display && (
              <React.Fragment key={index}>
                {item?.url === locale?.REDIRECT_URLS?.partners ? (
                  <a
                    href={item?.url ?? ''}
                    className="text-shipCove text-[14px] leading-[24px] mb-[5px]"
                    style={{order: index + 1}}
                    aria-label="click"
                    testid={`${
                      testIds.NAVIGATION_ITEMS
                    }-${getNavigationItemName(item?.urlTestId || item?.url)}`}
                    target="_blank">
                    {item?.label}
                  </a>
                ) : (
                  <CustomLink
                    testid={`${
                      testIds.NAVIGATION_ITEMS
                    }-${getNavigationItemName(item?.urlTestId || item?.url)}`}
                    aria-label="click"
                    to={item?.url ?? ''}
                    className="text-shipCove text-[14px] leading-[24px] mb-[5px]"
                    style={{order: index + 1}}>
                    {item?.label}
                  </CustomLink>
                )}
              </React.Fragment>
            ),
        )}
    </div>
  );
};

export default FooterMenu;
