import phone_selector_icon from '@images/icons/phone-selector-icon.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {components} from 'react-select';
import CreatableSelect from 'react-select/creatable';

const ReactCreatableSelect = ({
  options,
  className,
  placeholder,
  value,
  onChange,
  isSearchable = false,
  ...rest
}) => {
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img
            src={phone_selector_icon}
            className={classNames(
              {
                'rotate-[180deg]': props.selectProps.menuIsOpen,
                'rotate-0': !props.selectProps.menuIsOpen,
              },
              'transform',
            )}
            alt=""
          />
        </components.DropdownIndicator>
      )
    );
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#EDF3FE',
      height: '40px',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      opacity: 1,
      padding: '0px 5px',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#9CB0D5',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#002F88',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    menu: (styles) => ({
      ...styles,
      padding: '0px 4px',
    }),
    option: (styles, {isSelected}) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#EDF3FE' : '#ffffff',
        color: '#002F88',
        borderRadius: '5px',
        ':hover': {
          backgroundColor: '#EDF3FE',
        },
      };
    },
  };

  return (
    <CreatableSelect
      isClearable
      components={{DropdownIndicator, ClearIndicator: () => null}}
      className={classNames(
        className,
        'w-full rounded-[5px] text-resolutionBlue placeholder-poloBlue text-[14px]',
      )}
      styles={colourStyles}
      {...{options, placeholder, onChange, value, isSearchable}}
      {...rest}
    />
  );
};

ReactCreatableSelect.propTypes = {
  options: PropTypes.array,
  classNames: PropTypes.string,
  rest: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
export default ReactCreatableSelect;
