import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';

const BannerPromoDescription = ({
  children,
  className = 'flex flex-col items-center justify-end md:justify-center pb-[32px] md:pb-0',
  wrapperClassName = 'justify-center md:justify-end',
}) => {
  return (
    <div className={classNames('flex w-full h-full', wrapperClassName)}>
      <div
        className={classNames(
          'max-w-full min-w-[262px] w-[396px] px-10 text-center',
          className,
        )}>
        <div>{children}</div>
      </div>
    </div>
  );
};

BannerPromoDescription.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
};

export default BannerPromoDescription;
